import React from 'react';
import styled from 'styled-components/macro';
import colors from 'styles/colors';

export const Toggle = ({ labelOff, labelOn, onClick, active, disabled }) => {
  return (
    <ToggleContainer onClick={onClick} disabled={disabled}>
      <ToggleLabel highlight={!active}>{labelOff}</ToggleLabel>
      <ToggleSwitcher active={active}><span></span></ToggleSwitcher>
      <ToggleLabel highlight={active}>{labelOn}</ToggleLabel>
    </ToggleContainer>
  )
}

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
  cursor: pointer;
  opacity: ${props => props.disabled ? "0.6" : "1"};
`

export const ToggleLabel = styled.span`
  cursor: pointer;
  font-family: 'Raylo-Bold', Arial, sans-serif;
  font-weight: bold;
  font-size: 0.875em;
  color: ${colors.rayloLight};
  opacity: ${props => props.highlight ? "1" : "0.6"};
  transition: opacity .2s;
`

export const ToggleSwitcher = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 12px;
  padding: 0 5px;
  width: 47px;
  height: 25px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 13.12px;
  cursor: pointer;
  transition: justify-content .25s;

  span {
    display: inline-block;
    width: 17px;
    height: 17px;
    border-radius: 100%;
    background: #FFFFFF;
    transform: ${props => props.active ? 'translateX(100%)' : 'translateX(0)'};
    transition: transform .2s ease-in-out;
  }
`