import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useScript } from 'utils/useScript'
import { activateOptimize } from 'utils/optimize'

// HOOKS
import { useMutation } from '@apollo/client';

// STATE
import { useUiContext } from 'utils/useUiContext';

// GRAPHQL
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';

// VISUAL COMPONENTS
import * as L from 'styles/layout';
import * as T from 'styles/type';
import * as I from 'styles/inputs';
import { Button } from 'components/common/Button';
import { Modal } from 'components/common/Modal';
import { BackButton } from 'components/common/BackButton';
import { TextInputWithLoader } from 'components/common/TextInputWithLoader';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { RoyalMail } from 'screens/Feedback/RoyalMail'
import useRayloQuery from 'utils/useRayloQuery'
import { useHistory } from 'react-router';
import { captureErrorEvent } from 'utils/errorTracking'
import {useSegmentIdentifyAndCheckoutTracking} from 'hooks/segment/segment.hooks'

// LOQATE MAPPING
const fields = [ { element: "search", field: "" } ];
const options = { key: process.env.REACT_APP_LOQATE_KEY, search: { countries: "GBR" } }

const FORMSTATE_UNKNOWN = 0
const FORMSTATE_ADDRESS = 1
const FORMSTATE_COUNTRY = 2

export const StepAddressCheck = ({onBack, onSuccess, checkoutToken, slug}) => {
  useSegmentIdentifyAndCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'your details',
    checkoutScreen: 'add previous address',
    when: 'on page load'
  });

  const [scriptLoaded, scriptError] = useScript(
    'https://services.postcodeanywhere.co.uk/js/address-3.91.js'
  );
  // HOOKS
  const history = useHistory()
  const [control, setControl] = useState();
  const [addressType, setAddressType] = useState();
  const [missingAddress, setMissingAddress] = useState(false);
  const [address, setAddress] = useState({})
  const [previousAddress, setPreviousAddress] = useState({})
  const [updateMutation] = useMutation(CheckoutSteps.stepAddressCheck.mutation, {
    update: (proxy, {data: {updateCheckout: { errors }}}) => {
      if(errors && errors.length > 0) {
        setIsSubmitting(false)
        setFormErrors(_.filter(errors, {field: "base"}))
      } else {
        setShowFields(false)
        onSuccess()
      }
    }
  })
  const { setShowFeedback } = useUiContext()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [showFields, setShowFields] = useState(true)
  const [formErrors, setFormErrors] = useState(null)

  const [formState, setFormState] = useState(FORMSTATE_UNKNOWN)

  const { data: { checkout }, loading } = useRayloQuery(CheckoutSteps.stepAddressCheck.query, {
    variables: {
      token: checkoutToken
    }
  })

  function handleChange(e) {
    const fs = window.location.pathname.split("/")[4]
    if(fs === 'check') {
      setFormState(FORMSTATE_ADDRESS)
    } else if (fs === 'previous') {
      setFormState(FORMSTATE_COUNTRY)
    } else {
      setFormState(FORMSTATE_UNKNOWN)
    }
  }

  useEffect(() => {
    activateOptimize()
  })

  useEffect(() => {
    window.addEventListener('popstate', handleChange)
    return () => window.removeEventListener('popstate', handleChange)
  }, [])

  const updateFormStateAndSlug = (newState) => {
    setFormState(newState)
    if (newState === FORMSTATE_ADDRESS) {
      history.push(`/checkout/step/${slug}/check`)
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'checkout-step',
        'step': slug,
        'substep': 'check'
      })
    } else if (newState === FORMSTATE_COUNTRY) {
      history.push(`/checkout/step/${slug}/previous`)
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'checkout-step',
        'step': slug,
        'substep': 'previous'
      })
    } else if (newState === FORMSTATE_UNKNOWN) {
      history.push(`/checkout/step/${slug}`)
    }
  }


  // SETUP LOQATE
  useEffect(() => {
    if(!missingAddress && !loading && formState === FORMSTATE_COUNTRY && scriptLoaded && !scriptError) {
      if(window?.pca?.Address) {
        setControl(new window.pca.Address(fields, options))
      }
    }
  }, [missingAddress, loading, formState, scriptLoaded, scriptError])

  useEffect(() => {
    if(scriptError) {
      captureErrorEvent("PCA Address: failure", "StepAddressCheck")
      window.location = '/errors/oops'
    }
  }, [scriptError])

  useEffect(() => {
    if(control && !missingAddress) {
      control.listen("populate", (address, variations) => {
        setAddressType(address?.Type)
        setPreviousAddress({
          line1: address?.Line1,
          line2: address?.Line2,
          line3: address?.Line3,
          city: address?.City,
          region: address?.Province,
          postcode: address?.PostalCode,
          countryIsoAlpha2: address?.CountryIso2
        })
        setFormErrors(null)
        setIsSearching(false)
      })
      control.listen("search", (args) => {
        setIsSearching(!isSearching)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control, missingAddress,scriptLoaded])

  useEffect(() => {

    if(checkout && checkout.address) {
      setAddress(_.pick(checkout.address,'line1','line2','line3','city','county','postcode'))
    }    
    if(checkout && checkout.previousAddress) {
      setPreviousAddress(_.pick(checkout.previousAddress,'line1','line2','line3','city','county','postcode'))
    }
  }, [checkout])

  const onContinue = (recentChangeOfAddress, recentChangeOfCountry) => {
    setIsSubmitting(true)
    setFormErrors(null)
    updateMutation({
      variables: {
        recentChangeOfAddress,
        recentChangeOfCountry,
        checkoutToken: checkout.token
      }
    })
    return false;
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    setFormErrors(null)
    updateMutation({
      variables: {
        ...previousAddress,
        recentChangeOfAddress: true,
        recentChangeOfCountry: false,
        checkoutToken: checkout.token
      },
      update: (proxy, {data: {updateCheckout: { errors }}}) => {
        if(errors && errors.length > 0) {
          setIsSubmitting(false)
          setFormErrors(_.filter(errors, {field: "base"}))
        } else {
          setShowFields(false)
          onSuccess()
        }
      }
    })
    return false;
  }

  const reset = () => {
    setMissingAddress(false);
    setShowFeedback(false);
  }


  useEffect(() => {
    if(missingAddress) {
      setShowFeedback(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missingAddress])

  if(loading || !scriptLoaded || scriptError) return (<Modal visible text="Loading..." />)

  if(missingAddress) {
    return (<RoyalMail onBack={reset} />);
  }

  const addressFound = previousAddress && previousAddress.line1;
  const nonResidential = addressType && addressType !== 'Residential';



  return (
    <div className="checkout--step">
      <FadeIn visible={showFields}>
        <T.RayloSectionHeader>{formState === FORMSTATE_UNKNOWN ? "Your Address" : "Previous Address"}</T.RayloSectionHeader>
      </FadeIn>

      <form onSubmit={onSubmit}>

        {formErrors && formErrors.length > 0 ?
          formErrors.map(error => 
            <FadeIn visible={showFields}><div style={{marginBottom: 48}}>
              <I.RayloError visible={true}>
                {error.message || <span>&nbsp;</span>}
              </I.RayloError></div>
            </FadeIn>)
          : null
        }

        {formState === FORMSTATE_UNKNOWN ?

          <div>
            <SlideDownFadeIn visible={showFields}>
              <I.RayloFormRow>
                <I.RayloFieldHeader>
                  <I.RayloFieldLabel isFocused={true}>Delivery & Billing Address</I.RayloFieldLabel>
                </I.RayloFieldHeader>
                <I.RayloFieldContent>
                  {_.filter([address?.line1,address?.line2,address?.line3,address?.city,address?.region,address?.postcode], (o) => (o && o.length > 1)).map((o, index) => <T.RayloAddress key={index}>{o}<br/></T.RayloAddress>)}
                </I.RayloFieldContent>
              </I.RayloFormRow>
            </SlideDownFadeIn>

            <SlideDownFadeIn visible={showFields}>
                <I.RayloFormRow style={{marginTop: 48, marginBottom: 48}}>
                  <I.RayloFieldHeader>
                    <I.RayloFieldLabel isFocused={true}>Have you lived at this address for more than 3 years?</I.RayloFieldLabel>
                  </I.RayloFieldHeader>
                  <L.SignupFeedbackActions>
                    <div>
                      <Button
                        buttonStyle="secondary"
                        limitWidth="true"
                        onClick={() => updateFormStateAndSlug(FORMSTATE_ADDRESS) }>No</Button>
                    </div>
                    <div>
                      <Button
                        buttonStyle="secondary"
                        limitWidth="true"
                        onClick={() => onContinue(false, null) }>Yes</Button>
                    </div>
                  </L.SignupFeedbackActions>
                </I.RayloFormRow>
            </SlideDownFadeIn>
          </div>

        : formState === FORMSTATE_ADDRESS ? 

          <SlideDownFadeIn visible={showFields}>
              <I.RayloFormRow style={{marginTop: 48, marginBottom: 48}}>
                <I.RayloFieldHeader>
                  <I.RayloFieldLabel isFocused={true}>Was your previous address in the UK?</I.RayloFieldLabel>
                </I.RayloFieldHeader>
                <L.SignupFeedbackActions>
                  <div>
                    <Button
                      buttonStyle="secondary"
                      limitWidth="true"
                      onClick={() => onContinue(true, true) }>No</Button>
                  </div>
                  <div>
                    <Button
                      buttonStyle="secondary"
                      limitWidth="true"
                      onClick = {
                        () => updateFormStateAndSlug(FORMSTATE_COUNTRY)
                      } > Yes </Button>
                  </div>
                </L.SignupFeedbackActions>
              </I.RayloFormRow>
          </SlideDownFadeIn>

        : formState === FORMSTATE_COUNTRY ?

          <div>

            <SlideDownFadeIn visible={showFields}>
              <TextInputWithLoader
                field={{
                  id: "search",
                  error: nonResidential ? 1 : null
                }}
                label="Your Previous Address"
                placeholder="Start typing to search..."
                errorMessage="Unfortunately we can only accept residential addresses."
                searching={isSearching}
                />
            </SlideDownFadeIn>

            {addressFound && !nonResidential &&
              <SlideDownFadeIn visible={showFields}>
                <I.RayloFormRow>
                  <I.RayloFieldHeader>
                    <I.RayloFieldLabel isFocused={true}>Is this address correct?</I.RayloFieldLabel>
                  </I.RayloFieldHeader>
                  <I.RayloFieldContent>
                    {_.filter([previousAddress.line1,previousAddress.line2,previousAddress.line3,previousAddress.city,previousAddress.region,previousAddress.postcode], (o) => (o && o.length > 1)).map((o, index) => <T.RayloAddress key={index}>{o}<br/></T.RayloAddress>)}
                  </I.RayloFieldContent>
                </I.RayloFormRow>
              </SlideDownFadeIn>
            }

            <SlideDownFadeIn visible={showFields}>
              <L.SignupAddressActions>
                <div>
                  <Button
                    buttonStyle="secondary"
                    limitWidth="true"
                    onClick={(e) => onContinue(true, true) }>I can't find my address</Button>
                </div>

                {addressFound && !nonResidential &&
                  <div>
                    <Button
                      buttonStyle="primaryBlue"
                      disabled={isSubmitting}
                      type='submit'>Yes</Button>
                  </div>
                }
              </L.SignupAddressActions>
            </SlideDownFadeIn>

          </div>

        : null

        }

        <SlideDownFadeIn visible={showFields}>
          <div>
            <BackButton onClick={onBack} />
          </div>
        </SlideDownFadeIn>
      </form>
    </div>
  )
}
