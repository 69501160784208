import ContentSection from 'components/ContentSection';
import RayloPayModal from 'components/RayloPayModal';
import {
  IAfterLeaseChoices,
  useCheckoutContentContext,
} from 'screens/Checkout/containers/CheckoutStepsContainer/hooks/useCheckoutContentContext';
import { useUiContext } from 'utils/useUiContext';
import { StyledAfterLeaseChoiceRPModal } from './styles';
import { IAfterLeaseChoiceRPModal } from './types';

export const AfterLeaseChoiceRPModal = ({
  dataTestId,
}: IAfterLeaseChoiceRPModal) => {
  const { setShowRPAfterLeaseModal } = useUiContext();
  const { afterLeaseChoices } = useCheckoutContentContext();
  return (
    <StyledAfterLeaseChoiceRPModal data-testid={dataTestId}>
      <RayloPayModal
        fullPage
        header="Your choices after lease term"
        onClose={() => setShowRPAfterLeaseModal(false)}
      >
        {afterLeaseChoices?.map(({ title, copy }: IAfterLeaseChoices) => (
          <ContentSection title={title} content={copy} key={title} />
        ))}
      </RayloPayModal>
    </StyledAfterLeaseChoiceRPModal>
  );
};
