import gql from 'graphql-tag';

export const PROCESS_INCOMING_PAYMENT_MUTATION = gql`
  mutation ($incomingPaymentId: ID!, $checkoutToken: String) {
    processIncomingPayment(
      incomingPaymentId: $incomingPaymentId
      checkoutToken: $checkoutToken
    ) {
      incomingPayment {
        id
        state
      }
    }
  }
`;
