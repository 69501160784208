import FormCollection from 'components/FormCollection';
import { useEffect, useState } from 'react';
import { useStepNameEmailContext } from 'screens/Checkout/containers/StepNameEmail/hooks/useStepNameEmailContext';
import { INameEmailValues } from './types';

const fields = [
  {
    field: 'firstName',
    name: 'First Name',
    type: 'text',
    required: true,
  },
  {
    field: 'lastName',
    name: 'Last Name',
    type: 'text',
    required: true,
  },
  {
    field: 'email',
    name: 'Email address',
    type: 'email',
    required: true,
  },
];

interface IForm {
  onSubmit: (values: INameEmailValues) => void;
}

export const FormComponent = ({ onSubmit }: IForm) => {
  const [presetValueData, setPresetValueData] = useState<INameEmailValues>();
  const { presetValues } = useStepNameEmailContext();

  useEffect(() => {
    setPresetValueData(presetValues);
  }, [presetValues]);

  return (
    <FormCollection
      fields={fields}
      handleSubmit={onSubmit}
      formId="nameEmail"
      presetValues={presetValueData}
    />
  );
};
