
import gql from 'graphql-tag';
import { Checkout } from 'screens/Checkout/graphQL/fragments';

export const QL_INTERCOM = gql`
  query AccountIntercomQuery {
  customer {
    id
    email
    messagingDetails {
      intercom {
        appId
        userHash
        userId
      }
    }
  }
}`

export const MU_BLOCK = gql`
mutation AccountCreateBlockRequestMutation(
  $assetId: ID!
) {
  createBlockRequest(
    assetId: $assetId
  ) {
    asset {
      id
      blockState
    }
  }
}`

export const MU_CLAIM = gql`
  mutation AccountCreateClaimMutation(
    $insurancePolicyId: ID!,
    $reason: InsuranceClaimReasonType!
  ) {
    createInsuranceClaim(
      input: {
        insurancePolicyId: $insurancePolicyId,
        reason: $reason
      }
    ) {
      insuranceClaim {
        id
      }
      errors {
        code
        field
        message
      }
    }
}`

export const QL_CLAIM = gql`
  query AccountClaimQuery {
  customer {
    id
    orders {
      id
      items {
        id
        subscription {
          id
          insurancePolicy {
            id
            claimUrl
            claims {
              id
              state
              reason {
                enum
              }
            }
          }
          activeAsset {
            id
            blockState
          }
        }
      }
    }
  }
}`

export const QL_ASSET = gql`
  query AccountAssetQuery {
  customer {
    id
    orders {
      id
      items {
        id
        pricePlan {
          id
          includesInsurance
        }
        subscription {
          id
          activeAsset {
            id
            imeiNumber
            blockState
          }
        }
      }
    }
  }
}`

export const QL_IMEI = gql`
  query AccountImeiQuery {
  customer {
    id
    orders {
      id
      items {
        id
        subscription {
          id
          activeAsset {
            id
            imeiNumber
          }
        }
      }
    }
  }
}`

export const QL_INSURANCE = gql`
  query AccountInsuranceQuery {
  customer {
    id,
    orders {
      id,
      items {
        id,

        pricePlan {
          id,
          includesInsurance
        },
        subscription {
          id
          insurancePolicy {
            id
            claimUrl
            claims {
              createdAt
              externalReference
              id
              reason {
                displayName
                enum
              }
              state
              updatedAt
            }
          }
        }
        fulfillmentItem {
          id,
          dispatchedAt
        }
      }
    }
  }
}`

export const QL_CUSTOMER = gql`
  query AccountCustomerQuery {
  customer {
    id,
    firstName,
    lastName,
    email,
    subscriptions {
      id
      upgrade {
        checkout {
          token
        }
      }
    }
    address {
      id
      line1
      line2
      line3
      postcode
      region
      city
      country {
        id
        name
      }
    }
    preApproval {
      successful
      checkout {
        decision {
          outcome
        }
        token
        costSummary {
          recurring {
            totalAmount {
              valueAfterTax {
                value
              }
            }
          }
        }
        items {
          variant {
            displayName
            images {
              url
              tag
            }
            optionValues {
              name
              displayName
              optionType {
                slug
              }
            }
          }
        }
      }
    }
    orders {
      id
      state
      items {
        id
        subscription {
          id
          state
          createdAt
          upgrade {
            dueOn
            eligible
            checkout {
              id
              token
              state
            }
          }
          insurancePolicy {
            id
            claimUrl
            claims {
              createdAt
              externalReference
              id
              reason {
                displayName
                enum
              }
              state
              updatedAt
            }
          }

          activeAsset {
            id
            imeiNumber
            serialNumber
            blockState
          }
          arrearsAmount {
            currencyCode
            currencySymbol
            formattedValue
            valueInSubunit
          }
          startedOn
          lastScheduledPaymentOn
          nextPaymentScheduleEvent {
            amount {
              currencyCode
              currencySymbol
              formattedValue
              valueInSubunit
              value
            }
            paymentOn
          },
          paymentInfo {
            bankAccountName
            bankAccountNumber
            bankBranchIdentifier
          },
          paymentSchedule {
            amount {
              currencyCode
              currencySymbol
              formattedValue
              valueInSubunit
              value
            }
            paymentOn
          }
          transactions {
            amountExcludingTax {
              currencyCode
              currencySymbol
              formattedValue
              valueInSubunit
              value
            }
            amountIncludingTax {
              currencyCode
              currencySymbol
              formattedValue
              valueInSubunit
              value
            }
            description
            happenedAt
          }
        }
        fulfillmentItem {
          id,
          deliveredAt,
          dispatchedAt,
          expectedDeliveryAt,
          trackingUrl,
          trackingReference,
          courierName
        },
        timeline {
          happenedAt
          location
          message
          state
          stateDescription
          stateDetail
        }
        deliveryExpectedBetween {
          max
          min
        }
        deliveryEstimatedBetween {
          max
          min
        }
        costSummary {
          recurring {
            totalAmount {
              valueAfterTax {
                value
                currencyCode
              }
            }
          }
          initial {
            totalAmount {
              valueAfterTax {
                value
                currencyCode
              }
            }
          }
        }
        pricePlan {
          id,
          name,
          currencyCode,
          includesInsurance

          costSummary {
            initial {
              totalAmount {
                valueAfterTax {
                  value
                  currencyCode
                }
              }
            }
            recurring {
              totalAmount {
                valueAfterTax {
                  value
                  currencyCode
                }
              }
            }
          }
        },
        variant {
          id,
          displayName,
          optionValues {
            id,
            displayName,
            optionType {
              id,
              slug
            }
          }
          product {
            id,
            displayName
            preOrder
            releasedAt
            category {
              id
              displayName
            }
          },
          images {
            alt,
            position,
            tag,
            title
            url
          }
        }
      }
    }
  }
}`




export const QL_CUSTOMER_SIMPLE = gql`
  query AccountSimpleCustomerQuery {
  customer {
    id,
    firstName,
    lastName,
    email,
    address {
      id
      line1
      line2
      line3
      postcode
      region
      city
      country {
        id
        name
      }
    }
    orders {
      id
      items {
        id
        pricePlan {
          id
          includesInsurance
        }
      }
    }
  }
}`


export const QL_REFERRALS = gql `
  query AccountReferralsQuery {
  customer {
    id
    referralInfo {
      url
      successfulReferralCount
      pendingReferralCount
      unsuccessfulReferralCount
      externalReference
      referrals {
        id
        rewardAvailableAt
        name
        state {
          displayName
          enum
        }
      }
    }    
  }
}`

export const QL_REFERRAL_PROGRAMS = gql`
  query AccountReferralProgramsQuery {
  referralProgram {
    id
    reward {
      id
      imageUrl
      referralDescription
    }    
  }
}`
