import _ from 'lodash';
import { useEffect, useState } from 'react';
import { SlideDownFadeIn } from 'components/animations/Transitions';
import BackArrow from 'components/BackArrow';
import RadioButtonBoxGroup from 'components/RadioButtonBoxGroup';
import { ButtonTypes } from 'elements/Button/types';
import Container from 'elements/Container';
import Button from 'elements/Button';
import Copy from 'elements/Copy';
import Header from 'elements/Header';
import SelectComponent from 'elements/SelectComponent';
import Spacer from 'elements/Spacer';
import { useStepTradeInContext } from 'screens/Checkout/containers/StepTradeIn/hooks/useStepTradeInContext';
import { primaryColors } from 'styles/variables/colors';
import { StyledTradeInJourneyStepOne } from './styles';
import { ITradeInJourneyStepOne } from './types';

export const TradeInJourneyStepOne = ({
  dataTestId,
}: ITradeInJourneyStepOne) => {
  const [productValue, setProductValue] = useState<any>();
  const [variantValue, setVariantValue] = useState<any>();
  const [index, setIndex] = useState<integer>();
  const {
    manufacturerId,
    manufacturerOptions,
    modelOptions,
    modelId,
    sizeOptions,
    variantId,
    setStepIndex,
    setTradeInAccepted,
    handleManufacturerOnChange,
    handleProductSelect,
    handleVariantSelect,
  } = useStepTradeInContext();

  useEffect(() => {
    setIndex(_.findIndex(manufacturerOptions, { id: manufacturerId }));
  }, [manufacturerId, manufacturerOptions]);

  useEffect(() => {
    setProductValue(null);
  }, [manufacturerId, modelOptions]);

  useEffect(() => {
    setProductValue(
      modelOptions?.find((option: any) => option?.value === modelId),
    );
    setVariantValue(null);
  }, [modelId]);

  useEffect(() => {
    setVariantValue(
      sizeOptions?.find((option: any) => option?.value === variantId),
    );
  }, [variantId]);

  const subCopy = (
    <Copy color={primaryColors.c01}>
      On your iPhone, go to <strong>Settings {'>'} Your Name</strong> and scroll
      down to see the model
    </Copy>
  );
  const subCopy2 = (
    <Copy color={primaryColors.c01}>
      On your iPhone, go to{' '}
      <strong>
        Settings {'>'} General {'>'} About
      </strong>{' '}
    </Copy>
  );

  return (
    <StyledTradeInJourneyStepOne data-testid={dataTestId}>
      <BackArrow onClick={() => setTradeInAccepted(false)} />
      <Header level={2} color={primaryColors.c01}>
        Great. Let&apos;s get going
      </Header>
      <Spacer height={24} />
      <Container noPadding>
        <RadioButtonBoxGroup
          options={manufacturerOptions}
          label="What brand of phone do you have?"
          initialActiveIndex={index}
          onChange={handleManufacturerOnChange}
          flexRow
        />
      </Container>
      <Spacer height={40} />
      <SelectComponent
        label="What model do you have?"
        subCopy={subCopy}
        options={modelOptions}
        value={productValue}
        onChange={handleProductSelect}
      />
      {!!modelId && (
        <SlideDownFadeIn>
          <>
            <Spacer height={24} />
            <SelectComponent
              label="What’s your phone’s storage capacity?"
              subCopy={subCopy2}
              options={sizeOptions}
              value={variantValue}
              onChange={handleVariantSelect}
            />
          </>
        </SlideDownFadeIn>
      )}
      <Spacer height={24} />
      <Button
        size="large"
        buttonColor={ButtonTypes.b01}
        onClick={() => setStepIndex(1)}
        disabled={!variantId}
        buttonType="solid"
        text="Next"
        fullWidth
      />
    </StyledTradeInJourneyStepOne>
  );
};
