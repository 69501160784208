import store from "store"
import { isAdditonalTech } from "./handleGetSessionStorageValues"

export const resetUserToken = () => {
  // Remove UserToken for now until new UI provides logout functionality
  const tempUserToken = store.get('userToken')
  if (tempUserToken) {
    window?.sessionStorage?.clear()
    store.remove('userToken')
  }
}
