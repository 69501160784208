import {
  StyledBackArrowHeader,
  Wrapper,
} from 'components/BackArrowLink/styles';
import Container from 'elements/Container';
import Header from 'elements/Header';
import Icon from 'elements/Icon';
import { Link } from 'react-router-dom';
import { primaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { IBackArrowLink } from './types';

export const BackArrowLink = ({
  linkLocation,
  text,
  dataTestId,
  styles,
}: IBackArrowLink) => {
  return (
    <Wrapper>
      <Link to={linkLocation}>
        <StyledBackArrowHeader data-testid={dataTestId} styles={styles}>
          <Container width="24px" center padding="4px 0 0 0">
            <Icon
              name="BackArrow"
              color={primaryColors.b01}
              size={`${px2Rem(30)}`}
            />
          </Container>
          <Container justifyCenter>
            <Header color={primaryColors.b01} level={3} fontSize={18}>
              {text}
            </Header>
          </Container>
        </StyledBackArrowHeader>
      </Link>
    </Wrapper>
  );
};
