/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { CurrentLease } from 'components/Upgrade/CurrentLease';
import useRayloQuery from 'utils/useRayloQuery';
import { QL_HEADER } from 'screens/Upgrade/queries';
import _ from 'lodash'
import { useParams, useHistory } from 'react-router';
import { RecommendedPhones } from 'components/Upgrade/RecommendedPhones';
import { AllPhones } from 'components/Upgrade/AllPhones';
import { Modal } from 'components/common/Modal';

import 'styles/upgrade-subscription.css';

import iconArrowLeft from 'public/icon--arrow--pink--left.svg'
import { removeSessionStorage } from 'utils/handleSessionStorage';
import Header from 'elements/Header';
import { primaryColors } from 'styles/variables/colors';

export const SubscriptionUpgrades = () => {
  const { subscriptionId } = useParams()
  const history = useHistory()
  const { data: { customer }, loading } = useRayloQuery(QL_HEADER)
  const [phone, setPhone] = useState()
  const [products, setProducts] = useState([])

  useEffect(() => {
    removeSessionStorage('additionalTech')
  }, [])

  useEffect(() => {

    if (customer && customer.orders) {
      const items = _.filter(_.flatten(_.map(customer.orders, 'items')), o => o.subscription && o.subscription.id === subscriptionId)
      const item = _.first(items)
      setPhone(item?.variant?.displayName)
      setProducts(item?.subscription?.upgrade?.recommendedProducts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  const backToAccount = (event) => {
    event.preventDefault();
    history.push("/account")
  }


  if(loading || !phone) return (<Modal visible text="Loading..." />)

  return (

    <div className="upgrade-subscription">

      <div className="upgrade-subscription__header">
        <div className="upgrade-subscription__container">
          <div className="upgrade-subscription__left">
            <a href="#" onClick={backToAccount} className="upgrade-subscription__back"><img src={iconArrowLeft} alt="Arrow left icon" /> Account Overview</a>
            <p className="upgrade-subscription__phone">Choose your FREE upgrade</p>
            {products.length > 2 && <p className="upgrade-subscription__intro">Here are your top picks:</p>}
          </div>
          <div className="upgrade-subscription__right">
            <CurrentLease />
          </div>
        </div>
      </div>
      <AllPhones subscriptionId={subscriptionId} />      
    </div>
  )
}
