import styled from 'styled-components';

const StyledPhoneFeatureCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const FeatureColumn = styled.div<{ basis?: number }>`
  ${({ basis }) => basis && `flex-basis: ${basis}%`}
`;
export { StyledPhoneFeatureCard };
