import { Container, Copy, Spacer } from 'elements';
import Divider from 'elements/Divider';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { StyledRayloPayCheckoutFooter } from './styles';
import { IRayloPayCheckoutFooter } from './types';

export const RayloPayCheckoutFooter = ({
  dataTestId,
}: IRayloPayCheckoutFooter) => {
  return (
    <StyledRayloPayCheckoutFooter data-testid={dataTestId}>
      <Divider color={secondaryColors.c04} />
      <Spacer height={16} />
      <Container noPadding>
        <Copy color={primaryColors.c01} fontSize={12} dataTestId="footerCopy">
          © Copyright of Raylo Group {new Date().getFullYear()}. All rights
          reserved.
        </Copy>
      </Container>
    </StyledRayloPayCheckoutFooter>
  );
};
