import React from 'react';
import { Button } from 'components/common/Button';
import { BackButton } from 'components/common/BackButton';
import * as B from 'styles/buttons';

export const BackForwardGroup = ({submit, onBack, buttonStyle = "primaryBlue", buttonText = "Next"}) => (
  <B.BackForwardContainer>
    <div style={{ marginRight: 40, height: '100%' }}>
      <BackButton onClick={onBack} />
    </div>

    <div style={{ height: '100%' }}>
      <Button
        buttonStyle={buttonStyle}
        style={{ height: '44px' }}
        dataTestId={`${buttonText}ScreenButton`}
        {...submit}>
          {buttonText}
        </Button>
    </div>
  </B.BackForwardContainer>
)
