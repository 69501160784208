import RayloModal from 'components/RayloModal';
import { Spacer } from 'elements';
import { StyledRayloPayModal } from './styles';
import { IRayloPayModal } from './types';

export const RayloPayModal = ({
  dataTestId,
  children,
  header,
  onClose,
  hideLogo,
  fullPage,
}: IRayloPayModal) => {
  return (
    <StyledRayloPayModal data-testid={dataTestId}>
      <RayloModal
        header={header}
        onClose={onClose}
        rayloPay
        hideLogo={hideLogo}
        styles={{
          minHeight: fullPage ? '100vh' : 'initial',
          width: fullPage ? '100vw' : '90vw',
        }}
      >
        {children}
        <Spacer height={24} />
      </RayloModal>
    </StyledRayloPayModal>
  );
};
