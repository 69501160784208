/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

import 'styles/certified.css';
import cx from 'classnames';

import iconShieldPhone from "public/icon--modal-shield-phone.svg";
import iconPhoneCheck from "public/icon--phone-check.svg";
import iconWallet from "public/icon--wallet.svg";
import iconPhoneCharger from "public/icon--phone-charger.svg";
import iconPhoneDoubleCamera from "public/icon--phone-double-camera.svg";
import iconScreenProtector from "public/icon--screen-protector.svg";

export const CertifiedRefurbished = () => {

  const [tab, setTab] = useState(1)

  return (
    <div className="certified">
      <div className="certified__container">
        <div className="certified__left">
          <h3>Why Raylo Certified Refurbished?</h3>
          <div className="certified__tabs">
            <a onClick={() => setTab(1)} className={cx({active: tab === 1})}>Raylo 24 month warranty</a>
            <a onClick={() => setTab(2)} className={cx({active: tab === 2})}>Raylo 70-point check</a>
            <a onClick={() => setTab(3)} className={cx({active: tab === 3})}>Brand new accessories</a>
          </div>
        </div>
        <div className="certified__right">
          {tab === 1 && 
          <div className="certified__tab">
            <div className="certified__tab--inner">
              <div className="certified__tab--header">
                <img src={iconShieldPhone} alt="certification icon"/>
                <h4>Raylo 24 month warranty</h4>
              </div>
              <div className="certified__text"><p>Every Raylo device comes with a 24 month warranty whilst you’re on a Raylo subscription. If your device fails (unless it is damaged by you, another person or by accident) to meet our standards during that period, we’ll arrange for the device to be repaired or replaced at no additional cost. More information on warranty and how to make a claim can be found <a href="https://help.raylo.com/en/articles/4094747-raylo-s-warranty">here</a></p></div>
            </div>
          </div>}
          {tab === 2 && 
          <div className="certified__tab">
            <div className="certified__tab--inner">
              <div className="certified__tab--header">
                <img src={iconPhoneCheck} alt="certification check icon" />
                <h4>Raylo 70-point check</h4>
              </div>
              <div className="certified__text"><p><strong>Each Raylo Certified Refurbished phone is put through a rigorous 70-point check before shipping.</strong> Our team meticulously checks everything from buttons to battery, speakers to SIM slot, to make sure your new phone is almost indistinguishable from new.<br/><br/>And of course, as with any Raylo phone, everything’s backed by our warranty, just in case.</p></div>
            </div>
          </div>}
          {tab === 3 &&
          <div className="certified__tab">
            <div className="certified__tab--inner">
              <div className="certified__tab--header">
                <img src={iconWallet} alt="wallet icon" />
                <h4>Brand new accessories</h4>
              </div>
              <div className="certified__text">
                <p>All of our refurbished phones include:</p>
                <ul>
                  <li><img src={iconPhoneCharger} alt="" />
                    Brand new charger cable</li>
                  <li><img src={iconPhoneDoubleCamera} alt="" />
                    Eco-friendly case</li>
                  <li><img src={iconScreenProtector} alt="" />
                    Screen protector</li>
                </ul>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </div>
  )
}