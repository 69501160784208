import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet'

// Q0I5UUhITkluL2hvRXNycHFVZEcydXRzaWg3Q3NidzZTSXkwMDZlVXgrcXZIZz09LS1INlVybWU2MThWRzhJVlE2LS1YeXZaMzZQQ3VIbDJjb1RBaFFwZ0pRPT18fHx3aGFVL3ZVaWJSbDFtUlNUbGFySXdaMFdqSkpZVEg2Tld4NnNjM0JIVTJrPQ

// HOOKS

// GRAPHQL
import { QL_DECISION } from 'screens/Checkout/graphQL/queries';

// VISUAL COMPONENTS
import * as T from 'styles/type';
import { SlideLeft, SlideRight } from 'components/animations/Transitions';
import { PhoneImage }  from 'components/common/PhoneImage';
import { PlainHeader } from 'components/common/PlainHeader';
import { Button } from 'components/common/Button';
import { Modal } from 'components/common/Modal';
import { DeliveryEstimate } from 'components/common/DeliveryEstimate';
import Currency from 'react-currency-formatter';
import 'styles/upgrade-decision.css';
import shippingIcon from 'public/icon--shipping.svg';
import useRayloQuery from 'utils/useRayloQuery';
import { useHistory } from 'react-router';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { useErrorNavigator}  from 'utils/useErrorNavigator'
import { TipBox } from 'screens/Checkout/components/StepUpgrade';
import { useTrackCheckoutContext } from 'utils/trackCheckout';
export const DecisionAccepted = () => {
  const history = useHistory()
  const errorNav = useErrorNavigator()
  const [oldPhone, setOldPhone] = useState("unknown")
  const [feeSummary, setFeeSummary] = useState()
  const [costSummary, setCostSummary] = useState()
  const [showTip, setShowTip] = useState(false)
  const [showPostOfficeTip, setShowPostOfficeTip] = useState(false)
  const { checkoutToken } = useCheckoutContext()
  const { data: {checkout}, error, loading } = useRayloQuery(QL_DECISION, {
    variables: {
      checkoutToken
    }
  })
  useEffect(() => {
    if(checkout && checkout.items) {
      const item = checkout.items[0]
      const {originSubscription} = item
      if(originSubscription) {
        setOldPhone(originSubscription.activeAsset.variant.product?.displayName)
        setFeeSummary(originSubscription.feeSummary?.currentNonReturnFee)
        setCostSummary(originSubscription.pricePlan?.costSummary?.recurring?.totalAmount?.valueAfterTax)
      }
    }
  }, [checkout])

  const {checkoutID} = useCheckoutContext()

  useEffect(() => {
    if(window?.analytics) {
      window.analytics.identify(checkoutID)
      window.analytics.track('sale', {
        orderId: checkoutID,
      });
    }    
  }, [window?.analytics])

  if(error) {
    errorNav("Decision: failure", error)
  }

  if(loading) {
    return <Modal visible text="Loading..." />
  }

  const item = checkout?.items[0]
  const variant = item?.variant
  const product = variant?.product
  const address = checkout?.address
  const images = variant?.images

  return (
    <div className="checkout--index success">
      <Helmet><title>Congratulations - Raylo</title></Helmet>
      <PlainHeader />
      <SlideRight visible={true}>
        <div className="checkout--main">
          <div className="checkout--step">
            <T.RayloBodyHeading data-testid="CongratulationsAcceptedHeader">
              <strong>Congratulations</strong>, your {product.displayName} {product.preOrder ? "has been pre-ordered" : "is on its way"}
            </T.RayloBodyHeading>

            <T.RayloBodyType style={{ marginBottom: 12 }}>
              <strong>We’ll send you a confirmation email and let you know when it’s been shipped.</strong>
            </T.RayloBodyType>

            {!oldPhone || !feeSummary ? 
              <div>
                <img src={shippingIcon} alt="Delivery time" style={{ marginBottom: 14 }} />
                <T.RayloBodyType style={{ marginBottom: 8 }}>It should arrive <DeliveryEstimate delivery={item.deliveryEstimatedBetween}/> at:</T.RayloBodyType>

                  {_.filter([address.line1,address.line2,address.line3,address.city,address.region,address.postcode], (o) => (o?.length > 1)).map((o, index) => <T.RayloAddress key={index}>{o}<br/></T.RayloAddress>)}



                <div style={{ marginTop: 28 }}>
                  <Button buttonStyle="primaryPink" onClick={() => {
                    history.push("/account")
                  }}>
                    MY ACCOUNT
                  </Button>
                </div>
              </div>
          
            :

              <div className="upgrade-decision">
              <div className="upgrade-decision__inner">
                <p className="upgrade-decision__title">Once you’ve received your new device…</p>

                <ol>
                  <li><div>
                  <p>We’ll send pre-paid return packaging for your current {oldPhone} to {_.filter([address?.line1,address?.line2,address?.line3,address?.city], (o) => (o?.length > 1)).join(", ")}</p>

                  <p className="pink">Prefer to <T.RayloLink onClick={() => setShowTip(!showTip)}>keep your {oldPhone}</T.RayloLink> for <Currency quantity={feeSummary?.value} currency={feeSummary?.currencyCode} /> instead?</p>
                  {showTip && 
                  <div className="upgrade-decision__tip"><TipBox>
                      <T.RayloBodyType>No problem — contact us and you'll be able to pay the non-return fee of <Currency quantity={feeSummary?.value} currency={feeSummary?.currencyCode} />
                      </T.RayloBodyType>
                    </TipBox></div>}</div>
                  </li>

                  <li><div>
                  <p>Pop your {oldPhone} in the return packaging and drop it off at any Post Office within 14 days of receiving your new device</p>

                  <p className="pink"><T.RayloLink onClick={() => setShowPostOfficeTip(!showPostOfficeTip)}>Can't get to a Post Office?</T.RayloLink></p>

                  {showPostOfficeTip && 
                  <div className="upgrade-decision__tip"><TipBox>
                      <T.RayloBodyType>No problem — contact us and we'll see what we can do to help
                      </T.RayloBodyType>
                    </TipBox></div>}
                    </div>
                  </li>
                </ol>

                <div className="upgrade-decision__warning">
                 <p>If you don’t return your {oldPhone} within <strong>14 days</strong> of receiving your new device, you will be charged <strong><Currency quantity={costSummary.value} currency={costSummary.currencyCode} />/mo</strong> until it is returned.</p>
                </div>


                <div style={{ marginTop: 28 }}>
                  <Button buttonStyle="primaryPink" onClick={() => {
                    history.push("/account")
                  }}>
                    MY ACCOUNT
                  </Button>
                </div>
              </div>
              </div>
              }

          </div>
        </div>
      </SlideRight>

      <SlideLeft visible={true}>
        <div className="checkout--sidebar">
          {images && images[0] && images[0].url && <PhoneImage size="responsive" bg="404EFF" source={images[0].url} alt={product.displayName} />}
        </div>
      </SlideLeft>
    </div>
  )
}
