import TradeInView from 'checkout/Screens/components/TradeInView';
import { useSegmentIdentifyAndCheckoutTracking } from 'hooks/segment/segment.hooks';
import { useStepTradeInContext } from 'screens/Checkout/containers/StepTradeIn/hooks/useStepTradeInContext';
import TradeInJourney from '../TradeInJourney';
import { StyledStepTradeInContent } from './styles';
import { IStepTradeInContent } from './types';

export const StepTradeInContent = ({ dataTestId }: IStepTradeInContent) => {
  useSegmentIdentifyAndCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'trade in',
    checkoutScreen: 'want to trade in',
    when: 'on page load',
  });
  const { tradeInAccepted, tradeInExists } = useStepTradeInContext();
  return (
    <StyledStepTradeInContent data-testid={dataTestId}>
      {tradeInAccepted || tradeInExists ? <TradeInJourney /> : <TradeInView />}
    </StyledStepTradeInContent>
  );
};
