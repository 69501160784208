import { capitalize } from 'lodash';

export const toCamelCase = (input: string): string => {
  return input
    .split(/[\s_-]/)
    .map((element) => {
      return capitalize(element.toLowerCase());
    })
    .join('')
    .replace(/./, (char) => char.toLowerCase());
};
