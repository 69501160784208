import React from 'react';
import styled from 'styled-components/macro';

import _ from 'lodash'

import { Button } from 'components/common/Button';

// HOOKS

import useRayloQuery from 'utils/useRayloQuery';

import { QL_GET_MERCHANT_DETAILS, QL_VARIANTS } from 'screens/Products/queries';
import { EndpointForm } from "components/EndpointForm";

export const VariantsList = () => {
  function pickUrl(variant, pricePlan) {
    return `/pick/${variant.id}/${pricePlan.id}`;
  }

  const { data, error, loading } = useRayloQuery(QL_VARIANTS, {suspend: true});
  // Raylo Pay TODO - resinstate this line
  
  // const domain = window?.location?.hostname;
  
  const domain = window?.location?.hostname?.includes('raylopay') ? "app.staging.raylo.com" : window?.location?.hostname;
  const merchantQuery = useRayloQuery(QL_GET_MERCHANT_DETAILS, {variables: { domain }, skip: !domain, suspend: true});

  if(loading || merchantQuery.loading) return (<p>Loading</p>);
  if(error || merchantQuery.error) return (<p>Error</p>);
  const { variants } = data;

  const feelingLucky = () => {
    const variant = variants[_.random(0,variants.length - 1)]
    const plan = variant.pricePlans[_.random(0,variant.pricePlans.length - 1)]
    window.location = pickUrl(variant, plan)
  }

  return (
    <div>
      {process.env.REACT_APP_DYNAMIC_GRAPHQL_ENABLED === 'true' ? <EndpointForm /> : null}

      <div style={{margin: 20, display: "flex", justifyContent: "center"}}>
          <div style={{margin: 5}}><Button onClick={() => feelingLucky()}>Feeling Lucky?</Button></div>
      </div>

      <VariantTable>
        <thead>
          <tr>
            <th>Phone</th>
            <th>Plans (Insurance-Term)</th>
          </tr>
        </thead>
        <tbody>
        {variants.map((variant) => {

          return (
            <tr key={variant.id}>
              <td>{variant.displayName}</td>
              <td>

                {variant.pricePlans.map((plan) => {

                  return (
                    <a key={plan.id} title={plan.name} href={pickUrl(variant, plan)} data-testId='price-plan-link'>
                      {plan.includesInsurance ? "Y" : "N"}-{plan.termLength}
                    </a>
                  )

                })}
              </td>

            </tr>
          )
        })}</tbody>
      </VariantTable>
    </div>
  )
}

export default VariantsList 

const VariantTable = styled.table`
  color: white;
  border-collapse: collapse;
  width: 100%;
  margin: 20px;
  td, th {
    text-align: left;
    vertical-align: middle;
    padding: 5px;
  }
  tr td {
    border-bottom: 1px solid #333;
    font-size: 13px;
  }
  a {
    color: white;
    display: inline-block;
    margin: 5px;
    padding: 2px 4px;
    background-color: #404EFF;
    text-decoration: none;
    font-size: 12px;
  }
`
