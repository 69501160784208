import gql from 'graphql-tag';

export const ACCEPTABLE_DATA_TOKEN_MUTATION = gql`
  mutation createAcceptableDataMutation(
    $subscriptionOptions: [AcceptableDataSubscriptionOption!]
  ) {
    createAcceptableData {
      userToken(subscriptionOptions: $subscriptionOptions)
    }
  }
`;
