import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import auth0 from 'auth0-js';
import { AUTH_CONFIG } from 'utils/constants';

// HOOKS
import useFormal from "@kevinwolf/formal-web";

// COMPONENTS
import * as T from 'styles/type';
import * as A from 'styles/account';
import * as L from 'styles/layout';

import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { BlocksAnimation } from 'components/animations/BlocksAnimation';
import { AccountFooter } from 'components/Account/AccountFooter';
import { TextInput } from 'components/common/TextInput';
import { Modal } from 'components/common/Modal';
import { Button } from 'components/common/Button';

import { useSegmentLoginTracking } from 'hooks/segment/segment.hooks';

import userIcon from 'public/icon--user.svg';
import store from 'store'

import logo from 'public/raylo-logo.svg';


import * as yup from "yup";
import { useHistory } from 'react-router';
import colors from "../../styles/colors"

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Please enter a valid email address")
    .nullable().default("")
});

const webAuth = new auth0.WebAuth({
  domain: AUTH_CONFIG.domain,
  clientID: AUTH_CONFIG.clientId,
  audience: AUTH_CONFIG.audience,
  responseType: 'token'
});

const requestCode = (email, callback) => {
  useSegmentLoginTracking({
    loginScreen: 'Submit OTP request',
    email: email
  });
  webAuth.passwordlessStart({
    email: email,
    send: "code",
    connection: "email",
    authParams: {
      redirectUri: AUTH_CONFIG.redirectUri,
      state: AUTH_CONFIG.stateToken
    }
  }, callback);
}

const Heading = (props) => {
  return (
    <>
      <FadeIn>
        <img src={userIcon} alt="user icon" style={{ marginBottom: 20 }} />
      </FadeIn>

      <FadeIn>
        <div style={{ marginBottom: 40 }}>
          <T.RayloBodyHeading style={{ marginBottom: 0 }}>{ props.children }</T.RayloBodyHeading>
        </div>
      </FadeIn>
    </>
  )
}

export const Login = () => {

  const [formErrors, setFormErrors] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState('')

  // HANDLE FORM ERRORS
  useEffect(() => {
    if(formErrors) {
      let errors = {}
      _.each(formErrors, error => {
        if(error?.statusCode === 400) {
          errors.email = "Please check your email address and try again"
        } else {
          switch(error?.field) {
            case 'email' :
              errors.email = error?.message
              break;
            default:
          }
        }
      })
      formal.setErrors(errors)
    } else {
      formal.clearErrors()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors])

  const delaySubmitForm = () => {
    setIsSubmitting(true)
    setFormErrors([ { field: 'email', message: 'That was quick, please check your email before requesting a new link.' }])
    setTimeout(() => {
      formal.clearErrors()
      setIsSubmitting(false)
    }, 30000);
  }

  // FORM HANDLER
  const formal = useFormal({email: email}, {
    schema,
    onSubmit: (values) => {
      setIsSubmitting(true)
      requestCode(formal.values.email, (error) => {
        if(error) {
          setEmail('')
          setIsSubmitting(false)
          if(error?.statusCode === 400) {
            setFormErrors([error])
          } else {
            setFormErrors(error)
          }
        } else {
          setEmail(formal.values.email)
          setSuccess(true)
          delaySubmitForm()
        }
      })
    }
  });

  return (
    <>
    <L.PushDown>
    <A.AccountWrapper>
      <A.Logo>
        <img src={logo} alt="Raylo" />
      </A.Logo>
      <A.AccountForm>
        {success ?
          <A.AccountFormContainer>
            <Heading>Check your email</Heading>
            <FadeIn>
              <div style={{ marginBottom: 40 }}>
                <T.RayloBodyType style={{ marginBottom: 20 }}>Everyone hates passwords, so we don’t use them.</T.RayloBodyType>
                <T.RayloBodyType style={{ marginBottom: 0 }}>We've just sent an email with a magic link to:</T.RayloBodyType>
                <T.RayloBodyType style={{ marginBottom: 20, color: colors.rayloPink }}>{email}</T.RayloBodyType>
                <T.RayloBodyType style={{ marginBottom: 0 }}>Just click on the link in the latest email to login. It’s quick and secure.</T.RayloBodyType>
              </div>
            </FadeIn>
            <FadeIn>
              <div style={{ marginBottom: 40 }}>
                <T.RayloBodyType style={{ marginBottom: 0 }}>Didn't receive it?</T.RayloBodyType>
              </div>
            </FadeIn>
            <FadeIn>
              <Button
                buttonStyle='secondary'
                onClick={() => setSuccess(false)}
              >Try again</Button>
            </FadeIn>

          </A.AccountFormContainer>

        :
          <A.AccountFormContainer>
            <Heading>Log in to your Raylo Account</Heading>
            <SlideDownFadeIn>
              <form {...formal.getFormProps()}>
                <TextInput
                  type="email"
                  label="Email Address"
                  field={formal.getFieldProps('email')}
                  errorMessage={formal.errors.email}
                />

                <Button buttonStyle='secondary'
                  {...formal.getSubmitButtonProps()}
                  disabled={isSubmitting}
                  >
                  Log In
                </Button>
              </form>
            </SlideDownFadeIn>
          </A.AccountFormContainer>
        }
      </A.AccountForm>

      <BlocksAnimation />
    </A.AccountWrapper>
    <AccountFooter />
    </L.PushDown>
    </>
  )
}

export const Verify = () => {
  const verifyLogin = (email, code) => {
    useSegmentLoginTracking({
      loginScreen: 'Submit code to Auth0 for verification',
      email: email
    });
    const callbackUrl = `https://${window.location.hostname}/login/callback`;
    const url = `https://${AUTH_CONFIG.domain}/passwordless/verify_redirect?scope=openid&response_type=token&redirect_uri=${encodeURIComponent(callbackUrl)}&audience=${encodeURIComponent(AUTH_CONFIG.audience)}&state=${AUTH_CONFIG.stateToken}&verification_code=${code}&connection=email&client_id=${AUTH_CONFIG.clientId}&email=${encodeURIComponent(email)}`
    window.location.href = url;
  }

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const email = urlParams.get('email')
  const code = urlParams.get('code')
  const timestamp = urlParams.get('timestamp')
  const history = useHistory()

  if (!email || !code) {
    history.push("/login")
  }

  if (!timestamp || (timestamp * 1000) < Date.now() - 3600000) {
    // no timestamp sent or timestamp is older than 1 hour
    requestCode(email, (error) => {
      if (error) {
        history.push("/errors/login")
      } else {
        history.push("/login/expired")
      }
    });
  } else {
    useEffect(() => {
      useSegmentLoginTracking({
        loginScreen: 'Code verification page',
        email: email
      });
    });
  }

  return (
    <>
    <L.PushDown>
    <A.AccountWrapper>
      <A.Logo>
        <img src={logo} alt="Raylo" />
      </A.Logo>
      <A.AccountForm>
        <A.AccountFormContainer>
          <Heading>Link verified</Heading>
          <FadeIn>
            <div style={{ marginBottom: 40 }}>
              <T.RayloBodyType style={{ marginBottom: 0 }}>Click the button below to login.</T.RayloBodyType>
            </div>
          </FadeIn>
          <FadeIn>
            <Button
              buttonStyle='secondary'
              onClick={() => verifyLogin(email, code)}
            >Go to my account</Button>
          </FadeIn>
        </A.AccountFormContainer>
      </A.AccountForm>
      <BlocksAnimation />
    </A.AccountWrapper>
    <AccountFooter />
    </L.PushDown>
    </>
  );
}

export const Expired = () => {
  useSegmentLoginTracking({
    loginScreen: 'Link expired'
  });

  return(
    <>
    <L.PushDown>
    <A.AccountWrapper>
      <A.Logo>
        <img src={logo} alt="Raylo" />
      </A.Logo>
      <A.AccountForm>
        <A.AccountFormContainer>
          <Heading>Link expired</Heading>
          <FadeIn>
            <div style={{ marginBottom: 40 }}>
            <T.RayloBodyType style={{ marginBottom: 20 }}>It looks like you've followed an expired link.</T.RayloBodyType>
            <T.RayloBodyType style={{ marginBottom: 20 }}>We've just sent you a new link.</T.RayloBodyType>
            <T.RayloBodyType style={{ marginBottom: 0 }}>Just click on the link in the latest email to login. It’s quick and secure.</T.RayloBodyType>
            </div>
          </FadeIn>
        </A.AccountFormContainer>
      </A.AccountForm>
      <BlocksAnimation />
    </A.AccountWrapper>
    <AccountFooter />
    </L.PushDown>
    </>
  );
}

export const Callback = () => {
  const history = useHistory()
  if(window.location.hash.length > 2) {
    webAuth.parseHash({
      hash: window.location.hash,
      state: AUTH_CONFIG.stateToken
    }, (err, authResult) => {
      if(err || !authResult) {
        useSegmentLoginTracking({
          loginScreen: 'Error page',
          error: err?.error,
          errorDescription: err?.errorDescription
        });
        history.push("/errors/login")
      } else {
        useSegmentLoginTracking({
          loginScreen: 'Successful login'
        });
        store.set('userToken', authResult.accessToken)
        history.push('/account')
      }
    })
  }

  return (<L.MainWrapper><Modal visible text="Loading..." /></L.MainWrapper>)

}
