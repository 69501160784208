import {
  isAdditonalTech,
  isUpgrade,
} from 'utils/handleGetSessionStorageValues';

export enum CheckoutCategories {
  NEW = 'New',
  UPGRADE = 'Upgrade',
  ADDITIONAL_TECH = 'Additional Tech',
}

export const checkoutCategory = () => {
  if (isUpgrade()) {
    return CheckoutCategories.UPGRADE;
  }
  if (isAdditonalTech()) {
    return CheckoutCategories.ADDITIONAL_TECH;
  }

  return CheckoutCategories.NEW;
};
