import styled from 'styled-components';
import { primaryColors } from '../../styles/variables/colors';
import { px2Rem } from '../../utils/px2Rem';

const StyledCheckoutBar = styled.div`
  background-color: ${primaryColors.w01};
  width: 100%;
  height: ${px2Rem(80)};
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
`;

export { StyledCheckoutBar };
