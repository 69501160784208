import DualFontWeightHeader from 'components/DualFontWeightHeader';
import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Header from 'elements/Header';
import PreApprovalLabel from 'elements/PreApprovalLabel';
import Spacer from 'elements/Spacer';
import PreApproveBannerImage from 'public/pre_approved_banner.png';
import PromoPreApproveBannerImage from 'public/tech_mob.png';
import { useHistory } from 'react-router';
import { breakpoints } from 'styles/breakpoints';
import { primaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { IPreApprovalBannerMobile } from './types';

export const PreApprovalBannerMobile = ({
  dataTestId,
  promo,
}: IPreApprovalBannerMobile) => {
  const history = useHistory();

  const backgroundImage = promo
    ? PromoPreApproveBannerImage
    : PreApproveBannerImage;

  const handleViewAllTech = () => {
    history.push('/account/products');
  };

  return (
    <Container
      maxWidth={`${breakpoints.mobile}px`}
      dataTestId={dataTestId}
      height={`${px2Rem(328)}`}
      backgroundColor={promo ? primaryColors.b01 : primaryColors.c01}
      backgroundImage={backgroundImage}
      backgroundImageSize={promo ? '70%' : 'contain'}
      backgroundImagePosition={promo ? '45% 100%' : '100% 100%'}
      alignCenter
    >
      <PreApprovalLabel />
      <Spacer height={16} />
      <div
        style={{
          textAlign: 'center',
          padding: `0 ${px2Rem(16)}`,
        }}
      >
        <DualFontWeightHeader>
          {!promo ? (
            <Header level={2} color={primaryColors.p01}>
              <span>All your latest</span> pre-approved tech
            </Header>
          ) : (
            <Header level={2} color={primaryColors.p01}>
              <span>Get the latest</span> pre-approved tech{' '}
              <span>for you and your family</span>
            </Header>
          )}
        </DualFontWeightHeader>
        <Spacer height={16} />
        {!promo && (
          <Copy color={primaryColors.p01}>
            You can now lease additional tech for you or your family
          </Copy>
        )}
        {promo && (
          <Button
            buttonColor={ButtonTypes.b01}
            buttonType="ghost"
            onClick={handleViewAllTech}
            size="medium"
            text="view all tech"
          />
        )}
      </div>
    </Container>
  );
};
