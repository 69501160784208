import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Header from 'elements/Header';
import Icon from 'elements/Icon';
import Spacer from 'elements/Spacer';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { StyledPhoneFeatureCard } from './styles';
import { IPhoneFeatureCard } from './types';

export const PhoneFeatureCard = ({
  dataTestId,
  iconName,
  title,
  details,
  titleBold,
  detailWidth,
}: IPhoneFeatureCard) => {
  return (
    <StyledPhoneFeatureCard data-testid={dataTestId}>
      <Container padding="0" maxWidth={px2Rem(40)}>
        <Icon name={iconName} color={secondaryColors.p02} size={px2Rem(26)} />
      </Container>
      <Container padding="0">
        <Spacer height={4} />
        {titleBold ? (
          <Header level={4} color={primaryColors.c01} fontSize={16}>
            {title}
          </Header>
        ) : (
          <Copy color={primaryColors.c01} fontSize={16}>
            {title}
          </Copy>
        )}
      </Container>
      <Container alignRight padding="0" width={detailWidth}>
        <Spacer height={4} />
        <Copy color={secondaryColors.c02}>{details}</Copy>
      </Container>
    </StyledPhoneFeatureCard>
  );
};
