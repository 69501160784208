import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { PROCESS_INCOMING_PAYMENT_MUTATION } from 'graphql/mutations/incomingPayment/processIncomingPaymentMutation.graphql';
import { rayloLight } from 'styles/themes/rayloLight';
import { PlainHeader } from 'components/common/PlainHeader';
import { primaryColors } from 'styles/variables/colors';
import { useCheckoutContext } from 'utils/useCheckoutContext';

export const DepositPaymentStatusScreen = () => {
  // TODO: DEV-258
  // const [paymentStatus, setPaymentStatus] = useState(PaymentStatus.Initial);

  const { incomingPaymentId } = useParams();
  const { checkoutToken } = useCheckoutContext();

  const [processIncomingPayment] = useMutation(
    PROCESS_INCOMING_PAYMENT_MUTATION,
    {
      variables: {
        incomingPaymentId,
        checkoutToken,
      },
    },
  );

  useEffect(() => {
    processIncomingPayment();
  }, []);

  // TODO: DEV-258
  // const renderPaymentStatus = () => {
  //   switch (paymentStatus) {
  //     case PaymentStatus.Successful: {
  //       return <PaymentSuccessful/>;
  //     }
  //     case PaymentStatus.Failed: {
  //       return <PaymentFailed/>;
  //     }
  //     default: {
  //       return <PaymentProcessing/>;
  //     }
  //   }
  // };

  const backgroundColor = primaryColors.w01;

  return (
    <ThemeProvider theme={rayloLight}>
      <div
        className="checkout--index"
        style={{ backgroundColor, width: '100vw' }}
      >
        <Helmet>
          <title>Security Deposit - Raylo</title>
        </Helmet>
        <div className="checkout--header">
          <PlainHeader />
        </div>
        <div className="checkout--main">
          <div className="checkout--step">
            {/* TODO: DEV-258 */}
            {/* {renderPaymentStatus()} */}
            Processing...
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};
