import DirectDebitInfo from 'components/DirectDebitInfo';
import FormCollection from 'components/FormCollection';
import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import Container from 'elements/Container';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import TextLink from 'elements/TextLink';
import { useEffect, useState } from 'react';
import { useStepBankContext } from 'screens/Checkout/containers/StepBank/hooks/useStepBankContext';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { useUiContext } from 'utils/useUiContext';
import DirectDebitTotalDetails from '../DirectDebitTotalDetails';
import { StyledBankDetailsNew } from './styles';
import { IBankDetailsNew } from './types';

export const BankDetailsNew = ({ dataTestId }: IBankDetailsNew) => {
  const [presetValues, setPresetValues] = useState<any>(null);
  const { setShowModal, setShowDDContactModal } = useUiContext();
  const {
    values,
    directDebitMandateAuthorised,
    setConfirmDetails,
    setConfirmNewDetails,
    setValues,
    setFormErrors,
  } = useStepBankContext();
  const fields = [
    {
      field: 'accountName',
      name: 'Name of account holder(s)',
      type: 'text',
      required: true,
    },
    {
      field: 'accountNumber',
      name: 'Account number',
      type: 'number',
      pattern: 'd*',
      required: true,
      maxLength: 8,
    },
    {
      field: 'sortCode',
      name: 'Sort code',
      type: 'number',
      pattern: 'd*',
      required: true,
      maxLength: 6,
    },
  ];

  useEffect(() => {
    if (values?.bankAccountName) {
      setPresetValues({
        accountName: values?.bankAccountName,
        accountNumber: values?.bankAccountNumber,
        sortCode: values?.bankBranchIdentifier,
      });
    }
  }, [values]);

  const handleShowContactModal = () => {
    setShowModal(true);
    setShowDDContactModal(true);
  };
  const handleSubmit = (submittedValues: any) => {
    setConfirmDetails(true);
    setFormErrors(null);
    setConfirmNewDetails(true);
    setValues({
      bankAccountName: submittedValues?.accountName,
      bankAccountNumber: submittedValues?.accountNumber,
      bankBranchIdentifier: submittedValues?.sortCode,
    });
  };

  return (
    <StyledBankDetailsNew data-testid={dataTestId}>
      <Spacer height={48} />
      <Header level={2} color={primaryColors.c01}>
        Set up a direct debit with Raylo
      </Header>
      <Spacer height={16} />
      <DirectDebitTotalDetails
        backgroundColor={secondaryColors.c05}
        padding={px2Rem(16)}
      />
      <Spacer height={24} />
      <FormCollection
        fields={fields}
        handleSubmit={handleSubmit}
        formId="bankDetailsForm"
        presetValues={presetValues}
      />
      <DirectDebitInfo />
      <Spacer height={16} />
      <TextLink onClick={handleShowContactModal}>
        Please contact us if you have any questions
      </TextLink>
      <Spacer height={32} />
      <Container noPadding>
        <Button
          disabled={!directDebitMandateAuthorised}
          type="submit"
          size="large"
          text="Next"
          fullWidth
          buttonColor={ButtonTypes.b01}
          formId="bankDetailsForm"
        />
      </Container>
    </StyledBankDetailsNew>
  );
};
