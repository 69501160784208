import { Modal } from 'components/common/Modal';
import { useSegmentIdentifyAndCheckoutTracking } from 'hooks/segment/segment.hooks';
import { useEffect, useState } from 'react';
import { useCheckoutMobileContext } from 'screens/Checkout/containers/StepMobileJob/hooks/useCheckoutMobileContext';
import {
  CheckoutMode,
  useGetCheckoutMode,
} from 'screens/Checkout/hooks/useGetCheckoutMode';
import MobileConfirm from '../MobileConfirm';
import MobileNew from '../MobileNew';
import { StyledStepMobileContent } from './styles';
import { IStepMobileContent } from './types';

export const StepMobileContent = ({ dataTestId }: IStepMobileContent) => {
  const [confirm, setConfirm] = useState(false);
  useSegmentIdentifyAndCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'your details',
    checkoutScreen: 'add mobile number',
    when: 'on page load',
  });

  const { number, loading } = useCheckoutMobileContext();
  const { checkoutMode } = useGetCheckoutMode();

  useEffect(() => {
    if (checkoutMode !== CheckoutMode.NEW_CUSTOMER && number) {
      setConfirm(true);
    } else {
      setConfirm(false);
    }
  }, [checkoutMode, number]);

  if (loading) {
    return <Modal visible text="Loading..." />;
  }
  return (
    <StyledStepMobileContent data-testid={dataTestId}>
      {confirm ? <MobileConfirm /> : <MobileNew />}
    </StyledStepMobileContent>
  );
};
