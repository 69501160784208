import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router'
import _ from 'lodash'

import * as A from 'styles/account';
import * as T from 'styles/type';

import { AccountFooter } from 'components/Account/AccountFooter';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { BackButton } from 'components/common/BackButton';
import { Button } from 'components/common/Button';
import { PolicyBanner } from 'components/Account/PolicyBanner';
import { Link } from 'components/common/Link';
import { QL_CLAIM } from './queries';
import { Modal } from 'components/common/Modal';

import useRayloQuery from 'utils/useRayloQuery';

import { Helmet } from 'react-helmet';

export const InsuranceLost = () => {
  const history = useHistory()
  const { orderId } = useParams()
  const { data: { customer }, loading } = useRayloQuery(QL_CLAIM, {fetchPolicy: "network-only"})

  const [policy, setPolicy] = useState({})

  useEffect(() => {
    if(customer?.orders) {

      const order = customer?.orders?.find(order => order.id === orderId)
      const orderItem = order?.items[0]
      if(orderItem?.pricePlan) {
        if(!orderItem.pricePlan.includesInsurance) {
          window.location = '/account'
        }
      }
      const subscription = orderItem?.subscription
      if(subscription) {
        setPolicy(subscription.insurancePolicy)    
      } else {
        history.push("/account")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  if(loading || !(policy && policy.id)) return (<Modal visible text="Loading..." />)

  const existingClaim = _.find(policy.claims, o => o.reason.enum === "LOSS" && o.state === "STARTED")

  return (
    <>
      
      <Helmet><title>Lost Device - Raylo</title></Helmet>
      <A.AccountWrapper>
        <A.AccountForm>
          <A.AccountFormContainer>
            <FadeIn>
              <div style={{ marginBottom: 33 }}>
                <BackButton withText onClick={() => history.push(`/account/insurance/${orderId}`)} />
              </div>
            </FadeIn>

            <FadeIn>
              <div style={{ marginBottom: 40 }}>
                <T.RayloBodyHeading style={{ marginBottom: 8 }}>Lost Device</T.RayloBodyHeading>
                <T.RayloSubHeading>Before you make a claim, there are a few things you should know:</T.RayloSubHeading>
              </div>
            </FadeIn>

            <SlideDownFadeIn>
              <T.Steps>
                <li><strong>There is an excess of £125 that you will have to pay</strong></li>


                <li><strong>Within 24 hours confirm that the device has been lost.</strong> <br></br>If your device is a phone we will block the IMEI for you.</li>
              </T.Steps>
            </SlideDownFadeIn>
            {existingClaim ? 
              <>
                <SlideDownFadeIn>
                  <div style={{ marginBottom: 20, marginTop: 20 }}>
                    <T.RayloWarningText>You already have a claim in progress.</T.RayloWarningText>
                  </div>
                </SlideDownFadeIn>

                <SlideDownFadeIn>
                  <Button buttonStyle="secondary" onClick={() => history.push(`/account/insurance/${orderId}`)}>Back</Button>
                </SlideDownFadeIn>
              </>
            :
              <SlideDownFadeIn>
                <Button buttonStyle="primaryBlue" onClick={() => history.push(`/account/insurance/lost/block/${orderId}`)}>Continue</Button>
              </SlideDownFadeIn>
            }

            <SlideDownFadeIn>
              <div style={{ marginBottom: 20, marginTop: 20 }}>
                <T.RayloBodyType>Any questions? <Link href="" onClick={() => window.Intercom('show')}>Contact Us</Link></T.RayloBodyType>
              </div>
            </SlideDownFadeIn>
          </A.AccountFormContainer>
        </A.AccountForm>
      </A.AccountWrapper>

      <PolicyBanner />

      <AccountFooter />
    </>
  )
  }
