import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet'
import store from 'store'
import { activateOptimize } from 'utils/optimize'

// HOOKS
import { useUiContext } from 'utils/useUiContext'

// GRAPHQL
import { QL_RISK_PRICING } from 'screens/Checkout/graphQL/queries';

// VISUAL COMPONENTS
import * as T from 'styles/type';
import colors from 'styles/colors';
import * as B from 'styles/buttons';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { Button } from 'components/common/Button';
import { Modal } from 'components/common/Modal';
import { CatchAll } from 'screens/Errors/CatchAll';

import warningOutlineIcon from 'public/icon--warning--outline.svg';
import Currency from 'react-currency-formatter';

import { ChangePhone } from 'screens/Checkout/components/ChangePhone';
import { PhoneHeader } from 'screens/Checkout/components/PhoneHeader';
import { PhoneSummary } from 'screens/Checkout/components/PhoneSummary';
import { MU_RESTART_CHECKOUT } from '../graphQL/mutations';
import { useMutation } from '@apollo/client';
import useRayloQuery from 'utils/useRayloQuery'
import { captureErrorEvent } from 'utils/errorTracking'
import { useHistory } from 'react-router';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { useErrorNavigator } from "utils/useErrorNavigator"
import classNames from 'classnames'
import {useSegmentIdentifyAndCheckoutTracking} from 'hooks/segment/segment.hooks'

export const RiskPricing = () => {
  useSegmentIdentifyAndCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'counter offer',
    checkoutScreen: 'counter offer details',
    when: 'on page load'
  });

  const history = useHistory()
  const errorNav = useErrorNavigator()
  const { checkoutToken } = useCheckoutContext()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorCode, setErrorCode] = useState("")
  const [restartCheckout] = useMutation(MU_RESTART_CHECKOUT)

  const { showSummary, showConfigure, setShowConfigure } = useUiContext()

  const { data: {checkout}, error, loading } = useRayloQuery(QL_RISK_PRICING, {
    variables: {
      checkoutToken
    }
  })

  const actionRestart = () => {
    restartCheckout({
      variables: {
        checkoutToken
      },
      update: (proxy, {data: {restartCheckout: {checkout, errors }}}) => {
        setIsSubmitting(false)
        if(errors && errors.length > 0) {
          captureErrorEvent("GraphQL restart validation error", {errors: errors})
          setErrorCode("generic")
        } else {
          actionJumpToTerms()
        }
      }
    })
  }
  const actionJumpToTerms = () => {
    store.set('checkoutToken', checkoutToken)
    history.push("/checkout/step/sign")
  }
  const showTerms = () => {
    if (isSubmitting) {
      return
    }
    setIsSubmitting(true)
    if(checkout.state === "SUBMITTED") {
      actionRestart()
    } else {
      actionJumpToTerms()
    }
  }

  useEffect(() => {
    activateOptimize()
  })

  if(errorCode === "generic") {
    return (<CatchAll onClick={() => setErrorCode("")} buttonStyle="secondary" />)
  }


  if(error) {
    errorNav('Error in risk pricing', error)
  }

  if(loading || !checkout) {
    return <Modal visible text="Loading..." />
  }

  const variant = checkout?.items[0]?.variant
  const product = variant?.product
  const pricePlan = checkout?.items[0]?.pricePlan
  const costSummary = checkout?.items[0]?.pricePlan?.costSummary

  const storage = _.find(variant?.optionValues, o => o.optionType.slug === 'storage').displayName
  const color = _.find(variant?.optionValues, o => o.optionType.slug === 'colour').displayName

  return (
    <div className={classNames("checkout--index", { showConfigure, showSummary })}>
      <Helmet><title>
        Your Subscription - Raylo
      </title></Helmet>
      <div className="checkout--header"><PhoneHeader checkoutToken={checkoutToken} /></div>
      <div className="checkout--main">
        <div className="checkout--step">
          <FadeIn>
            <T.RayloBodyHeading margin="34px">Your Subscription</T.RayloBodyHeading>
          </FadeIn>

          <FadeIn>
            <T.RayloBodyHeading margin="24px">
              <strong>{product.displayName} {storage} {color}</strong>
              {pricePlan.includesInsurance && <><br/>including insurance</>}</T.RayloBodyHeading>
          </FadeIn>

          <FadeIn>
            <div style={{ marginBottom: 28, paddingBottom: 28, borderBottom: '1px solid #434343' }}>
              <T.RayloWarningText>Total Monthly Payment: <span style={{ color: '#FFF', marginLeft: 10 }}><Currency quantity={costSummary.recurring.totalAmount.valueAfterTax.value} currency={pricePlan.currencyCode} /></span></T.RayloWarningText>
              <T.RayloBodyType style={{ color: colors.rayloPink }}>(This is an <span style={{ color: '#FFF' }}>increase of <Currency quantity={costSummary.recurring.adjustmentAmount.valueAfterTax.value} currency={pricePlan.currencyCode} /> per month</span>, compared with the standard price of <Currency quantity={costSummary.recurring.totalAmount.valueAfterTax.value - costSummary.recurring.adjustmentAmount.valueAfterTax.value} currency={pricePlan.currencyCode} /> per month)</T.RayloBodyType>
            </div>
          </FadeIn>
          { false && 
            <SlideDownFadeIn>
              <div style={{ marginBottom: 42, paddingBottom: 28, borderBottom: '1px solid #434343' }}>
                <img src={warningOutlineIcon} alt="" />
            
                <T.RayloBodyType style={{ fontWeight: 'bold' }}>You can still change your device to lower your monthly price</T.RayloBodyType>
            
                <B.UnderlineButton onClick={() => setShowConfigure(true)} >Change Phone</B.UnderlineButton>
              </div>
            </SlideDownFadeIn>
          }

          <SlideDownFadeIn>
            <div style={{ display: 'flex', marginBottom: 20 }}>
              <div style={{ marginRight: 40 }}>
                <Button buttonStyle="secondary" onClick={() => history.push(`/checkout/decision/later`)}>No Thanks</Button>
              </div>
              <div>
                <Button buttonStyle="primaryBlue" onClick={() => showTerms(checkout.state)}>Continue to T&CS</Button>
              </div>
            </div>
          </SlideDownFadeIn>

          <SlideDownFadeIn>
            <T.RayloWarningText>Not sure? No problem! You can always <B.UnderlineButton inline onClick={() => history.push(`/checkout/decision/later`)}>Decide Later</B.UnderlineButton>.<br/>This will be valid for 5 days</T.RayloWarningText>
          </SlideDownFadeIn>
        </div>
      </div>

      <FadeIn>
        <div className={classNames("checkout--sidebar", { showConfigure, showSummary})}>
          {showConfigure ? (
            <ChangePhone checkoutToken={checkoutToken} />
          ) : (
            <PhoneSummary checkoutToken={checkoutToken} displayOnly={true} />
          )}
        </div>
      </FadeIn>
    </div>
  )

}
