import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';

const StyledStatusBanner = styled.div`
  & h2 {
    color: #fff;
    font-weight: normal;
    font-size: ${px2Rem(24)};
  }
  & button {
    color: #fff;
    border-color: #fff;
  }
`;

export { StyledStatusBanner };
