import { useEffect, useState } from 'react'
import constate from 'constate'
import _ from 'lodash';
import store from 'store'
import { useLazyQuery } from '@apollo/client';
import { QL_CHECKOUT_BASE } from 'screens/Checkout/graphQL/queries';
import { setErrorContext } from 'utils/errorTracking'
import { CheckoutMode } from 'screens/Checkout/hooks/useGetCheckoutMode';
import { useUiContext } from './useUiContext';
import { getThemeFromString } from './getThemeFromString';
import { isFeatureEnabled } from './isFeatureEnabled';

const useCheckout = () => {
  const [termsAcceptedData, setTermsAcceptedData] = useState({})
  const [progressBarSteps, setProgressBarSteps] = useState([])
  const [currentStep, setCurrentStep] = useState("")
  const [previousStep, setPreviousStep] = useState("")
  const [mode, setMode] = useState();
  const [customerDetails, setCustomerDetails] = useState()
  const [showStepTradeIn, setShowStepTradeIn] = useState(false)
  const [showStepInsurance, setShowStepInsurance] = useState(false)
  const [merchantHasTradeIn, setMerchantHasTradeIn] = useState(false)
  const [merchantHasInsuranceOption, setMerchantHasInsuranceOption] = useState(false)
  const [merchantHasChangeProductOption, setMerchantHasChangeProductOption] = useState(false)

  const [isRPCheckout, setIsRPCheckout] = useState(false)
  const {setTheme, setIsRayloPay} = useUiContext()

  let checkoutToken
  if (process.env.NODE_ENV !== 'test') {
    checkoutToken = store.get('checkoutToken')
  } else {
    checkoutToken = 'A'
  }

  const [loadCheckout, { data, loading, called }] = useLazyQuery(QL_CHECKOUT_BASE, {
    variables: {
      token: checkoutToken
    }
  })

  useEffect(() => {
    if(process.env.NODE_ENV === "development") {
      setIsRPCheckout(true)
    } else {
      setIsRPCheckout(window?.location?.hostname?.includes('raylopay'))
    }
  }, [])

  useEffect(() => {
    if (!!checkoutToken) {
      loadCheckout()
    }
  }, [checkoutToken, loadCheckout])

  useEffect(() => {
    if(data) {
      const features = data?.checkout?.merchant?.features
      setMerchantHasInsuranceOption(isFeatureEnabled(features, 'insurance'))
      setMerchantHasTradeIn(isFeatureEnabled(features, 'trade_in'))
      setMerchantHasChangeProductOption(isFeatureEnabled(features, 'change_variant'))
    }
  }, [loadCheckout, data])

  useEffect(() => {
    if (data?.checkout?.id) {
      setErrorContext("checkout", { id: data.checkout.id })
      setShowStepTradeIn(data?.checkout?.items?.[0]?.variant?.product?.category?.slug === 'phone' && merchantHasTradeIn);
      setShowStepInsurance(merchantHasInsuranceOption);
    }
    const brandingTheme = data?.checkout?.merchant?.brandingTheme
    if(brandingTheme) {
      setTheme(getThemeFromString(brandingTheme))
      setIsRayloPay(brandingTheme !== 'rayloLight')
    }
  }, [data, merchantHasTradeIn, merchantHasInsuranceOption])

  useEffect(() => {
    if (mode === CheckoutMode.UPGRADE) {
      setProgressBarSteps(
        _.compact(_.concat(
          showStepInsurance ? ['StepInsurance'] : [],
          [
            'StepUpgrade',
            'StepInsurance',
            'StepAddress',
            'StepMobileJob',
            'StepBank',
            'StepSign'
          ]
        ))
      )
    }
    if (mode === CheckoutMode.ADDITIONAL_TECH) {
      setProgressBarSteps(_.compact(_.concat(
        showStepInsurance ? ['StepInsurance'] : [],
          [
            'StepInsurance',
            'StepAddress',
            'StepMobileJob',
            'StepBank',
            'StepSign'
          ]
        ))
      )
    }
    if(mode === CheckoutMode.NEW_CUSTOMER) {
      if (!isRPCheckout) {
        setProgressBarSteps(_.compact(_.concat(
            showStepTradeIn ? ['StepTradeIn'] : [],
            showStepInsurance ? ['StepInsurance'] : [],
            [
              'StepNameEmail',
              'StepAddress',
              'StepMobileJob',
              'StepBank',
              'StepSign'
            ]
          ))
        )
      }
      if (isRPCheckout) {
        setProgressBarSteps(_.compact(_.concat(
          'StepLeaseTerm',
          showStepTradeIn ? ['StepTradeIn'] : [],
          [
            'StepInsurance',
            'StepNameEmail',
            'StepAddress',
            'StepMobileJob',
            'StepBank',
            'StepSign'
          ]
        )))
      }
    }
    }, [mode, showStepTradeIn])


  let checkout

  if (!!data && !!data.checkout) {
    checkout = data.checkout
  }

  let condition = {
    state: null,
    outcome: null,
    reason: null
  }

  if (!!checkout) {
    condition.state = checkout.state
    if (checkout.decision) {
      if (checkout.decision.outcome) {
        condition.outcome = checkout.decision.outcome
      }
      if (checkout.decision.outcomeReason) {
        condition.reason = checkout.decision.outcomeReason
      }
    }
  }

  return {
    checkoutID: data?.checkout?.id,
    progressBarSteps,
    currentStep,
    previousStep,
    customerDetails,
    hasCheckoutToken: !!checkoutToken,
    checkoutToken,
    checkoutCalled: called,
    checkoutLoading: loading,
    condition,
    termsAcceptedData,
    merchantHasInsuranceOption,
    merchantHasTradeIn,
    merchantHasChangeProductOption,
    isRPCheckout,
    setTermsAcceptedData,
    setProgressBarSteps,
    setMode,
    setPreviousStep,
    setCurrentStep,
    setCustomerDetails,
  }

}

const [CheckoutProvider, useCheckoutContext] = constate(useCheckout);
export { CheckoutProvider, useCheckoutContext }
