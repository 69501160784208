import React, {useEffect} from 'react'

import {Overview} from 'screens/Account/Overview'
import {Payments} from 'screens/Account/Payments'
import {Insurance} from 'screens/Account/Insurance'
import {InsuranceDamage} from 'screens/Account/InsuranceDamage'
import {InsuranceLiquid} from 'screens/Account/InsuranceLiquid'
import {InsuranceLost} from 'screens/Account/InsuranceLost'
import {InsuranceBlock} from 'screens/Account/InsuranceBlock'
import {InsuranceStolen} from 'screens/Account/InsuranceStolen'
import {InsuranceCrime} from 'screens/Account/InsuranceCrime'
import {InsuranceClaim} from 'screens/Account/InsuranceClaim'
import {Referrals} from 'screens/Account/Referrals'
import {YourAccount} from 'screens/Account/YourAccount'
import {AccountHeader} from 'components/Account/AccountHeader'
import {UpgradeRouter} from 'screens/Upgrade/UpgradeRouter'
import * as Sentry from '@sentry/react'
// NAVIGATION
import {Switch, Route, useLocation} from 'react-router-dom'
import {IntercomUtil} from 'components/Account/IntercomUtil'
import {AccountFooter} from 'components/Account/AccountFooter'
import store from 'store'
import {PhoneProfile} from './PhoneProfile'
import {Products} from './Products'
import {PaymentScreen} from "./Payment/PaymentScreen"
import {ArrearsPaymentStatusScreen} from "screens/Account/Payment/ProcessingPayment/ArrearsPaymentStatusScreen"

const SentryRoute = Sentry.withSentryRouting(Route)

const AccountWrapper = ({url, children}) => {

  useEffect(() => {
    window.loadIntercom()
  }, [])

  return (
    <>
      <IntercomUtil url={url}/>
      {children || null}
    </>
  )
}

export const AccountRouter = () => {

  const location = useLocation()

  const userToken = store.get("userToken")

  useEffect(() => {
    if (!userToken) {
      window.location = "/login"
    }
  }, [userToken])

  return (
    <AccountWrapper url={location.pathname}>
      <Switch>
        <SentryRoute path="/account/payment/:incomingPaymentId/processing">
          <AccountHeader section={'payment/processing'}/>
          <ArrearsPaymentStatusScreen/>
          <AccountFooter/>
        </SentryRoute>
        <SentryRoute path="/account/payment/:subscriptionId">
          <AccountHeader section={'payment'}/>
          <PaymentScreen/>
          <AccountFooter/>
        </SentryRoute>
        <SentryRoute path="/account/upgrade">
          <AccountHeader/>
          <UpgradeRouter/>
          <AccountFooter/>
        </SentryRoute>
        <SentryRoute path="/account/additional-tech">
          <AccountHeader/>
          <UpgradeRouter/>
          <AccountFooter/>
        </SentryRoute>
        <SentryRoute path="/account/products">
          <AccountHeader/>
          <Products/>
          <AccountFooter/>
        </SentryRoute>
        <SentryRoute path="/account/payments/:orderId">
          <AccountHeader/>
          <Payments/>
        </SentryRoute>
        <SentryRoute path="/account/insurance/damage/:orderId">
          <AccountHeader/>
          <InsuranceDamage/>
        </SentryRoute>
        <SentryRoute path="/account/insurance/liquid/:orderId">
          <AccountHeader/>
          <InsuranceLiquid/>
        </SentryRoute>
        <SentryRoute path="/account/insurance/lost/claim/:orderId">
          <AccountHeader/>
          <InsuranceClaim lost={true}/>
        </SentryRoute>
        <SentryRoute path="/account/insurance/lost/block/:orderId">
          <AccountHeader/>
          <InsuranceBlock lost={true}/>
        </SentryRoute>
        <SentryRoute path="/account/insurance/lost/:orderId">
          <AccountHeader/>
          <InsuranceLost/>
        </SentryRoute>
        <SentryRoute path="/account/insurance/stolen/claim/:orderId">
          <AccountHeader/>
          <InsuranceClaim stolen={true}/>
        </SentryRoute>
        <SentryRoute path="/account/insurance/stolen/crime/:orderId">
          <AccountHeader/>
          <InsuranceCrime/>
        </SentryRoute>
        <SentryRoute path="/account/insurance/stolen/block/:orderId">
          <AccountHeader/>
          <InsuranceBlock stolen={true}/>
        </SentryRoute>
        <SentryRoute path="/account/insurance/stolen/:orderId">
          <AccountHeader/>
          <InsuranceStolen/>
        </SentryRoute>
        <SentryRoute path="/account/insurance/:orderId">
          <AccountHeader/>
          <Insurance/>
        </SentryRoute>
        <SentryRoute path="/account/refer-a-friend">
          <AccountHeader section="refer-a-friend"/>
          <Referrals/>
        </SentryRoute>
        <SentryRoute path="/account/your-account">
          <AccountHeader section="account"/>
          <YourAccount/>
        </SentryRoute>
        <SentryRoute path="/account/:orderId">
          <AccountHeader section="view-device"/>
          <PhoneProfile/>
        </SentryRoute>
        <SentryRoute path="/account">
          <AccountHeader/>
          <Overview/>
        </SentryRoute>
      </Switch>
    </AccountWrapper>
  )
}
