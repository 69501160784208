import useRayloQuery from 'utils/useRayloQuery';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { checkoutCategory } from 'utils/checkoutCategory';
import { QL_SEGMENT_EVENT } from './checkoutSegmentEventProperties.graphql';

const mapRawProductDataToEventProperties = (data: any): {} => {
  const products = data.checkout.items.map((item: any) => {
    const product: any = {
      product_id: item.variant.id,
      condition: item.variant.condition,
      insurance: item.pricePlan.includesInsurance,
      lease_term: `${item.pricePlan.termLength} ${item.pricePlan.recurringPeriod}`,
      manufacturer: item.variant.product.manufacturer.displayName,
      monthly_total: item.costSummary.recurring.totalAmount.valueAfterTax.value,
      name: item.variant.displayName,
      price:
        item.pricePlan.costSummary.recurring.totalAmount.valueAfterTax.value,
      product_category: item.variant.product.category.displayName,
    };

    item.variant.optionValues.forEach((optionValue: any) => {
      const optionName = optionValue.optionType.name;
      product[optionName] = optionValue.displayName;
    });

    return product;
  });

  return {
    order_id: data?.checkout?.id,
    checkout_type: checkoutCategory(),
    products,
  };
};

export const useProductPropertiesEventQuery = (
  onCompleted: (result: {}) => void,
) => {
  const { checkoutToken } = useCheckoutContext();

  useRayloQuery(QL_SEGMENT_EVENT, {
    variables: { token: checkoutToken },
    onCompleted: (data: {}) => {
      onCompleted(mapRawProductDataToEventProperties(data));
    },
  });
};
