import React from 'react';

import * as T from 'styles/type';
import { FadeIn } from 'components/animations/Transitions';
import { BlocksAnimation } from 'components/animations/BlocksAnimation';
import lockIcon from 'public/icon--lock-pink.svg';
import {useSegmentIdentifyAndCheckoutTracking} from 'hooks/segment/segment.hooks'

export const KudosMain = ({paymentInfo, hasProviderError, children}) => {
  useSegmentIdentifyAndCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'credit kudos',
    checkoutScreen: 'credit kudos intro',
    when: 'on page load'
  });

  return (
    <div className="checkout--index">
      <div className="checkout--main">
        <div className="checkout--step feedback">


          <FadeIn>
            {hasProviderError
            ? 
            <T.RayloBodyHeading margin="28px"><strong>Please try again.</strong> There was a problem with your connection.</T.RayloBodyHeading>
            : 
            <T.RayloBodyHeading 
            data-testid='CreditKudosNeedMoreInformationHeader'
            margin="28px">We need a little more information before we dispatch your new tech.</T.RayloBodyHeading>
            }
          </FadeIn>

          <FadeIn>
            <div style={{marginBottom: 28, paddingBottom: 28, borderBottom: '1px solid #434343'}}>
              <T.RayloBodyType><span className="pink">Connect to Credit Kudos to securely share a snapshot of your account history. Once completed your device will be on it’s way.</span>
              </T.RayloBodyType>
            </div>
          </FadeIn>


          <FadeIn>
            <div style={{marginBottom: 28, paddingBottom: 28, borderBottom: '1px solid #434343'}}>
              <T.RayloBodyType style={{marginBottom: 14}}>
                Credit Kudos will prompt you to log into your <strong className="highlight" style={{whiteSpace: 'nowrap'}}>{paymentInfo.bankName}</strong> account:
              </T.RayloBodyType>

              <T.RayloBodyType>
                <strong className="highlight">{paymentInfo.bankBranchIdentifier}</strong>
                <span style={{opacity: 0.5, marginLeft: 10, marginRight: 10}}>|</span>
                <strong className="highlight">{paymentInfo.bankAccountNumber}</strong>
              </T.RayloBodyType>
            </div>
          </FadeIn>

          <div style={{marginBottom: 28}}>{children}</div>

          <FadeIn>
            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', color: "#FFCEC7" }}>
            <img src={lockIcon} alt="Icon of a secure lock" style={{marginRight: 10}} />
              <T.RayloBodyType style={{fontSize: '0.7rem'}}>
                <span className="pink">
                Both Raylo and Credit Kudos are authorised by the Financial Conduct Authority (FCA), so you can trust that your data will be held securely.
                </span>
              </T.RayloBodyType>
            </div>
          </FadeIn>

        </div>
      </div>
      <FadeIn>
        <BlocksAnimation />
      </FadeIn>
    </div>
  )

}
