import { StyledDualFontWeightHeader } from './styles';
import { IDualFontWeightHeader } from './types';

export const DualFontWeightHeader = ({
  dataTestId,
  children,
}: IDualFontWeightHeader) => {
  return (
    <StyledDualFontWeightHeader data-testid={dataTestId}>
      {children}
    </StyledDualFontWeightHeader>
  );
};
