import gql from 'graphql-tag';

export const QL_SEGMENT_EVENT = gql`
  query SegmentEventQuery($token: String!) {
    checkout(token: $token) {
      id
      items {
        pricePlan {
          termLength
          recurringPeriod
          includesInsurance
          costSummary {
            recurring {
              totalAmount {
                valueAfterTax {
                  value
                }
              }
            }
          }
        }
        variant {
          id
          displayName
          condition
          optionValues {
            displayName
            optionType {
              displayName
              name
            }
          }
          product {
            category {
              displayName
            }
            manufacturer {
              displayName
            }
          }
        }
        costSummary {
          recurring {
            totalAmount {
              valueAfterTax {
                value
              }
            }
          }
        }
      }
    }
  }
`;
