import React, { useEffect, useState } from 'react';
import _ from 'lodash';


import { useMedia } from 'use-media';


// STATE
import { useUiContext } from 'utils/useUiContext'


import {QL_HEADER} from 'screens/Checkout/graphQL/queries';

import { Header } from 'components/Checkout/Header';
import useRayloQuery from 'utils/useRayloQuery'

export const PhoneHeader = ({ checkoutToken }) => {

  // HOOKS
  const { showSummary, setShowSummary, showConfigure, setShowConfigure } = useUiContext()
  const matches = useMedia({maxWidth: 1024 });
  const mobile = useMedia({maxWidth: 450});

  const { data: { checkout }, loading } = useRayloQuery(QL_HEADER, {
    variables: {
      token: checkoutToken
    }
  })

  const [phone, setPhone] = useState({})

  useEffect(() => {

    if(checkout && checkout.items) {

      const variant = checkout.items[0].variant

      setPhone({
        model: variant.product.displayName,
        storage: _.find(variant.optionValues, o => o.optionType.slug === 'storage').displayName,
        color: _.find(variant.optionValues, o => o.optionType.slug === 'colour').displayName
      })
    }

  }, [checkout])

  useEffect(() => {
    if(showConfigure) {
      setShowSummary(false)
    }
    setShowConfigure(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[matches]);

  const closeModals = (event) => {
    event.preventDefault();
    setShowSummary(false)
    setShowConfigure(false)
  }


  if(loading) return (<div></div>)


  return (
    <Header
      summaryVisible={false}
      phone={phone}
      configure={showConfigure}
      showSummary={showSummary}
      mobileHeader={mobile}
      onBack={closeModals}
      onToggle={() => setShowSummary(!showSummary)} />
  )
}
