import Button from 'elements/Button';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Icon from 'elements/Icon';
import { Link } from 'react-router-dom';
import { primaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { IIconButton } from './types';

export const IconButton = ({
  dataTestId,
  size,
  buttonType,
  text,
  iconName,
  onClick,
  href,
  hrefTestId,
  iconColor,
}: IIconButton) => {
  const IconButtonComponent = () => (
    <div data-testid={dataTestId}>
      <Button iconText size={size} buttonType={buttonType} onClick={onClick}>
        <Container padding="0" flexRow>
          <Container
            padding={`${px2Rem(4)} ${px2Rem(8)} 0 0`}
            width="fit-content"
            center
          >
            <Icon name={iconName} size={`${px2Rem(16)}`} color={iconColor} />
          </Container>
          <Container padding="0" center>
            <Copy color={primaryColors.c01}>{text}</Copy>
          </Container>
        </Container>
      </Button>
    </div>
  );
  if (href) {
    return (
      <Link to={href} data-testid={hrefTestId}>
        <IconButtonComponent />
      </Link>
    );
  }
  return <IconButtonComponent />;
};
