import styled from 'styled-components';

const Wrapper = styled.div`
  & a {
    text-decoration: none;
  }
`;

const StyledBackArrowHeader = styled.div`
  display: flex;
  height: 50px;
  justify-content: center;
  ${({ styles }) => styles}
`;

export { StyledBackArrowHeader, Wrapper };
