import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';
import { ICopy } from './types';

const StyledCopy = styled.p<ICopy>`
  ${({ color }) => color && `color: ${color};`}
  ${({ fontSize }) => fontSize && `font-size: ${px2Rem(fontSize)};`}
  ${({ bold }) => bold && 'font-family: Raylo-Bold;'}
  ${({ italic }) => italic && 'font-style: italic;'}
  ${({ lineHeight }) => lineHeight && `line-height: ${px2Rem(lineHeight)};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ verticalAlign }) =>
    verticalAlign && `margin-top: ${px2Rem(verticalAlign)};`}
  ${({ styles }) => styles}
`;

export { StyledCopy };
