import DateOfBirthInput from 'components/DateOfBirthInput';
import RadioButtonBoxGroup from 'components/RadioButtonBoxGroup';
import TextInput from 'elements/TextInput';
import { useEffect, useState } from 'react';
import { dateValidation, isUser18, validation } from 'utils/formValidation';
import { StyledFormCollection } from './styles';
import { IFormCollection } from './types';

export const FormCollection = ({
  dataTestId,
  fields,
  handleSubmit,
  formId,
  presetValues,
}: IFormCollection) => {
  const [values, setValues] = useState<any>(presetValues || {});
  const [errors, setErrors] = useState<any>({});
  const [success, setSuccess] = useState<any>({});

  useEffect(() => {
    setValues(presetValues);
    if (presetValues?.dateOfBirth) {
      const dateArray = presetValues?.dateOfBirth?.split('-');
      setValues({
        mobilePhoneNumber: presetValues?.mobilePhoneNumber.replace('+44', '0'),
        dateOfBirth: {
          DD: dateArray[2],
          MM: dateArray[1],
          YYYY: dateArray[0],
        },
      });
    } else {
      setValues(presetValues);
    }
  }, [presetValues]);

  const handleInputChange = (
    field: string,
    e: any,
    type?: string,
    maxLength?: number,
  ) => {
    const { value, placeholder } = e.target;
    let valid = true;
    if (type && maxLength) {
      if (type === 'number' && value?.length > maxLength) {
        valid = false;
      }
    }
    if (field === 'dateOfBirth') {
      if (placeholder === 'DD' || placeholder === 'MM') {
        if (value?.length > 2) {
          valid = false;
        }
      }
      if (placeholder === 'YYYY') {
        if (value?.length > 4) {
          valid = false;
        }
      }
    }
    if (valid) {
      if (field === 'dateOfBirth') {
        setValues({
          ...values,
          [field]: {
            ...values?.[field],
            [placeholder]: value,
          },
        });
      } else {
        setValues({
          ...values,
          [field]: value,
        });
      }
    }
  };

  const handleRadioSelect = (field: string, value: string) => {
    setValues({
      ...values,
      [field]: value,
    });
  };

  // validate completed date of birth

  const isDateOfBirthComplete = () => {
    const dob = values?.dateOfBirth;
    const day = dateValidation('day', dob?.DD);
    const month = dateValidation('month', dob?.MM);
    const year = dateValidation('year', dob?.YYYY);
    return day === 'success' && month === 'success' && year === 'success';
  };

  useEffect(() => {
    if (values?.dateOfBirth) {
      const dob = values?.dateOfBirth;
      const completeDOB = isDateOfBirthComplete();
      const validUserAge = isUser18(dob?.DD, dob?.MM, dob?.YYYY);
      if (completeDOB) {
        if (!validUserAge) {
          setErrors({
            ...errors,
            dateOfBirth: 'Sorry, you have to be 18 or older to sign up',
          });
        } else {
          delete errors.dateOfBirth;
        }
      } else {
        delete errors.dateOfBirth;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.dateOfBirth]);

  const handleValidation = (
    type: string,
    field: string,
    required: boolean,
    name: string,
  ) => {
    const formValues = values;
    if (required) {
      const validationMessage = validation(
        type,
        formValues?.[field],
        field,
        name,
      );
      if (validationMessage !== 'success') {
        delete success[field];
        setErrors({
          ...errors,
          [field]: validationMessage,
        });
      } else {
        delete errors[field];
        setSuccess({
          ...success,
          [field]: validationMessage,
        });
      }
    }
  };

  const formatFiles = () => {
    const valueObject = values;
    if (valueObject?.mobilePhoneNumber) {
      const formattedNumber = valueObject?.mobilePhoneNumber
        .replace(/\s/g, '')
        .trim();
      valueObject.mobilePhoneNumber = formattedNumber;
    }
    setValues(valueObject);
  };

  const handleSubmitForm = (e: any) => {
    e.preventDefault();
    // validate all required fields
    const errorObject: any = {};
    const customValidation = ['dateOfBirth'];
    fields.forEach(({ type, field, name }) => {
      if (!customValidation.includes(field)) {
        const validationMessage = validation(
          type,
          values?.[field],
          field,
          name,
        );
        if (validationMessage !== 'success') {
          errorObject[field] = validationMessage;
        }
      }
      if (field === 'dateOfBirth') {
        const validationMessage = dateValidation(field, values?.[field]);
        if (validationMessage !== 'success') {
          errorObject[field] = validationMessage;
        }
      }
    });
    setErrors(errorObject);
    formatFiles();
    if (!Object.keys(errorObject).length) {
      handleSubmit(values);
    }
  };

  const handleOnBlur = (
    type: string,
    field: string,
    required: boolean,
    name: string,
  ) => {
    handleValidation(type, field, required, name);
  };

  return (
    <StyledFormCollection data-testid={dataTestId}>
      <form id={formId} onSubmit={handleSubmitForm}>
        {fields.map(
          ({
            name,
            type,
            field,
            required,
            maxLength,
            tooltip,
            options,
            pattern,
          }) => {
            if (field === 'dateOfBirth') {
              return (
                <DateOfBirthInput
                  name={name}
                  onChange={(e: any) =>
                    handleInputChange(field, e, type, maxLength)
                  }
                  error={errors[field]}
                  values={values?.dateOfBirth}
                />
              );
            }
            if (type === 'radio') {
              return (
                <RadioButtonBoxGroup
                  options={options}
                  label={name}
                  flexRow
                  onChange={handleRadioSelect}
                />
              );
            }
            return (
              <TextInput
                key={name}
                type={type}
                labelText={name}
                maxLength={maxLength}
                tooltip={tooltip}
                onChange={(e: any) =>
                  handleInputChange(field, e, type, maxLength)
                }
                onBlur={() => handleOnBlur(type, field, required, name)}
                error={errors[field]}
                success={success[field]}
                value={values?.[field]}
                fieldName={field}
                pattern={pattern}
                onFocus={(e: any) => e.target.select()}
              />
            );
          },
        )}
      </form>
    </StyledFormCollection>
  );
};
