import useMedia from 'use-media';
import { px2Rem } from 'utils/px2Rem';
import { useHistory } from 'react-router';
import DualFontWeightHeader from 'components/DualFontWeightHeader';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Header from 'elements/Header';
import PreApprovalLabel from 'elements/PreApprovalLabel';
import Spacer from 'elements/Spacer';
import PreApproveBannerImage from 'public/pre_approved_banner.png';
import { breakpoints } from 'styles/breakpoints';
import { primaryColors } from 'styles/variables/colors';
import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import { StyledButtonWrapper } from './styles';
import { IPreApprovalBanner } from './types';

export const PreApprovalBanner = ({
  dataTestId,
  bannerImage,
  isAccountHomePage,
  bannerImageSize,
  headerCopy,
  headerCopy2,
  headerSubCopy,
}: IPreApprovalBanner) => {
  const isTablet = useMedia({ maxWidth: breakpoints.desktop });
  const backgroundImageSize = isTablet ? '40%' : 'initial';

  const image = bannerImage || PreApproveBannerImage;
  const imageSize = bannerImageSize || backgroundImageSize;

  const history = useHistory();
  const handleViewAllTech = () => {
    history.push('/account/products');
  };
  return (
    <Container
      dataTestId={dataTestId}
      backgroundColor={primaryColors.b01}
      height={px2Rem(207)}
      maxWidth={px2Rem(1220)}
      padding="0"
      flexRow
      backgroundImage={image}
      backgroundImageSize={imageSize}
    >
      <Container
        padding={`${px2Rem(24)} ${px2Rem(36)}`}
        maxWidth={isAccountHomePage ? px2Rem(572) : 'initial'}
      >
        {!isAccountHomePage && (
          <DualFontWeightHeader>
            <Header color={primaryColors.p01} fontSize={36}>
              <span>All your latest</span> pre-approved tech
            </Header>
          </DualFontWeightHeader>
        )}
        {isAccountHomePage && (
          <Header
            level={1}
            color={primaryColors.p01}
            subFontWeight="normal"
            fontSize={36}
          >
            <span>{headerCopy}</span> {headerSubCopy}
            <span> {headerCopy2}</span>
          </Header>
        )}
        <Spacer height={16} />
        {!isAccountHomePage && (
          <Copy color={primaryColors.p01}>
            You can now lease additional tech for you or your family
          </Copy>
        )}
        {!isAccountHomePage && (
          <>
            <Spacer height={24} />
            <PreApprovalLabel />
          </>
        )}
        {isAccountHomePage && (
          <StyledButtonWrapper>
            <Spacer height={12} />
            <Button
              buttonColor={ButtonTypes.b01}
              buttonType="ghost"
              onClick={handleViewAllTech}
              size="medium"
              text="view all tech"
            />
          </StyledButtonWrapper>
        )}
      </Container>
      {isAccountHomePage && (
        <Container
          width={isTablet ? '50%' : '70%'}
          alignRight
          padding={`${px2Rem(24)} ${px2Rem(36)}`}
        >
          <PreApprovalLabel />
        </Container>
      )}
    </Container>
  );
};
