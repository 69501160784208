import { useState, useEffect } from 'react'
import constate from 'constate'
import { useHistory, useLocation } from 'react-router-dom'
import { rayloLight } from 'styles/themes/rayloLight'
import { affordableMobiles } from 'styles/themes/affordableMobiles'


const useUi = ({pricePlan = null}) => {

  const [showSummary, setShowSummary] = useState(false)
  const [showConfigure, setShowConfigure] = useState(false)
  const [showFeedback, setShowFeedback] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showDDModal, setShowDDModal] = useState(false)
  const [showDDContactModal, setShowDDContactModal] = useState(false)
  const [showInsuranceModal, setShowInsuranceModal] = useState(false)
  const [showTradeInModal, setShowTradeInModal] = useState(false)
  const [showTradeInTermsModal, setShowTradeInTermsModal] = useState(false)
  const [showPrivacyModal, setShowPrivacyModal] = useState(false)

  const [uiPricePlan, setUiPricePlan] = useState(pricePlan)
  const [showReview, setShowReview] = useState(false)

  const [tradeInInfo, setTradeInInfo] = useState(undefined)
  const [checkoutStep, setCheckoutStep] = useState(undefined)
  const [accountTheme, setAccountTheme] = useState(rayloLight)
  const [additionalTech, setAdditionalTech] = useState(false)
  const [lightMode, setLightMode] = useState(false)
  const [showHeader, setShowHeader] = useState(true)
  const [hideProgressBar, setHideProgressBar] = useState(false)
  const [isRayloPay, setIsRayloPay] = useState(false)
  const [theme, setTheme] = useState(rayloLight)
  const [showRPAfterLeaseModal, setShowRPAfterLeaseModal] = useState(false)
  const [showRPCancelOrderModal, setShowRPCancelOrderModal] = useState(false)

  const history = useHistory()
  let location = useLocation()

  useEffect(() => {
    if(window?.location?.hostname?.includes('raylopay')){
      setIsRayloPay(true)
    }
  }, [])
  
  useEffect(() => {
    setTheme(affordableMobiles)
  }, [isRayloPay])

  useEffect(() => {
    if(showConfigure) {
      if(location.pathname.indexOf('change-phone') < 0) {
        location.pathname = `${location.pathname}/change-phone`
        history.push(location)
      }
    } else if(location.pathname.indexOf('change-phone') > 0) {
      location.pathname = location.pathname.replace("/change-phone","")
      history.push(location)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showConfigure])

  const handleShowTradeInTermsModal = () => {
    setShowModal(true)
    setShowTradeInTermsModal(true)
  }

  return {
    showSummary,
    showConfigure,
    showFeedback,
    showModal,
    showDDModal,
    showDDContactModal,
    showInsuranceModal,
    uiPricePlan,
    showReview,
    tradeInInfo,
    checkoutStep,
    accountTheme,
    additionalTech,
    lightMode,
    showHeader,
    hideProgressBar,
    showTradeInModal,
    showTradeInTermsModal,
    showPrivacyModal,
    isRayloPay,
    theme,
    showRPAfterLeaseModal,
    showRPCancelOrderModal,
    setTheme,
    setIsRayloPay,
    setShowSummary,
    setShowConfigure,
    setShowFeedback,
    setShowModal,
    setShowDDModal,
    setShowDDContactModal,
    setShowInsuranceModal,
    setShowTradeInModal,
    setShowTradeInTermsModal,
    setUiPricePlan,
    setShowReview,
    setTradeInInfo,
    setCheckoutStep,
    setAccountTheme,
    setAdditionalTech,
    setLightMode,
    setShowHeader,
    setHideProgressBar,
    handleShowTradeInTermsModal,
    setShowPrivacyModal,
    setShowRPAfterLeaseModal,
    setShowRPCancelOrderModal
  }

}

const [UiProvider, useUiContext] = constate(useUi);
export { UiProvider, useUiContext }
