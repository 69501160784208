import styled from 'styled-components';
import { uiStateColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { ITextInput } from './types';

const StyledTextInput = styled.div<ITextInput>`
  height: ${px2Rem(118)};
  ${({ height }) => height && `height: ${height};`}
  & p:nth-child(1) {
    color: ${({ theme }) => theme?.form?.textInput?.labelColor};
    ${({ error }) => error && `color: ${uiStateColors.error}`}
  }

  @media (max-width: 600px) {
    & .place-top::after {
      left: 90% !important;
    }
  }
`;

const Input = styled.input<ITextInput>`
  width: 100%;
  height: ${px2Rem(56)};
  background-color: initial;
  font-size: ${px2Rem(16)};
  color: ${({ theme }) => theme?.form?.textInput?.color};
  border: solid 1px ${({ theme }) => theme?.form?.textInput?.borderColor};
  border-radius: 0;
  padding: ${px2Rem(18)} ${px2Rem(18)} ${px2Rem(18)} ${px2Rem(12)};
  ::placeholder {
    color: ${({ theme }) => theme?.form?.textInput?.placeholderColor};
    font-size: ${px2Rem(14)};
  }
  :focus {
    outline: none;
    border: solid 1px
      ${({ theme }) => theme?.form?.textInput?.focus?.borderColor};
  }
  ${({ error }) =>
    error &&
    `
    border-color: ${uiStateColors.error}
  `}
`;

const InputWrapper = styled.div`
  position: relative;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: ${px2Rem(20)};
  right: ${px2Rem(12)};
  height: ${px2Rem(16)};
  width: ${px2Rem(16)};
`;

export { StyledTextInput, Input, IconWrapper, InputWrapper };
