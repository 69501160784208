import React, { useEffect } from 'react';
import { activateOptimize } from 'utils/optimize'

import dayjs from 'dayjs'

// VISUAL COMPONENTS
import * as T from 'styles/type';
import { BackForwardGroup } from 'components/common/BackForwardGroup';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';

export const Age = ({onBack, dateOfBirth, onOK}) => {

  const date = dayjs(dateOfBirth)
  let now = dayjs().subtract(18,'year')
  const years = date.diff(now,'year')
  now = now.add(years, 'year')
  const days = date.diff(now,'day')

  useEffect(() => {
    activateOptimize()
  })

  return (
    <div className="checkout--step feedback">

      <>
        <FadeIn>
          <T.RayloBodyHeading><strong>Sorry, you have to be 18 or older to sign up</strong></T.RayloBodyHeading>
        </FadeIn>

        <SlideDownFadeIn>
          <div style={{ marginBottom: 40 }}>
            <T.RayloBodyType>We’d love to have you as a customer — just {years} {years === 1 ? 'year' : 'years'} and {days} {days === 1 ? 'day' : 'days'} to go!</T.RayloBodyType>
          </div>
        </SlideDownFadeIn>

        <SlideDownFadeIn>
          <BackForwardGroup
            submit={onOK}
            onBack={onBack}
            buttonText="OK" />
        </SlideDownFadeIn>
      </>
    </div>

  )
}
