import AfterLeaseChoiceRPModal from 'components/AfterLeaseChoiceRPModal';
import CheckoutBar from 'components/CheckoutBar';
import HowItWorks from 'components/HowItWorks';
import IconText from 'components/IconText';
import InformationBox from 'components/InformationBox';
import PricePlanOptions from 'components/PricePlanOptions';
import RayloPayCheckoutFooter from 'components/RayloPayCheckoutFooter';
import { Container, Header, Spacer } from 'elements';
import TrustPilotBanner from 'elements/TrustPilotBanner';
import { useEffect } from 'react';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { useUiContext } from 'utils/useUiContext';
import { StyledStepLeaseTerm } from './styles';
import { IStepLeaseTerm } from './types';

export const StepLeaseTerm = ({
  dataTestId,
  title,
  handleSelect,
  pricePlans,
  activeIndex,
  activePricePlan,
  imageUrl,
  confirmOnClick,
}: IStepLeaseTerm) => {
  const { showRPAfterLeaseModal, setShowRPAfterLeaseModal } = useUiContext();

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('update', {
        hide_default_launcher: true,
      });
    }
  }, []);

  return (
    <>
      <StyledStepLeaseTerm data-testid={dataTestId}>
        <TrustPilotBanner />
        <Spacer height={32} />
        <Container noPadding>
          <Header
            color={primaryColors.c01}
            level={2}
            lineHeight={29}
            subColor={secondaryColors.c02}
          >
            Welcome to Raylo Pay.{' '}
            <span>First lets choose the length of your lease</span>
          </Header>
        </Container>
        <Spacer height={20} />
        <Container noPadding boxShadow border={secondaryColors.c04}>
          <Container
            padding={`0 ${px2Rem(16)}`}
            backgroundColor={secondaryColors.c05}
          >
            <Spacer height={12} />
            {imageUrl && (
              <>
                <Container width="100%" noPadding center>
                  <img
                    src={imageUrl}
                    height={150}
                    alt="product display"
                    data-testid="productImage"
                  />
                </Container>
                <Spacer height={32} />
              </>
            )}
            <Header level={3} fontSize={18}>
              {title}
            </Header>
            <Spacer height={12} />
          </Container>
          <Spacer height={24} />
          <Container padding={`0 ${px2Rem(16)}`}>
            <PricePlanOptions
              pricePlans={pricePlans}
              activeIndex={activeIndex}
              onClick={handleSelect}
            />
          </Container>
        </Container>
        <Spacer height={24} />
        <InformationBox
          title="After the lease term – what happens?"
          linkText="Find out about your choices"
          onClick={() => setShowRPAfterLeaseModal(true)}
        />
        <Spacer height={32} />
        <IconText
          iconName="CustomerSupport"
          iconColor={secondaryColors.p02}
          copyColor={primaryColors.c01}
          copyText="Any questions?"
          linkText="We're here to help"
        />
        <Spacer height={24} />
        <HowItWorks title="How RayloPay works" />
        <RayloPayCheckoutFooter />
        <Spacer height={96} />
        <CheckoutBar
          monthlyPrice={activePricePlan?.monthlyPrice}
          buttonOnClick={confirmOnClick}
        />
      </StyledStepLeaseTerm>
      {showRPAfterLeaseModal && <AfterLeaseChoiceRPModal />}
    </>
  );
};
