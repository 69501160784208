import { useEffect } from "react"
import { useHistory, useParams } from "react-router"
import { getLowestCostPricePlan } from "utils/getLowestCostPricePlan"
import useRayloQuery from "utils/useRayloQuery"
import { GET_VARIANT_PRICE_PLANS } from "../graphQL/queries"

export const GetPricePlan = () => {
  const history = useHistory()
  const {variantId} = useParams()
  const {data} = useRayloQuery(GET_VARIANT_PRICE_PLANS, {
    variables: {
      ids: [variantId]
    },
    skip: !variantId
  })

  useEffect(() => {
    const pricePlans = data?.variants?.[0]?.pricePlans

    if(pricePlans) {
      const pricePlanId = getLowestCostPricePlan(pricePlans);
      const checkoutUrl = `/pick/${variantId}/${pricePlanId}${window?.location?.search}`;
      history.push(checkoutUrl);
    }
  }, [data])

  return (
    <></>
  )
}