import { Button, Container, Copy, Spacer } from 'elements';
import { ButtonTypes } from 'elements/Button/types';
import { primaryColors } from 'styles/variables/colors';
import { StyledCheckoutBar } from './styles';
import { ICheckoutBar } from './types';

export const CheckoutBar = ({
  dataTestId,
  monthlyPrice,
  buttonOnClick,
}: ICheckoutBar) => {
  return (
    <StyledCheckoutBar data-testid={dataTestId}>
      <Container
        width="100%"
        height="100%"
        boxShadowTop
        padding="0 20px"
        centerVertical
      >
        <Spacer height={12} />
        <Container maxWidth="414px" noPadding flexRow>
          <Container noPadding height="fit-content">
            <Copy color={primaryColors.c01} fontSize={14}>
              Pay monthly
            </Copy>
            <Spacer height={8} />
            <Copy
              color={primaryColors.c01}
              fontSize={16}
              bold
              dataTestId="monthlyPriceDisplay"
            >
              £{monthlyPrice}
            </Copy>
          </Container>
          <Container noPadding height="fit-content">
            <Copy color={primaryColors.c01} fontSize={14}>
              Pay today
            </Copy>
            <Spacer height={8} />
            <Copy
              color={primaryColors.c01}
              fontSize={16}
              bold
              dataTestId="monthlyUpfrontDisplay"
            >
              £0
            </Copy>
          </Container>
          <Container noPadding alignRight>
            <Button
              dataTestId="confirmCheckout"
              size="medium"
              text="confirm"
              buttonColor={ButtonTypes.b01}
              onClick={buttonOnClick}
            />
          </Container>
        </Container>
        <Spacer height={12} />
      </Container>
    </StyledCheckoutBar>
  );
};
