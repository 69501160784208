import DualFontWeightHeader from 'components/DualFontWeightHeader';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Header from 'elements/Header';
import { RadioDisplayBox, StyledRadioButtonBox } from './styles';
import { IRadioButtonBox } from './types';

export const RadioButtonBox = ({
  dataTestId,
  label,
  active,
  onClick,
  large,
  index,
  pricePlan,
  monthlyPrice,
  termLength,
}: IRadioButtonBox) => {
  const handleClick = () => {
    onClick(index);
  };
  return (
    <StyledRadioButtonBox data-testid={dataTestId}>
      {!pricePlan ? (
        <RadioDisplayBox onClick={handleClick} active={active} large={large}>
          {label}
        </RadioDisplayBox>
      ) : (
        <RadioDisplayBox onClick={handleClick} active={active} large={large}>
          <Container flexRow width="100%">
            <Container noPadding>
              <Copy bold={active} color="black" fontSize={14}>
                {termLength} months lease
              </Copy>
            </Container>
            <Container alignRight noPadding>
              <DualFontWeightHeader>
                <Header
                  level={6}
                  color="black"
                  fontSize={18}
                  dataTestId={`monthlyPriceOptionView-${index}`}
                >
                  <span
                    style={{
                      fontSize: '14px',
                    }}
                  >
                    From
                  </span>{' '}
                  £{monthlyPrice}
                  <span
                    style={{
                      fontSize: '14px',
                    }}
                  >
                    /mo
                  </span>
                </Header>
              </DualFontWeightHeader>
            </Container>
          </Container>
        </RadioDisplayBox>
      )}
    </StyledRadioButtonBox>
  );
};
