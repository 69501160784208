import React, { useEffect, useState } from 'react';
import { captureErrorEvent } from 'utils/errorTracking'
import _ from 'lodash'
import { activateOptimize } from 'utils/optimize'

// HOOKS

import {  useHistory, useLocation } from 'react-router-dom'

// GRAPHQL
import { QL_KUDOS } from 'screens/Checkout/graphQL/queries';
import { MU_RESTART_CHECKOUT, MU_START_BANKING_CONNECTION } from 'screens/Checkout/graphQL/mutations';

// VISUAL COMPONENTS
import { Modal } from 'components/common/Modal';
import { useMutation } from '@apollo/client';

import useRayloQuery from 'utils/useRayloQuery';
import { KudosError } from 'components/Checkout/KudosError';
import { KudosRedirectButton } from 'components/Checkout/KudosRedirectButton';
import { KudosMain } from 'components/Checkout/KudosMain';
import { KudosWaiting } from 'components/Checkout/KudosWaiting';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { KUDOS_CONFIG } from 'utils/constants';
import { useErrorNavigator}  from 'utils/useErrorNavigator'

export const Kudos = () => {

  const history = useHistory()
  const errorNav = useErrorNavigator()
  let location = useLocation()

  const { checkoutToken } = useCheckoutContext()

  const [oauthRedirectUri, setOauthRedirectUri] = useState(undefined)
  const [errorCode, setErrorCode] = useState(undefined)
  const [restartCheckout] = useMutation(MU_RESTART_CHECKOUT)
  const [startBankingConnection] = useMutation(MU_START_BANKING_CONNECTION)
  const [startBankingConnectionRequested, setStartBankingConnectionRequested] = useState(false)
  const [forceTimeout, setForceTimeout] = useState(false)

  const { data: {checkout}, error, loading, refetch, startPolling, stopPolling } = useRayloQuery(QL_KUDOS, {
    variables: {
      checkoutToken,
      callbackUri: KUDOS_CONFIG.callbackUri
    }
  })

  const isBankConnectionRequested = (checkout) => {
    return (
      checkout?.decision?.outcome === "USER_ACTION_REQUIRED" &&
      checkout?.decision?.outcomeReason === "bank_connection_requested"
    )
  }

  useEffect(() => {
    activateOptimize()
  })

  useEffect(() => {
    if(errorCode) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode])

  const pushIfNotCurrent = (url) => {
    if(location.pathname !== url) {
      history.push(url)
    }
  }

  useEffect(() => {
    if(forceTimeout) {
      stopPolling()
      setErrorCode('timeout')
      pushIfNotCurrent(`/checkout/decision/bank-connection/timeout`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceTimeout])

  const forceStopPolicy = () => {
    setForceTimeout(true)
  }

  useEffect(() => {
    // Checkout needs to be present before evaluating this useEffect
    if(checkout && checkout.decision) {
      if(!isBankConnectionRequested(checkout)) {
        history.push('/checkout/decision')
      }
    }

    if(!isBankConnectionRequested(checkout)) {
      return
    }

    if (checkout && (checkout.state === 'ABANDONED' || checkout.state === 'SUBMITTED')) {
      restartCheckout({
        variables: {
          checkoutToken
        }
      })
    }

    if (checkout?.bankingConnection) {
      if (startBankingConnectionRequested && checkout.bankingConnection.oauthRedirectUri) {
        setOauthRedirectUri(checkout.bankingConnection.oauthRedirectUri)

        if (checkout.bankingConnection.state === "AUTHORIZED" && !forceTimeout) {
          pushIfNotCurrent("/checkout/decision/bank-connection/in-progress")
          startPolling(5000)
        } else {
          stopPolling()
        }
      }
      else if (!startBankingConnectionRequested) {
        // send graphQL mutation startBankingConnection
        startBankingConnection({
          variables: {
            checkoutToken
          }
        })
        // set state to mark startBankingConnectionRequested
        setStartBankingConnectionRequested(true)
      }
    }

    if(checkout?.incompleteAttributes && !forceTimeout) {
      const incompleteError = _.find(checkout.incompleteAttributes, {field: 'user_action_requirement'})
      if(incompleteError) {
        switch (incompleteError.code) {
          case "bank_connection_unauthorized":
            pushIfNotCurrent(`/checkout/decision/bank-connection`);
            break;     
          case "bank_connection_mismatch":
            pushIfNotCurrent(`/checkout/decision/bank-connection/bank-account-mismatch`)
            break;         
          case "bank_connection_missing":
            break;             
          default:
            captureErrorEvent("Kudos: incompleteAttributes", {incompleteError})
            pushIfNotCurrent(`/checkout/decision/bank-connection/${incompleteError.code}`)
            break;
        }
        setErrorCode(incompleteError.code)
      } else {
        setErrorCode(undefined)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout, forceTimeout])

  useEffect(() => {
    if(startBankingConnectionRequested && !checkout?.bankingConnection?.oauthRedirectUri) {
      startPolling(2000)
    } else {
      stopPolling()
    }
  }, [startBankingConnectionRequested, checkout])

  if(loading) {
    return <Modal visible text="Loading..." />
  }

  if(error) {
    errorNav("Kudos: failure", error)
  }

  if(checkout?.bankingConnection && isBankConnectionRequested(checkout)) {
    if((errorCode && ["account_mismatch","timeout"].includes(errorCode)) || checkout.bankingConnection.state === "ESTABLISHED" || checkout.bankingConnection.state === "REFUTED") {
      return (<KudosError
        errorCode={errorCode}
        paymentInfo={checkout.paymentInfo}>
          <KudosRedirectButton uri={oauthRedirectUri} />
        </KudosError>)
    } else if(checkout.bankingConnection.state === "AUTHORIZED") {
      return (<KudosWaiting stopPolling={forceStopPolicy} />)
    } else if(checkout.bankingConnection.state === "UNAUTHORIZED" && oauthRedirectUri) {
      return (<KudosMain
        hasProviderError={checkout.bankingConnection.hasProviderError}
        paymentInfo={checkout.paymentInfo}>
          <KudosRedirectButton uri={oauthRedirectUri} />
        </KudosMain>)
         } else if(checkout.bankingConnection.state === "UNAUTHORIZED") {
          // the component needs to check for changes in oauthRedirectUri so it eventually loads the correct page once that variable is set
          return(<Modal visible text="Loading..." />)
    } else if(checkout.bankingConnection.state === "VERIFIED") {
      // return to checkout decision to display the updated outcome
      history.push(`/checkout/decision/`)
    } else {
      errorNav("Kudos: failure - no state")
    }
  }
  return <Modal visible text="Loading..." />
}
