import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints';
import { px2Rem } from 'utils/px2Rem';

const StyledPreApprovedDeviceCard = styled.div`
  max-width: ${px2Rem(362)};
  & h2 {
    text-align: center;
  }
  & ul {
    margin: 0;
    padding: 0;
    padding-left: ${px2Rem(16)};
    color: #fff;
    font-size: ${px2Rem(16)};
    line-height: ${px2Rem(30)};
  }
  @media (max-width: ${breakpoints.mobile}) {
    margin: auto;
  }
`;

export { StyledPreApprovedDeviceCard };
