import React, { useEffect } from 'react';
// VISUAL COMPONENTS
import * as T from 'styles/type';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { Loader } from 'components/common/Loader';
import { BlocksAnimation } from 'components/animations/BlocksAnimation';

export const KudosWaiting = ({stopPolling}) => {

  useEffect(() => {
    const timer = setTimeout(() => {
      stopPolling()
    }, 1000 * 3 * 60)
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="checkout--index">
      <div className="checkout--main">
        <div className="checkout--step feedback">
          <FadeIn>
            <>
              <SlideDownFadeIn>
              <T.RayloBodyHeading margin="20px">
                <strong>OK. Great.</strong> We’re running our checks now …
              </T.RayloBodyHeading>
              </SlideDownFadeIn>

              <SlideDownFadeIn>
              <T.RayloBodyType style={{ color: '#FFCEC7', marginBottom: 28 }}>
                This can take up to two minutes
              </T.RayloBodyType>
              </SlideDownFadeIn>

              <FadeIn>
              <Loader type="quad" />
              </FadeIn>
            </>
          </FadeIn>
        </div>
      </div>
      <FadeIn>
        <BlocksAnimation />
      </FadeIn>
    </div>
  )
}
