import { breakpoints } from 'styles/breakpoints';
import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';

const StyledStepTradeInInsurance = styled.div`
  padding-top: ${px2Rem(80)};

  @media (max-width: ${breakpoints.desktop}px) {
    padding-top: ${px2Rem(40)};
  }
`;

export { StyledStepTradeInInsurance };
