import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { activateOptimize } from 'utils/optimize'

// HOOKS
import { useMutation } from '@apollo/client';
import useForceUpdate from 'use-force-update';

// GRAPHQL
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';

// VISUAL COMPONENTS
import * as T from 'styles/type';
import * as L from 'styles/layout';
import * as I from 'styles/inputs';
import { Link } from 'components/common/Link';
import { BackForwardGroup } from 'components/common/BackForwardGroup';
import { FadeIn } from 'components/animations/Transitions';
import { Insurance } from 'components/Checkout/Insurance';
import { Modal } from 'components/common/Modal';
import { Toggle } from 'components/common/Toggle';
import brokenPhone from 'public/icon--broken-phone.svg';
import globe from 'public/icon--globe.svg';
import fiveStar from 'public/icon--5-star.svg';
import { MU_SWAP_CHECKOUT } from '../graphQL/mutations';
import { SoldOut } from 'screens/Errors/SoldOut';
import { InvalidChoice } from 'screens/Errors/InvalidChoice';
import { CatchAll } from 'screens/Errors/CatchAll';
import { useUiContext } from 'utils/useUiContext'
import useRayloQuery from 'utils/useRayloQuery'
import { Button } from 'components/common/Button';
import styled from 'styled-components/macro';
import Currency from 'react-currency-formatter';
import { getSessionStorage } from 'utils/handleSessionStorage';
import { isAdditonalTech } from 'utils/handleGetSessionStorageValues';
import { formatDisplayPrice } from 'utils/formatDisplayPrice';
import { useHistory } from 'react-router';
import {useSegmentIdentifyAndCheckoutTracking} from 'hooks/segment/segment.hooks'


export const StepInsurance = ({ checkoutToken, setPricePlanCallback, onSuccess, onBack }) => {
  useSegmentIdentifyAndCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'insurance',
    checkoutScreen: 'add insurance',
    when: 'on page load'
  });

  // HOOKS
  const [showPolicy, setShowPolicy] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const { uiPricePlan, setUiPricePlan, setShowFeedback } = useUiContext()
  const [insuranceChosen, setInsuranceChosen] = useState(false)
  const [invalidChoice, setInvalidChoice] = useState(false)

  const [originalPricePlan, setOriginalPricePlan] = useState({})
  const [variant, setVariant] = useState({})
  const [item, setItem] = useState({})
  const [errorCode, setErrorCode] = useState("")
  const [pricePlanWithInsurance, setPricePlanWithInsurance] = useState({})
  const [pricePlanWithoutInsurance, setPricePlanWithoutInsurance] = useState({})
  const [additionalTech, setAdditionalTech] = useState(false)

  const history = useHistory()

  const [swapCheckoutItem] = useMutation(MU_SWAP_CHECKOUT, {
    update: (proxy, { data: { swapCheckoutItem: { checkout, errors } } }) => {
      if (errors && errors.length > 0) {
        if (_.filter(errors, { code: "sold_out" }).length > 0) {
          setErrorCode("sold_out")
        } else if (_.filter(errors, { code: "blank" }).length > 0) {
          setErrorCode("bad_choice")
        } else {
          setErrorCode("generic")
        }
      } else {
        onSuccess()
      }
    },
    refetchQueries: ['getProducts', 'queryPhoneSummary']
  })
  const forceUpdate = useForceUpdate()


  const handleInsuranceChange = () => {
    const pricePlanToSwap = uiPricePlan.id === pricePlanWithInsurance.id ? pricePlanWithoutInsurance : pricePlanWithInsurance
    setUiPricePlan(pricePlanToSwap)
  }

  const yesInsurance = () => {
    setInsuranceChosen(true)
    setInvalidChoice(false)
    setUiPricePlan(pricePlanWithInsurance)
  }
  const noInsurance = () => {
    setInsuranceChosen(true)
    setInvalidChoice(false)
    setUiPricePlan(pricePlanWithoutInsurance)
  }

  const showInsuranceModal = () => {
    setShowFeedback(true)
    setShowPolicy(true)
  }

  const hideInsuranceModal = () => {
    setShowFeedback(false)
    setShowPolicy(false)
  }

  const onValidateAndSubmit = () => {
    setInvalidChoice(false)
    if (uiPricePlan.id === pricePlanWithoutInsurance.id && !insuranceChosen) {
      setInvalidChoice(true)
      return
    }
    return onSubmit()
  }

  const onSubmit = (validate = false) => {
    if (originalPricePlan.id === uiPricePlan.id) {
      onSuccess()
      return
    }

    swapCheckoutItem({
      variables: {
        variantId: variant.id,
        pricePlanId: uiPricePlan.id,
        checkoutToken,
        itemId: item.id
      }
    })
  }

  useEffect(() => {
    setAdditionalTech(!!getSessionStorage('additionalTech'))
  })

  useEffect(() => {
    if (additionalTech) {
      setShowLoading(true)
      const timer1 = setTimeout(() => {
        setShowLoading(false)
      }, 3000)
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [additionalTech])


  const { data: { checkout }, loading } = useRayloQuery(CheckoutSteps.stepInsurance.query, {
    variables: {
      token: checkoutToken
    }
  })

  useEffect(() => {
    activateOptimize()
  })

  useEffect(() => {
    if (checkout) {
      const { variant, pricePlan } = checkout.items[0]
      setUiPricePlan(pricePlan)
      setOriginalPricePlan(pricePlan)
      setVariant(variant)
      setItem(checkout.items[0])
      setPricePlanWithInsurance(_.find(variant.pricePlans, { includesInsurance: true, termLength: pricePlan.termLength, recurringPeriod: pricePlan.recurringPeriod }))
      setPricePlanWithoutInsurance(_.find(variant.pricePlans, { includesInsurance: false, termLength: pricePlan.termLength, recurringPeriod: pricePlan.recurringPeriod }))
      forceUpdate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout])

  const handleOnBack = () => {
    if (isAdditonalTech()) {
      history.push('/account')
    } else {
      onBack()
    }
  }

  if (errorCode === "sold_out") {
    return (<SoldOut onClick={() => setErrorCode("")} buttonStyle="secondary" />)
  } else if (errorCode === "bad_choice") {
    return (<InvalidChoice onClick={() => setErrorCode("")} buttonStyle="secondary" />)
  } else if (errorCode === "generic") {
    return (<CatchAll onClick={() => setErrorCode("")} buttonStyle="secondary" />)
  }

  if (loading || !uiPricePlan || !pricePlanWithInsurance || !pricePlanWithInsurance.costSummary) return (<Modal visible text="Loading..." />)

  return (
    <div className="checkout--step">
      {showPolicy &&
        <Insurance visible={showPolicy} onBack={hideInsuranceModal} />
      }
      {showLoading &&
        <Modal visible text="Loading..." />
      }

      <L.InsuranceSignup>
        <FadeIn visible={true}>
          <T.RayloBodyHeading>
            Add our insurance for just £{formatDisplayPrice(pricePlanWithInsurance.costSummary.recurring.insuranceAmount.valueAfterTax.value)}/mo
          </T.RayloBodyHeading>
        </FadeIn>

        <FadeIn visible={true}>
          <p className="intro">For just £{formatDisplayPrice(pricePlanWithInsurance.costSummary.recurring.insuranceAmount.valueAfterTax.value)} per month, you can benefit from Raylo’s very own insurance policy which includes:</p>
        </FadeIn>

        <FadeIn visible={true}>
          <ul className="features">
            <li>
              <img src={brokenPhone} alt="Icon of a broken smartphone" />
              <span>Cover against loss, theft &amp; accidental damage</span>
            </li>
            <li>
              <img src={globe} alt="Icon of the Earth with a tick mark" />
              <span>Worldwide protection</span>
            </li>
          </ul>
        </FadeIn>

        <FadeIn visible={true}>
          <div>
            <p>Provided by Pier, underwritten by AXA and 5-star Defaqto rating.</p>
          </div>
        </FadeIn>

        <FadeIn visible={true}>
          <div>
            <p className="policy">Since it's tied to your Raylo subscription, you won't be able to add it later. <Link onClick={() => showInsuranceModal()} inline>Read our policy summary for more information</Link></p>
          </div>
        </FadeIn>



        <FadeIn visible={true}>
          <Toggle active={uiPricePlan.id === pricePlanWithInsurance.id} labelOff="Don't Include" labelOn={`Include for £${formatDisplayPrice(pricePlanWithInsurance.costSummary.recurring.insuranceAmount.valueAfterTax.value)}/mo`} onClick={handleInsuranceChange} />
        </FadeIn>

        <FadeIn visible={true}>
          <InsuranceMonthly>
            <p>Total Monthly Payment</p>
            <p><Currency quantity={uiPricePlan.costSummary.recurring.totalAmount.valueAfterTax.value} currency={uiPricePlan.costSummary.recurring.totalAmount.valueAfterTax.currencyCode} /></p>
          </InsuranceMonthly>
        </FadeIn>

      </L.InsuranceSignup>
      <FadeIn visible={true}>
        <BackForwardGroup onBack={handleOnBack} submit={{ onClick: onSubmit }} />
      </FadeIn>
    </div>
  )
}

export const InsuranceMonthly = styled.div`
  margin-top: 14px;
  padding-top: 24px;
  border-top: 1px solid rgba(151,151,151,0.5);
  display: flex; justify-content: space-between; align-items: center;

`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  button:first-child {
    margin-right: 10px;
  }

  @media screen and (max-width: 320px) {
    button {
      padding: 1em 1.25em;
    }
  }

`
