import { Container, Icon, Spacer } from 'elements';
import { StyledHowItWorksCard, StyledStepNumber } from './styles';
import { IHowItWorksCard } from './types';

export const HowItWorksCard = ({
  dataTestId,
  icon,
  content,
  step,
}: IHowItWorksCard) => {
  return (
    <StyledHowItWorksCard data-testid={dataTestId}>
      <Container>
        <Container flexRow noPadding>
          <Container noPadding>
            <StyledStepNumber>{step}</StyledStepNumber>
          </Container>
          <Container noPadding alignRight>
            <Icon name={icon || ''} />
          </Container>
        </Container>
        <Container noPadding>{content}</Container>
        <Spacer height={8} />
      </Container>
    </StyledHowItWorksCard>
  );
};
