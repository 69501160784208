import {useEffect, useState} from 'react'
import _ from 'lodash'
import {Helmet} from 'react-helmet'
import {useCookies} from 'react-cookie'
import {ThemeProvider} from 'styled-components'

// STATE
import {useUiContext} from 'utils/useUiContext'
import 'styles/checkout-layout.css'
import {ChangePhoneContext} from 'utils/useChangePhoneContext'

// COMPONENTS
import * as L from 'styles/layout'
import {Modal} from 'components/common/Modal'
import {ProgressBar} from 'components/Checkout/ProgressBar'
import {FadeIn} from 'components/animations/Transitions'
import {BlocksAnimation} from 'components/animations/BlocksAnimation'
import {ChangePhone} from 'screens/Checkout/components/ChangePhone'
import {PhoneHeader} from 'screens/Checkout/components/PhoneHeader'
import {PhoneSummary} from 'screens/Checkout/components/PhoneSummary'

// GRAPHQL
import {CheckoutSteps, QL_CHECKOUT_BASE, QL_HEADER} from 'screens/Checkout/graphQL/queries'
import {FRONTEND_URL} from 'utils/constants'
import {captureErrorEvent} from 'utils/errorTracking'
import useRayloQuery from 'utils/useRayloQuery'
import {useHistory} from 'react-router'
import {useCheckoutContext} from 'utils/useCheckoutContext'
import {useTrackCheckoutContext} from 'utils/trackCheckout'

// Utils
import classNames from 'classnames'
import { useMutation } from '@apollo/client';
import CheckoutStepsContainer from './containers/CheckoutStepsContainer';
import { SLUGS } from './helpers/slugs';
import Container from 'elements/Container';
import { pageTitles } from './helpers/pageTitles';
import { CheckoutMode, useGetCheckoutMode } from './hooks/useGetCheckoutMode';
import { primaryColors } from 'styles/variables/colors';
import { rayloLight } from 'styles/themes/rayloLight';
import CheckoutHeader from './components/CheckoutHeader';
import useMedia from 'use-media';
import { breakpoints } from 'styles/breakpoints';
import RayloModal from 'components/RayloModal';
import DirectDebitModal from 'components/DirectDebitModal';
import DirectDebitContactModal from './components/DirectDebitContactModal';
import InsuranceModal from './components/InsuranceModal';
import { getLatestSubscriptionBankDetails } from 'utils/getLatestSubscriptionBankDetails';
import TradeInModal from './components/TradeInModal';
import TradeInTermsModal from './components/TradeInTermsModal';
import PrivacyModal from './components/PrivacyModal';
import RayloPayHeader from 'components/RayloPayHeader';
import CheckoutProgressBar from 'components/CheckoutProgressBar';
import { CheckoutStepsComponent } from './components/CheckoutSteps/CheckoutSteps';
import { Spacer } from 'elements';

const slugLookup = (slug) => {
  return _.invert(SLUGS)[slug]
}

export const Checkout = ({initialStep = 'StepInsurance'}) => {

  const history = useHistory()
  const {
    checkoutToken,
    currentStep,
    merchantHasInsuranceOption,
    merchantHasTradeIn,
    isRPCheckout,
    setCurrentStep,
    setMode,
    setCustomerDetails
  } = useCheckoutContext()
  const {checkoutMode} = useGetCheckoutMode()
  const isMobile = useMedia({maxWidth: breakpoints.desktop})

  const [trackings, setTrackings] = useState(undefined)
  const [waitForExperiment, setWaitForExperiment] = useState(0)
  const [cookies, removeCookie] = useCookies(['raylo_tracking'])
  const [isUpgrade, setIsUpgrade] = useState(false)

  useEffect(() => {
    if (waitForExperiment === 0) {
      if (cookies && cookies.raylo_tracking && cookies.raylo_tracking !== "undefined") {
        setWaitForExperiment(1)
        setTrackings(cookies.raylo_tracking)
      } else {
        setWaitForExperiment(2)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies])

  const {
    setCheckoutStep,
    showSummary,
    showFeedback,
    showModal,
    showDDModal,
    showDDContactModal,
    showConfigure,
    lightMode,
    showHeader,
    showInsuranceModal,
    showTradeInModal,
    showTradeInTermsModal,
    setShowConfigure,
    setLightMode,
    showPrivacyModal,
    theme,
  } = useUiContext()
  const {trackCheckout} = useTrackCheckoutContext()
  const [step, setStep] = useState(slugLookup(initialStep))
  const [hasInsurance, setHasInsurance] = useState(false)
  const [firstLoad, setFirstLoad] = useState(false)
  const [customerInfo, setCustomerInfo] = useState(null)
  const [additionalTech, setAdditionalTech] = useState(false)
  const [title, setTitle] = useState(undefined)
  const [condition, setCondition] = useState(undefined)
  const [backgroundColor, setBackgroundColor] = useState('initial')

  const {data: {checkout}, loading} = useRayloQuery(QL_CHECKOUT_BASE, {
    skip: waitForExperiment < 2,
    variables: {
      token: checkoutToken
    }
  })

  const {data: {checkout: checkoutTwo}, loading: loadingTwo} = useRayloQuery(CheckoutSteps.stepUpgrade.query, {
    variables: {
      token: checkoutToken
    }
  })

  const {data: {checkout: checkoutThree}} = useRayloQuery(QL_HEADER, {
    variables: {
      token: checkoutToken
    }
  })

  const [updateMutation] = useMutation(CheckoutSteps.stepUpgrade.mutation, {
    update: (proxy, {data: {updateCheckout: {errors, checkout}}}) => {
      if (errors && errors.length > 0) {
        console.log(errors)
      } else {
        if (window?.analytics) {
          window.analytics.identify(checkout.id, {email: checkout.customerInfo.email})
        }
      }
    }
  })

  useEffect(() => {
    if (checkout?.customer) {
      const subscriptions = checkout.customer?.subscriptions
      const bankDetails = getLatestSubscriptionBankDetails(subscriptions)
      setCustomerDetails(bankDetails)
    }
  }, [checkout, loading])

  useEffect(() => {
    setTitle(checkoutThree?.items[0]?.variant?.product?.displayName)
    setCondition(checkoutThree?.items[0]?.variant?.condition)
  }, [checkoutThree])

  useEffect(() => {
    setAdditionalTech(checkoutMode === CheckoutMode.ADDITIONAL_TECH)
  }, [checkoutMode])

  useEffect(() => {
    setMode(checkoutMode)
    setLightMode(true)
  }, [checkoutMode])

  useEffect(() => {
    if (history?.location?.pathname === '/checkout/step/bank/view' && checkoutMode !== CheckoutMode.NEW_CUSTOMER) {
      setCurrentStep('StepBank')
      history.push('/checkout/step/bank')
    }
  }, [history?.location])

  useEffect(() => {
    if (checkoutTwo) {
      if (checkoutTwo.customerInfo) {
        setCustomerInfo(_.pick(checkoutTwo.customerInfo, 'firstName', 'lastName', 'dateOfBirth', 'email'))
      } else if (checkoutTwo.customer) {
        setCustomerInfo(_.pick(checkoutTwo.customer, 'firstName', 'lastName', 'dateOfBirth', 'email'))
      }
    }
  }, [checkoutTwo])

  useEffect(() => {
    // add stored name, email and dob for customers buying additional tech
    if (customerInfo && additionalTech) {
      updateMutation({
        variables: {
          ...customerInfo,
          checkoutToken
        }
      })
    }
  }, [customerInfo, additionalTech])

  if (!checkoutToken) {
    window.location = FRONTEND_URL
  }

  function handleChange(e) {
    const pathElements = window.location.pathname.split("/")
    const slug = pathElements[3]
    const changePhone = pathElements[4] && pathElements[4] === 'change-phone'
    setStep(slugLookup(slug))
    setShowConfigure(changePhone)
  }

  useEffect(() => {
    window.addEventListener('popstate', handleChange)
    return () => window.removeEventListener('popstate', handleChange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCheckoutStep(step)
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  const stepsOrder = (insurance, upgrade = false, checkout) => {

    let stepStripe = showStepStripe(checkout) ? ['StepStripe'] : []

    if (checkoutMode === CheckoutMode.ADDITIONAL_TECH) {
      return _.compact(_.concat(
        merchantHasInsuranceOption ? ['StepInsurance'] : [],
        [
          'StepAddress',
          'StepMobileJob'
        ],
        stepStripe,
        [
          'StepBank',
          'StepSign'
        ]
      ))
    }
    if (checkoutMode === CheckoutMode.UPGRADE) {
      return _.compact(_.concat(
        [
          'StepUpgrade',
          'StepInsurance',
          'StepAddress',
          'StepMobileJob',
        ],
        stepStripe,
        [
          'StepBank',
          'StepSign'
        ]
      ))
    }
    if (checkoutMode === CheckoutMode.NEW_CUSTOMER) {
      if(isRPCheckout) {     
        return _.compact(_.concat(
          'StepLeaseTerm',
          showStepTradeIn(checkout) ? ['StepTradeIn'] : [],
          merchantHasInsuranceOption ? ['StepInsurance'] : [],
          [
            'StepNameEmail',
            'StepAddress',
            'StepMobileJob',
          ],
          stepStripe,
          [
            'StepBank',
            'StepSign'
          ]
        ))
      }
      return _.compact(_.concat(
        showStepTradeIn(checkout) ? ['StepTradeIn'] : [],
        merchantHasInsuranceOption ? ['StepInsurance'] : [],
        [
          'StepNameEmail',
          'StepAddress',
          'StepMobileJob'
        ],
        stepStripe,
        [
          'StepBank',
          'StepSign'
        ]
      ))
    }
  }

  const showStepStripe = (checkout) => {
    return checkout?.initialPaymentMethodRequired
  }

  const showStepTradeIn = (checkout) => {
    return checkout?.items[0]?.variant?.product?.category?.slug === 'phone' && merchantHasTradeIn
  }

  const nextStep = (current) => {
    if (current === "StepSignOffer") {
      current = "StepSign"
    }
    const steps = stepsOrder(hasInsurance, isUpgrade, checkout)
    const currentPos = steps.indexOf(current)
    if (steps.length > currentPos) {
      return goToStep(steps[currentPos + 1])
    } else {
      return window.location = FRONTEND_URL
    }
  }

  const previousStep = (current) => {
    if (current === "StepSignOffer") {
      current = "StepSign"
    }
    const steps = stepsOrder(hasInsurance, isUpgrade, checkout)
    const currentPos = steps.indexOf(current)
    if (currentPos > 0) {
      return goToStep(steps[currentPos - 1])
    } else {
      return window.location = FRONTEND_URL
    }
  }

  const stepNumber = (current) => {
    if (current === "StepSignOffer") {
      current = "StepSign"
    }
    const steps = stepsOrder(hasInsurance, isUpgrade, checkout)
    const currentPos = steps?.indexOf(current)
    return currentPos + 1
  }
  const globalStepNumber = (current) => {
    if (current === "StepSignOffer") {
      current = "StepSign"
    }
    const steps = stepsOrder(false, isUpgrade, checkout)
    const currentPos = steps.indexOf(current)
    return currentPos + 1
  }

  const totalSteps = () => {
    return stepsOrder(hasInsurance, isUpgrade, checkout)?.length
  }

  const paymentStep = () => {
    return Math.min(stepNumber("StepStripe"), stepNumber("StepBank"))
  }

  const goToStep = (newStep, replace = false) => {
    const slug = SLUGS[newStep]
    if (replace) {
      history.push(`/checkout/step/${slug}`)
    } else {
      history.push(`/checkout/step/${slug}`)
    }
    setStep(newStep)
    setCurrentStep(newStep)
  }

  useEffect(() => {
    if (waitForExperiment !== 1 || !checkoutToken) {
      return
    }

    try {
      trackCheckout(checkoutToken, trackings, () => {
        //clean cookie
        setWaitForExperiment(2)
        removeCookie('raylo_tracking')
      })
    } catch (e) {
      captureErrorEvent("Error track checkout", {trackings: trackings, errors: e})
      setWaitForExperiment(2)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutToken, cookies, waitForExperiment])

  useEffect(() => {
    if (checkout && checkout.decision && checkout.decision.outcome === "USER_ACTION_REQUIRED" && checkout.decision.outcomeReason === "counter_offer") {
      setFirstLoad(true)
      goToStep("StepSignOffer")
    } else if (checkout && checkout.decision && checkout.decision.outcome === "USER_ACTION_REQUIRED" && checkout.decision.outcomeReason === "bank_connection_requested") {
      history.push("/checkout/decision/bank-connection")
    } else if (checkout && checkout.decision && checkout.decision.outcome === "USER_ACTION_REQUIRED" && checkout.decision.outcomeReason === "security_deposit") {
      history.push("/checkout/decision/security-deposit")
    } else if (checkout && checkout.token && checkout.state === "STARTED") {
      if (!firstLoad) {
        setFirstLoad(true)
        if (checkout.items[0].originSubscription) {
          setIsUpgrade(true)
          goToStep(stepsOrder(false, true, checkout)?.[0], true)
        } else {
          if (checkout.items[0].pricePlan.includesInsurance) {
            setHasInsurance(true)
            const firstStep = stepsOrder(true, isUpgrade, checkout)[0]
            if (step !== firstStep) {
              goToStep(firstStep, true)
            }
          } else {
            goToStep(stepsOrder(false, isUpgrade, checkout)[0], true)
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, checkout])

  useEffect(() => {
    if (checkout && checkout.id && window.smartlook) {
      window.smartlook('identify', checkout.id)
    }
  }, [checkout])

  useEffect(() => {
    if (lightMode) {
      setBackgroundColor(primaryColors.w01)
      if (showConfigure) {
        setBackgroundColor(primaryColors.b01)
      }
    }
  }, [lightMode, showConfigure])

  useEffect(() => {
    if (step) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'event': 'checkout',
        'ecommerce': {
          'checkout': {
            'actionField': {
              'step': globalStepNumber(step),
              'option': step
            }
          }
        }
      })
      setCurrentStep(step)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  const backToAccount = () => {
    const subscriptionId = checkout.items[0].originSubscription.id
    const variantId = checkout.items[0].variant.id
    window.location = `/account/upgrade/${subscriptionId}/pick/${variantId}`
  }

  if (loading || waitForExperiment < 2) return (<L.MainWrapper><Modal visible text="Loading..." /></L.MainWrapper>)

  const displayOnly = checkout && checkout.decision && checkout.decision.outcome === "USER_ACTION_REQUIRED"
  const backgroundColorToRender = lightMode && isMobile && !showSummary ? primaryColors.w01 : primaryColors.b01

  const CheckoutStepsView = () => (
    <CheckoutStepsContainer
      step={currentStep}
      checkout={checkout}
      checkoutToken={checkoutToken}
      backToAccount={backToAccount}
      previousStep={previousStep}
      goToStep={goToStep}
      nextStep={nextStep}
    />
  )

  return (
    <ThemeProvider theme={theme}>
      {showModal &&
         <>
         {lightMode && showTradeInModal && <TradeInModal />}
         {lightMode && showDDModal && <DirectDebitModal />}
         {lightMode && showDDContactModal && <DirectDebitContactModal />}
         {lightMode && showInsuranceModal && <InsuranceModal />}
         {lightMode && showTradeInTermsModal && <TradeInTermsModal />}
         {lightMode && showPrivacyModal && <PrivacyModal />}
        </>
      }

      {isRPCheckout && 
        <Container noPadding backgroundColor={primaryColors.w01} width="100vw" styles={{
          minHeight: '100vh',
          overflow: 'scroll'
        }}>
          <RayloPayHeader />
          {/* <CheckoutProgressBar /> */}
          <Spacer height={12} />
          <CheckoutStepsView />
        </Container>
      }

      {!isRPCheckout &&
        (
          <Container noPadding>
          <div className={classNames("checkout--index", { showConfigure, showSummary })} style={{
            backgroundColor
          }}>
            <Helmet><title>
              {pageTitles[step] || "Checkout"} - Raylo
            </title></Helmet>
            {
              lightMode && showHeader && !showModal &&<CheckoutHeader title={title} condition={condition} />
            }
            {!lightMode &&
            <div className="checkout--header"><PhoneHeader checkoutToken={checkoutToken} /></div>}

            <div className="checkout--main">
              {showModal && !lightMode &&
                <Modal visible={showModal} />
              }
              <CheckoutStepsView />
            </div>

            {!showFeedback && !lightMode &&
              <div className="checkout--footer"><ProgressBar position={
                stepNumber(step)
              }
                total={
                  totalSteps()
                }
                paymentStep={
                  paymentStep()
                }
              /></div>
            }

            {showFeedback ?
              (
                <FadeIn>
                  <BlocksAnimation />
                </FadeIn>
              ) : (
                <div className={classNames("checkout--sidebar", { showConfigure, showSummary })} style={{
                  background: backgroundColorToRender,
                }}>
                  {(showConfigure) ? (
                    <ChangePhoneContext><ChangePhone checkoutToken={checkoutToken} /></ChangePhoneContext>
                  ) : (
                    <PhoneSummary checkoutToken={checkoutToken} displayOnly={displayOnly} />
                  )}
                </div>
              )
            }
          </div>
          </Container>
        )
      }
    </ThemeProvider>
  )
}
