import styled from 'styled-components/macro';
import colors from './colors';
import { secondaryColors } from './variables/colors';

export const AccountWrapper = styled.section`
  display: flex;
  position: relative;
  background-color: ${colors.black};
`

export default AccountWrapper;

export const Logo = styled.div`
  position: absolute;
  top: 40px;
  left: 40px;

  @media (max-width: 540px) {
    top: 20px;
    left: 20px;
  }
`
// TODO - change to theme colors
export const AccountContainer = styled.section`
  background-color: ${secondaryColors.c05};
  height: 100%;
  min-height: 500px;
`;

export const AccountForm = styled.div`
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 120px 40px;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 1024px) {
    justify-content: center;
  }

  @media (max-width: 768px) {
    padding: 95px 40px;
  }

  @media (max-width: 540px) {
    padding: 50px 0;
  }
`

export const AccountFormContainer = styled.div`
  min-width: 320px;
  max-width: 420px;
  width: 100%;

  @media (max-width: 540px) {
    padding: 20px;
  }

  @media (max-width: 420px) {
    min-width: auto;
    max-width: auto;
  }
`

export const AccountActions = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 374px) {
    flex-direction: column-reverse;
    margin-bottom: 20px;

    div {
      margin-bottom: 12px;
      margin-right: 0;
    }

    button {
      width: 100%;
    }
  }
`

export const PaymentRow = styled.div`
  padding-bottom: 28px;
  border-bottom: 1px solid #E0E0E0;
  margin-bottom: 60px;

  @media (max-width: 768px) {
    padding-bottom: 20px;
    margin-bottom: 28px;
  }
`

export const PaymentRowHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    margin-bottom: 12px;
  }
`

export const PaymentRowData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    align-items: center;
  }
`

export const OnTime = styled.span`
  font-family: 'Raylo-Regular', Arial, sans-serif;
  font-weight: normal;
  font-size: 0.875em;
  color: ${colors.rayloBlue};
  line-height: 1.714;
  margin-right: 40px;

  img {
    margin-right: 14px;
  }

  @media (max-width: 400px) {
    margin-right: 20px;

    img {
      margin-right: 8px;
    }
  }
`

export const Banner = styled.section`
  width: 100%;
  padding: 48px 20px;
  text-align: center;

  .icon {
    margin-bottom: 14px;
  }

  .heading {
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-size: 1em;
    font-weight: 500;
    color: ${colors.rayloDark};
    text-align: center;
    line-height: 1.875;
    margin: 0 0 12px 0;
  }

  .body {
    font-family: 'Raylo-Regular', Arial, sans-serif;
    font-weight: normal;
    font-size: 0.875em;
    font-weight: normal;
    color: #2D2D2D;
    text-align: center;
    line-height: 2.14;
    margin: 0 auto 28px;
    max-width: 550px;

    strong {
      font-family: 'Raylo-Medium', Arial, sans-serif;
      font-weight: 500;
    }
  }
`

export const PhoneBlockedBanner = styled(Banner)`
  background-color: ${colors.rayloBlue2Light};
`

export const ReferAFriendBanner = styled(Banner)`
  background-color: ${colors.rayloDark};

  .heading {
    color: ${colors.rayloLight};
  }
`

export const InsuranceBanner = styled(Banner)`
  background-color: ${colors.rayloBlue2Light};
`

export const OverduePaymentBanner = styled(Banner)`
  background-color: ${colors.rayloPink};
`

export const PolicyBanner = styled(Banner)`
  background-color: ${colors.rayloPink3Light};
`

export const ClaimBanner = styled(Banner)`
  background-color: ${colors.black};
  padding: 175px 20px;

  @media (max-width: 540px) {
    padding: 60px 20px;
  }

  .heading {
    color: ${colors.rayloLight};
    font-family: 'Raylo-Regular', Arial, sans-serif;
    font-weight: normal;
    font-size: 1.5em;
    letter-spacing: -0.26px;
  }

  .body {
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-weight: 500;
    color: ${colors.rayloLight};
  }

  .buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 650px;

    button {
      width: 200px;
      margin: 10px;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
    }

    @media (max-width: 500px) {
      flex-direction: column;
      width: 100%;
      max-width: 220px;
    }
  }
`

export const PaymentOverviewBanner = styled.section`
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 125px 20px;

  img {
    margin-bottom: 12px;
  }

  .body {
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-weight: 500;
    font-size: 0.9375em;
    color: #CBCBCB;
    text-align: center;
    line-height: 2;
    margin-top: 12px;
  }

  @media (max-width: 768px) {
    padding: 75px 20px;
  }
`

export const DirectDebitDetails = styled.section`
  background: ${colors.rayloBlue2Light};
  padding: 64px 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 540px) {
    padding: 64px 20px;
  }

  .heading {
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-size: 0.9375em;
    font-weight: 500;
    color: ${colors.rayloDark};
    line-height: 1.6;
    margin-bottom: 48px;
  }

  .details-table {
    list-style-type: none;
    margin: 0 auto 48px;
    padding: 0;
    width: 100%;
    max-width: 820px;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #D2D6FF;

      font-family: 'Raylo-Medium', Arial, sans-serif;
      font-size: 0.875em;
      font-weight: 500;
      color: ${colors.rayloDark};
      line-height: 1.5;

      span:last-of-type {
        font-family: 'Raylo-Regular', Arial, sans-serif;
        font-weight: normal;
        color: ${colors.rayloDark};
      }
    }
  }

  .update-details {
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875em;
    color: ${colors.rayloDark};
    line-height: 2.14;
  }
`

export const MakingAClaim = styled.section`
  display: flex;
  justify-content: flex-start;
  padding: 120px 40px;
  max-width: 1140px;
  margin: 0 auto;
`

export const MakingAClaimContainer = styled.div`
  width: 50%;
`

export const AccountDetailsChangePassword = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 540px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const PasswordChanged = styled.span`
  font-family: 'Raylo-Medium', Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875em;
  color: ${colors.rayloPink};
  line-height: 2.14;
  margin-left: 12px;

  @media (max-width: 540px) {
    margin-left: 0;
    margin-top: 12px;
  }
`

export const AccountDetailsRow = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 48px;
  }
`

export const ChangeAddress = styled.div`
  margin-top: 20px;

  p {
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875em;
    color: ${colors.rayloLight};
    line-height: 2.14;
  }
`

