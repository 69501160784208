import useMedia from "use-media";
import AddTechComponent from "components/AddTechComponent";
import DeviceCard from "components/DeviceCard";
import Container from "elements/Container";
import Grid, { GridColumn } from "elements/Grid";
import Header from "elements/Header";
import Spacer from "elements/Spacer";
import { useRef, useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { rayloLight } from "styles/themes/rayloLight";
import { px2Rem } from "utils/px2Rem";
import { breakpoints } from "styles/breakpoints";

export const YourDevices = ({ phones, preApproved, activeOrderIsPending }) => {
  const ref = useRef(null);

  const isSmallMobile = useMedia({ maxWidth: breakpoints.smallMobile })
  const isTablet = useMedia({ maxWidth: 768 })
  const maxWidth = isTablet ? 560 : 1360
  const [addTechWidth, setAddTechWidth] = useState(0)

  useEffect(() => {
    setAddTechWidth(ref?.current?.offsetWidth)
  }, [addTechWidth])

  return (
    <ThemeProvider theme={rayloLight}>
      <Container center>
        <Spacer height={12} />
        <Header level={2} fontSize={24}>Your Tech</Header>
        <Spacer height={12} />
        <Container alignCenter maxWidth={`${px2Rem(maxWidth)}`} noPadding={isSmallMobile}>
          <Grid center>
            {phones?.map((phone, i) => (
              <>
                <GridColumn columns={2} sm={1} md={1} colPadding="0 32px 32px 32px" key={i}>
                  <div ref={ref}>
                    <DeviceCard key={i} phone={phone} ref={ref} />
                  </div>
                </GridColumn>
              </>
            ))
            }
          </Grid>
        </Container>
        {preApproved && !activeOrderIsPending &&
          <Container alignCenter padding="0" width={`${px2Rem(addTechWidth)}`}>
            <AddTechComponent link="/account/products" />
          </Container>
        }
      </Container>
    </ThemeProvider>
  );
}
