import { useEffect, useState } from 'react';
import Divider from 'elements/Divider';
import Header from 'elements/Header';
import DoubleButtonFullWidth from 'components/DoubleButtonFullWidth';
import { DeliveryEstimate } from 'components/common/DeliveryEstimate';
import PhoneDetailsCard from 'components/PhoneDetailsCard';
import PhoneFeatureCard from 'components/PhoneFeatureCard';
import PhonePricingCard from 'components/PhonePricingCard';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Icon from 'elements/Icon';
import Spacer from 'elements/Spacer';
import { useSegmentIdentifyAndCheckoutTracking } from 'hooks/segment/segment.hooks';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import useRayloQuery from 'utils/useRayloQuery';
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { ProductCategories } from 'types';
import { FeaturesWrapper, StyledOrderSummaryMobile } from './styles';
import { IOrderSummaryMobile } from './types';

export const OrderSummaryMobile = ({
  dataTestId,
  phoneData,
  delivery,
  onContinue,
  onChangePhone,
}: IOrderSummaryMobile) => {
  const {
    storage,
    color,
    hexColor,
    termLength,
    insurance,
    payMonthly,
    payRecurring,
    categoryName,
  } = phoneData;
  const { url } = phoneData.image;
  const phoneCost = payRecurring?.baseAmount?.valueAfterTax?.value;
  const insuranceCost = payRecurring?.insuranceAmount?.valueAfterTax?.value;
  const displayAccessories = categoryName?.includes(ProductCategories.PHONE);
  const [tradeInExists, setTradeInExists] = useState(false);
  const [tradeInCashback, setTradeInCashback] = useState<number>();
  useSegmentIdentifyAndCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'summary',
    checkoutScreen: 'order summary',
    when: 'on page load',
  });

  const { checkoutToken, merchantHasInsuranceOption } = useCheckoutContext();

  const {
    data: { checkout },
    loading,
  } = useRayloQuery(CheckoutSteps.stepTradeIn.query, {
    fetchPolicy: 'network-only',
    variables: {
      token: checkoutToken,
    },
  });

  useEffect(() => {
    if (!loading) {
      const tradeInAmount =
        checkout?.tradeIns?.[0]?.costSummary?.recurringTotalAmount
          ?.valueAfterTax?.value;
      setTradeInCashback(Math.abs(tradeInAmount));
      setTradeInExists(!!checkout?.tradeIns?.[0]?.id);
    }
  }, [checkout, loading]);

  return (
    <StyledOrderSummaryMobile data-testid={dataTestId}>
      <FeaturesWrapper>
        <PhoneDetailsCard
          url={url}
          storage={storage}
          phoneColor={color}
          phoneHexColor={hexColor}
        />
        {displayAccessories && (
          <>
            <Divider width="100%" color={secondaryColors.c04} opacity="0.5" />
            <Spacer height={24} />
            <Header level={4} color={primaryColors.c01}>
              Accessories
            </Header>
            <Spacer height={16} />
            <PhoneFeatureCard
              iconName="CustomScreenProtector"
              details="£0"
              title="Case &amp; screen protector"
              detailWidth="10%"
            />
          </>
        )}
        <Spacer height={24} />
        <Divider width="100%" color={secondaryColors.c04} opacity="0.5" />
        <Spacer height={24} />
        <PhoneFeatureCard
          iconName="SynchronizeArrowClock"
          details={`${termLength} Months`}
          title="Lease Term"
          titleBold
          detailWidth="60%"
        />
        {merchantHasInsuranceOption && (
          <>
            <Spacer height={24} />
            <Divider width="100%" color={secondaryColors.c04} opacity="0.5" />
            <Spacer height={24} />
            <PhoneFeatureCard
              iconName="CheckShield"
              details={insurance ? 'Included' : ' Not added'}
              title="Insurance"
              titleBold
              detailWidth="60%"
            />
          </>
        )}
      </FeaturesWrapper>
      <Spacer height={24} />
      <PhonePricingCard
        payMonthly={payMonthly}
        phoneCost={phoneCost}
        insuranceCost={merchantHasInsuranceOption ? insuranceCost : 0}
      />
      <FeaturesWrapper>
        {!!tradeInExists && (
          <>
            <Spacer height={24} />
            <Container noPadding flexRow>
              <Container noPadding width={px2Rem(44)}>
                <Icon name="PhoneRefresh" size="24px" />
              </Container>
              <Container noPadding>
                <Copy color={secondaryColors.p02}>
                  £{tradeInCashback?.toFixed(2)}/mo Trade-In Cashback
                </Copy>
              </Container>
            </Container>
            <Spacer height={12} />
            <Divider width="100%" color={secondaryColors.c04} opacity="0.5" />
          </>
        )}
        <Spacer height={24} />
        <Container noPadding flexRow>
          <Container noPadding width={px2Rem(44)}>
            <Icon
              name="ShipmentPackage"
              color={secondaryColors.p02}
              size={px2Rem(24)}
            />
          </Container>
          <Container noPadding>
            <Copy color={secondaryColors.p02}>
              Delivered <DeliveryEstimate delivery={delivery} />
            </Copy>
          </Container>
        </Container>
        <Spacer height={24} />
        <DoubleButtonFullWidth
          topButtonText="Continue"
          bottomButtonText="Change device"
          topButtonTestId="overview-confirm"
          topButtonOnClick={onContinue}
          bottomButtonOnClick={onChangePhone}
          singleButton={!merchantHasInsuranceOption}
        />
      </FeaturesWrapper>
      <Spacer height={64} />
    </StyledOrderSummaryMobile>
  );
};
