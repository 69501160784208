import { breakpoints } from 'styles/breakpoints';
import { uiStateColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import styled from 'styled-components';

const StyledDeviceCard = styled.div`
  & a {
    text-decoration: none;
  }
`;

const UpgradeIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${px2Rem(44)};
  height: ${px2Rem(44)};
  background-color: ${uiStateColors.success};
  border-radius: 50%;
  padding-top: ${px2Rem(4)};
  & svg {
    transform: rotate(90deg);
  }
  @media (max-width: ${breakpoints.smallMobile}px) {
    width: ${px2Rem(38)};
    height: ${px2Rem(38)};
  }
`;

export { StyledDeviceCard, UpgradeIconWrapper };
