import { Container, Header, Spacer } from 'elements';
import { primaryColors } from 'styles/variables/colors';
import { HowItWorksContent } from './HowItWorksContent';
import { StyledHowItWorks } from './styles';
import { IHowItWorks } from './types';
import { HowItWorksProvider } from './useHowItWorksContext';

export const HowItWorks = ({ dataTestId, title }: IHowItWorks) => {
  return (
    <HowItWorksProvider>
      <StyledHowItWorks data-testid={dataTestId}>
        <Container noPadding>
          <Header level={3} color={primaryColors.c01} fontSize={18}>
            {title}
          </Header>
          <Spacer height={24} />
          <HowItWorksContent />
        </Container>
      </StyledHowItWorks>
    </HowItWorksProvider>
  );
};
