import styled from 'styled-components';

const StyledStepLeaseTerm = styled.div`
  & h3 {
    color: ${({ theme }) => theme?.checkout?.subHeader?.color};
  }
  & h4 {
    color: ${({ theme }) => theme?.checkout?.sectionHeader?.color};
  }
`;

export { StyledStepLeaseTerm };
