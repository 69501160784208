import React from 'react';
import * as L from 'styles/layout';
import * as T from 'styles/type';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { BlocksAnimation } from 'components/animations/BlocksAnimation';

export const KudosError = ({errorCode, children, paymentInfo}) => {
  if(errorCode === 'global') {
    return (
      <div className="checkout--index">
        <div className="checkout--main">
          <div className="checkout--step feedback">
            <FadeIn>
              <T.RayloBodyHeading margin="28px"><strong>Sorry,</strong> there's been a problem connecting with Credit Kudos</T.RayloBodyHeading>
            </FadeIn>

            <FadeIn>
              <div style={{marginBottom: 28, paddingBottom: 28, borderBottom: '1px solid #434343'}}>
                <T.RayloBodyType style={{marginBottom: 16}}><span className="pink">Our engineering team have been alerted and they are investigating.</span></T.RayloBodyType>
                <T.RayloBodyType>We'll email you when the issue is fixed.</T.RayloBodyType>
              </div>
            </FadeIn>

            <FadeIn>
              <div>
                <T.RayloBodyType>If you have any questions please contact us on <span className="pink">0333 050 9880</span> or email <T.RayloLink href="mailto:help@raylo.com" target="_blank">help@raylo.com</T.RayloLink></T.RayloBodyType>
              </div>
            </FadeIn>
          </div>
        </div>
        <>
          <FadeIn>
            <BlocksAnimation />
          </FadeIn>
        </>
      </div>
    )
  } else if(errorCode === "timeout") {
    return(
      <div className="checkout--index">
        <div className="checkout--main">
          <div className="checkout--step feedback">
            <L.SignupFeedbackContainer>
              <SlideDownFadeIn>
                <div>
                  <T.RayloBodyHeading><strong>We need to run a few extra checks before we can ship your device.</strong></T.RayloBodyHeading>
                  <T.RayloBodyType style={{ marginBottom: 12 }}>There’s nothing more for you to do at this stage and we will be back in touch shortly.</T.RayloBodyType>
                  <T.RayloBodyType>Sorry for the delay, but we hope your device will be worth the wait.</T.RayloBodyType>
                </div>
              </SlideDownFadeIn>
            </L.SignupFeedbackContainer>
          </div>
        </div>
        <>
          <FadeIn>
            <BlocksAnimation />
          </FadeIn>
        </>
      </div>
    )
  } else {
    return (
      <div className="checkout--index">
      <div className="checkout--main">
        <div className="checkout--step feedback">

          
          <FadeIn>
            <div style={{marginBottom: 28, borderBottom: '1px solid #434343'}}>
              {
                errorCode === 'bank_connection_mismatch' ?
                <T.RayloBodyHeading margin="28px"><strong>Sorry,</strong> it looks like you've connected with a different bank account to the one you've given us for your direct debit</T.RayloBodyHeading>
                :
                  <T.RayloBodyHeading margin="28px"><strong>Sorry,</strong> there has been a problem connecting to your bank account.<br/>Please try again.</T.RayloBodyHeading>
              }
            </div>
          </FadeIn>

          <FadeIn>
            <div style={{marginBottom: 28, paddingBottom: 28, borderBottom: '1px solid #434343'}}>
              <T.RayloBodyType style={{marginBottom: 16}}>
                You'll need to connect to this account:
              </T.RayloBodyType>
              <T.RayloBodyType>
                <strong className="highlight">{paymentInfo.bankName}</strong><br/>
                <strong className="highlight">{paymentInfo.bankBranchIdentifier}</strong>
                <span style={{opacity: 0.5, marginLeft: 10, marginRight: 10}}>|</span>
                <strong className="highlight">{paymentInfo.bankAccountNumber}</strong>
              </T.RayloBodyType>
            </div>
          </FadeIn>
        <FadeIn>
          <div style={{marginBottom: 28}}>
            <T.RayloBodyType>Connect below and Credit Kudos will securely transfer you to your bank who will prompt you to login into your online banking.
            </T.RayloBodyType>
          </div>
        </FadeIn>

          <div style={{marginBottom: 28}}>{children}</div>

        </div>
      </div>
      <FadeIn>
        <BlocksAnimation />
      </FadeIn>
    </div>)
  }
}
