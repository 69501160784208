import Container from 'elements/Container';
import Divider from 'elements/Divider';
import Header from 'elements/Header';
import Icon from 'elements/Icon';
import Spacer from 'elements/Spacer';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { StyledRayloModal } from './styles';
import { IRayloModal } from './types';

export const RayloModal = ({
  dataTestId,
  children,
  onClose,
  rayloPay,
  header = '',
  hideLogo,
  styles,
}: IRayloModal) => {
  return (
    <StyledRayloModal data-testid={dataTestId}>
      {onClose && !rayloPay && (
        <>
          <Container
            alignRight
            width="90%"
            maxWidth={px2Rem(500)}
            noPadding
            onClick={onClose}
            cursorPointer
          >
            <Container
              backgroundColor={primaryColors.p01}
              width={px2Rem(50)}
              height={px2Rem(50)}
              center
              padding="4px 0 0 0"
            >
              <Icon name="Close" size={px2Rem(24)} color={primaryColors.c01} />
            </Container>
          </Container>
          <Spacer height={12} />
        </>
      )}
      <Container
        noPadding
        backgroundColor={primaryColors.w01}
        styles={{
          position: 'relative',
          width: '90%',
          maxHeight: '80vh',
          overflow: 'scroll',
          ...styles,
        }}
        maxWidth={px2Rem(414)}
      >
        <Container
          padding={`0 ${px2Rem(16)}`}
          center
          backgroundColor={primaryColors.w01}
          styles={{
            position: 'sticky',
            top: '0',
          }}
        >
          {rayloPay && (
            <Container
              padding="0 6px 0 0"
              alignRight
              onClick={onClose}
              cursorPointer
            >
              <Spacer height={20} />
              <Icon name="Close" size={px2Rem(24)} color={primaryColors.c01} />
            </Container>
          )}
          <Spacer height={24} />
          {!hideLogo && (
            <>
              <Icon
                name={rayloPay ? 'RPLogoLightLarge' : 'ShieldPhone'}
                color={secondaryColors.p02}
              />

              <Spacer height={16} />
            </>
          )}
          <Container
            center
            padding={rayloPay ? '0 60px' : '0'}
            styles={{
              textAlign: 'center',
            }}
          >
            <Header
              level={2}
              color={rayloPay ? primaryColors.c01 : primaryColors.b01}
            >
              {header}
            </Header>
          </Container>
          <Spacer height={16} />
        </Container>
        <Container padding={`0 ${px2Rem(24)}`}>
          <Divider width="100%" color={secondaryColors.c04} />
          <Spacer height={16} />
          {children}
        </Container>
      </Container>
    </StyledRayloModal>
  );
};
