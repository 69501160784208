
import React, { useEffect, useState } from 'react';

import { QL_INTERCOM } from 'screens/Account/queries';
import useRayloQuery from 'utils/useRayloQuery';


export const IntercomUtil = ({url}) => {

  const [loaded, setLoaded] = useState(false)

  const { data: { customer } } = useRayloQuery(QL_INTERCOM)

  // useEffect(() => {
  //   if(customer && customer.id) {
  //     const { appId, userHash, userId } = customer.messagingDetails.intercom
  //     window.Intercom('boot', {
  //       app_id: appId,
  //       user_hash: userHash,
  //       user_id: userId,
  //       email: customer.email,
  //       created_at: parseInt((new Date()).getTime()/1000)
  //     })
  //     setLoaded(true)
  //   }
  // },[customer])
  // useEffect(() => {
  //   if(loaded) {
  //     window.Intercom("update", {last_request_at: parseInt((new Date()).getTime()/1000)})
  //   }
  // }, [url, loaded])



  return (
    <></>
  )
}
