import { useEffect, useState } from 'react';
import constate from 'constate';
import useRayloQuery from 'utils/useRayloQuery';
import gql from 'graphql-tag';
import _ from 'lodash';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { useMutation } from '@apollo/client';
import { useUiContext } from 'utils/useUiContext';
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';

interface IUseStepTradeIn {
  onSuccess: () => void;
}
const useStepTradeIn = ({ onSuccess }: IUseStepTradeIn) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [tradeInAccepted, setTradeInAccepted] = useState(false);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [manufacturerOptions, setManufacturerOptions] = useState<any>([]);
  const [manufacturerId, setManufacturerId] = useState<string>();
  const [modelId, setModelId] = useState<string>();
  const [modelOptions, setModelOptions] = useState<any>([]);
  const [productIds, setProductIds] = useState<any>([]);
  const [variantIds, setVariantIds] = useState<any>();
  const [sizes, setSizes] = useState<any>();
  const [sizeOptions, setSizeOptions] = useState<any>();
  const [variantId, setVariantId] = useState<any>();
  const [productName, setProductName] = useState<string>();
  const [tradeInQuestions, setTradeInQuestions] = useState<any>();
  const [gradeId, setGradeId] = useState<string>();
  const [gradeSlug, setGradeSlug] = useState<string>();
  const [questionsAnswered, setQuestionsAnswered] = useState<boolean>(false);
  const [showQuestion2, setShowQuestion2] = useState<boolean>(false);
  const [showQuestion3, setShowQuestion3] = useState<boolean>(false);
  const [tradeInTotal, setTradeInTotal] = useState<string>();
  const [termLength, setTermLength] = useState<number>();
  const [termLengthValue, setTermLengthValue] = useState<string>();
  const [answers, setAnswers] = useState<any>([]);
  const [formErrors, setFormErrors] = useState<any>();
  const [tradeInExists, setTradeInExists] = useState<boolean>(false);
  const [tradeInId, setTradeInId] = useState<string>();
  const [tradeInValue, setTradeInValue] = useState<string | undefined>();
  const [pricePlan, setPricePlan] = useState<any>();

  const { checkoutToken } = useCheckoutContext();
  const { setHideProgressBar } = useUiContext();

  useEffect(() => {
    if (tradeInAccepted) {
      setHideProgressBar(true);
    } else {
      setHideProgressBar(false);
    }
  }, [tradeInAccepted]);

  const {
    data: { checkout },
    loading,
  } = useRayloQuery(CheckoutSteps.stepTradeIn.query, {
    variables: {
      token: checkoutToken,
    },
  });

  const {
    data: { checkout: checkoutTwo },
    loading: loadingTwo,
  } = useRayloQuery(CheckoutSteps.stepTradeIn.queryMax, {
    skip: !pricePlan,
    variables: {
      token: checkoutToken,
      input: {
        termLength: pricePlan ? pricePlan?.termLength : undefined,
        countryId: pricePlan ? pricePlan?.country.id : undefined,
      },
    },
  });

  useEffect(() => {
    if (checkout) {
      setPricePlan(checkout.items[0].pricePlan);
    }
  }, [checkout]);

  useEffect(() => {
    if (checkoutTwo) {
      if (!checkoutTwo?.items[0]?.variant?.headlineTradeInMaxAmount?.value) {
        onSuccess();
      }
      setTradeInValue(
        checkoutTwo?.items[0]?.variant?.headlineTradeInMaxAmount?.value,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutTwo]);

  useEffect(() => {
    if (checkout) {
      setTermLength(checkout.items?.[0]?.pricePlan?.termLength);
      const tradeIn = checkout.tradeIns?.[0];
      if (tradeIn) {
        const total =
          tradeIn.costSummary.recurringTotalAmount.valueAfterTax.value;
        setTradeInTotal(`£${Math.abs(total).toFixed(2)}`);
        setTradeInExists(true);
        setTradeInId(tradeIn?.id);
        setGradeSlug(tradeIn?.grade?.slug);
        setProductName(tradeIn?.costSummary?.displayName);
        setStepIndex(-1);
      }
    }
  }, [checkout]);

  useEffect(() => {
    setTradeInValue(
      checkoutTwo?.items?.[0]?.variant?.headlineTradeInMaxAmount
        ?.formattedValue,
    );
  }, [checkoutTwo, loadingTwo]);

  const {
    data: { manufacturers },
    loading: loadingManufacturers,
  } = useRayloQuery(
    gql`
      query queryManufacturersForTradeIn {
        manufacturers(forTradeIn: true, forCheckout: false) {
          id
          displayName
        }
      }
    `,
    {
      skip: !tradeInAccepted,
    },
  );

  useEffect(() => {
    if (!loadingManufacturers) {
      const options = manufacturers?.map(({ displayName, id }: any) => {
        return {
          label: displayName,
          large: false,
          id,
        };
      });
      setManufacturerOptions(_.sortBy(options, ['label']));
    }
  }, [manufacturers, loadingManufacturers]);

  const handleManufacturerOnChange = (value: string) => {
    setManufacturerId(
      manufacturerOptions.find((option: any) => option.label === value)?.id,
    );
    setModelId(undefined);
    setVariantId(undefined);
  };

  useEffect(() => {
    setManufacturerId(manufacturerOptions?.[0]?.id);
  }, [manufacturerOptions]);

  const {
    data: { manufacturers: manufacturerProducts },
    loading: loadingManufacturerProducts,
  } = useRayloQuery(
    gql`
      query queryManufacturersWithProducts($ids: [ID!]) {
        manufacturers(ids: $ids, forTradeIn: true, forCheckout: false) {
          id
          displayName
          products {
            id
          }
        }
      }
    `,
    {
      variables: { ids: [manufacturerId] },
      skip: !manufacturerId,
    },
  );

  useEffect(() => {
    if (!loadingManufacturerProducts) {
      setProductIds(
        _.map(_.flatten(_.map(manufacturerProducts, 'products')), 'id'),
      );
    }
  }, [manufacturerProducts]);

  const {
    data: { products },
    loading: loadingProducts,
  } = useRayloQuery(
    gql`
      query queryProductsForTradeIn($ids: [ID!]) {
        products(ids: $ids, forTradeIn: true, forCheckout: false) {
          id
          displayName
          position
        }
      }
    `,
    {
      variables: {
        ids: productIds,
      },
      skip: productIds.length === 0,
    },
  );

  useEffect(() => {
    if (!loadingProducts) {
      const productOptions = products?.map(({ displayName, id }: any) => {
        return {
          value: id,
          label: displayName,
        };
      });
      setModelOptions(productOptions);
    }
  }, [products, loadingProducts]);

  const handleProductSelect = ({ value }: any) => {
    setModelId(value);
    setProductName(products?.find(({ id }: any) => id === value)?.displayName);
  };

  const {
    data: { products: productVariants },
    loading: loadingProductVariants,
  } = useRayloQuery(
    gql`
      query queryProducts($ids: [ID!]) {
        products(ids: $ids, forTradeIn: true, forCheckout: false) {
          id
          variants {
            id
          }
        }
      }
    `,
    {
      variables: {
        ids: [modelId],
      },
      skip: !modelId || modelId === 'other',
    },
  );

  useEffect(() => {
    if (productVariants) {
      setVariantIds(_.map(_.flatten(_.map(productVariants, 'variants')), 'id'));
    }
  }, [productVariants]);

  const {
    data: { variants },
    loading: loadingVariants,
  } = useRayloQuery(
    gql`
      query queryVariantsForTradeInStep(
        $ids: [ID!]
        $termLength: Int!
        $countryId: ID!
      ) {
        variants(ids: $ids, forTradeIn: true, forCheckout: false) {
          id
          displayName
          tradeInMaxAmount(
            input: { termLength: $termLength, countryId: $countryId }
          ) {
            formattedValue
            value
          }
          optionValues {
            id
            displayName
            raw
            position
            optionType {
              id
              slug
            }
          }
        }
      }
    `,
    {
      variables: {
        ids: variantIds,
        termLength: checkout
          ? checkout.items[0].pricePlan.termLength
          : undefined,
        countryId: checkout
          ? checkout.items[0].pricePlan.country.id
          : undefined,
      },
      skip: !variantIds || variantIds.length === 0 || !checkout,
    },
  );

  useEffect(() => {
    if (variants) {
      const p: any = {};

      _.each(variants, (v) => {
        const size = _.find(
          v.optionValues,
          (o) => o.optionType.slug === 'storage',
        );

        if (!p?.[size.id]) {
          p[size.id] = {
            ...size,
            variantId: v.id,
            tradeInMaxAmount: v.tradeInMaxAmount,
          };
        }

        if (p?.[size.id].tradeInMaxAmount.value < v.tradeInMaxAmount.value) {
          p[size.id].tradeInMaxAmount = v.tradeInMaxAmount;
        }
      });
      setSizes(_.orderBy(p, 'position'));
    }
  }, [variants]);

  useEffect(() => {
    const options = sizes?.map((size: any) => {
      return {
        value: size.variantId,
        label: `${size.displayName} up to ${size.tradeInMaxAmount.formattedValue}`,
      };
    });
    setSizeOptions(options);
  }, [sizes]);

  const handleVariantSelect = ({ value }: any) => {
    setVariantId(value);
  };

  const {
    data: { tradeInGradingQuestions },
  } = useRayloQuery(
    gql`
      query queryTradeInGradingQuestions {
        tradeInGradingQuestions {
          id
          displayName
          notes
          gradingAnswers {
            id
            displayName
            followUpQuestion {
              id
            }
            grade {
              id
              displayName
              slug
            }
          }
          hasLeadingGradingAnswers
          notes
        }
      }
    `,
    {
      skip: !tradeInAccepted,
    },
  );

  useEffect(() => {
    setTradeInQuestions(
      tradeInGradingQuestions?.map((question: any) => {
        return {
          label: question.displayName,
          options: question.gradingAnswers.map((answer: any) => {
            return {
              label: answer.displayName,
              large: true,
              grade: answer.grade?.id,
              gradeSlug: answer.grade?.slug,
              id: answer.id,
            };
          }),
        };
      }),
    );
  }, [tradeInGradingQuestions]);

  const handleQuestionAnswer = (value: string, label?: string) => {
    const selectedQuestion = tradeInQuestions?.find(
      (question: any) => question.label === label,
    );
    const selectedQuestionNumber =
      tradeInQuestions?.indexOf(selectedQuestion) + 1;
    const productGradeId = selectedQuestion.options.find(
      (option: any) => option.label === value,
    )?.grade;
    const productGradeSlug = selectedQuestion.options.find(
      (option: any) => option.label === value,
    )?.gradeSlug;
    const selectedAnswer = selectedQuestion.options.find(
      (option: any) => option.label === value,
    )?.id;
    const questionAnswers = answers;
    questionAnswers[selectedQuestionNumber - 1] = selectedAnswer;
    setAnswers(questionAnswers);
    if (productGradeId) {
      setGradeId(productGradeId);
      setGradeSlug(productGradeSlug);
    } else {
      setGradeId(undefined);
      if (selectedQuestionNumber === 1) {
        setShowQuestion2(true);
      }
      if (selectedQuestionNumber === 2) {
        setShowQuestion3(true);
      }
    }
  };

  useEffect(() => {
    if (gradeId) {
      setQuestionsAnswered(true);
    } else {
      setQuestionsAnswered(false);
    }
  }, [gradeId]);

  const {
    data: { variants: finalVariants },
  } = useRayloQuery(
    gql`
      query queryFinalVariants(
        $ids: [ID!]
        $termLength: Int!
        $countryId: ID!
        $gradeId: ID
      ) {
        variants(ids: $ids, forTradeIn: true, forCheckout: false) {
          id
          displayName
          tradeInMaxAmount(
            input: {
              termLength: $termLength
              countryId: $countryId
              gradeId: $gradeId
            }
          ) {
            formattedValue
            value
            currencyCode
          }
          product {
            id
            displayName
          }
          optionValues {
            id
            displayName
            raw
            optionType {
              id
              slug
            }
          }
        }
      }
    `,
    {
      variables: {
        ids: [variantId],
        termLength,
        countryId: checkout
          ? checkout?.items?.[0]?.pricePlan?.country?.id
          : undefined,
        gradeId,
      },
      skip: !variantId || !termLength, // || !gradeId
    },
  );

  useEffect(() => {
    if (finalVariants?.length) {
      const monthlyValue = finalVariants?.[0]?.tradeInMaxAmount?.value;
      if (termLength) {
        setTermLengthValue(`£${(monthlyValue * termLength).toFixed(2)}`);
      }
      setTradeInTotal(finalVariants?.[0]?.tradeInMaxAmount?.formattedValue);
    }
  }, [finalVariants]);

  useEffect(() => {
    const tradeIn = checkout?.tradeIns?.[0];
    if (tradeIn && termLength) {
      const monthly = Math.abs(
        tradeIn?.costSummary?.recurringTotalAmount?.valueAfterTax?.value,
      );
      const total = (monthly * termLength).toFixed(2);
      setTermLengthValue(`£${total}`);
    }
  }, [checkout, termLength]);

  const [updateMutation] = useMutation(
    gql`
      mutation CreateCheckoutTradeIn(
        $checkoutToken: String!
        $variantId: ID!
        $gradeId: ID!
        $gradingAnswerIds: [ID!]!
      ) {
        createCheckoutTradeIn(
          checkoutToken: $checkoutToken
          input: {
            variantId: $variantId
            gradeId: $gradeId
            gradingAnswerIds: $gradingAnswerIds
          }
        ) {
          tradeIn {
            createdAt
            id
            updatedAt
          }
          errors {
            code
            message
            field
          }
        }
      }
    `,
    {
      update: (
        proxy,
        {
          data: {
            createCheckoutTradeIn: { errors },
          },
        },
      ) => {
        if (errors && errors.length > 0) {
          setFormErrors(errors);
        } else {
          setFormSubmitted(true);
        }
      },
      refetchQueries: ['queryPhoneSummary', 'CheckoutPhoneSummaryQuery'],
    },
  );

  const commit = () => {
    if (tradeInExists) {
      onSuccess();
    } else {
      updateMutation({
        variables: {
          checkoutToken,
          variantId,
          gradeId,
          gradingAnswerIds: answers,
        },
      });
    }
  };

  const [updateMutationRemoveTradeIn] = useMutation(
    gql`
      mutation RemoveCheckoutTradeIn($checkoutToken: String!, $tradeInId: ID!) {
        removeCheckoutTradeIn(
          checkoutToken: $checkoutToken
          tradeInId: $tradeInId
        ) {
          success
        }
      }
    `,
    {
      update: (
        proxy,
        {
          data: {
            removeCheckoutTradeIn: { success },
          },
        },
      ) => {
        if (success) {
          setTradeInExists(false);
          setTradeInAccepted(false);
          setStepIndex(0);
        }
      },
      refetchQueries: ['queryPhoneSummary', 'CheckoutPhoneSummaryQuery'],
    },
  );

  const removeTradeIn = () => {
    updateMutationRemoveTradeIn({
      variables: {
        checkoutToken,
        tradeInId,
      },
    });
  };

  useEffect(() => {
    if (formSubmitted) {
      setHideProgressBar(false);
      onSuccess();
    }
  }, [formSubmitted]);

  return {
    loadingProductVariants,
    loadingVariants,
    loading,
    loadingTwo,
    stepIndex,
    tradeInAccepted,
    formSubmitted,
    manufacturerOptions,
    modelOptions,
    manufacturerId,
    modelId,
    variants,
    sizeOptions,
    variantId,
    productName,
    tradeInQuestions,
    tradeInTotal,
    questionsAnswered,
    showQuestion2,
    showQuestion3,
    gradeSlug,
    termLength,
    termLengthValue,
    answers,
    formErrors,
    tradeInExists,
    tradeInValue,
    commit,
    setStepIndex,
    setFormSubmitted,
    setTradeInAccepted,
    setModelId,
    setGradeId,
    handleManufacturerOnChange,
    handleProductSelect,
    handleVariantSelect,
    handleQuestionAnswer,
    removeTradeIn,
  };
};

const [StepTradeInProvider, useStepTradeInContext] = constate(useStepTradeIn);
export { StepTradeInProvider, useStepTradeInContext };
