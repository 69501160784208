import React, { useState } from 'react';
import * as I from 'styles/inputs';
import { Loader } from 'components/common/Loader';
import { Tooltip } from 'components/common/Tooltip';

export const TextInputWithLoader = (
  {
    label,
    tooltip,
    placeholder,
    errorMessage,
    field,
    searching
  }) => {
  const [isFocused, setIsFocused] = useState(false)
  const toggle = () => setIsFocused(!isFocused)
  const { error } = field;
  return (
    <I.RayloFormRow>
      <I.RayloFieldHeader>
        {label &&
          <I.RayloFieldLabel isFocused={isFocused}>
            {label}
          </I.RayloFieldLabel>
        }

        {tooltip &&
          <Tooltip direction="left">{tooltip}</Tooltip>
        }
      </I.RayloFieldHeader>

      <I.RayloTextInputWithLoader
        isFocused={isFocused}>

        <input
          {...field}
          type="text"
          placeholder={placeholder}
          onFocus={toggle}
          onBlur={toggle}
        />

        {searching && isFocused &&
          <Loader type="single" />
        }
      </I.RayloTextInputWithLoader>

      {error &&
        <I.RayloError visible={error}>
          {errorMessage || <span>&nbsp;</span>}
        </I.RayloError>
      }
    </I.RayloFormRow>
  )
}