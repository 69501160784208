import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';
import { IPreApprovalBanner } from './types';

const StyledPreApprovalBanner = styled.div``;
const StyledButtonWrapper = styled.div`
  & button {
    width: ${px2Rem(194)};
    border-color: #fff;
    color: #fff;
  }
`;

const AccountHomePageBannerWrapper = styled.div<IPreApprovalBanner>`
  @media (max-width: 830px) {
    h1 > span {
      font-size: ${px2Rem(24)};
    }
  }
`;

export {
  StyledPreApprovalBanner,
  StyledButtonWrapper,
  AccountHomePageBannerWrapper,
};
