import { useEffect, useState } from 'react';
import constate from 'constate';

export interface IAfterLeaseChoices {
  title: string;
  copy: string;
}

const useCheckoutContent = () => {
  const [afterLeaseChoices, setAfterLeaseChoices] =
    useState<IAfterLeaseChoices[]>();

  useEffect(() => {
    setAfterLeaseChoices([
      {
        title: 'Upgrade',
        copy: 'Apply for a new device of your choice with no upgrade fee. We’ll send it to you and you have 14 days to return the old one',
      },
      {
        title: 'Keep',
        copy:
          'If you’re happy with the device you’ve been using, then just carry on paying monthly or pay a one off non-return fee:' +
          '<br><br>Before 24 months: £188.99' +
          '<br>Before 24 months: £92.99' +
          '<br>Before 36 months: £1.00' +
          '<br> <br>If you want to purchase the device, which would enable you to sell it or give it to somebody else, please contact us to discuss.',
      },
      {
        title: 'Cancel',
        copy: 'Hand the device back and sail off into the sunset.',
      },
    ]);
  }, []);

  return {
    afterLeaseChoices,
  };
};

const [CheckoutContentProvider, useCheckoutContentContext] =
  constate(useCheckoutContent);
export { CheckoutContentProvider, useCheckoutContentContext };
