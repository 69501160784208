import React from 'react';
import useMedia from 'use-media';
import { useHistory } from 'react-router';
import { FadeIn } from 'components/animations/Transitions';
import { Card } from 'components/common/Card';
import Spacer from 'elements/Spacer';
import { px2Rem } from 'utils/px2Rem';
import { breakpoints } from 'styles/breakpoints';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import Header from 'elements/Header';
import Icon from 'elements/Icon';
import Copy from 'elements/Copy';
import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import { track } from 'helpers/analytics/segment';

export const PaymentFailed = () => {
  const history = useHistory();

  const isMobile = useMedia({ maxWidth: breakpoints.mobile });

  return (
    <FadeIn>
      <div>
        {!isMobile && <Spacer height={48} />}
        <Card
          width="1200"
          styles={`
            margin: auto;
            min-height: ${px2Rem(800)};
            
            @media (max-width: ${breakpoints.mobile}px) {
              padding: ${px2Rem(36)} ${px2Rem(30)} ${px2Rem(20)} ${px2Rem(30)};
              max-width: 100%;
              box-shadow: none;
            `}
        >
          <div style={{ maxWidth: px2Rem(362), margin: 'auto' }}>
            <Header
              level={1}
              color={primaryColors.c01}
              fontSize={24}
              style={isMobile ? { marginTop: px2Rem(30) } : undefined}
              centered
            >
              Unfortunately we were unable to take payment
            </Header>
            <Icon
              name="PaymentUnsuccessful"
              styles={`
          width: fit-content;
          display: block;
          margin: ${px2Rem(29)} auto ${px2Rem(29)};
        `}
            />
            <Header
              level={4}
              color={primaryColors.c01}
              fontSize={16}
              style={isMobile ? { marginTop: px2Rem(30) } : undefined}
              centered
            >
              Thank you
            </Header>
            <Spacer height={28} />
            <div
              style={{
                padding: px2Rem(20),
                backgroundColor: secondaryColors.p05,
              }}
            >
              <Copy fontSize={16} textAlign="center">
                <span style={{ fontWeight: 'bold' }}>
                  Please try again ensuring your card details are correct and
                  that you have sufficient funds in your account.
                </span>
              </Copy>
              <Spacer height={28} />
              <Copy fontSize={16} textAlign="center">
                If your payment is still declined please speak with your bank
              </Copy>
            </div>
            <Spacer height={28} />
            <Copy fontSize={16} textAlign="center">
              If you need to talk about your Raylo account please get in touch:
            </Copy>
            <Spacer height={8} />
            <div
              style={{
                margin: `${px2Rem(12)} auto 0`,
                width: 'fit-content',
              }}
            >
              <Copy fontSize={16}>
                <span style={{ fontWeight: 'bold' }}>Email: </span>
                <a href="mailto:payments@raylo.com">payments@raylo.com</a>
              </Copy>
              <Spacer height={8} />
              <Copy fontSize={16}>
                <span style={{ fontWeight: 'bold' }}>Phone: </span>
                <a href="tel:0333 050 9541">0333 050 9541</a>
              </Copy>
              <Spacer height={8} />
              <Copy fontSize={16}>or please use the web chat below</Copy>
              <Spacer height={52} />
            </div>
            <Button
              text="try again"
              buttonColor={ButtonTypes.b01}
              fullWidth
              size="large"
              onClick={() => {
                track('My Account Try Again Clicked', {
                  screen: 'payment failure',
                  when: "on clicking the 'Try Again' button",
                  url: window.location.href,
                });
                history.goBack();
              }}
            />
          </div>
        </Card>
        {!isMobile && <Spacer height={256} />}
      </div>
    </FadeIn>
  );
};
