import { FadeIn } from 'components/animations/Transitions';
import { Modal } from 'components/common/Modal';
import { useSegmentIdentifyAndCheckoutTracking } from 'hooks/segment/segment.hooks';
import { useEffect, useState } from 'react';
import { useStepBankContext } from 'screens/Checkout/containers/StepBank/hooks/useStepBankContext';
import BankDetailsConfirm from '../BankDetailsConfirm';
import BankDetailsNew from '../BankDetailsNew';
import { StyledStepBankContent } from './styles';
import { IStepBankContent } from './types';

export const StepBankContent = ({ dataTestId }: IStepBankContent) => {
  useSegmentIdentifyAndCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'bank info',
    checkoutScreen: 'add bank details',
    when: 'on page load',
  });
  const { confirmDetails, loading } = useStepBankContext();
  const [pageMounting, setPageMounting] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageMounting(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  if (loading || pageMounting) {
    return <Modal visible text="Loading..." />;
  }
  return (
    <FadeIn>
      <StyledStepBankContent data-testid={dataTestId}>
        {confirmDetails ? <BankDetailsConfirm /> : <BankDetailsNew />}
      </StyledStepBankContent>
    </FadeIn>
  );
};
