import { useStepTradeInContext } from 'screens/Checkout/containers/StepTradeIn/hooks/useStepTradeInContext';
import TradeInJourneyDecision from '../TradeInJourneyDecision';
import TradeInJourneyStepOne from '../TradeInJourneyStepOne';
import TradeInJourneyStepTwo from '../TradeInJourneyStepTwo';
import { StyledTradeInJourney } from './styles';
import { ITradeInJourney } from './types';

export const TradeInJourney = ({ dataTestId }: ITradeInJourney) => {
  const { stepIndex, tradeInExists } = useStepTradeInContext();
  return (
    <StyledTradeInJourney data-testid={dataTestId}>
      {stepIndex === 0 && <TradeInJourneyStepOne />}
      {stepIndex === 1 && <TradeInJourneyStepTwo />}
      {stepIndex === 2 && <TradeInJourneyDecision />}
      {tradeInExists && <TradeInJourneyDecision />}
    </StyledTradeInJourney>
  );
};
