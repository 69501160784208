import { Toggle as ToggleComponent } from 'components/common/Toggle';
import { StyledToggle } from './styles';
import { IToggle } from './types';

export const Toggle = ({
  dataTestId,
  labelOff,
  labelOn,
  onClick,
  active,
  textSize,
}: IToggle) => {
  return (
    <StyledToggle data-testid={dataTestId} active={active} textSize={textSize}>
      <ToggleComponent
        labelOff={labelOff}
        labelOn={labelOn}
        onClick={onClick}
        active={active}
        disabled={false}
      />
    </StyledToggle>
  );
};
