import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router'
import * as A from 'styles/account';
import * as T from 'styles/type';

import { AccountFooter } from 'components/Account/AccountFooter';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { BackButton } from 'components/common/BackButton';
import { Button } from 'components/common/Button';
import { PolicyBanner } from 'components/Account/PolicyBanner';
import { Link } from 'components/common/Link';
import { QL_CLAIM, MU_CLAIM } from './queries';
import { Modal } from 'components/common/Modal';
import { useMutation } from '@apollo/client';
import useRayloQuery from 'utils/useRayloQuery';
import { Helmet } from 'react-helmet';


export const InsuranceClaim = ({lost, stolen}) => {
  const history = useHistory()
  const { orderId } = useParams()

  const { data: { customer }, loading } = useRayloQuery(QL_CLAIM, {fetchPolicy: "network-only"})
  const [updateMutation] = useMutation(MU_CLAIM, {
    update: (proxy, {data: {createInsuranceClaim: { InsuranceClaim, errors }}}) => {
      window.location = policy.claimUrl
    }
  })
  const [policy, setPolicy] = useState({})

  useEffect(() => {

    if(customer?.orders) {

      const order = customer.orders.find(order => order.id === orderId)
      const orderItem = order.items[0]

      if(orderItem?.pricePlan) {
        if(!orderItem.pricePlan.includesInsurance) {
          window.location = '/account'
        }
      }

      const subscription = orderItem?.subscription
      if(subscription) {
        setPolicy(subscription.insurancePolicy)

        if(subscription.activeAsset?.blockState === "UNBLOCKED") {
          if(lost) {
            history.push(`/account/insurance/lost/${orderId}`)
          } else {
            history.push(`/account/insurance/stolen/${orderId}`)
          }
        }
      
      } else {
        history.push("/account")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  const onClaim = () => {

    updateMutation({
      variables: {
        insurancePolicyId: policy.id,
        reason: lost ? "LOSS" : "THEFT"
      }
    })
  }
  if(loading || !(policy?.id)) return (<Modal visible text="Loading..." />)
  return (
<>
      

      <Helmet><title>Making a Claim - Raylo</title></Helmet>
      <A.AccountWrapper>
        <A.AccountForm>
          <A.AccountFormContainer>
            <FadeIn>
              <div style={{ marginBottom: 33 }}>
              <BackButton withText onClick={() => history.push("/account/insurance")} />
              </div>
            </FadeIn>

            <FadeIn>
              <div style={{ marginBottom: 40 }}>
                <T.RayloBodyHeading style={{ marginBottom: 0 }}>Now you're ready to make an insurance claim</T.RayloBodyHeading>
              </div>
            </FadeIn>

            <SlideDownFadeIn>
              <T.RayloBodyType>We have begun the process of blocking your phone. Typically, this  takes up to 24 hours to complete. You can, however, make an insurance claim immediately.</T.RayloBodyType>
            </SlideDownFadeIn>

            <SlideDownFadeIn>
              <div style={{ marginBottom: 20, marginTop: 20 }}>
                <T.RayloWarningText>Clicking ‘Make a Claim’ below will redirect you to the Pier Insurance website where you can complete the claim process</T.RayloWarningText>
              </div>
            </SlideDownFadeIn>

            <SlideDownFadeIn>
              <Button buttonStyle="primaryBlue" onClick={onClaim}>Make a claim</Button>
            </SlideDownFadeIn>

            <SlideDownFadeIn>
              <div style={{ marginBottom: 20, marginTop: 20 }}>
                <T.RayloBodyType>Any questions? <Link href="" onClick={() => window.Intercom('show')}>Contact Us</Link></T.RayloBodyType>
              </div>
            </SlideDownFadeIn>
          </A.AccountFormContainer>
        </A.AccountForm>
      </A.AccountWrapper>

      <PolicyBanner />

      <AccountFooter />
    </>
  )
  }
