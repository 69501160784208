import gql from 'graphql-tag';

export const CHECKOUT_INCOMING_PAYMENTS = gql`
  query SegmentEventQuery($token: String!) {
    checkout(token: $token) {
      incomingPayments {
        id
        state
        partnerReferences {
          referenceType
          reference
        }
      }
    }
  }
`;
