import { primaryColors } from '../../variables/colors';
import { rayloDark } from '../rayloDark';

export const checkout = {
  ...rayloDark.checkout,
  wrapper: {
    backgroundColor: '#fff',
  },
  subHeader: {
    color: primaryColors.c01,
  },
  sectionHeader: {
    color: primaryColors.c01,
  },
};
