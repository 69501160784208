import { Container, Copy, Icon } from 'elements';
import TextLink from 'elements/TextLink';
import { px2Rem } from 'utils/px2Rem';
import { StyledIconText } from './styles';
import { IIconText } from './types';

export const IconText = ({
  dataTestId,
  iconName,
  iconColor,
  copyColor,
  copyText,
  linkText,
}: IIconText) => {
  return (
    <StyledIconText data-testid={dataTestId}>
      <Container noPadding flexRow>
        <Container noPadding width="fit-content">
          <Icon name={iconName} color={iconColor} size={px2Rem(24)} />
        </Container>
        <Container noPadding centerHorizontal margin={`0 0 0 ${px2Rem(12)}`}>
          <Copy color={copyColor} verticalAlign={-5} bold fontSize={14}>
            {copyText}{' '}
            <TextLink fontSize={14} regular>
              {linkText}
            </TextLink>
          </Copy>
        </Container>
      </Container>
    </StyledIconText>
  );
};
