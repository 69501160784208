import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';

const StyledIconWithText = styled.div`
  display: flex;
`;

const IconWrapper = styled.div`
  ${({ onClick }) => onClick && 'cursor: pointer;'}
`;

const TextWrapper = styled.div<{ textMargin?: number }>`
  margin-left: ${px2Rem(16)};
`;

export { StyledIconWithText, IconWrapper, TextWrapper };
