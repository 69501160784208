/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Modal from 'react-modal';
import iconModalClose from "public/icon--modal-close.svg";
import iconShield from "public/icon--shield.svg";
import 'styles/condition-modal.css';

if(process.env.NODE_ENV !== "test") {
  Modal.setAppElement('#root');
}


export const ConditionModal = ({modalIsOpen, setIsOpen}) => {
  return (

    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setIsOpen(false)}
      contentLabel="Example Modal"
      className="condition-modal"
    >
      <a className="condition-modal--close" onClick={() => setIsOpen(false)}>
        <img src={iconModalClose} alt="close" />
      </a>
      
      <div className="condition-modal--inner">
        <img className="condition-modal--icon" src={iconShield} alt="condition icon"/>
        <h2>Condition Explained</h2>
        <h3>Very good</h3>
        <p>Our refurbished devices have been used and spent a little time in people's bags and pockets and so might have some light scratches. All Raylo phones come with a screen protector and case and are Raylo Certified having passed through our comprehensive 70 point check, so it will look and feel great. And of course if you are not 100% happy, you have 30 days to return it to us and cancel for free.</p>
      </div>

    </Modal>
  )
}