import React, {useState} from 'react';
import styled from 'styled-components/macro'
import colors from 'styles/colors';
import Currency from 'react-currency-formatter';

import howItWorksPhone from 'public/how-it-works-phone.svg';
import howItWorksWallet from 'public/how-it-works-wallet.svg';
import howItWorksCalendar from 'public/how-it-works-calendar.svg';
import { useChangePhoneContext } from 'utils/useChangePhoneContext';
import { ChoiceModal } from 'components/Account/ChoiceModal';
import * as T from 'styles/type';

export const HowItWorks = () => {

  const { product, pricePlan, loading } = useChangePhoneContext()
  const [modalIsOpen, setIsOpen] = useState(false);

  if(loading || !product || !pricePlan) {
    return null
  }

  return (
    <HowItWorksRow>
      <ChoiceModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
      <HowItWorksBox>
        <HowItWorksNumber dark>1</HowItWorksNumber>
        <HowItWorksImage><img src={howItWorksPhone} alt="Phone" /></HowItWorksImage>
        <HowItWorksHeader white>Choose the tech and term you want.</HowItWorksHeader>
        <HowItWorksBody pink><p>It’s super quick and easy. We only do the best devices.</p></HowItWorksBody>
      </HowItWorksBox>
      <HowItWorksBox>
        <HowItWorksNumber pink>2</HowItWorksNumber>
        <HowItWorksImage><img src={howItWorksWallet} alt="Wallet" /></HowItWorksImage>
        <HowItWorksHeader>Pay a Lower Monthly Fee</HowItWorksHeader>
        <HowItWorksBody><p>Get the latest tech without having to own it</p></HowItWorksBody>
      </HowItWorksBox>
      <HowItWorksBox>
        <HowItWorksNumber blue>3</HowItWorksNumber>
        <HowItWorksImage><img src={howItWorksCalendar} alt="Calendar" /></HowItWorksImage>
        <HowItWorksHeader>After {pricePlan.termLength} Months, the choice is yours</HowItWorksHeader>
        <HowItWorksBody>
          <p>Apply to upgrade, keep your {product.displayName} for <Currency quantity={pricePlan.feeSummary.endOfTermNonReturnFee.value} currency={pricePlan.costSummary.recurring.totalAmount.valueAfterTax.currencyCode} /> or leave us by returning your device. </p>
          <T.RayloLink color={colors.rayloBlue} onClick={() => setIsOpen(true)}>Learn more</T.RayloLink>
        
        </HowItWorksBody>
      </HowItWorksBox>
    </HowItWorksRow>
  )
}

const HowItWorksRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 -10px;
`

const HowItWorksBox = styled.div`
  background: ${colors.rayloDark};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 10px 20px 10px;
  text-align: center;
  font-family: 'Raylo-Bold', Arial, sans-serif;
  font-size: 0.875em;
  font-size: 14px;
  line-height: 2em;
  padding: 50px;
  color: black;
  position: relative;
  flex: 1;
  min-width: 300px;

  &:nth-child(2n) {
    background: ${colors.rayloPink3Light};
  }
  &:nth-child(3n) {
    background: ${colors.raylo4Light};
  }
`

const HowItWorksHeader = styled.div`
  color: ${props => props.white ? 'white' : 'black'};
`

const HowItWorksNumber = styled.div`
  border-radius: 100px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  font-family: 'Raylo-Medium', Arial, sans-serif;
  font-size: 1em;
  position: absolute;
  top: 20px;
  left: 20px;
  background: ${props => props.dark ? '#3E3E3E' : 'white'};
  color: ${props => props.pink ? colors.rayloPink3Dark : props.blue ? colors.rayloBlue : colors.rayloPink};
`

const HowItWorksImage = styled.div`
  min-height: 106px;
  margin-bottom: 50px;
  img {
    display: block;
  }
  
`
const HowItWorksBody = styled.div`
  flex: 1;
  font-family: 'Raylo-Medium', Arial, sans-serif;
  color: ${props => props.pink ? colors.rayloPink : 'black'};
  p { 
    opacity: 0.6; 
    margin-bottom: 10px; 
  }
`
