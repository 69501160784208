import { primaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import styled from 'styled-components';

const StyledNumberedSteps = styled.div``;
const StyledOutlinedNumber = styled.span`
  color: ${primaryColors.w01};
  background: ${primaryColors.b01};
  border: ${px2Rem(2)} solid ${primaryColors.b01};
  width: ${px2Rem(30)};
  height: ${px2Rem(30)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: ${px2Rem(16)};
  font-weight: bold;
  padding: ${px2Rem(4)} ${px2Rem(9)};
  gap: ${px2Rem(10)};
`;

export { StyledNumberedSteps, StyledOutlinedNumber };
