import gql from 'graphql-tag';

export const CUSTOMER_INCOMING_PAYMENTS = gql`
  query CustomerIncomingPaymentsQuery {
    customer {
      subscriptions {
        id
        incomingPayments {
          id
          state
          partnerReferences {
            referenceType
            reference
          }
        }
      }
    }
  }
`;
