import useMedia from 'use-media';
import { useHistory } from 'react-router';

import { Container, Icon, Spacer, Copy, Button, Header } from 'elements';

import { px2Rem } from 'utils/px2Rem';
import { breakpoints } from 'styles/breakpoints';
import { primaryColors } from 'styles/variables/colors';
import { track } from 'helpers/analytics/segment';
import { IOverdueBanner } from './types';

export const OverdueBanner = ({ device, customer }: IOverdueBanner) => {
  const isMobile = useMedia({ maxWidth: breakpoints.mobile });
  const history = useHistory();

  return (
    <Container
      center
      backgroundColor={primaryColors.b01}
      styles={{
        textAlign: 'center',
        paddingBottom: 0,
        paddingTop: 0,
      }}
    >
      <Spacer height={24} />
      <Header level={2} color="#fff">
        Hi {customer?.firstName}
      </Header>
      <Spacer height={24} />
      <div>
        <Icon
          name="WhiteAlert"
          size={isMobile ? px2Rem(28) : px2Rem(24)}
          styles={`
            ${
              isMobile
                ? ''
                : `
            display: inline-block;
            vertical-align: sub;
            margin-right: ${px2Rem(12)};
            `
            }
          `}
        />
        {isMobile && <Spacer height={12} />}
        <Copy fontSize={24} color="#fff" styles="display: inline-block;">
          You need to make a payment
        </Copy>
      </div>
      <Spacer height={24} />
      <Container padding="0" width={isMobile ? '95%' : px2Rem(242)}>
        <Button
          buttonType="ghost"
          styles={`
          width: 100%;
          ${isMobile ? 'margin: auto;' : ''}
          `}
          text="pay now"
          size="medium"
          onClick={() => {
            track('My Account Pay Now Clicked', {
              screen: 'lease details',
              where: 'lease details header',
              when: "on clicking the 'Pay Now' button in the My Account header",
              url: window.location.href,
            });
            history.push(`/account/payment/${device?.subscription.id}`);
          }}
        />
        <Spacer height={isMobile ? 24 : 48} />
      </Container>
    </Container>
  );
};
