import { useState, useEffect } from 'react';
import _ from 'lodash';

// HOOKS
import { useMutation } from '@apollo/client';

// STATE
import { useUiContext } from 'utils/useUiContext'

// GRAPHQL
import { QL_CHANGE_PHONE } from 'screens/Checkout/graphQL/queries';
import { MU_SWAP_CHECKOUT, MU_RESTART_CHECKOUT, MU_RESUME_CHECKOUT } from '../graphQL/mutations';

// COMPONENTS
import { FadeIn } from 'components/animations/Transitions';
import { PhoneImage }  from 'components/common/PhoneImage';
import { Modal } from 'components/common/Modal';
import { SoldOut } from 'screens/Errors/SoldOut';
import { InvalidChoice } from 'screens/Errors/InvalidChoice';
import { CatchAll } from 'screens/Errors/CatchAll';
import { PhoneConfigure } from 'components/Checkout/PhoneConfigure';
import useRayloQuery from 'utils/useRayloQuery'
import { useErrorNavigator}  from 'utils/useErrorNavigator'
import { useChangePhoneContext } from 'utils/useChangePhoneContext';
import { ProductCategories } from 'types';

export const ChangePhone = ({checkoutToken}) => {
  const [currentCategoryId, setCurrentCategoryId] = useState(undefined)
  const [isLaptop, setIsLaptop] = useState(false)
  const errorNav = useErrorNavigator()
  const { variant, pricePlan } = useChangePhoneContext()

  // HOOKS
  const { setShowConfigure, setUiPricePlan, setShowSummary } = useUiContext()
  const [swapCheckoutItem] = useMutation(MU_SWAP_CHECKOUT, {
    update: (proxy, {data: {swapCheckoutItem: {checkout, errors }}}) => {
      setIsSubmitting(false)
      if(errors && errors.length > 0) {
        if(_.filter(errors, {code: "sold_out"}).length > 0) {
          setErrorCode("sold_out")
        } else if(_.filter(errors, {code: "blank"}).length > 0) {
          setErrorCode("bad_choice")
        } else {
          setErrorCode("generic")
        }
      } else {
        setUiPricePlan(checkout.items[0].pricePlan)
        setShowConfigure(false)
      }
    },
    refetchQueries: ['getProducts', 'queryRiskPricing', 'stepTermsQuery','stepSignQuery']
  })
  const [restartCheckout] = useMutation(MU_RESTART_CHECKOUT, {
    update: (proxy, {data: {restartCheckout: { errors }}}) => {
      setIsSubmitting(false)
      if(errors && errors.length > 0) {
        if(_.filter(errors, {code: "sold_out"}).length > 0) {
          setErrorCode("sold_out")
        } else if(_.filter(errors, {code: "blank"}).length > 0) {
          setErrorCode("bad_choice")
        } else {
          setErrorCode("generic")
        }
      } else {
        actionSwap()
      }
    }
  })
  const [resumeCheckout] = useMutation(MU_RESUME_CHECKOUT, {
    update: (proxy, {data: {resumeCheckout: {checkout, errors}}}) => {
      if(errors && errors.length > 0) {
        errorNav("GraphQL resume validation error", {errors: errors})
      } else {
        actionSwap()
      }
    }
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorCode, setErrorCode] = useState("")

  const { data: { checkout }, loading: loadingCheckout } = useRayloQuery(QL_CHANGE_PHONE, {
    variables: {
      token: checkoutToken
    }
  })

  const actionRestart = () => {
    setIsSubmitting(true)
    restartCheckout({
      variables: {
        checkoutToken
      }
    })
  }

  const actionResume = () => {
    resumeCheckout({
      variables: {
        checkoutToken
      }
    })
  }

  const actionSwap = () => {

    setIsSubmitting(true)
    const item = checkout.items[0]
    swapCheckoutItem({
      variables: {
        variantId: variant.id,
        pricePlanId: pricePlan.id,
        checkoutToken,
        itemId: item.id
      }
    })
  }

  const onConfirm = () => {

    if(isSubmitting) {
      return
    }

    setIsSubmitting(true)

    const item = checkout.items[0]
    const { pricePlan: existingPricePlan, variant: existingVariant } = item

    if(existingVariant.id === variant.id && existingPricePlan.id === pricePlan.id) {
      setShowConfigure(false)
      setIsSubmitting(false)
    } else {
      if (checkout.decision && checkout.decision.outcome === "USER_ACTION_REQUIRED" && checkout.state === "SUBMITTED") {
        actionRestart()
      } else if(checkout.state === "ABANDONED") {
        actionResume()
      } else {
        actionSwap()
      }
    }
  }
  const [configureLoaded, setConfigureLoaded] = useState(false)

  useEffect(() => {
    setConfigureLoaded(true)
  }, [])

  useEffect( () => () => setConfigureLoaded(false), [] );

  useEffect(() => {
    const categoryId = checkout?.items[0]?.variant?.product?.category?.id
    if(categoryId) {
      setCurrentCategoryId(categoryId)
      setIsLaptop(checkout?.items[0]?.variant?.product?.category?.displayName === ProductCategories.LAPTOP)
    }
  }, [checkout])

  const handleBack = () => setShowSummary(false)

  if(errorCode === "sold_out") {
    return (<SoldOut onClick={() => setErrorCode("")} buttonStyle="secondary" />)
  } else if(errorCode === "bad_choice") {
    return (<InvalidChoice onClick={() => setErrorCode("")} buttonStyle="secondary" />)
  } else if(errorCode === "generic") {
    return (<CatchAll onClick={() => setErrorCode("")} buttonStyle="secondary" />)
  }

  if(loadingCheckout) return <Modal visible text="Loading..." />



  const image = variant && _.first(variant.images, {tag: "Front" })

  return (
    <FadeIn exit={true}>
      <div className="phone-configure">
        <div className="phone-configure--image">
          <div style={{
            width: isLaptop ? '70%' : '100%'
          }}>
            {variant && image && <PhoneImage size="responsive" bg="404EFF" source={`${image.url}?bg=#00000000`} alt={image.alt || variant.displayName} />}
          </div>
        </div>

        <div className="phone-configure--form">
          <PhoneConfigure
            onConfirm={onConfirm}
            disabled={isSubmitting}
            configureLoaded={configureLoaded}
            categoryId={currentCategoryId}
            buttonLabel="Confirm"
            changeDevice
          />
        </div>
      </div>
    </FadeIn>
  )
}
