import { secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import styled from 'styled-components';

const StyledNumberedList = styled.div``;
const StyledOutlinedNumber = styled.span`
  color: ${secondaryColors.p02};
  border: ${px2Rem(2)} solid;
  width: ${px2Rem(20)};
  height: ${px2Rem(20)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: ${px2Rem(12)};
  font-weight: bold;
`;

export { StyledNumberedList, StyledOutlinedNumber };
