import { useQuery } from '@apollo/client'

const useRayloQuery = (query, options = {}, onCompleted, onError) => {
  const result = useQuery(query, options, onCompleted, onError)
  return {
    ...result,
    data: result.data ? result.data : {},
  }

}

export default useRayloQuery
