import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import colors from 'styles/colors';

export const ProgressBar = ({position, total, paymentStep}) => {

  const [timelineEvents] = useState([
    {
      id: 1,
      text: "Your Progress",
      active: true,
    },
    {
      id: 2,
      text: "Review Order",
      active: false
    }
  ]);

  const stages = total;

  const [currentPosition, setCurrentPosition] = useState('')

  useEffect(() => {
    let pos = ((position - 1) / stages) * 100;
    setCurrentPosition(`${pos}%`)
    switch(true) {
      case position === 1 : {
        setCurrentPosition(`calc(${pos}% + 20px)`);
        break;
      }
      case position === stages : {
        timelineEvents[0].active = false;
        timelineEvents[1].active = true;
        setCurrentPosition('calc(100% - 32px)');
        break;
      }
      default : {
        timelineEvents[0].active = true;
        timelineEvents[1].active = false;
      }
    }
  }, [position, timelineEvents, paymentStep, stages])

  return (
    <ProgressBarContainer>
      <ProgressBarBackground />
      <ProgressBarTimelineEvents events={timelineEvents.length}>
        {timelineEvents
          .map(e =>
            <ProgressBarTimelineEvent key={e.id} active={e.active}>
              {e.text}
            </ProgressBarTimelineEvent>
          )
        }
      </ProgressBarTimelineEvents>

      <ProgressBarTimeline>
        <ProgressBarThumb position={currentPosition} />
        <ProgressBarProgress position={currentPosition} />
      </ProgressBarTimeline>

    </ProgressBarContainer>
  )
}

export const ProgressBarContainer = styled.div`
`

export const ProgressBarBackground = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 16px;
  height: 75px;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 20%,rgba(0,0,0,1) 100%);
  
  @media screen and (max-height: 650px) {
    height: 30px;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 70%,rgba(0,0,0,1) 100%);
  }
  @media screen and (max-width: 540px) {
    height: 30px;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 70%,rgba(0,0,0,1) 100%);
  }
`

export const ProgressBarTimeline = styled.div`
  display: block;
  width: 100%;
  height: 12px;
  background-color: ${colors.rayloBlue};
  position: relative;
  z-index: 2;
`

export const ProgressBarThumb = styled.div`
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: ${colors.rayloDark};
  position: absolute;
  top: 0;
  left: ${props => props.position || '0'};
  transition: left .75s ease-in-out;

  &::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    bottom: 100%;
    background: ${colors.rayloPink};
  }
`

export const ProgressBarProgress = styled.div`
  display: block;
  height: 12px;
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => props.position || '0'};
  background: ${colors.rayloPink};
  transition: width .75s ease-in-out;
`

export const ProgressBarTimelineEvents = styled.div`
  width: 100%;
  padding: 0 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;

  @media screen and (max-height: 650px) {
    display: none;
  }
  @media screen and (max-width: 540px) {
    display: none;
  }
`

export const ProgressBarTimelineEvent = styled.div`
  display: inline-block;
  font-family: 'Raylo-Bold', Arial, sans-serif;
  font-size: 0.75em;
  font-weight: bold;
  color: ${props => props.active ? `${colors.rayloPink}` : `${colors.raylo3Dark}`};
  letter-spacing: 0.5px;
  line-height: 4.8;
  text-transform: uppercase;
  text-align: left;
  transition: color .35s;

  &:last-of-type {
    text-align: right;
  }
`
