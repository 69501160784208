import React, { useEffect } from 'react';
import { activateOptimize } from 'utils/optimize'

// VISUAL COMPONENTS
import * as T from 'styles/type';
import { Button } from 'components/common/Button';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';

export const DirectDebit = ({onBack, onOK}) => {
  useEffect(() => {
    activateOptimize()
  })
  return (
  <div className="checkout--step feedback">
    <FadeIn>
      <T.RayloBodyHeading><strong>It looks like your bank account doesn’t support direct debits</strong></T.RayloBodyHeading>
    </FadeIn>

    <SlideDownFadeIn>
      <div style={{ marginBottom: 40 }}>
        <T.RayloBodyType>This sometimes happens if someone enters their savings account details by mistake. Please check that you’ve entered valid Current Account details and try again.</T.RayloBodyType>
      </div>
    </SlideDownFadeIn>

    <SlideDownFadeIn>
      <Button
        buttonStyle='primaryBlue'
        onClick={onBack}
        style={{ height: '44px' }}>
        Try again
      </Button>
    </SlideDownFadeIn>
  </div>
)}
