import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro'
import colors from 'styles/colors';
import Currency from 'react-currency-formatter';
import useRayloQuery from 'utils/useRayloQuery';
import { QL_CURRENT_LEASE } from 'screens/Upgrade/queries';
import _ from 'lodash'
import { useParams } from 'react-router';
import { getSessionStorage } from 'utils/handleSessionStorage';

export const CurrentLease = () => {

  const { subscriptionId } = useParams()

  const { data: { customer } } = useRayloQuery(QL_CURRENT_LEASE)

  const [phone, setPhone] = useState()
  const [termLength, setTermLength] = useState()
  const [amount, setAmount] = useState()
  const [additionalTech, setAdditionalTech] = useState(false)

  useEffect(() => {

    if (customer && customer.orders) {

      const items = _.filter(_.flatten(_.map(customer.orders, 'items')), o => o.subscription && o.subscription.id === subscriptionId)
      const item = _.first(items)
      setTermLength(item?.subscription?.pricePlan?.termLength)
      setPhone(item?.variant?.displayName)
      setAmount(item?.costSummary?.recurring?.totalAmount?.valueAfterTax)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  useEffect(() => {
    setAdditionalTech(!!getSessionStorage('additionalTech'))
  }, [])

  if(phone && termLength && amount && !additionalTech) {
    return (
      <CurrentLeaseBox>
        <CurrentLeaseHeader>Your Current Lease</CurrentLeaseHeader>
        <CurrentLeasePhone>{phone}</CurrentLeasePhone>
        <CurrentLeasePrice>
          <Currency quantity={amount.value} currency={amount.currencyCode} />/month
          ({termLength} month lease)
          </CurrentLeasePrice>
      </CurrentLeaseBox>
    )
  } else {
    return (<></>)
  }
}

const CurrentLeaseBox = styled.div`
  background: ${colors.rayloDark};
  padding: 25px;
  font-size: 0.875em;
  line-height: 2em;
  font-family: 'Raylo-Bold', Arial, sans-serif;
  font-weight: bold;
`

const CurrentLeaseHeader = styled.div`
  color: #FFFFFF;
`

const CurrentLeasePhone = styled.div`
  color: ${colors.rayloPink};
`
const CurrentLeasePrice = styled.div`
  font-family: 'Raylo-Medium', Arial, sans-serif;
  font-weight: 500;
  color: ${colors.rayloPink};
  opacity: 0.7;
`