import React from 'react';

import { Switch, Route } from 'react-router-dom'
import { Callback, Expired, Login, Verify } from './index'

import * as Sentry from '@sentry/react';
const SentryRoute = Sentry.withSentryRouting(Route);
export const LoginRouter = () => {
  return (
    <Switch>
      <SentryRoute path="/login/callback">
        <Callback />
      </SentryRoute>
      <SentryRoute path="/login/expired">
        <Expired />
      </SentryRoute>
      <SentryRoute path="/login/verify">
        <Verify />
      </SentryRoute>
      <SentryRoute path="/login">
        <Login />
      </SentryRoute>
    </Switch>
  )
}
