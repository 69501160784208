import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';
import colors from 'styles/colors';
import { SlideDownFadeIn } from 'components/animations/Transitions';
import store from 'store'
import { FRONTEND_URL } from 'utils/constants';

import { QL_CUSTOMER } from 'screens/Account/queries';
import useRayloQuery from 'utils/useRayloQuery';
import { useMedia } from 'use-media';

import logo from 'public/raylo-logo.svg';
import { useHistory, useParams } from 'react-router';
import { DeviceSwitcher } from 'components/Account/DeviceSwitcher';
import { parseOrder } from 'screens/Account/Overview';
import _ from 'lodash'
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { primaryColors } from 'styles/variables/colors';

export const AccountHeader = ({active = "", section = "your-devices"}) => {
  const [mobileNavVisible, setMobileNavVisible] = useState(false);
  const [mobileNavHeight, setMobileNavHeight] = useState(0)
  const history = useHistory()

  const mobile = useMedia({maxWidth: 768});

  const { data: { customer } } = useRayloQuery(QL_CUSTOMER)
  const { orderId } = useParams()

  const [phone, setPhone] = useState(null)
  const [phones, setPhones] = useState([])

  useEffect(() => {

    if(customer && customer.orders) {
      let allPhones = []
      _.each(customer.orders, (order) => {
        const p = parseOrder(order)
        allPhones.push(p)
        if(order.id === orderId) {
          setPhone(p)
        }
      })
      setPhones(allPhones)
    }
  }, [customer])

  useEffect(() => {
    const nav = document.getElementById('nav')
    if (nav) {
      setMobileNavHeight(nav.scrollHeight);
    }
  }, [mobile])

  const logout = (e) => {
    e.preventDefault()
    store.remove('userToken')
    window.sessionStorage.clear()
    window.Intercom('shutdown')
    window.location = FRONTEND_URL
    return
  }

  return (
    <>
    <AccountHeaderSC>
      <AccountHeaderContainer>
        <Link to='/account' replace>
          <AccountHeaderLogo src={logo} alt="Raylo Logo" />
        </Link>

        <AccountHeaderNav id="nav" visible={mobileNavVisible} maxHeight={mobileNavHeight}>
          <ul>
            <SlideDownFadeIn visible={mobileNavVisible}>
              <li className={cx({"active": section === "your-devices"})}>
                <NavLink href="" onClick={() => history.push("/account")}>Account Overview</NavLink>
              </li>
            </SlideDownFadeIn>

            <SlideDownFadeIn visible={mobileNavVisible}>
              <li className={cx({"active": section === "account"})}>
                <NavLink href="" onClick={() => history.push("/account/your-account")}>Account Details</NavLink>
              </li>
            </SlideDownFadeIn>

            <SlideDownFadeIn visible={mobileNavVisible}>
              <li className={cx({"active": section === "refer-a-friend"})}>
                <NavLink href="" onClick={() => history.push("/account/refer-a-friend")}>Referrals</NavLink>
              </li>
            </SlideDownFadeIn>

            <SlideDownFadeIn visible={mobileNavVisible}>
              <li className={cx({"active": section === "logout"})}>
                <NavLink href="" onClick={logout}>Logout</NavLink>
              </li>
            </SlideDownFadeIn>
          </ul>
        </AccountHeaderNav>

        <NavToggle onClick={() => setMobileNavVisible(!mobileNavVisible)}>
          <NavToggleIcon active={mobileNavVisible}>
            <span></span>
          </NavToggleIcon>
        </NavToggle>
      </AccountHeaderContainer>
    </AccountHeaderSC>
    </>
  )
}

export const AccountHeaderSC = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height: 90px;
  padding: 0 35px;
  background-color: ${primaryColors.b01};
  position: relative;

  @media (max-width: 1200px) {
    padding: 0px 20px;
    height: auto;
  }
`

export const AccountHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const AccountHeaderLogo = styled.img`
  display: inline-block;
  color: ${colors.rayloPink};
`

export const AccountHeaderNav = styled.nav`

  @media (max-width: 850px) {
    position: absolute;
    z-index: 5;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    overflow: hidden;
    max-height: ${props => props.visible ? `${props.maxHeight}px` : '0'};
    background-color: ${primaryColors.b01};
    transition: max-height .65s ease-in-out;
  }

  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;

    @media (max-width: 850px) {
      display: block;
      padding-left: 20px;
    }
  }

  li {
    height: 90px;
    @media (max-width: 1200px) {
      height: auto;
    }
    font-weight: bold;
    display: flex;
    align-items: flex-start;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    &.active, &:hover {
      border-bottom: 4px solid ${colors.rayloPink};
      width: fit-content;
      & a {
        font-weight: bold;
      }
    }
    &:not(:last-of-type) {
      margin-right: 80px;

      @media (max-width: 1200px) {
        margin-right: 28px;
      }

      @media (max-width: 850px) {
        margin-right: 0;
      }
    }

    @media (max-width: 850px) {
      display: block;
      margin-bottom: 20px;
      padding-left: 0;
    }
  }
`

export const NavLink = styled.a`
  font-family: 'Raylo-Regular', Arial, sans-serif;
  font-size: 0.875em;
  font-weight: normal;
  color: #FFFFFF;
  letter-spacing: 0.9px;
  line-height: 4.14;
  text-transform: uppercase;
  text-decoration: none;
`

export const NavToggle = styled.div`
  display: none;
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 1px;
  background-color: transparent;
  cursor: pointer;

  @media (max-width: 850px) {
    display: inline-block;
  }
`

export const NavToggleIcon = styled.div`
  display: inline-block;
  vertical-align: super;

  span,
  span:before,
  span:after {
    cursor: pointer;
    height: 2px;
    width: 20px;
    background: #FFF;
    position: absolute;
    display: block;
    content: '';
    transition: all 0.35s;
  }

  span:before {
    top: ${props => props.active ? '0' : '-6px'};
    transform: ${props => props.active ? 'rotate(45deg)' : 'none' };
  }

  span:after {
    bottom: ${props => props.active ? 'unset' : '-6px'};
    top: ${props => props.active ? '6px' : 'unset'};
    transform: ${props => props.active ? 'translateY(-6px) rotate(-45deg)' : 'none'};
  }

  span {
    background-color: ${props => props.active ? 'transparent' : '#FFF'};
  }
`
