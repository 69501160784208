import { Copy } from 'elements';
import { StyledFindOutMoreModal } from './styles';
import { IFindOutMoreModal } from './types';

export const FindOutMoreModal = ({ dataTestId }: IFindOutMoreModal) => {
  return (
    <StyledFindOutMoreModal data-testid={dataTestId}>
      <Copy color="black">Find Out More</Copy>
    </StyledFindOutMoreModal>
  );
};
