// GQL
import gql from 'graphql-tag';
import { Checkout } from 'screens/Checkout/graphQL/fragments'

export const CheckoutSteps = {
  step0: {
    customer: gql`
      query CheckoutStepStartQuery {
        customer {
          id
          preApproval {
            checkout {
              token
            }
          }
          orders {
            id
            items {
              id
              subscription {
                id
                upgrade {
                  checkout {
                    id
                    token
                  }
                }
              }
            }
          }
        }
      }
    `,
    mutation: gql`
      mutation CheckoutCreateMutation($pricePlanId: ID!, $variantId: ID!, $originSubscriptionId: ID, $merchantId: ID) {
        createCheckout(input: {
          privacyPolicyAccepted: true,
          merchantId: $merchantId,
          items: [{
            variantId: $variantId,
            pricePlanId: $pricePlanId,
            originSubscriptionId: $originSubscriptionId,
          }]
        }) {
          checkout {
            ...CheckoutMutationBase
            initialPaymentMethodRequired
          },
          errors {
            field,
            code,
            message
          }
        }
      }
    ${Checkout.fragments.mutationBase}`
  },
  stepUpgrade: {
    query: gql`
      query CheckoutStepUpgradeQuery($token: String!) {
        checkout(token: $token) {
          id
          token
          initialPaymentMethodRequired
          customerInfo {
            id
            firstName
            lastName
            dateOfBirth
            email
          }
          customer {
            id
            firstName
            lastName
            dateOfBirth
            email
          }
          items {
            id
            originSubscription {
              id
              feeSummary {
                currentNonReturnFee {
                  value
                  currencyCode
                }
              }
              activeAsset {
                id

                variant {
                  id
                  product {
                    id
                    displayName
                  }
                }
              }
            }
            variant {
              id
              
              product {
                id
                displayName
              }
            }
          }
        }
      }`,
    mutation: gql`
      mutation CheckoutStepUpgradeMutation(
        $firstName: String!,
        $lastName: String!,
        $email: String!,
        $dateOfBirth: String!,
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken,
          input: {
            customerInfo: {
              firstName: $firstName,
              lastName: $lastName,
              email: $email,
              dateOfBirth: $dateOfBirth
            }
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            customerInfo {
              firstName
              lastName
              dateOfBirth
              email
            }
          },
          errors {
            field,
            code,
            message
          }
        }
      }
      ${Checkout.fragments.mutationBase}`
    },
  stepEmail: {
    mutation: gql`
      mutation CheckoutStep1Mutation(
        $email: String!,
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken,
          input: {
            customerInfo: {
              email: $email
            }
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            customerInfo {
              firstName,
              lastName,
              email
            }
          },
          errors {
            field,
            code,
            message
          }
        }
      }
      ${Checkout.fragments.mutationBase}`,
    query: gql`
      query CheckoutStepEmailQuery($token: String!) {
        checkout(token: $token) {
          id
          token
          customer {
            id
            email
          }
          customerInfo {
            email
          }
        }
      }`,
  },
  stepNameEmail: {
    query: gql`
      query CheckoutStepNameEmailQuery($token: String!) {
        checkout(token: $token) {
          id,
          token,
          initialPaymentMethodRequired,
          customerInfo {
            firstName,
            lastName,
            email
          }
        }
      }`,
    mutation: gql`
      mutation CheckoutStepNameEmailMutation(
        $firstName: String!,
        $lastName: String!,
        $email: String!,
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken,
          input: {
            customerInfo: {
              firstName: $firstName,
              lastName: $lastName,
              email: $email
            }
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            customerInfo {
              firstName,
              lastName,
              email
            }
          },
          errors {
            field,
            code,
            message
          }
        }
      }
      ${Checkout.fragments.mutationBase}`
  },
  step2: {
    query: gql`
      query CheckoutStep2Query($token: String!) {
        checkout(token: $token) {
          id,
          token,
          initialPaymentMethodRequired,
          customerInfo {
            dateOfBirth
          },
          address {
            country {
              isoAlpha2
            }
          }
        }
      }
     `,
    mutation: gql`
      mutation CheckoutStep2Mutation(
        $dateOfBirth: String!,
        $countryIsoAlpha2: String!
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken,
          input: {
            customerInfo: {
              dateOfBirth: $dateOfBirth,
            },
            address: {
              countryIsoAlpha2: $countryIsoAlpha2
            }
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            customerInfo {
              dateOfBirth
            },
            address {
              country {
                isoAlpha2
              }
            }
          },
          errors {
            field,
            code,
            message
          }
        }
      }
      ${Checkout.fragments.mutationBase}`
  },
  stepAddressCheck: {
    query: gql`
      query CheckoutStepAddressCheckQuery($token: String!) {
        checkout(token: $token) {
          id
          token
          initialPaymentMethodRequired
          recentChangeOfAddress
          recentChangeOfCountry
          previousAddress {
            line1
            line2
            line3
            city
            region
            postcode
          }
          address {
            line1
            line2
            line3
            city
            region
            postcode
          }
        }
      }`,
    mutation: gql`
      mutation CheckoutStepAddressCheckMutation(
        $recentChangeOfAddress: Boolean,
        $recentChangeOfCountry: Boolean,
        $line1: String,
        $line2: String,
        $line3: String,
        $city: String,
        $region: String,
        $postcode: String,
        $countryIsoAlpha2: String,
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken,
          input: {
            recentChangeOfAddress: $recentChangeOfAddress
            recentChangeOfCountry: $recentChangeOfCountry
            previousAddress: {
              line1: $line1,
              line2: $line2,
              line3: $line3,
              city: $city,
              region: $region,
              postcode: $postcode,
              countryIsoAlpha2: $countryIsoAlpha2
            }            
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            recentChangeOfAddress
            recentChangeOfCountry
            previousAddress {
              line1
              line2
              line3
              city
              region
              postcode
            }
          },
          errors {
            field,
            code,
            message
          }
        }
      }  
      ${Checkout.fragments.mutationBase}`
  },
  stepAddress: {
    query: gql`
      query CheckoutStepAddressQuery($token: String!) {
        checkout(token: $token) {
          id
          token
          initialPaymentMethodRequired
          recentChangeOfAddress
          recentChangeOfCountry
          items {
            id
            originSubscription {
              id
            }
          }
          customer {
            id
            address {
              line1
              line2
              line3
              city
              region
              postcode
              country {
                id
                isoAlpha2
              }
            }
          }
          address {
            line1
            line2
            line3
            city
            region
            postcode
            country {
              id
              isoAlpha2
            }
          }
        }
      }`,
    mutation: gql`
      mutation CheckoutStep3Mutation(
        $line1: String,
        $line2: String,
        $line3: String,
        $city: String,
        $region: String,
        $postcode: String,
        $countryIsoAlpha2: String,
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken,
          input: {
            address: {
              line1: $line1,
              line2: $line2,
              line3: $line3,
              city: $city,
              region: $region,
              postcode: $postcode,
              countryIsoAlpha2: $countryIsoAlpha2
            }
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            address {
              line1,
              line2,
              line3,
              city,
              region,
              postcode
            }
          },
          errors {
            field,
            code,
            message
          }
        }
      }  
      ${Checkout.fragments.mutationBase}`
  },
  stepMobile: {
    query: gql`
      query CheckoutMobileQuery($token: String!) {
        checkout(token: $token) {
          id,
          token
          initialPaymentMethodRequired
          customerInfo {
            mobilePhoneNumber
            dateOfBirth
          }
          customer {
            id
            mobilePhoneNumber
          }
          items {
            id
            originSubscription {
              id
            }
          }
        }
      }`,
    mutation: gql`
      mutation CheckoutMobileMutation(
        $mobilePhoneNumber: String!,
        $dateOfBirth: String!,
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken,
          input: {
            customerInfo: {
              mobilePhoneNumber: $mobilePhoneNumber,
              dateOfBirth: $dateOfBirth
            }
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            customerInfo {
              mobilePhoneNumber
              dateOfBirth
            }
          },
          errors {
            field,
            code,
            message
          }
        }
      }
      ${Checkout.fragments.mutationBase}`,
    mutation2: gql`
      mutation CheckoutMobileMutation(
        $mobilePhoneNumber: String!,
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken,
          input: {
            customerInfo: {
              mobilePhoneNumber: $mobilePhoneNumber,
            }
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            customerInfo {
              mobilePhoneNumber
            }
          },
          errors {
            field,
            code,
            message
          }
        }
      }
      ${Checkout.fragments.mutationBase}`,
  },
  stepInsurance: {
    query: gql`
      query CheckoutStepInsuranceQuery($token: String!) {
        checkout(token: $token) {
          id
          token
          initialPaymentMethodRequired
          items {
            id
            ...CheckoutItemPricePlan
            variant {
              id
              ...VariantPricePlan
            }
          }
        }
      }
    ${Checkout.fragments.pricePlan}
    ${Checkout.fragments.variantPricePlan}`,

    mutation: gql`
      mutation CheckoutStepInsuranceMutation(
        $mobilePhoneNumber: String!,
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken,
          input: {
            customerInfo: {
              mobilePhoneNumber: $mobilePhoneNumber
            }
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            customerInfo {
              mobilePhoneNumber
            }
          },
          errors {
            field,
            code,
            message
          }
        }
      }  
      ${Checkout.fragments.mutationBase}`
  },
  stepStripe: {
    query: gql`
      query CheckoutStepStripQuery($token: String!) {
        checkout(token: $token) {
          id,
          token,
          initialPaymentMethodRequired
          customerInfo {
            firstName,
            lastName,
            email
          },
          address {
            line1,
            line2,
            city,
            postcode,
            region
          },
          paymentInfo {
            id
            cardDetails {
              brand
              last4
            }
          },
          items {
            id
            ...CheckoutItemPricePlan
          }
        }
      }
      ${Checkout.fragments.pricePlan}`
  },
  stepBank: {
    query: gql`
      query CheckoutStepBankQuery($token: String!) {
        checkout(token: $token) {
          id
          token
          initialPaymentMethodRequired
          items {
            id
            ...CheckoutItemPricePlan
            originSubscription {
              id
              paymentInfo {
                id
                bankAccountName
                bankAccountNumber
                bankBranchIdentifier
              }
            }
          }
          paymentInfo {
            id
            bankAccountName
            bankAccountNumber
            bankBranchIdentifier
            directDebitMandateAuthorised
          }
        }
      }
    ${Checkout.fragments.pricePlan}`,
    mutation: gql`
      mutation CheckoutStepBankMutation(
        $bankAccountName: String!,
        $bankAccountNumber: String!,
        $bankBranchIdentifier: String!,
        $directDebitMandateAuthorised: Boolean!,
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken,
          input: {
            paymentInfo: {
              bankAccountName: $bankAccountName,
              bankAccountNumber: $bankAccountNumber,
              bankBranchIdentifier: $bankBranchIdentifier,
              directDebitMandateAuthorised: $directDebitMandateAuthorised,
            }
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            paymentInfo {
              id
              bankAccountName,
              bankAccountNumber,
              bankBranchIdentifier,
              directDebitMandateAuthorised
            }
          },
          errors {
            field,
            code,
            message
          }
        }
      }  
      ${Checkout.fragments.mutationBase}`
  },
  step7: {
    query: gql`
      query CheckoutStep7Query($token: String!) {
        checkout(token: $token) {
          id
          token
          initialPaymentMethodRequired
          termsAndConditionsAcceptedAt
          customerInfo {
            marketingOptInAt
          }
          items {
            id
            ...CheckoutItemPricePlan
          }
        }
      }
    ${Checkout.fragments.pricePlan}`,
    termsQuery: gql`
      query CheckoutStep7TermsQuery($token: String!) {
        checkout(token: $token) {
          id
          token
          initialPaymentMethodRequired
          items {
            id
            unsignedHireAgreementUrl
          }
        }
      }`
    ,
    termsMutation: gql`
      mutation CheckoutStep7TermsMutation(
        $checkoutToken: String!
      ) {
        createCheckoutUnsignedHireAgreement(checkoutToken: $checkoutToken) {
          success
        }
      }
    `,

    mutation: gql`
      mutation CheckoutStep7Mutation(
        $marketingOptIn: Boolean!,
        $deviceInfoFingerprint: String,
        $checkoutToken: String!
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken,
          input: {
            customerInfo: {
              marketingOptIn: $marketingOptIn
            }
            deviceInfo: {
              fingerprint: $deviceInfoFingerprint
            }
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            customerInfo {
              marketingOptInAt
            }
          },
          errors {
            field,
            code,
            message
          }
        }
      }  
      ${Checkout.fragments.mutationBase}`
  },
  stepTradeIn: {
    query: gql`
    query CheckoutStepTradeIn($token: String!) {
      checkout(token: $token) {
        id
        items {
          id
          pricePlan {
            id
            termLength
            country {
              id
            }
          }
        }
        tradeIns {
          id
          grade {
            id
            slug
          }
          costSummary {
            category
            displayName
            recurringTotalAmount {
              valueAfterTax {
                value
                currencyCode
              }
            }
          }
        }
      }
    }`,
    queryMax: gql`
    query CheckoutStepTradeInMax($token: String!, $input: TradeInMaxAmountInput!) {
      checkout(token: $token) {
        id
        items {
          id
          variant {
            id
            headlineTradeInMaxAmount(input: $input) {
              formattedValue
              value
              currencyCode
            }
          }
        }
      }
    }`,
  },
  step8: {
    query: gql`
      query CheckoutStep8SignQuery($token: String!) {
        checkout(token: $token) {
          id
          token
          reference
          initialPaymentMethodRequired
          termsAndConditionsAcceptedAt
          tradeInTermsAndConditionsAcceptedAt
          decision {
            outcome
            outcomeReason
          }
          customerInfo {
            firstName
            lastName
            email
            marketingOptInAt
          }
          tradeIns {
            id
          }
          address {
            line1
            line2
            line3
            city
            region
            postcode
          }
          items {
            id
            originSubscription {
              id
            }
            pricePlan {
              id
              termLength
              recurringPeriod
              currencyCode
              termLength
              lastRecurringPeriod
              includesInsurance
              createdAt
              ...PricePlanCostSummary
              ...PricePlanFeeSummary
            }
            ...CheckoutItemVariant
          }
        }
      }
      ${Checkout.fragments.variant}
      ${Checkout.fragments.costSummary}
      ${Checkout.fragments.feeSummary}`,
    mutation: gql`
      mutation CheckoutStep8SignMutation(
        $termsAndConditionsAccepted: Boolean!,
        $tradeInTermsAndConditionsAccepted: Boolean,
        $checkoutToken: String!,
        $marketingOptIn: Boolean!,
        $deviceInfoFingerprint: String
      ) {
        updateCheckout(
          checkoutToken: $checkoutToken,
          input: {
            termsAndConditionsAccepted: $termsAndConditionsAccepted,
            tradeInTermsAndConditionsAccepted: $tradeInTermsAndConditionsAccepted
            customerInfo: {
              marketingOptIn: $marketingOptIn
            }
            deviceInfo: {
              fingerprint: $deviceInfoFingerprint
            }
          }
        ) {
          checkout {
            ...CheckoutMutationBase
            reference
            termsAndConditionsAcceptedAt
            tradeInTermsAndConditionsAcceptedAt
            customerInfo {
              marketingOptInAt
            }
          },
          errors {
            field,
            code,
            message
          }
        }
       }
      ${Checkout.fragments.mutationBase}`
  },
}

export const QL_COUNTRIES = gql`
  query CheckoutCountriesQuery{
  countries {
    name,
    isoAlpha2
  }
}`

export const QL_HEADER = gql`
  query CheckoutHeaderQuery ($token: String!) {
    checkout(token: $token) {
      id,
      items {
        id,
        ...CheckoutItemVariant
      }
    }
  }
  ${Checkout.fragments.variant}
`;

export const QL_CHECKOUT_STATE = gql`
  query CheckoutStateQuery($token: String!) {
    checkout(token: $token) {
      id
      token
      state
      decision {
        outcome
        outcomeReason
      }
    }
  }
`;

export const QL_CHECKOUT_BASE = gql`
  query CheckoutBaseQuery($token: String!) {
    checkout(token: $token) {
      ...CheckoutBase,
      merchant {
        brandingTheme
        features {
          enabled
          slug
        }
      }
      customer {
        id
        subscriptions {
          startedOn
          paymentInfo {
            bankAccountName
            bankAccountNumber
            bankBranchIdentifier
          }
        }
      }
      items {
        id
        originSubscription {
          id
        }
        variant {
          id
          product {
            id
            category {
              id
              slug
            }
          }
        }
        pricePlan {
          id
          includesInsurance
          termLength
          recurringPeriod
        }
      }
      experiments {
        name
        variation
      }
    }
  }
  ${Checkout.fragments.base}
`;

export const QL_VARIANT_PRICE_PLANS = gql`
  query VariantPricePlansQuery($ids: [ID!]) {
    pricePlans: variants(ids: $ids) {
      pricePlans {
        id
        name
        currencyCode
        includesInsurance
        recurringPeriod
        termLength
        costSummary {
          initial {
            totalAmount {
              valueAfterTax {
                value
                currencyCode
              }
            }
          }
          recurring {
            insuranceAmount {
              valueAfterTax {
                value
                currencyCode
              }
            }
            baseAmount {
              valueAfterTax {
                value
                currencyCode
              }
            }
            adjustmentAmount {
              valueAfterTax {
                value
                currencyCode
              }
            }
            totalAmount {
              valueAfterTax {
                value
                currencyCode
              }
            }
          }
        }
      }
    }
  }
`

export const QL_MANUFACTURERS = gql`
  query ManufacturersQuery {
    manufacturers {
      id
      displayName
      products {
        id
        displayName
        available
        preOrder
        releasedAt
        manufacturer {
          id
        }
        variants {
          id
          available
          condition
          position
          deliveryExpectedBetween {
            max
            min
          }
          images {
            alt
            url
            tag
          }
          optionValues {
            id
            name
            displayName
            raw
            optionType {
              id
              slug
              displayName
            }
          }
        }
      }
    }
  }
`;

export const QL_CHANGE_PHONE = gql`
  query CheckoutChangePhoneQuery($token: String!) {
    checkout(token: $token) {
      ...CheckoutBase
      items {
        id
        pricePlan {
          id
          name
          currencyCode
          includesInsurance
          recurringPeriod
          termLength
          costSummary {
            initial {
              totalAmount {
                valueAfterTax {
                  value
                  currencyCode
                }
              }
            }
            recurring {
              insuranceAmount {
                valueAfterTax {
                  value
                  currencyCode
                }
              }
              baseAmount {
                valueAfterTax {
                  value
                  currencyCode
                }
              }
              adjustmentAmount {
                valueAfterTax {
                  value
                  currencyCode
                }
              }
              totalAmount {
                valueAfterTax {
                  value
                  currencyCode
                }
              }
            }
          }
        }
        variant {
          id
          displayName
          available
          condition
          deliveryExpectedBetween {
            max
            min
          }
          optionValues {
            id
            displayName
            raw
            optionType {
              id
              slug
            }
          }
          product {
            id
            displayName
            preOrder
            releasedAt
            manufacturer {
              id
            }
            category {
              id
              displayName
            }
          }
          images {
            alt
            position
            tag
            title
            url
          }
        }
      }
    }
  }
  ${Checkout.fragments.base}
`;

export const QL_SUMMARY = gql`
  query CheckoutPhoneSummaryQuery($token: String!) {
    checkout(token: $token) {
      id
      state
      decision {
        outcome
        outcomeReason
      }
      items {
        id,
        deliveryEstimatedBetween {
          max
          min
        }
        deliveryExpectedBetween {
          max
          min
        }
        ...CheckoutItemPricePlan,
        ...CheckoutItemVariant
      }
      tradeIns {
        id
        grade {
          id
          slug
        }
        costSummary {
          category
          displayName
          recurringTotalAmount {
            valueAfterTax {
              value
              currencyCode
            }
          }
        }
      }
      referrer {
        firstName
      }
    }
    referralProgram {
      reward {
        referralDescription
      }
    }
  }
  ${Checkout.fragments.pricePlan}
  ${Checkout.fragments.variant}
`;

export const QL_DECISION = gql`
  query CheckoutDecisionQuery($checkoutToken: String!) {
    checkout(token: $checkoutToken) {
      id
      state
      decision {
        outcome
        outcomeReason
      },
      ...CheckoutAddress
      items {
        id
        costSummary {
          initial {
            totalAmount {
              valueAfterTax {
                value
                currencyCode
              }
            }
          }
        }
        originSubscription {
          id
          pricePlan {
            id
            costSummary {
              recurring {
                totalAmount {
                  valueAfterTax {
                    currencyCode
                    value
                  }
                }
              }
            }                
          }
          feeSummary {
            currentNonReturnFee {
              value
              currencyCode
            }
          }
          activeAsset {
            id

            variant {
              id
              product {
                id
                displayName
              }
            }
          }
        }
        pricePlan {
          id
        }
        deliveryEstimatedBetween {
          min
          max
        }
        deliveryExpectedBetween {
          min
          max
        }
        variant {
          id,
          deliveryExpectedBetween {
            max
            min
          }
          images {
            alt,
            url,
            tag
          }
          preOrder
          product {
            id,
            displayName
            preOrder
          }
        }
      }
    }
  }
  ${Checkout.fragments.address}
`

export const QL_PRODUCTS = gql`
  query CheckoutProductsQuery {   
  products {
    id
    displayName
    available
    variants {
      id
      available
      images {
        alt
        url
        tag
      },
      ...VariantPricePlan
      optionValues {
        id
        name
        raw
        displayName
        optionType {
          id
          slug
          displayName
        }
      }
    },
  }
}
${Checkout.fragments.variantPricePlan}`

export const QL_RISK_PRICING = gql `
  query CheckoutRiskPricingQuery($checkoutToken: String!) {
    checkout(token: $checkoutToken) {
      id
      state
      decision {
        outcome
        outcomeReason
      }
      customerInfo {
        email
      }
      items {
        id
        ...CheckoutItemPricePlan
        ...CheckoutItemVariant
      }
    }
  }
  ${Checkout.fragments.pricePlan}
  ${Checkout.fragments.variant}
`

export const QL_KUDOS = gql `
  query CheckoutBankingConnectionQuery($checkoutToken: String!, $callbackUri: String!) {
    checkout(token: $checkoutToken) {
      id
      state
      decision {
        outcome
        outcomeReason
      }
      bankingConnection {
        hasProviderErrors
        state
        provider
        oauthRedirectUri(callbackUri: $callbackUri)
      }
      paymentInfo {
        bankName
        bankAccountNumber
        bankBranchIdentifier
      }
      incompleteAttributes {
        code
        field
        message
      }
    }
  }
`

export const GET_MERCHANT_ID = gql`
  query MerchantQuery($domain: String!) {
    merchant(domain: $domain) {
      id
    }
  }
`

export const GET_VARIANT_PRICE_PLANS = gql`
  query VariantPricePlans($ids: [ID!]) {
    variants(ids: $ids) {
      pricePlans {
        id
        costSummary {
          recurring {
            totalAmount {
              valueAfterTax {
                value
              }
            }
          }
        }
        includesInsurance
      }
    }
  }
`

export const GET_CHECKOUT_PRICE_PLANS = gql`
  query GetCheckoutItemPricePlans($token: String!) {
    checkout(token: $token) {
      costSummary {
        recurring {
          totalAmount {
            valueAfterTax {
              value
            }
          }
        }
      }
      items {
        id
        variant {
          id
          displayName
          pricePlans {
            id
            termLength
            costSummary {
              recurring {
                totalAmount {
                  valueAfterTax {
                    value
                  }
                }
              }
            }
            includesInsurance
          }
          images {
            url
          }
        }
      }
    }
  }
`