import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { CheckoutProvider } from 'utils/useCheckoutContext';
import { Checkout } from '..';
import { RouteChecker } from './RouteChecker';
import { Decision } from './Decision';
import { Kudos } from './Kudos';
import { DecideLater } from './DecideLater';
import { RiskPricing } from './RiskPricing';
import { DecisionAccepted } from './DecisionAccepted';
import { DecisionCounterOffer } from './DecisionCounterOffer';
import { DecisionSecurityDeposit } from './DecisionSecurityDeposit';
import { DecisionReferred } from './DecisionReferred';
import { DecisionDeclined } from './DecisionDeclined';
import { CheckoutResume } from './CheckoutResume';
import { SecurityDepositScreen } from 'screens/Checkout/components/StepSecurityDepositContent';
import {
  DepositPaymentStatusScreen
} from "screens/Checkout/components/StepSecurityDepositContent/DepositPaymentStatusScreen"

export const CheckoutRouter = () => {
  return (
    <div className="checkout">
      <CheckoutProvider>
        <Switch>
          <RouteChecker path="/checkout/decision/offer-details" conditions={[
              {state: "SUBMITTED", outcome: "USER_ACTION_REQUIRED", reason: "counter_offer" }
            ]}>
            <RiskPricing />
          </RouteChecker>
          <RouteChecker path="/checkout/decision/later" conditions={[
              {state: "SUBMITTED", outcome: "USER_ACTION_REQUIRED", reason: "counter_offer" }
            ]}>
            <DecideLater />
          </RouteChecker>
          <RouteChecker path="/checkout/decision/bank-connection" conditions={[
              {state: "SUBMITTED", outcome: "USER_ACTION_REQUIRED", reason: "bank_connection_requested" },
              {state: "STARTED", outcome: "USER_ACTION_REQUIRED", reason: "bank_connection_requested" }
            ]}>
            <Kudos />
          </RouteChecker>
          <RouteChecker path="/checkout/decision/offer"  conditions={[
              {state: "SUBMITTED", outcome: "USER_ACTION_REQUIRED", reason: "counter_offer" }
            ]}>
            <DecisionCounterOffer />
          </RouteChecker>
          <RouteChecker path="/checkout/decision/security-deposit"  conditions={[
              {state: "SUBMITTED", outcome: "USER_ACTION_REQUIRED", reason: "security_deposit" }
            ]}>
            <DecisionSecurityDeposit />
          </RouteChecker>
          <RouteChecker path="/checkout/decision/pay-deposit" conditions={[
              {state: "SUBMITTED", outcome: "USER_ACTION_REQUIRED", reason: "security_deposit" }
            ]}>
            <SecurityDepositScreen />
          </RouteChecker>
          <RouteChecker path="/checkout/decision/process-deposit/:incomingPaymentId" conditions={[
            {state: "SUBMITTED", outcome: "USER_ACTION_REQUIRED", reason: "security_deposit" }
          ]}>
            <DepositPaymentStatusScreen />
          </RouteChecker>
          <RouteChecker path="/checkout/decision/accepted"  conditions={[
              {state: "SUBMITTED", outcome: "ACCEPTED"},
              {state: "ACCEPTED"}
            ]}>
            <DecisionAccepted />
          </RouteChecker>
          <RouteChecker path="/checkout/decision/referred"  conditions={[
              {state: "SUBMITTED", outcome: "PENDING"},
              {state: "SUBMITTED", outcome: "REFERRED"}
            ]}>
            <DecisionReferred />
          </RouteChecker>
          <RouteChecker path="/checkout/decision/cancelled" conditions={[
            {state: "SUBMITTED", outcome: "DECLINED"},
            {state: "SUBMITTED", outcome: "CANCELLED"},
            {state: "CANCELLED"}
          ]}>
            <DecisionDeclined/>
          </RouteChecker>
          <Redirect from='/checkout/decision/:checkoutToken/:step' to='/go/:checkoutToken'/>
          <Redirect from='/checkout/decision/:checkoutToken' to='/go/:checkoutToken'/>
          <RouteChecker path="/checkout/decision"  conditions={[
              {state: "SUBMITTED", outcome: "PENDING"}
            ]}>
            <Decision />
          </RouteChecker>
          <RouteChecker path="/checkout/resume" conditions={[
              {state: "ABANDONED"}
            ]}>
            <CheckoutResume />
          </RouteChecker>
          <RouteChecker path="/checkout/step" conditions={[
              {state: "STARTED"}
            ]}>
            <Checkout />
          </RouteChecker>
          <Redirect from='/checkout/:variantId/:pricePlanId' to='/pick/:variantId/:pricePlanId'/>
          <Redirect from='/checkout/:checkoutToken' to='/go/:checkoutToken'/>
          <RouteChecker path="/checkout" conditions={[
              {state: "STARTED"}
            ]}>
            <Checkout />
          </RouteChecker>
        </Switch>
      </CheckoutProvider>
    </div>
  )
}
