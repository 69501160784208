import { SlideDownFadeIn } from 'components/animations/Transitions';
import BackArrow from 'components/BackArrow';
import BulletList from 'components/BulletList';
import RadioButtonBoxGroup from 'components/RadioButtonBoxGroup';
import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import Copy from 'elements/Copy';
import DualToneHeader from 'elements/DualToneHeader';
import Spacer from 'elements/Spacer';
import { useStepTradeInContext } from 'screens/Checkout/containers/StepTradeIn/hooks/useStepTradeInContext';
import { primaryColors } from 'styles/variables/colors';
import { StyledTradeInJourneyStepTwo } from './styles';
import { ITradeInJourneyStepTwo } from './types';

export const TradeInJourneyStepTwo = ({
  dataTestId,
}: ITradeInJourneyStepTwo) => {
  const { setStepIndex } = useStepTradeInContext();
  const {
    productName,
    tradeInQuestions,
    questionsAnswered,
    handleQuestionAnswer,
    showQuestion2,
    showQuestion3,
  } = useStepTradeInContext();

  const question2SubCopy = (
    <>
      <Copy color={primaryColors.c01}>
        Answer yes if all of the following apply.{' '}
      </Copy>
      <Spacer height={12} />
      <BulletList
        listItems={[
          'No major cracks, chips and scratches',
          "If there's glass on the back, it's not shattered",
        ]}
      />
    </>
  );
  const question3SubCopy = (
    <>
      <Copy color={primaryColors.c01}>
        Answer yes if all of the following apply.{' '}
      </Copy>
      <Spacer height={12} />
      <BulletList
        listItems={[
          'The touchscreen is free of cracks, chips and scratches',
          'The cameras work',
        ]}
      />
    </>
  );
  return (
    <StyledTradeInJourneyStepTwo data-testid={dataTestId}>
      <BackArrow onClick={() => setStepIndex(0)} />
      <DualToneHeader>
        Just a few more questions about your <span>{productName}</span>
      </DualToneHeader>
      <Spacer height={24} />
      <RadioButtonBoxGroup
        options={tradeInQuestions?.[0]?.options}
        label={tradeInQuestions?.[0]?.label}
        initialActiveIndex={-1}
        onChange={handleQuestionAnswer}
        flexRow
        dataTestId="TradeInQuestion1"
      />
      {showQuestion2 && (
        <SlideDownFadeIn>
          <>
            <Spacer height={24} />
            <RadioButtonBoxGroup
              options={tradeInQuestions?.[1]?.options}
              label={tradeInQuestions?.[1]?.label}
              initialActiveIndex={-1}
              subCopy={question2SubCopy}
              onChange={handleQuestionAnswer}
              flexRow
              dataTestId="TradeInQuestion2"
            />
          </>
        </SlideDownFadeIn>
      )}
      {showQuestion3 && (
        <>
          <Spacer height={24} />
          <RadioButtonBoxGroup
            options={tradeInQuestions?.[2]?.options}
            label={tradeInQuestions?.[2]?.label}
            initialActiveIndex={-1}
            onChange={handleQuestionAnswer}
            subCopy={question3SubCopy}
            flexRow
            dataTestId="TradeInQuestion3"
          />
        </>
      )}
      <Spacer height={24} />
      <Button
        size="large"
        buttonColor={ButtonTypes.b01}
        onClick={() => setStepIndex(2)}
        disabled={!questionsAnswered}
        buttonType="solid"
        text="Next"
        fullWidth
      />
    </StyledTradeInJourneyStepTwo>
  );
};
