import Container from 'elements/Container';
import { StepAgeCountry } from 'screens/Checkout/components/StepAgeCountry';
import { IStepAgeCountry } from './types';

export const StepAgeCountryContainer = ({
  dataTestId,
  checkoutToken,
  onBack,
  onSuccess,
}: IStepAgeCountry) => {
  return (
    <Container dataTestId={dataTestId} noPadding>
      <StepAgeCountry
        checkoutToken={checkoutToken}
        onBack={onBack}
        onSuccess={onSuccess}
      />
    </Container>
  );
};
