export const pageTitles = {
  StepInsurance: 'insurance',
  StepNameEmail: 'Your Details',
  StepEmail: 'Your Details',
  StepAgeCountry: 'Your Details',
  StepAddress: 'Your Address',
  StepAddressCheck: 'Your Address',
  StepMobileJob: 'Your Details',
  StepStripe: 'Card Details',
  StepBank: 'Bank Details',
  StepTerms: 'What happens next',
  StepSign: 'Terms and Conditions',
  StepSignOffer: 'Terms and Conditions',
  StepTradeIn: 'Trade In',
  StepUpgrade: 'Upgrade',
};
