import styled from 'styled-components/macro';
import colors from './colors';
import {uiStateColors} from "./variables/colors"

export const RayloBodyType = styled.p`
  font-family: 'Raylo-Regular', Arial, sans-serif;
  font-size: 0.875em;
  font-weight: normal;
  color: ${colors.rayloLight};
  line-height: 1.625;

  strong {
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-weight: 500;
  }
  .pink {
    color: ${colors.rayloPink};
  }

  .highlight {
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: bold;
    color: ${colors.rayloPink};
  }

`

export const RayloBodyTypeSmall = styled(RayloBodyType)`
  font-size: 0.75em;
`

export const RayloBodyTypeInverted = styled(RayloBodyType)`
color: ${colors.rayloPink};
strong, .white, .highlight {
  color: ${colors.rayloLight};
}
`
export const RayloBodyTypeInvertedSmall = styled(RayloBodyTypeInverted)`
  font-size: 0.75em;
`

export const RayloBodyHeading = styled.h1`
  font-family: 'Raylo-Regular', Arial, sans-serif;
  font-size: 1.375em;
  font-weight: normal;
  color: ${colors.rayloLight};
  line-height: 1.275;
  margin-bottom: ${props => props.margin || '40px'};

  strong {
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: bold;
  }

  em {
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: bold;
    font-style: italic;
  }
  span.pink {
    color: ${colors.rayloPink};
  }
`

export const RayloLink = styled.a`
  color: ${props => props.color ? `${props.color}` : `${colors.rayloPink}`};
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  position: relative;
  white-space: ${props => props.inline ? 'normal' : 'nowrap'};
  cursor: pointer;
  text-decoration: none;

  /*
  &:hover {
    text-decoration: ${props => props.inline ? 'underline' : 'none'};

    &::after {
      width: ${props => props.inline ? '0' : '100%'};
    }
  }
  */

  &::after {
    content: '';
    display: block;
    height: 1px;
    background-color: ${props => props.color ? `${props.color}` : `${colors.rayloPink}`};
    position: absolute;
    width: 100%; /* ${props => props.inline ? '0' : '100%'}; */
    left: 0;
    top: 110%;
    transition: width .25s;
  }
`

export const RayloTooltipLabel = styled.span`
  font-family: 'Raylo-Regular', sans-serif;
  font-size: 1.125em;
  font-weight: normal;
  color: ${colors.rayloLight};
  line-height: 1.27;
  margin-right: 18px;
`

export const RayloSectionHeader = styled.h1`
  font-family: 'Raylo-Regular', Arial, sans-serif;
  font-size: 1.875em;
  font-weight: normal;
  color: ${colors.rayloLight};
  line-height: 1.93;
  margin: ${props => props.hasSubHeading ? '0' : '0 0 35px'};
  text-transform: uppercase;

  @media (max-width: 540px) {
    font-size: 1.375em;
    margin: ${props => props.hasSubHeading ? '0' : '0 0 20px'};
  }
`

export const RayloSubHeading = styled.h2`
  font-family: 'Raylo-Medium', sans-serif;
  font-size: 0.875em;
  font-weight: 500;
  color: ${colors.rayloPink};
  line-height: 2.14;
  margin-bottom: 40px;

  @media (max-width: 450px) {
    margin-bottom: 20px;
  }
`

export const RayloLabel = styled.h5`
  font-family: 'Raylo-Medium', Arial, sans-serif;
  font-size: 0.875em;
  font-weight: 500;
  color: ${colors.rayloPink};
  line-height: 1.875;
  margin: ${props => props.noMargin ? '0' : '0 0 0.4em'};
  white-space: nowrap;
`

export const RayloAccountLabel = styled(RayloLabel)`
  font-family: 'Raylo-Bold', Arial, sans-serif;
  font-weight: bold;
  color: ${colors.rayloLight};
`

export const RayloTradeInLabel = styled(RayloLabel)`
  font-family: 'Raylo-Bold', Arial, sans-serif;
  font-weight: bold;
  white-space: normal;
  color: ${colors.rayloLight};
`

export const RayloData = styled.p`
  font-family: 'Raylo-Bold', sans-serif;
  font-size: 0.875em;
  font-weight: bold;
  color: ${colors.rayloLight};
  line-height: 1.875;
  white-space: nowrap;

  strong {
    color: ${colors.rayloPink};
    font-weight: bold;
  }

`

export const RayloAccountData = styled(RayloData)`
  font-family: 'Raylo-Medium', Arial, sans-serif;
  font-weight: 500;
  color: ${colors.rayloPink};
`

export const RayloPrice = styled(RayloData)`
  font-family: 'Raylo-Regular', Arial, sans-serif;
  font-weight: normal;
`

export const RayloColor = styled(RayloData)`
  &::before {
    content: '';
    display: inline-block;
    vertical-align: text-bottom;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    background: ${props => props.color ? props.color : '#2F2F31'};
    border: 1px solid #7277FF;
    margin-right: 12px;
  }
`

export const RayloAddress = styled.span`
  font-family: 'Raylo-Regular', Arial, sans-serif;
  font-size: 0.875em;
  font-weight: normal;
  color: ${colors.rayloLight};
  line-height: 1.85;
`

export const RayloBodyList = styled.ol`
  margin: 0;
  padding: 0;
  list-style-position: inside;
  color: ${colors.rayloLight};
  font-family: 'Raylo-Regular', Arial, sans-serif;
  font-size: 0.875em;
  font-weight: normal;
  line-height: 1.857;
`

export const RayloUnorderedList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: ${props => props.styleType || 'initial'};
  list-style-position: inside;
  color: ${colors.rayloLight};
  font-family: 'Raylo-Regular', Arial, sans-serif;
  font-weight: normal;
  font-size: 0.875em;
  line-height: 1.857;

  li {
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      margin-bottom: 18px;
    }
  }
`

export const RayloWarningText = styled.p`
  font-family: 'Raylo-Bold', Arial, sans-serif;
  font-weight: bold;
  font-size: 0.875em;
  color: ${colors.rayloPink};
  line-height: 2.14;
`

export const AlmostThere = styled.div`
  font-family: 'Raylo-Regular', Arial, sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  color: ${colors.rayloLight};
  letter-spacing: -0.26px;
  margin-bottom: 40px;

  .almost {
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-size: inherit;
    font-weight: bold;
    margin: 0 0 4px;
  }

  .next {
    font-size: 1.25rem;
    margin: 0;
    color: ${colors.rayloPink};
  }
`

export const Steps = styled.ol`
  margin: 0 0 40px;
  padding: 0 0 0 40px;
  list-style-position: inside;
  list-style-type: none;
  counter-reset: step;

  @media (max-width: 540px) {
    padding: 0;
  }

  p {
    margin-bottom: 15px;
  }

  & > li {
    position: relative;
    color: ${colors.rayloLight};
    font-family: 'Raylo-Regular', Arial, sans-serif;
    font-weight: normal;
    font-size: 0.875em;
    letter-spacing: 0;
    line-height: 1.725;
    margin-bottom: 28px;

    @media (max-width: 540px) {
      padding-left: 32px;
    }

    strong {
      font-family: 'Raylo-Bold', Arial, sans-serif;
      font-weight: bold;
    }

    .title {
      color: ${colors.rayloPink};
    }

    &::before {
      position: absolute;
      top: 0;
      left: -40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      border: 1px solid ${colors.rayloPink};
      border-radius: 100%;
      color: ${colors.rayloPink};
      counter-increment: step;
      content: counter(step);

      @media (max-width: 540px) {
        left: 0;
        margin-bottom: 8px;
      }
    }

    ul {
      list-style-type: disc;
      list-style-position: outside;
      padding-left: 16px;

      li {
        margin-bottom: 12px;
      }
    }
  }
`

export const UnnumberedSteps = styled.ul`
  margin: 0 0 40px;
  padding: 0 0 0 40px;
  list-style-position: inside;
  list-style-type: none;
  counter-reset: step;

  @media (max-width: 540px) {
    padding: 0;
  }

  p {
    margin-bottom: 15px;
  }

  & > li {
    position: relative;
    color: ${colors.rayloLight};
    font-family: 'Raylo-Regular', Arial, sans-serif;
    font-weight: normal;
    letter-spacing: 0;
    margin-bottom: 10px;

    @media (max-width: 540px) {
      padding-left: 32px;
    }

    strong {
      font-family: 'Raylo-Bold', Arial, sans-serif;
      font-weight: bold;
    }

    ul {
      list-style-type: disc;
      list-style-position: outside;
      padding-left: 16px;

      li {
        margin-bottom: 12px;
      }
    }
  }
`


export const GenericError = styled.div`
  background: white;
  color: red;
  padding: 10px;
  text-align: center;
  margin: 50px auto;
  max-width: 200px;
`

export const PaymentRowLabel = styled.h3`
  font-family: 'Raylo-Medium', Arial, sans-serif;
  font-size: 0.9375em;
  font-weight: 500;
  color: #2D2D2D;
  line-height: 1.6;
  margin: 0 24px 0 0;
`

export const Payment = styled.span`
  font-family: 'Raylo-Mono', 'Raylo Regular', Arial, sans-serif;
  font-size: 1.0625em;
  color: ${props => props.overdue ? `${uiStateColors.error}` : '#2D2D2D'};
  line-height: 1.4;

  @media (max-width: 540px) {
    font-size: 0.8125em;
  }
`

export const SecuredByStripe = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  img {
    margin-right: 16px;
  }

  p {
    font-family: 'Raylo-Regular';
    font-weight: 400;
    font-size: 0.875rem;
    color: ${colors.raylo2Light};
    line-height: 1.5;
  }
`

export const RowLabel = styled.h3`
  font-family: 'Raylo-Medium', Arial, sans-serif;
  font-size: 0.9375em;
  font-weight: 500;
  color: #2D2D2D;
  line-height: 1.6;
  margin: 0 24px 0 0;
`
