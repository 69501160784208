import BackArrow from 'components/BackArrow';
import DoubleButtonFullWidth from 'components/DoubleButtonFullWidth';
import FormErrorMessages from 'components/FormErrorMessages';
import NumberedList from 'components/NumberedList';
import PhoneFeatureCard from 'components/PhoneFeatureCard';
import Divider from 'elements/Divider';
import DualToneHeader from 'elements/DualToneHeader';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { useStepTradeInContext } from 'screens/Checkout/containers/StepTradeIn/hooks/useStepTradeInContext';
import { breakpoints } from 'styles/breakpoints';
import { secondaryColors } from 'styles/variables/colors';
import useMedia from 'use-media';
import { StyledTradeInJourneyDecision } from './styles';
import { ITradeInJourneyDecision } from './types';

export const TradeInJourneyDecision = ({
  dataTestId,
}: ITradeInJourneyDecision) => {
  const {
    gradeSlug,
    tradeInTotal,
    termLengthValue,
    termLength,
    productName,
    formErrors,
    tradeInExists,
    setFormSubmitted,
    setStepIndex,
    commit,
    removeTradeIn,
  } = useStepTradeInContext();
  const isMobile = useMedia({ maxWidth: breakpoints.mobile });
  const faulty = gradeSlug === 'faulty';

  const listItems = [
    `We’ll collect your ${productName} in a few days after your new phone arrives`,
    'We’ll run a few tests and erase your data.',
    `If everything checks out, you’ll get ${tradeInTotal} each month for ${termLength} months (${termLengthValue} in total).`,
  ];

  const handleBottomClick = () => {
    if (tradeInExists) {
      removeTradeIn();
    } else {
      setFormSubmitted(true);
    }
  };

  return (
    <StyledTradeInJourneyDecision data-testid={dataTestId}>
      {isMobile && <Spacer height={40} />}
      {!tradeInExists && <BackArrow onClick={() => setStepIndex(1)} />}
      <DualToneHeader>
        {faulty ? (
          <>
            It sounds like your phone is faulty. Don’t worry, you can still earn
            at <span>{tradeInTotal}/mo</span> cashback
          </>
        ) : (
          <>
            Good news. Your old phone could earn you{' '}
            <span>{tradeInTotal}/mo</span> in trade-cashback
          </>
        )}
      </DualToneHeader>
      <Spacer height={24} />
      <Header level={4}>What happens next?</Header>
      <Spacer height={24} />
      <NumberedList listItems={listItems} />
      <Spacer height={24} />
      <Divider width="100%" color={secondaryColors.c04} />
      <Spacer height={24} />
      <Header level={4}>Trade-in cashback</Header>
      <Spacer height={12} />
      <PhoneFeatureCard
        iconName="PhoneRefresh"
        details={`${tradeInTotal}/mo`}
        title={productName || ''}
        detailWidth="10%"
      />
      <Spacer height={12} />
      <Divider width="100%" color={secondaryColors.c04} />
      <Spacer height={32} />
      <Header level={4}>Sound good?</Header>
      <Spacer height={24} />
      <FormErrorMessages formErrors={formErrors} />
      <DoubleButtonFullWidth
        topButtonText="Yes, I'd like to trade in"
        bottomButtonText={tradeInExists ? 'No, let me change it' : 'No thanks'}
        topButtonOnClick={commit}
        bottomButtonOnClick={handleBottomClick}
      />
    </StyledTradeInJourneyDecision>
  );
};
