import { useEffect } from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet'
import { activateOptimize } from 'utils/optimize'

// HOOKS

import { useUiContext } from 'utils/useUiContext'

// GRAPHQL
import { QL_RISK_PRICING } from 'screens/Checkout/graphQL/queries';

// VISUAL COMPONENTS
import * as T from 'styles/type';
import colors from 'styles/colors';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { BackButton } from 'components/common/BackButton';
import { Modal } from 'components/common/Modal';
import { useErrorNavigator}  from 'utils/useErrorNavigator'

import Currency from 'react-currency-formatter';

import { PhoneHeader } from 'screens/Checkout/components/PhoneHeader';
import { PhoneSummary } from 'screens/Checkout/components/PhoneSummary';
import useRayloQuery from 'utils/useRayloQuery';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { useHistory } from 'react-router';
import classNames from 'classnames'
import {useSegmentIdentifyAndCheckoutTracking} from 'hooks/segment/segment.hooks'

export const DecideLater = () => {
  useSegmentIdentifyAndCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'counter offer',
    checkoutScreen: 'counter offer decide later',
    when: 'on page load'
  });

  const { checkoutToken } = useCheckoutContext()
  const history = useHistory()
  const errorNav = useErrorNavigator()

  const { showSummary } = useUiContext()

  const { data: {checkout}, error, loading } = useRayloQuery(QL_RISK_PRICING, {
    variables: {
      checkoutToken
    }
  })

  useEffect(() => {
    activateOptimize()
  })


  if(error) {
    errorNav("DecisionLater: failure", error)
  }

  if(loading) {
    return <Modal visible text="Loading..." />
  }

  const variant = checkout?.items[0]?.variant
  const product = variant?.product
  const pricePlan = checkout?.items[0]?.pricePlan
  const costSummary = pricePlan?.costSummary

  const storage = _.find(variant?.optionValues, o => o.optionType.slug === 'storage').displayName
  const color = _.find(variant?.optionValues, o => o.optionType.slug === 'colour').displayName

  return (
    <div className={classNames("checkout--index", { showSummary })}>
      <Helmet><title>
        Decide Later - Raylo
      </title></Helmet>
      <div className="checkout--header"><PhoneHeader checkoutToken={checkoutToken} /></div>
      <div className="checkout--main">
        <div className="checkout--step">
          <FadeIn>
            <div style={{ marginBottom: 28, paddingBottom: 18, borderBottom: '1px solid #434343' }}>
              <T.RayloBodyHeading margin="28px"><strong>No problem</strong>, we’ll email you a link so you can come back and decide any time within the next 5 days</T.RayloBodyHeading>
            </div>
          </FadeIn>

          <SlideDownFadeIn>
            <div style={{ marginBottom: 28, paddingBottom: 18, borderBottom: '1px solid #434343' }}>
              <T.RayloBodyHeading margin="24px"><strong>{product.displayName} {storage} {color}</strong>{pricePlan.includesInsurance && <><br/>including insurance</>}</T.RayloBodyHeading>
              <T.RayloWarningText>Total Monthly Payment: <span style={{ color: '#FFF', marginLeft: 10 }}><Currency quantity={costSummary.recurring.totalAmount.valueAfterTax.value} currency={pricePlan.currencyCode} /></span></T.RayloWarningText>
            </div>
          </SlideDownFadeIn>

          <SlideDownFadeIn>
            <div>
              <div style={{ marginBottom: 34 }}>
                <T.RayloBodyType>You can safely close this window and come back at any time using the link sent to <span style={{ color: colors.rayloPink }}>{checkout.customerInfo.email}</span></T.RayloBodyType>
              </div>
              <BackButton onClick={() => history.push(`/checkout/decision/offer-details`)} />
            </div>
          </SlideDownFadeIn>
        </div>
      </div>
      <FadeIn>
        <div className={classNames("checkout--sidebar", { showSummary})}>
          <PhoneSummary checkoutToken={checkoutToken} displayOnly={true} />
        </div>
      </FadeIn>
    </div>
  )

}
