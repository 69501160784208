import { InsuranceCopy } from 'components/Checkout/InsuranceCopy';
import RayloModal from 'components/RayloModal';
import { useUiContext } from 'utils/useUiContext';
import { StyledInsuranceModal } from './styles';
import { IInsuranceModal } from './types';

export const InsuranceModal = ({ dataTestId }: IInsuranceModal) => {
  const { setShowInsuranceModal, setShowModal } = useUiContext();

  const handleClose = () => {
    setShowModal(false);
    setShowInsuranceModal(false);
  };
  return (
    <StyledInsuranceModal data-testid={dataTestId}>
      <RayloModal
        header="Insurance Policy Summary"
        onClose={handleClose}
        dataTestId={dataTestId}
      >
        <InsuranceCopy />
      </RayloModal>
    </StyledInsuranceModal>
  );
};
