import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import store from 'store';

import * as L from 'styles/layout';
import {Modal } from 'components/common/Modal';

// ROUTING
import { Switch, Route, Router } from 'react-router-dom'

// GRAPHQL
import { apolloClient } from 'utils/graphql';
import { ApolloProvider } from '@apollo/client';

// GLOBAL STATE
import {StateProvider} from 'utils/StateProvider'

import { history } from 'utils/history'
import { enableErrorTracking} from "utils/errorTracking"
import { CheckoutRouter } from 'screens/Checkout/components/CheckoutRouter';
import { Go } from 'screens/Checkout/Go';
import { ProductSelector } from 'screens/Checkout/components/ProductSelector';
import Products from 'screens/Products';
import { ErrorRouter } from 'screens/Errors';
import { LoginRouter } from 'screens/Login/LoginRouter';
import { AccountRouter } from 'screens/Account';
import { CookiesProvider } from 'react-cookie';
import { ThemeProvider } from 'styled-components';
import { rayloDark } from 'styles/themes/rayloDark';
import RayloPayWidget from 'components/MerchantCheckoutWidget/widget';
import RayloFindOutMoreWidget from 'components/MerchantCheckoutWidget/widget';

import { resetSessionForNewCheckout } from 'utils/resetSessionForNewCheckout';
import { GetPricePlan } from 'screens/Checkout/components/GetPricePlan';
import MerchantCheckoutWidget from 'components/MerchantCheckoutWidget';
import FindOutMoreModal from 'merchantCheckout/FindOutMoreModal';

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);

enableErrorTracking()

if (process.env.REACT_APP_MAINTENANCE_URL) {
  window.location = process.env.REACT_APP_MAINTENANCE_URL
}

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <ThemeProvider theme={rayloDark}>
    <Suspense fallback={<L.MainWrapper><Modal visible text="Loading..." /></L.MainWrapper>}>
      <Router history={history}>
        <StateProvider>
          <CookiesProvider>
            <Switch>
              <SentryRoute exact path="/go/:checkoutToken" component={() => <Go/>} />
              <SentryRoute exact path="/pick/:variantId/:pricePlanId/:subscriptionId" component={() => {
                store.remove('checkoutToken')
                return (
                  <ProductSelector/>
                )
              }}
              />
              <SentryRoute exact path="/pick/:variantId/:pricePlanId" component={() => {
                resetSessionForNewCheckout()
                return (<ProductSelector/>)
              }}
              />
              <SentryRoute exact path="/pick/:variantId" component={() => {
                resetSessionForNewCheckout()
                return (<GetPricePlan />)
              }}
              />
              <SentryRoute exact path="/merchant-checkout-widget" component={() => {
                return (<MerchantCheckoutWidget {...window.xprops} />)
              }}
              />
              <SentryRoute exact path="/merchant-find-out-more" component={() => {
                return (<FindOutMoreModal {...window.xprops} />)
              }}
              />
              <SentryRoute path="/account" component={() =>  <AccountRouter />} />
              <SentryRoute path="/checkout" component={() =>  <CheckoutRouter />} />
              <SentryRoute path="/login" component={() =>  <LoginRouter />} />
              <SentryRoute path="/errors" component={() =>  <ErrorRouter />} />
              <SentryRoute path="/" component={() =>  <Products />} />
            </Switch>
          </CookiesProvider>
        </StateProvider>
      </Router>
    </Suspense>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root')
);
