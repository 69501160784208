import React, { useEffect, useState, Fragment } from 'react';
import useFormal from "@kevinwolf/formal-web";
import { useMutation, useQuery } from '@apollo/client'
import store from 'store'
import { Button } from 'components/common/Button';

import * as yup from "yup";
import colors from 'styles/colors';
import { TextInput } from 'components/common/TextInput';
import { CheckboxInputString } from 'components/common/CheckboxInputString';
import { ACCEPTABLE_DATA_TOKEN_MUTATION } from '../graphql/mutations/acceptableData/createAcceptableDataMutation.graphql';
import { ACCEPTABLE_DATA_SUBSCRIPTION_OPTION_ENUM } from "../graphql/types/acceptableDataSubscriptionOptionType.graphql";
import { toSentence } from "../helpers/format/toSentence";
import { toCamelCase } from "../helpers/format/toCamelCase";

const schema = yup.object().shape({
  endpoint: yup.string().nullable().default(""),
  override: yup.string().default("Y")
});

export const EndpointForm = () => {
  const getEndpoint = () => {
    return (store.get("endpoint") || process.env.REACT_APP_GRAPHQL_ENDPOINT);
  }

  const [possibleSubscriptionOptions, setPossibleSubscriptionOptions] = useState([]);
  const [subscriptionOptionsChoices, setSubscriptionOptionsChoices] = useState(new Set());
  const [isFormValid, setIsFormValid] = useState(false);

  const setSubscriptionChoicesOnChange = (e) => {
    const checkboxValue = e.target.value;

    if (e.target.checked) {
      subscriptionOptionsChoices.add(checkboxValue);
    } else {
      subscriptionOptionsChoices.delete(checkboxValue);
    }
    setSubscriptionOptionsChoices(subscriptionOptionsChoices)

    const choicesValid = subscriptionOptionsChoicesValid(
        Array.from(subscriptionOptionsChoices), possibleSubscriptionOptions
    );
    if ((atLeastOneOptionSelected(subscriptionOptionsChoices)) && choicesValid) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  function subscriptionOptionsChoicesValid(choices, possibleChoices) {
    return choices.every(element => {
      return possibleChoices.includes(element);
    });
  }

  function atLeastOneOptionSelected(subscriptionOptionsChoices) {
    return subscriptionOptionsChoices.size > 0
  }

  const [loginTestCustomer] = useMutation(ACCEPTABLE_DATA_TOKEN_MUTATION, {
    variables: {
      subscriptionOptions: Array.from(subscriptionOptionsChoices)
    },
    update: (proxy, { data: { createAcceptableData: {
      userToken
    } } }) => {
      store.set('userToken', userToken)
      window.location = '/account'
    }
  })

  const getOverride = () => {
    return (store.get("override") || "Y");
  }

  const resetLocalStorage = () => {
    console.log("Clearing local storage");
    store.clearAll();
    window?.sessionStorage.clear()
    window.location.reload();
  }

  const resetToOriginal = () => {
    console.log("Reseting to original")
    store.remove("endpoint")
    window.location.reload();
  }

  const testCustomerLogin = () => {
    store.remove('userToken')
    store.remove('checkoutToken')
    loginTestCustomer();
  }

  const formal = useFormal({endpoint: getEndpoint(), override: getOverride()}, {
    schema,
    onSubmit: (values) => {
      store.set("endpoint", values.endpoint)
      store.set("override", values.override)
      window.location.reload();
    }
  })

  useEffect(() => {
    // SET DEFAULTS
    store.set("override", getOverride())
  }, [])

  useQuery(ACCEPTABLE_DATA_SUBSCRIPTION_OPTION_ENUM, {
    onCompleted(data) {
      setPossibleSubscriptionOptions(
        data.__type.enumValues.map(
            (value) => value.name
        )
      );
  }});

  const subscriptionOptionInputs = () => {
    return possibleSubscriptionOptions.map((option) => {
      let camelCasedOption = toCamelCase(option);

      return(
        <Fragment key={camelCasedOption}>
        <input key={`${camelCasedOption}_checkbox`} type="checkbox" id={camelCasedOption} name={camelCasedOption} value={option} data-testid={camelCasedOption} onChange={setSubscriptionChoicesOnChange} />
        <label key={`${camelCasedOption}_label`} htmlFor={camelCasedOption}> {toSentence(option)}</label><br />
        </Fragment>
      )
    });
    };

  return (
    <div style={{marginTop: 20, padding: 20, backgroundColor: colors.rayloDark, textAlign: "center", color: "white"}}>
      <div>
        <form {...formal.getFormProps()}>
          <TextInput
            label="GraphQL endpoint"
            value={getEndpoint()}
            field={formal.getFieldProps('endpoint')}
            tooltip="Add your endpoint e.g. https://raylo-api-staging-pr-1.herokuapp.com/"

            errorMessage={null}
          />
          <div style={{marginTop: -30, marginBottom: 10}}>
            current: <strong>{getEndpoint()}</strong>
          </div>
          <CheckboxInputString
            value="Y"
            noValue="N"
            label="Override"
            checked={formal.values.override === 'Y'}
            field={formal.getFieldProps('override')}
            errorMessage={null}
          />
          <Button
            type="submit"
            limitWidth="true"
          >Set
          </Button>
          <Button
            limitWidth="true"
            onClick={resetToOriginal}
          >Use original
          </Button>
        </form>

      </div>

      <div style={{paddingTop: 20}}>
        <Button
          limitWidth="true"
          onClick={resetLocalStorage}
        >Clear Local storage
        </Button>
      </div>
      <div style={{paddingTop: 20}}>
        <fieldset style={{width: '300px', margin: 'auto'}}>
          <legend>Subscription types</legend>

          <div style={{textAlign: 'left', paddingLeft: '15px'}}>
            {subscriptionOptionInputs()}
          </div>
          <div style={{paddingTop: 20}}>
            <Button
                limitWidth="true"
                disabled={!isFormValid}
                onClick={testCustomerLogin}
                dataTestId="testCustomerLogin"
            >Create customer and login
            </Button>
          </div>
        </fieldset>
      </div>
    </div>
  )
}
