import { Modal } from 'components/common/Modal';
import DoubleButtonRow from 'components/DoubleButtonRow';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import DualToneHeader from 'elements/DualToneHeader';
import Header from 'elements/Header';
import Icon from 'elements/Icon';
import Spacer from 'elements/Spacer';
import TextLink from 'elements/TextLink';
import { useStepTradeInContext } from 'screens/Checkout/containers/StepTradeIn/hooks/useStepTradeInContext';
import { breakpoints } from 'styles/breakpoints';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import useMedia from 'use-media';
import { px2Rem } from 'utils/px2Rem';
import { useUiContext } from 'utils/useUiContext';
import { StyledTradeInView } from './styles';
import { ITradeInView } from './types';

export const TradeInView = ({ dataTestId }: ITradeInView) => {
  const isMobile = useMedia({ maxWidth: breakpoints.mobile });
  const {
    setFormSubmitted,
    setTradeInAccepted,
    tradeInValue,
    loading,
    loadingTwo,
  } = useStepTradeInContext();
  const { setShowModal, setShowTradeInModal } = useUiContext();

  const tradeInText = isMobile ? "Yes, I'd like to trade in" : 'Yes, trade in';

  const handleShowModal = () => {
    setShowModal(true);
    setShowTradeInModal(true);
  };

  const handleFormSubmit = () => setFormSubmitted(true);

  if (loading || loadingTwo) {
    return <Modal visible text="Loading..." />;
  }
  return (
    <StyledTradeInView data-testid={dataTestId} data-loaded={!!tradeInValue}>
      <DualToneHeader>
        Get cashback of <span>up to {tradeInValue} per month</span> when you
        trade in your old phone
      </DualToneHeader>
      <Spacer height={16} />
      <Container center>
        <Icon
          name="PhoneRefresh"
          size={px2Rem(60)}
          color={secondaryColors.p02}
        />
      </Container>
      <Spacer height={16} />
      <Container noPadding>
        <Copy color={primaryColors.c01} fontSize={16}>
          See how easy our{' '}
          <TextLink fontSize={16} onClick={handleShowModal}>
            Trade In Cashback
          </TextLink>{' '}
          is
        </Copy>
      </Container>
      <Spacer height={16} />
      <Container noPadding>
        <Header level={4} color={primaryColors.c01} fontSize={16}>
          Do you have a iPhone or Samsung phone to trade in?
        </Header>
      </Container>
      <Spacer height={80} />
      <Container noPadding>
        <DoubleButtonRow
          leftButtonText={tradeInText}
          rightButtonText="No thanks"
          leftButtonOnClick={() => setTradeInAccepted(true)}
          rightButtonOnClick={handleFormSubmit}
          rightButtonDataTestId="skipTradeIn"
        />
      </Container>
    </StyledTradeInView>
  );
};
