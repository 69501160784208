import styled from 'styled-components';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { ICheckBox } from './types';

const StyledCheckBox = styled.div<ICheckBox>`
  cursor: pointer;
  height: ${px2Rem(24)};
  width: ${px2Rem(24)};
  border: solid 1px ${secondaryColors.c03};
  ${({ active }) => active && `background: ${primaryColors.b01}`}
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { StyledCheckBox };
