
import gql from 'graphql-tag'

import { Checkout } from 'screens/Checkout/graphQL/fragments'

export const MU_CREATE_PAYMENT = gql`
  mutation CreateCheckoutPaymentMutation($checkoutToken: String!) {
    createCheckoutPayment(checkoutToken: $checkoutToken) {
      clientSecret
    }
  }
`
export const MU_RESTART_CHECKOUT = gql`
  mutation RestartCheckoutMutation($checkoutToken: String!) {
    restartCheckout(
      checkoutToken: $checkoutToken
    ) {
      checkout {
        id
        state
        token 
        decision {
          outcomeReason
          outcome
        }
      }
      errors {
        field
        code
        message

      }
    }
  }`

export const MU_START_BANKING_CONNECTION = gql`
    mutation StartBankingConnectionMutation($checkoutToken: String!) {
        startBankingConnection(
            checkoutToken: $checkoutToken
        ) {
            success
        }
    }`

export const MU_SWAP_CHECKOUT = gql`
  mutation SwapCheckoutItemMutation(
    $pricePlanId: ID!, 
    $variantId: ID!,
    $itemId: ID!,
    $checkoutToken: String!) {
    swapCheckoutItem(
      checkoutToken: $checkoutToken,
      input: {
        existingItemId: $itemId,
        newItem: {
          variantId: $variantId,
          pricePlanId: $pricePlanId
        }
      }
    ) {
      checkout {
        ...CheckoutBase,
        ...CheckoutCustomerInfo,
        ...CheckoutPaymentInfo,
        ...CheckoutAddress,
        ...CheckoutIncomplete,
        items {
          id
          pricePlan {
            id
            name
            currencyCode          
            termLength
            recurringPeriod
            lastRecurringPeriod
            includesInsurance
            createdAt
            ...PricePlanCostSummary
            ...PricePlanFeeSummary
          }
          variant {
            id
            displayName
            hireAgreementName
            available
            optionValues {
              id
              displayName
              raw
              optionType {
                id
                slug
              }
            }
            product {
              id
              displayName
            },
            images {
              alt
              position
              tag
              title
              url
            }
            ...VariantPricePlan
          }
        }
      },
      errors {
        field,
        code,
        message
      }
    }
  }
  ${Checkout.fragments.base}
  ${Checkout.fragments.customerInfo}
  ${Checkout.fragments.paymentInfo}
  ${Checkout.fragments.address}
  ${Checkout.fragments.incomplete}
  ${Checkout.fragments.costSummary}
  ${Checkout.fragments.feeSummary}
  ${Checkout.fragments.variantPricePlan}
`;

export const MU_RESUME_CHECKOUT = gql`
  mutation ResumeCheckoutMutation(
    $checkoutToken: String!) {
    resumeCheckout(
      checkoutToken: $checkoutToken,
    ) {
      checkout {
        id
        state
        token
        decision {
          outcome
          outcomeReason
        }
      }
      errors {
        field
        code
        message
      }
    }
  }
`

export const MU_SUBMIT_CHECKOUT = gql`
  mutation SubmitCheckoutMutation(
    $checkoutToken: String!) {
    submitCheckout(
      checkoutToken: $checkoutToken,
    ) {
      checkout {
        id,
        state,
        decision {
          updatedAt,
          outcome
          outcomeReason
        }
      }
      errors {
        field
        code
        message
      }
    }
  }
`

export const MU_TRACKING_CHECKOUT = gql`
  mutation TrackCheckoutMutation($token: String!, $inputs: [UpdateCheckoutTrackingInput!]!) {
    trackCheckout(
      checkoutToken: $token
      inputs: $inputs
    ) {
      success
      errors {
        code
        field
        message
      }
    }
  }
`
