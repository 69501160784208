import BulletList from 'components/BulletList';
import { Container, Copy, Icon } from 'elements';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { ProductPageWidget } from 'raylo-ui-component-library';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import useRayloQuery from 'utils/useRayloQuery';
import {
  SavingsCopy,
  StyledMerchantCheckoutWidget,
  StyledMerchantProductWidget,
  WidgetSpacer,
} from './styles';
import { IMerchantCheckoutWidget } from './types';

const PRICE_PLAN_QUERY = gql`
  query Variants($ids: [ID!]) {
    variants(ids: $ids) {
      pricePlans {
        costSummary {
          recurring {
            totalAmount {
              valueBeforeTax {
                value
              }
            }
          }
        }
      }
    }
  }
`;

export const MerchantCheckoutWidget = ({
  JWTToken,
  onSubmit,
}: IMerchantCheckoutWidget) => {
  const [price, setPrice] = useState();
  const [isCheckout, setIsCheckout] = useState<boolean>();

  useEffect(() => {
    const widgetName = new URLSearchParams(window?.location.search).get(
      'widget',
    );
    if (widgetName === 'checkout') {
      setIsCheckout(true);
    }
  }, []);

  const { data } = useRayloQuery(PRICE_PLAN_QUERY, {
    variables: {
      ids: [JWTToken],
    },
    skip: !JWTToken,
  });

  useEffect(() => {
    const monthlyCost =
      data?.variants?.[0]?.pricePlans?.[0]?.costSummary?.recurring?.totalAmount
        ?.valueBeforeTax?.value;
    if (monthlyCost) {
      setPrice(monthlyCost);
    }
  }, [data]);

  if (isCheckout) {
    return (
      <StyledMerchantCheckoutWidget>
        <Container
          width="100%"
          height="100%"
          backgroundColor={secondaryColors.c04}
        >
          <Copy color={primaryColors.c01} bold>
            Lease now, then upgrade or return
          </Copy>
          <WidgetSpacer height={12} />
          <Container padding={`0 0 0 ${px2Rem(4)}`} flexRow>
            <Icon
              name="CircleFill"
              color={primaryColors.c01}
              size={px2Rem(8)}
            />
            <Container padding="0 0 0 12px" flexRow>
              <Copy color={primaryColors.c01} lineHeight={22} bold>
                £{price}/mo
              </Copy>
              <SavingsCopy>Save £289 on full price</SavingsCopy>
            </Container>
          </Container>
          <WidgetSpacer height={8} />
          <BulletList
            listItems={[
              'Interest free',
              'Lease for 12 or 24 months',
              'No hidden fees',
              'Pay £0 for wear and tear',
              'Rated 5 stars on TrustPilot',
            ]}
          />
        </Container>
      </StyledMerchantCheckoutWidget>
    );
  }

  return (
    <StyledMerchantProductWidget>
      <ProductPageWidget number={price || 0} linkOnClick={onSubmit} />
    </StyledMerchantProductWidget>
  );
};
