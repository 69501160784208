import styled from 'styled-components';
import { secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';

const StyledDeviceLeaseProfileCard = styled.div``;

const UpgradeIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${px2Rem(44)};
  height: ${px2Rem(44)};
  background-color: ${secondaryColors.p02};
  border-radius: 50%;
  padding-top: ${px2Rem(4)};
  & svg {
    transform: rotate(90deg);
  }
`;

export { StyledDeviceLeaseProfileCard, UpgradeIconWrapper };
