import BankDetailsContainer from 'components/BankDetailsContainer';
import DirectDebitInfo from 'components/DirectDebitInfo';
import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { useStepBankContext } from 'screens/Checkout/containers/StepBank/hooks/useStepBankContext';
import { primaryColors } from 'styles/variables/colors';
import { useUiContext } from 'utils/useUiContext';
import DirectDebitTotalDetails from '../DirectDebitTotalDetails';
import { StyledDirectDebitConfirm } from './styles';
import { IDirectDebitConfirm } from './types';

export const DirectDebitConfirm = ({ dataTestId }: IDirectDebitConfirm) => {
  const {
    values,
    directDebitMandateAuthorised,
    setDirectDebitMandateAuthorised,
    setConfirmDetails,
    setFormSubmitted,
  } = useStepBankContext();
  const { setShowModal, setShowDDModal } = useUiContext();
  const handleCheck = () =>
    setDirectDebitMandateAuthorised(!directDebitMandateAuthorised);
  const handleEdit = () => setConfirmDetails(false);
  const handleSubmit = () => setFormSubmitted(true);
  const handleShowModal = () => {
    setShowModal(true);
    setShowDDModal(true);
  };

  return (
    <>
      <StyledDirectDebitConfirm data-testid={dataTestId}>
        <Spacer height={40} />
        <Header level={2} color={primaryColors.c01}>
          Direct Debit confirmation
        </Header>
        <Spacer height={24} />
        <DirectDebitTotalDetails />
        <Spacer height={16} />
        <BankDetailsContainer bankDetails={values} onEdit={handleEdit} />
        <Spacer height={16} />
        <DirectDebitInfo
          onCheckboxClick={handleCheck}
          onInfoClick={handleShowModal}
          authorised={directDebitMandateAuthorised}
        />
        <Spacer height={48} />
        <Button
          dataTestId="directDebitConfirm"
          buttonColor={ButtonTypes.b01}
          disabled={!directDebitMandateAuthorised}
          fullWidth
          onClick={handleSubmit}
          size="large"
          text="Next"
        />
      </StyledDirectDebitConfirm>
    </>
  );
};
