import DoubleButtonFullWidth from 'components/DoubleButtonFullWidth';
import IconWithText from 'components/IconWithText';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Divider from 'elements/Divider';
import DualToneHeader from 'elements/DualToneHeader';
import Grid from 'elements/Grid';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import TextLink from 'elements/TextLink';
import Toggle from 'elements/Toggle';
import { useSegmentIdentifyAndCheckoutTracking } from 'hooks/segment/segment.hooks';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { formatDisplayPrice } from 'utils/formatDisplayPrice';
import { StyledStepTradeInInsurance } from './styles';
import { IStepInsuranceContent } from './types';

export const StepInsuranceContent = ({
  dataTestId,
  toggleLabelOff,
  toggleLabelOn,
  toggleOnClick,
  toggleActive,
  insuranceTotal,
  onSubmit,
  onShowPolicyInfo,
  totalPrice,
}: IStepInsuranceContent) => {
  useSegmentIdentifyAndCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'insurance',
    checkoutScreen: 'add insurance',
    when: 'on page load',
  });
  return (
    <StyledStepTradeInInsurance data-testid={dataTestId}>
      <Container noPadding>
        <DualToneHeader>
          Add our insurance for just{' '}
          <span>£{formatDisplayPrice(insuranceTotal)} per month</span>
        </DualToneHeader>
      </Container>
      <Spacer height={24} />
      <Container noPadding>
        <Copy fontSize={16}>
          For just £{formatDisplayPrice(insuranceTotal)} per month, you can
          benefit from Raylo’s very own insurance policy which includes:
        </Copy>
      </Container>
      <Spacer height={24} />
      <Container width="320px" padding={`0 ${px2Rem(24)}`}>
        <IconWithText
          iconName="PhoneDamage"
          iconSize={48}
          iconColor={secondaryColors.p02}
          text="Cover against loss, theft & accidental damage"
          textSize={16}
        />
      </Container>
      <Spacer height={24} />
      <Container width="320px" padding={`0 ${px2Rem(24)}`}>
        <IconWithText
          iconName="NetworkShield"
          iconSize={48}
          iconColor={secondaryColors.p02}
          text="Worldwide protection"
          textSize={16}
        />
      </Container>
      <Spacer height={32} />
      <Container noPadding>
        <Toggle
          labelOff={toggleLabelOff}
          labelOn={toggleLabelOn}
          onClick={toggleOnClick}
          textSize={16}
          active={toggleActive}
        />
      </Container>
      <Spacer height={24} />
      <Divider color={secondaryColors.c04} />
      <Spacer height={24} />
      <Container noPadding>
        <Grid>
          <Container padding="0" width="50%">
            <Header level={4} color={primaryColors.c01}>
              Total monthly payments
            </Header>
          </Container>
          <Container padding="0" width="50%" alignRight>
            <Header level={4} color={primaryColors.c01}>
              £{formatDisplayPrice(totalPrice)}
            </Header>
          </Container>
        </Grid>
      </Container>
      <Spacer height={24} />
      <Divider color={secondaryColors.c04} />
      <Spacer height={24} />
      <Container noPadding>
        <Container backgroundColor={secondaryColors.c05}>
          <Copy fontSize={14}>
            Provided by Pier, underwritten by AXA and 5-star Defaqto rating.
          </Copy>
          <Spacer height={16} />
          <Copy fontSize={14} color={secondaryColors.c02}>
            Since it&apos;s tied to your Raylo subscription, you won&apos;t be
            able to add it later. Read our{' '}
            <TextLink onClick={onShowPolicyInfo}>policy summary</TextLink> for
            more information
          </Copy>
        </Container>
      </Container>
      <Spacer height={24} />
      <Container noPadding>
        <DoubleButtonFullWidth
          singleButton
          topButtonOnClick={onSubmit}
          topButtonText="next"
          topButtonTestId="confirmInsurance"
        />
      </Container>
    </StyledStepTradeInInsurance>
  );
};
