import React from 'react';
import styled from 'styled-components/macro'
import * as T from 'styles/type';
import colors from 'styles/colors';
import directDebitLogo from 'public/direct_debit_logo.png';

import { BackButton } from 'components/common/BackButton';
import { PlainHeader } from 'components/common/PlainHeader';


export const DDModal = ({ visible, onBack }) => {
  return (
    <RayloModal visible={visible}>
      <PlainHeader />
      <ModalContainer>
        <ModalHeader>
          <ModalBack>
            <BackButton onClick={onBack}/>
            <p className="back-text" onClick={onBack}>Back</p>
          </ModalBack>
          <div>
            <img src={directDebitLogo} alt="The Direct Debit Guarantee" style={{paddingRight: 8}}/>
          </div>
        </ModalHeader>

        <T.RayloSectionHeader>
          The Direct Debit Guarantee
        </T.RayloSectionHeader>

        <T.RayloUnorderedList>
          <li>This Guarantee is offered by all Banks and Building Societies that accept instructions to pay Direct Debits.</li>

          <li>If there are any changes to the amount, date or frequency of your Direct Debit Eazipay Ltd re Raylo Group Limited will notify you 5 working days in advance of your account being debited or as otherwise agreed. If you request Eazipay Ltd re Raylo Group Limited to collect a payment, confirmation of the amount and date will be given to you at the time of the request.</li>

          <li>If an error is made in the payment of your Direct Debit, by Eazipay Ltd re Raylo Group Limited or your Bank or Building Society, you are entitled to a full and immediate refund of the amount from your Bank or Building Society.
          <ul>
            <li>If you receive a refund you are not entitled to, you must pay it back when Eazipay Ltd re Raylo Group Limited asks you to.</li>
          </ul></li>

          <li>You can cancel a Direct Debit at any time by simply contacting your Bank or Building Society.  Written confirmation may be required. Please also notify us.</li>
        </T.RayloUnorderedList>
      </ModalContainer>
    </RayloModal>
  )
}
export const RayloModal = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: #000;
  opacity: ${props => props.visible ? '1' : '0'};
  transition: opacity .25s ease-in .25s;

  ::-webkit-scrollbar {
	   width: 9px;
  }

  ::-webkit-scrollbar-track {
  	background: none;
  }

  ::-webkit-scrollbar-thumb {
  	background: ${colors.rayloPink};
  	border: 0;
  	box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
    border-radius: 12px;
  }

  scrollbar-color: ${colors.rayloPink} #000;
  scrollbar-width: thin;
`

export const ModalContainer = styled.section`
  padding: 100px 40px 200px 12.6%;
  max-width: 800px;

  @media (max-width: 768px) {
    padding: 120px 20px;
  }
`

export const ModalHeader = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ModalBack = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  .back-text {
    font-size: 0.75em;
    color: #FFF;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-left: 14px;
    cursor: pointer;
  }
`
