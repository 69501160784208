import React from 'react'
import {PhoneStatusUpgrade} from './PhoneStatusUpgrade'
import {PhoneBlockedBanner} from './PhoneBlockedBanner'
import {Delivery} from './Delivery'
import {YourTechStatus} from "screens/Account/YourTechStatus"
import {OverdueBanner} from "./Banners/OverdueBanner/OverdueBanner"

export const PhoneStatus = ({phone, customer}) => {
  switch (phone.status) {
    case YourTechStatus.blocked:
      return <PhoneBlockedBanner asset={phone.subscription.activeAsset}/>
    case YourTechStatus.arrears:
      return <OverdueBanner device={phone} customer={customer}/>
    case YourTechStatus.upgradeAvailable:
      return <PhoneStatusUpgrade phone={phone} name={customer.firstName}/>
    default:
      return <Delivery phone={phone} address={customer.address}/>
  }
}
