import StepSignContent from 'screens/Checkout/components/StepSignContent';
import { StepSignProvider } from './hooks/useStepSignContext';

export const StepSignContainer = () => {
  return (
    <StepSignProvider>
      <StepSignContent />
    </StepSignProvider>
  );
};
