import RayloModal from 'components/RayloModal';
import Spacer from 'elements/Spacer';
import { useUiContext } from 'utils/useUiContext';
import { IPrivacyModal } from './types';

export const PrivacyModal = ({ dataTestId }: IPrivacyModal) => {
  const { setShowModal, setShowPrivacyModal } = useUiContext();
  const handleModalClose = () => {
    setShowModal(false);
    setShowPrivacyModal(false);
  };
  return (
    <RayloModal
      header="Raylo Privacy Policy"
      onClose={handleModalClose}
      dataTestId={dataTestId}
    >
      <p>Raylo Modal</p>
      <Spacer height={40} />
    </RayloModal>
  );
};
