import { Container, Copy, Icon, Spacer } from 'elements';
import Divider from 'elements/Divider';
import { Link } from 'react-router-dom';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { useUiContext } from 'utils/useUiContext';
import { StyledRayloPayHeader } from './styles';
import { IRayloPayHeader } from './types';

export const RayloPayHeader = ({ dataTestId }: IRayloPayHeader) => {
  const { setShowRPCancelOrderModal } = useUiContext();
  return (
    <StyledRayloPayHeader data-testid={dataTestId}>
      <Container
        padding={`0 ${px2Rem(16)}`}
        cursorPointer
        center
        height="100%"
        width="100%"
      >
        <Container
          flexRow
          width="100%"
          maxWidth="414px"
          noPadding
          centerVertical
        >
          <Container noPadding>
            <Link to="/">
              <Icon name="RPLogoLight" color="white" dataTestId="logoIcon" />
            </Link>
            <Spacer height={8} />
            <Copy color={primaryColors.c01}>
              Lease it for less. Always interest-free
            </Copy>
          </Container>
          <Container
            noPadding
            alignRight
            width="fit-content"
            onClick={() => setShowRPCancelOrderModal(true)}
          >
            <Icon name="Close" size="16px" color={primaryColors.c01} />
          </Container>
        </Container>
      </Container>
      <Divider color={secondaryColors.c04} padding={16} />
    </StyledRayloPayHeader>
  );
};
