import React, {useEffect, useState } from 'react';
import _ from 'lodash';
import { activateOptimize } from 'utils/optimize'

// HOOKS
import { useMutation } from '@apollo/client';

// GRAPHQL
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';

// VISUAL COMPONENTS
import * as T from 'styles/type';
import * as L from 'styles/layout';
import { Modal } from 'components/common/Modal';
import { SlideDownFadeIn } from 'components/animations/Transitions';
import Currency from 'react-currency-formatter';
import { Button } from 'components/common/Button';
import { BackButton } from 'components/common/BackButton';
import styled from 'styled-components/macro';

// FORM VALIDATION
import useRayloQuery from 'utils/useRayloQuery'
import {useSegmentIdentifyAndCheckoutTracking} from 'hooks/segment/segment.hooks'


export const StepUpgrade = ({onSuccess, onBack, checkoutToken}) => {
  useSegmentIdentifyAndCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'upgrade',
    checkoutScreen: 'let\'s upgrade you',
    when: 'on page load'
  });

  // HOOKS
  const [showFields, setShowFields] = useState(true)
  const [phone, setPhone] = useState(undefined)
  const [oldPhone, setOldPhone] = useState("unknown")
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [customerInfo, setCustomerInfo] = useState()

  const [feeSummary, setFeeSummary] = useState()
  const [showTip, setShowTip] = useState(false)

  const { data: { checkout }, loading } = useRayloQuery(CheckoutSteps.stepUpgrade.query, {
    variables: {
      token: checkoutToken
    }
  })

  const [updateMutation] = useMutation(CheckoutSteps.stepUpgrade.mutation, {
    update: (proxy, {data: {updateCheckout: { errors, checkout }}}) => {
      if(errors && errors.length > 0) {
        setIsSubmitting(false)
      } else {
        if(window.analytics) {
          window.analytics.identify(checkout.id, { email: checkout.customerInfo.email })
        }
        setShowFields(false)
        onSuccess()
      }
    }
  })

  const onSubmit = () => {
    setIsSubmitting(true)
    updateMutation({
      variables: {
        ...customerInfo,
        checkoutToken
      }
    })
  }

  useEffect(() => {
    if(checkout) {
      if(checkout.customerInfo) {
        setCustomerInfo(_.pick(checkout.customerInfo,'firstName','lastName','dateOfBirth', 'email'))
      } else if(checkout.customer) {
        setCustomerInfo(_.pick(checkout.customer,'firstName','lastName','dateOfBirth', 'email'))
      }
    }
  }, [checkout])

  useEffect(() => {
    if(checkout && checkout.items) {
      const item = checkout.items[0]
      const {variant, originSubscription} = item
      setPhone(variant.product.displayName)
      if(originSubscription) {
        setOldPhone(originSubscription.activeAsset.variant.product.displayName)
        setFeeSummary(originSubscription.feeSummary.currentNonReturnFee)
      }
    }
  }, [checkout])

  useEffect(() => {
    activateOptimize()
  })

  if(loading) return (<Modal visible text="Loading..." />)
  return (
    <div className="checkout--step">
      <SlideDownFadeIn visible={showFields}>
        <L.RayloSection noBorder>
          <T.RayloBodyHeading>Great. Let's upgrade you</T.RayloBodyHeading>
          <T.RayloBodyType>After you receive your new {phone}, we’ll send pre-paid return packaging for your current {oldPhone}.</T.RayloBodyType>
        </L.RayloSection>
      </SlideDownFadeIn>
      <SlideDownFadeIn visible={showFields}>
        <L.RayloSection noBorder>
          <T.RayloBodyTypeInvertedSmall>Prefer to <T.RayloLink onClick={() => setShowTip(!showTip)}>keep your {oldPhone}</T.RayloLink> instead?</T.RayloBodyTypeInvertedSmall>
        </L.RayloSection>
      </SlideDownFadeIn>
      {showTip && 
      <SlideDownFadeIn visible={showFields}>
      <TipBox>
          <T.RayloBodyType>No problem — once you’ve completed your upgrade, you’ll be offered the chance to keep your current {oldPhone} by paying a non-return fee of <Currency quantity={feeSummary.value} currency={feeSummary.currencyCode} />
          </T.RayloBodyType>
        </TipBox>
      </SlideDownFadeIn>}

      <ButtonContainer>
       <BackButton onClick={onBack} />
        <Button
          buttonStyle="primaryBlue"
          onClick={() => onSubmit()}
          disabled={isSubmitting}
          type='submit'>Let's go</Button></ButtonContainer>
    </div>
  )
}



export const TipBox = styled.div`
  background: #212121;
  padding: 20px;
  margin-bottom: 40px;
  margin-top: -20px
`


export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  div:first-child {
    margin-right: 20px;
  }

`
