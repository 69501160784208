import { useEffect, useState } from 'react';
import constate from 'constate';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import useRayloQuery from 'utils/useRayloQuery';
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';
import { useMutation } from '@apollo/client';
import { credoAppCollectData } from 'utils/credoApp';
import { MU_SUBMIT_CHECKOUT } from 'screens/Checkout/graphQL/mutations';
import { useErrorNavigator } from 'utils/useErrorNavigator';
import _ from 'lodash';

const useStepSign = () => {
  const [formErrors, setFormErrors] = useState<any>(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [errorType, setErrorType] = useState<string | undefined>(undefined);
  const [tradeInExists, setTradeInExists] = useState<boolean>(false);
  const [optInTrue, setOptInTrue] = useState(false);
  const { checkoutToken } = useCheckoutContext();

  const errorNav = useErrorNavigator();

  const {
    data: { checkout },
    loading,
  } = useRayloQuery(CheckoutSteps.step8.query, {
    fetchPolicy: 'network-only',
    variables: {
      token: checkoutToken,
    },
  });

  const [submitMutation] = useMutation(MU_SUBMIT_CHECKOUT, {
    update: (
      proxy,
      {
        data: {
          submitCheckout: { errors },
        },
      },
    ) => {
      if (errors && errors.length > 0) {
        if (_.find(errors, { field: 'payment_info.initial_payment_method' })) {
          setErrorType('card');
        } else {
          errorNav('GraphQL submit validation error', { errors });
        }
      } else {
        setOrderPlaced(true);
      }
    },
  });

  const [updateMutation] = useMutation(CheckoutSteps.step8.mutation, {
    update: (
      proxy,
      {
        data: {
          updateCheckout: { errors },
        },
      },
    ) => {
      if (errors?.length > 0) {
        setFormErrors(errors);
        setOrderPlaced(false);
      } else {
        setFormErrors(null);
        submitMutation({
          variables: {
            checkoutToken: checkout.token,
          },
        });
        credoAppCollectData(checkout.reference);
      }
    },
  });

  const [phone, setPhone] = useState({});
  const [noInsurance, setNoInsurance] = useState(false);

  useEffect(() => {
    if (checkout?.items) {
      const { variant } = checkout.items[0];
      const { pricePlan } = checkout.items[0];

      setNoInsurance(!pricePlan.includesInsurance);

      setPhone({
        model: variant.product.displayName,
        hireAgreementName: variant.hireAgreementName,
        payMonthly:
          pricePlan.costSummary.recurring.totalAmount.valueAfterTax.value,
        payToday: pricePlan.costSummary.initial.totalAmount.valueAfterTax.value,
        payRecurring: pricePlan.costSummary.recurring,
        currency: pricePlan.currencyCode,
        pricePlan,
        feeSummary: pricePlan.feeSummary,
        termLength: pricePlan.termLength,
        lastRecurringPeriod: pricePlan.lastRecurringPeriod,
        isTermLengthOverOrEqual18Months: pricePlan.termLength >= 18,
      });
      setTradeInExists(!!checkout?.tradeIns?.[0]?.id);
    }
  }, [checkout]);

  const handleUpdateCheckout = () => {
    const blackboxData = window?.IGLOO?.getBlackbox();
    updateMutation({
      variables: {
        marketingOptIn: optInTrue,
        termsAndConditionsAccepted: true,
        tradeInTermsAndConditionsAccepted: tradeInExists,
        checkoutToken: checkout?.token,
        deviceInfoFingerprint: blackboxData && blackboxData.blackbox,
      },
    });
  };

  const handleSubmitCheckout = () => {
    handleUpdateCheckout();
  };

  return {
    phone,
    checkout,
    noInsurance,
    loading,
    formSubmitted,
    formErrors,
    errorType,
    orderPlaced,
    tradeInExists,
    optInTrue,
    setErrorType,
    setFormSubmitted,
    handleSubmitCheckout,
    setOptInTrue,
  };
};

const [StepSignProvider, useStepSignContext] = constate(useStepSign);
export { StepSignProvider, useStepSignContext };
