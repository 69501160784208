import React from 'react'

import { Route, useHistory } from 'react-router-dom'
import { useCheckoutContext } from 'utils/useCheckoutContext'
import { Modal } from 'components/common/Modal'
import { FRONTEND_URL } from 'utils/constants'
import { match } from 'ex-patterns'
import { each } from 'lodash'
import * as Sentry from '@sentry/react';


const SentryRoute = Sentry.withSentryRouting(Route);

export const RouteChecker = ({ children, conditions,  ...rest }) => {
   const { hasCheckoutToken, checkoutLoading, checkoutCalled, condition, checkoutToken } = useCheckoutContext()
   const history = useHistory()

   let hasMatch = false
   each(conditions, o => {
    if(match(o, condition)[0]) {
      hasMatch = true 
    }
   })


  return (
    <SentryRoute {...rest}
      render={props => (
        hasCheckoutToken 
          ? 
          !checkoutLoading && checkoutCalled
            ? 
            hasMatch
              ?
              children
              :
              history.push(`/go/${checkoutToken}`)
            : 
            <Modal visible text="Loading..." />
          : 
          window.location = FRONTEND_URL
      )} />
  )
}
