import React from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash'

import * as T from 'styles/type';
import * as A from 'styles/account';
import colors from 'styles/colors';

import downloadIcon from 'public/icon--download-statement.svg';
import dayjs from 'dayjs';

export const PreviousPayments = ({transactions}) => {

  const orderedTransactions = _.orderBy(
    _.filter(transactions, o => o.amountIncludingTax.value >= 0 || true)
  , ['happenedAt'], ['desc'])

  return (
    <div>
      <A.PaymentRowHeader>
        <T.PaymentRowLabel>Previous Payments</T.PaymentRowLabel>
      </A.PaymentRowHeader>

      <PreviousPaymentsWrapper>
        {orderedTransactions.map((transaction,i) =>
          <PreviousPayment key={i}>
            <PreviousPaymentLeft>
              <PreviousPaymentDate>{dayjs(transaction.happenedAt).format("DD/MM/YY")}</PreviousPaymentDate>

              <PreviousPaymentInfo>
                <PreviousPaymentType>
                  <span>{transaction.description}</span>
                </PreviousPaymentType>
              </PreviousPaymentInfo>
            </PreviousPaymentLeft>

            <PreviousPaymentRight className={transaction.amountIncludingTax.value < 0 ? 'negative' : null}>
              <PreviousPaymentTotal>
                <span className={transaction.amountIncludingTax.value < 0 ? 'negative' : null}>{transaction.amountIncludingTax.formattedValue}</span>
                {false && <img src={downloadIcon} alt="Download a statement" />}
              </PreviousPaymentTotal>
            </PreviousPaymentRight>
          </PreviousPayment>
        )}
      </PreviousPaymentsWrapper>
    </div>
  )
}

export const PreviousPaymentsWrapper = styled.ol`
list-style-type: none;
margin: 0;
padding: 0;
`

export const PreviousPayment = styled.li`
display: flex;
justify-content: space-between;
padding-bottom: 20px;
border-bottom: 1px solid #E0E0E0;

&:not(:last-of-type) {
  margin-bottom: 20px;
}

@media (max-width: 675px) {
  flex-direction: column;
}
`

export const PreviousPaymentLeft = styled.div`
display: flex;
align-items: flex-start;
width: 50%;

@media (max-width: 675px) {
  margin-bottom: 20px;
  width: auto;
}

@media (max-width: 420px) {
  justify-content: space-between;
}
`

export const PreviousPaymentRight = styled.div`
display: flex;
align-items: flex-end;

&.negative {
  flex-basis: 200px;

  @media (max-width: 675px) {
    flex-basis: auto;
    width: 175px;
  }

  @media (max-width: 420px) {
    width: 100%;
  }
}

@media (max-width: 675px) {
  align-self: flex-end;
}

@media (max-width: 420px) {
  justify-content: space-between;
  width: 100%;
}
`

export const PreviousPaymentDate = styled.span`
display: inline-block;
font-family: 'Raylo-Medium', Arial, sans-serif;
font-weight: 500;
font-size: 0.875em;
width: 30%;
color: ${colors.rayloDark};
line-height: 1.5;

@media (max-width: 675px) {
  width: auto;
  margin-right: 28px;
}
`

export const PreviousPaymentInfo = styled.div`
font-family: 'Raylo-Regular', Arial, sans-serif;
font-weight: normal;
font-size: 0.875em;
color: ${colors.raylo2Dark};
line-height: 1.5;
width: 70%;

@media (max-width: 675px) {
  width: auto;
}
`

export const PreviousPaymentType = styled.div`
&:not(:last-of-type) {
  margin-bottom: 8px;
}

span:first-of-type {
  font-family: 'Raylo-Mono', 'Raylo-Regular', Arial, sans-serif;
  font-weight: normal;
  margin-right: 12px;
}

span {
  @media (max-width: 420px) {
    display: block;
  }
}
`

export const PreviousPaymentStatus = styled.div`
display: flex;
align-items: center;
font-family: 'Raylo-Regular', Arial, sans-serif;
font-weight: normal;
font-size: 0.875em;
color: ${colors.raylo2Dark};
line-height: 1.5;
margin-right: 48px;

img {
  margin-right: 12px;
}
`

export const PreviousPaymentTotal = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
font-family: 'Raylo-Mono', 'Raylo-Regular', Arial, sans-serif;
font-size: 0.875em;
color: ${colors.rayloBlue3Dark};
line-height: 1.5;

span {
  margin-right: 12px;

  &.negative {
    color: ${colors.rayloPink3Dark};
  }
}
`
