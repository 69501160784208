export const getPricePlans = (pricePlans: any) => {
  const plans = pricePlans?.items?.[0]?.variant?.pricePlans;
  const filteredPlans = plans?.filter(
    ({ includesInsurance }: any) => !includesInsurance,
  );
  filteredPlans?.sort((a: any, b: any) => a.termLength - b.termLength);
  const returnedPlans = filteredPlans?.map((plan: any) => {
    return {
      id: plan?.id,
      termLength: plan?.termLength,
      monthlyPrice:
        plan?.costSummary?.recurring?.totalAmount?.valueAfterTax?.value,
    };
  });
  return returnedPlans;
};
