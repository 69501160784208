import React, {useEffect, useState} from 'react';
import { useChangePhoneContext } from 'utils/useChangePhoneContext';
import { compact, map } from 'lodash'

import 'styles/phone-features.css';

import iconEye from 'public/icon--feature-eye.svg';
import iconBattery from 'public/icon--feature-battery.svg';
import iconCamera from 'public/icon--feature-camera.svg';
import iconCamera2 from 'public/icon--feature-camera-2.svg';
import iconCamera3 from 'public/icon--feature-camera-3.svg';
import iconPower from 'public/icon--feature-power.svg';

export const PhoneFeatures = () => {

  const { product, loading } = useChangePhoneContext()


  const [parsedSpec, setParsedSpec] = useState([])

  useEffect(() => {
    if(product && product.specifications) {
      setParsedSpec(map(compact(product.specifications.split("\n")), (item) => {

        let name = item.replace(/^- /,'')

        const emoji = name.split(" ")[0]

        let icon = ""

        switch (emoji) {
          case "🔋":
            name = name.replace("🔋 ", "")
            icon = iconBattery            
            break;
          case "📷📷📷":
            name = name.replace("📷📷📷 ", "")
            icon = iconCamera3            
            break;
          case "📷📷":
            name = name.replace("📷📷 ", "")
            icon = iconCamera2          
            break; 
          case "📷":
            name = name.replace("📷 ", "")
            icon = iconCamera           
            break; 
          case "👁️":
            name = name.replace("👁️ ", "")
            icon = iconEye            
            break;
          case "⚡":
            name = name.replace("⚡ ", "")
            icon = iconPower            
            break;
          default:
            icon = undefined
            break;
        }




        return {
          name,
          icon
        }

      }))
    }
  }, [product])

  if(loading || !product) {
    return null
  }
  return (
    <div className="phone-features">
      <div className="phone-features--container">
        <div className="phone-features--header">{product.displayName} Features</div>

        <div className="phone-features--list">
          {parsedSpec.map((item,i) => {

            return (
            <div className="phone-features--feature" key={i}>
              <div className="phone-features--feature__image">{item.icon && <img src={item.icon} alt="Feature icon" />}</div>
              <div className="phone-features--feature__title">{item.name}</div>
            </div>)
          })}
        </div>
      </div>
    </div>
  )
}
