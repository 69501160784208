import styled from 'styled-components';

const StyledPhoneDetailsCard = styled.div`
  background-color: white;
  display: flex;
`;

const StyledPhoneImage = styled.img`
  width: 123px;
  height: 146px;
`;

export { StyledPhoneDetailsCard, StyledPhoneImage };
