export const SLUGS = {
  StepInsurance: 'insurance',
  StepNameEmail: 'name-email',
  StepEmail: 'email',
  StepAgeCountry: 'age',
  StepAddress: 'address',
  StepAddressCheck: 'previous-address',
  StepMobileJob: 'mobile',
  StepStripe: 'credit-card',
  StepBank: 'bank',
  StepBankNewCustomer: 'bank',
  StepTerms: 'terms',
  StepSign: 'sign',
  StepSignOffer: 'sign-offer',
  StepTradeIn: 'trade-in',
  StepUpgrade: 'upgrade',
  StepLeaseTerm: 'lease-term',
};
