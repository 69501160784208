export const YourTechStatus = {
  blocked: 'blocked',
  arrears: 'Payment overdue',
  upgradeAvailable: 'Upgrade available',
  upgradeComplete: 'Upgrade complete',
  upgradeSoon: 'Upgrade soon',
  active: 'Active',
  delivered: 'Order delivered',
  dispatched: 'Order dispatched',
  pending: 'Pending further checks',
  preorder: 'preorder',
  orderPlaced: 'Order placed',
  orderCancelled: 'Order cancelled',
  paymentFailure: 'Payment failure',
  returnDue: 'Return due',
  returnReceived: 'Return received',
  leaseCancelled: 'Lease cancelled',
  started: 'started',
};
