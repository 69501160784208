import { Container, Copy, Icon, Spacer } from 'elements';
import TextLink from 'elements/TextLink';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { StyledInformationBox } from './styles';
import { IInformationBox } from './types';

export const InformationBox = ({
  dataTestId,
  title,
  linkText,
  onClick,
}: IInformationBox) => {
  return (
    <StyledInformationBox data-testid={dataTestId}>
      <Container
        backgroundColor={secondaryColors.b05}
        styles={{
          borderRadius: '4px',
          border: `1px solid ${secondaryColors.b04}`,
        }}
      >
        <Container noPadding flexRow>
          <Container noPadding width="90%">
            <Copy bold fontSize={14} color={primaryColors.c01}>
              {title}
            </Copy>
          </Container>
          <Container noPadding alignRight width="10%">
            <Icon name="Info" />
          </Container>
        </Container>
        <Spacer height={4} />
        <TextLink fontSize={14} onClick={onClick}>
          {linkText}
        </TextLink>
      </Container>
    </StyledInformationBox>
  );
};
