import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import Spacer from 'elements/Spacer';
import { StyledDoubleButtonFullWidth } from './styles';
import { IDoubleButtonFullWidth } from './types';

export const DoubleButtonFullWidth = ({
  dataTestId,
  topButtonText,
  topButtonOnClick,
  bottomButtonText,
  bottomButtonOnClick,
  singleButton,
  topButtonType,
  bottomButtonType,
  formId,
  topButtonTestId,
  bottomButtonTestId,
  ghostMode,
}: IDoubleButtonFullWidth) => {
  const upperButtonType = ghostMode ? 'ghost' : undefined;
  return (
    <StyledDoubleButtonFullWidth data-testid={dataTestId}>
      <Button
        text={topButtonText}
        size="large"
        buttonColor={ButtonTypes.b01}
        buttonType={upperButtonType}
        onClick={topButtonOnClick}
        formId={formId}
        type={topButtonType}
        dataTestId={topButtonTestId}
      />
      {!singleButton && (
        <>
          <Spacer height={24} />
          <Button
            text={bottomButtonText}
            size="large"
            buttonType="ghost"
            onClick={bottomButtonOnClick}
            type={bottomButtonType}
            dataTestId={bottomButtonTestId}
          />
        </>
      )}
    </StyledDoubleButtonFullWidth>
  );
};
