import React, {useState, useEffect} from 'react'
import _ from 'lodash'
import dayjs from 'dayjs'

// NAVIGATION
import {Modal} from 'components/common/Modal'

import {AccountFooter} from 'components/Account/AccountFooter'
import {QL_CUSTOMER} from 'screens/Account/queries'
import {PaymentSummary} from 'components/Account/PaymentSummary'

import useRayloQuery from 'utils/useRayloQuery'

import {Helmet} from 'react-helmet'
import {PhoneStatus} from 'components/Account/PhoneStatus'
import {useParams} from 'react-router'

import {parseOrder} from './Overview'
import Container from 'elements/Container'
import {secondaryColors} from 'styles/variables/colors'
import ReferAFriend from 'components/ReferAFriend'
import {px2Rem} from 'utils/px2Rem'
import DeviceLeaseProfileCard from 'components/DeviceLeaseProfileCard'
import Header from 'elements/Header'
import Spacer from 'elements/Spacer'
import useMedia from 'use-media'
import {breakpoints} from 'styles/breakpoints'
import InsuranceBanner from 'components/InsuranceBanner'
import {DirectDebitSummary} from 'components/Account/DirectDebitSummary'
import BackArrowLink from '../../components/BackArrowLink'
import {YourTechStatus} from "./YourTechStatus"
import {track} from 'helpers/analytics/segment'

export const PhoneProfile = () => {

  const {orderId} = useParams()

  const [phone, setPhone] = useState(null)
  const [phones, setPhones] = useState([])
  const [subscriptionId, setSubscriptionId] = useState(null)
  const [upgradeAvailable, setUpgradeAvailable] = useState(false)
  const [orderItem, setOrderItem] = useState({})
  const [trackedAccountViewedEvent, setTrackedAccountViewedEvent] = useState(false)
  const isMobile = useMedia({maxWidth: breakpoints.mobile})

  const {data: {customer}, loading} = useRayloQuery(QL_CUSTOMER, {fetchPolicy: "network-only"})

 useEffect(() => {
    if (customer && customer.orders) {
      const order = customer.orders.find(order => order.id === orderId)
      setOrderItem(order.items[0])
      let allPhones = []
      _.each(customer.orders, (order) => {
        const p = parseOrder(order)
        allPhones.push(p)
        if (order.id === orderId) {
          setPhone(p)
        }
      })
      setPhones(allPhones)
    }
  }, [customer])

  useEffect(() => {
    const order = customer?.orders?.find(({id}) => id === orderId)
    setSubscriptionId(order?.items[0]?.subscription?.id)
    setUpgradeAvailable(order?.items[0]?.subscription?.upgrade?.eligible)
  }, [customer])

  useEffect(() => {
    if (phone && !trackedAccountViewedEvent) {
      track('My Account Viewed', {
        screen: 'lease details',
        in_arrears: phone.status === YourTechStatus.arrears ? 'Yes' : 'No',
        when: 'on page load',
        url: window.location.href
      })

      setTrackedAccountViewedEvent(true)
    }
  })

  if (loading || !phone) return (<Modal visible text="Loading..."/>)

  const DeviceDetails = () => (
    <Container
      backgroundColor={secondaryColors.c05}
      padding={isMobile ? `16px` : '0'}
    >
      <Container maxWidth={px2Rem(1120)} padding="0" styles={{
        alignSelf: "center"
      }}>
        <Spacer height={24}/>
        <BackArrowLink text="Account overview" linkLocation={'/account'}/>
        <Spacer height={24}/>
        <Container alignCenter padding="0">
          <Header level={2} fontSize={isMobile ? 18 : 24}>{phone?.model}</Header>
        </Container>
        <Spacer height={24}/>
        <DeviceLeaseProfileCard deviceData={phone} subscriptionId={subscriptionId} upgradeAvailable={upgradeAvailable}/>
      </Container>
    </Container>
  )

  const InsuranceDetails = () => (
    <Container
      backgroundColor={secondaryColors.c05}
      padding="0"
    >
      <Spacer height={48}/>
      <Container alignCenter padding="0">
        <Header level={2} fontSize={isMobile ? 18 : 24}>Insurance</Header>
      </Container>
      <Spacer height={24}/>
      <Container maxWidth={px2Rem(1120)} padding="0" styles={{
        alignSelf: "center"
      }}>
        <InsuranceBanner orderId={orderId}/>
      </Container>
    </Container>
  )

  const PaymentInfo = () => (
    <Container
      backgroundColor={secondaryColors.c05}
      padding="0"
    >
      <Spacer height={48}/>
      <Container alignCenter padding="0">
        <Header level={2} fontSize={isMobile ? 18 : 24}>Payment Overview</Header>
      </Container>
      <Spacer height={24}/>
      <Container maxWidth={px2Rem(1120)} boxShadow={!isMobile} padding="0" styles={{
        alignSelf: "center"
      }}>
        <PaymentSummary subscription={phone?.subscription}/>
      </Container>
      {!isMobile && <Spacer height={24}/>}
    </Container>
  )

  const DirectDebitDetails = () => (
    <Container
      backgroundColor={secondaryColors.c05}
      padding="0"
    >
      <Spacer height={48}/>
      <Container alignCenter padding="0">
        <Header level={2} fontSize={isMobile ? 18 : 24}>Direct Debit details</Header>
      </Container>
      <Spacer height={24}/>
      <Container maxWidth={px2Rem(1120)} boxShadow={!isMobile} padding="0" styles={{
        alignSelf: "center"
      }}>
        <DirectDebitSummary subscription={orderItem?.subscription}/>
      </Container>
      {!isMobile && <Spacer height={24}/>}
    </Container>
  )

  return (
    <>
      <Helmet><title>Overview - Raylo</title></Helmet>
      <PhoneStatus
        key={phone.id}
        customer={customer}
        phone={phone}
      />
      <DeviceDetails/>
      {phone.insurance && <InsuranceDetails/>}
      {phone?.subscription && <PaymentInfo/>}
      {orderItem?.subscription && <DirectDebitDetails/>}
      <ReferAFriend/>
      <AccountFooter/>
    </>
  )
}
