import PreApprovalBanner from 'components/PreApprovalBanner';
import PreApprovalBannerMobile from 'components/PreApprovalBannerMobile';
import { AllPhones } from 'components/Upgrade/AllPhones';
import Container from 'elements/Container';
import Spacer from 'elements/Spacer';
import { breakpoints } from 'styles/breakpoints';
import { secondaryColors } from 'styles/variables/colors';
import useMedia from 'use-media';
import { px2Rem } from 'utils/px2Rem';
import BackArrowLink from '../../components/BackArrowLink';

export const Products = () => {
  const isMobile = useMedia({ maxWidth: breakpoints.mobile });
  return (
    <Container
      backgroundColor={secondaryColors.c04}
      center
      padding={isMobile ? '0' : px2Rem(16)}
    >
      <Container maxWidth={px2Rem(1220)} padding="0">
        <Spacer height={24} />
        <Container padding={`0 ${px2Rem(16)}`}>
          <BackArrowLink text="Account overview" linkLocation="/account" />
        </Container>
        <Spacer height={24} />
        <Container
          backgroundColor="#fff"
          maxWidth={`${px2Rem(1220)}`}
          padding="0"
        >
          {isMobile ? <PreApprovalBannerMobile /> : <PreApprovalBanner />}
          <AllPhones additionalTech />
        </Container>
      </Container>
    </Container>
  );
};
