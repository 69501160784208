import styled from 'styled-components';
import { secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';

const StyledPhonePricingCard = styled.div`
  background-color: ${secondaryColors.c05};
  padding: ${px2Rem(24)};
`;

export { StyledPhonePricingCard };
