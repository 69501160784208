import styled from 'styled-components';
import colors from './colors';
import AccountWrapper from './account';

export const OuterWrapper = styled.div`
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  // min-height: 100vh;
  // background-color: #000;
  // @media (max-width: 540px) {
  //   display: block;
  // }
`

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width:50vw;
  min-height: 100vh;
  // width: 100%;
  // min-height: 100vh;
  // display: flex;
  // justify-content: flex-start;
  // position: relative;

  // background: green;

  // @media (max-width: 540px) {
  //   display: block;
  //   height: auto;
  // }
`

export const PushDown = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > ${AccountWrapper} {
    flex: 1;
  }

  @media (max-height: 850px) {
    height: auto;
  }

  @media (max-width: 375px) and (min-height: 850px) {
    height: auto;
  }
`

export const HeaderBlocker = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: calc(50% - 16px);
  height: 0;
  background: linear-gradient(to top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 45%,rgba(0,0,0,1) 100%);
  z-index: 1;

  @media (max-height: 1000px) {
    height: 75px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`

export const InviteForm = styled.section`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-height: 500px) and (min-width: 541px) {
    ::-webkit-scrollbar {
       width: 9px;
    }

    ::-webkit-scrollbar-track {
      background: none;
    }

    ::-webkit-scrollbar-thumb {
      background: ${colors.rayloPink};
      border: 0;
      box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
    }

    scrollbar-color: ${colors.rayloPink} #000;
    scrollbar-width: thin;
  }

  @media (max-width: 1024px) {
    width: 100%;
    position: static;
    height: auto;
  }

  @media (max-width: 540px) {
    display: block;
    padding-top: 120px;
  }

  @media (max-height: 500px) {
    display: block;
    height: auto;
    padding: 75px 0 0;
    overflow: auto;
  }
`

export const InviteFormContainer = styled.div`
  padding: 40px;
  max-width: 425px;

  @media (max-width: 540px) {
    padding: 20px 20px 0;
    max-width: unset;
  }

  @media (max-height: 500px) {
    padding-bottom: 75px;
    margin: 0 auto;
  }
`

export const SignupForm = styled.section`
  flex: 1;
  // max-width: 450px;
  // width: 100%;
  // margin: 0 auto;
  // position:relative;

  // ::-webkit-scrollbar {
	//    width: 9px;
  // }

  // ::-webkit-scrollbar-track {
  // 	background: none;
  // }

  // ::-webkit-scrollbar-thumb {
  // 	background: ${colors.rayloPink};
  // 	border: 0;
  // 	box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  // }

  // scrollbar-color: ${colors.rayloPink} #000;
  // scrollbar-width: thin;

  // @media (max-height: 1000px) and (max-width: 660px) {
  //   display: block;
  //   height: auto;
  //   padding: 100px 0 0;
  //   overflow: auto;
  // }

  // @media (max-height: 1000px) and (min-width: 661px) {
  //   padding: 125px 40px 0;
  //   overflow: auto;
  //   display: block;
  //   height: auto;
  // }

  // @media (max-width: 1024px) {
  //   width: 100%;
  //   position: static;
  //   height: auto;
  // }

  // @media (max-width: 660px) {
  //   padding: 100px 0px 80px;
  //   display: block;
  // }

  // @media (max-width: 450px) {
  //   padding: 100px 0 40px;
  // }
`

export const SignupFormContainer = styled.div`
  min-width: 320px;
  max-width: 420px;
  width: 100%;

  @media (max-width: 450px) {
    width: 100%;
    padding: 0 20px;
    min-width: unset;
    max-width: unset;
  }

  @media (max-height: 1000px) and (max-width: 1024px) {
    margin: 0 auto;
  }

  @media (max-height: 1000px) and (min-width: 661px) {
    padding-bottom: 145px;
    margin: 0 auto;
  }

  @media (max-height: 1000px) and (max-width: 660px) {
    padding-bottom: 120px;
    margin: 0 auto;
  }

  @media (max-width: 660px) {
    margin: 0 auto;
  }
`

export const SignupOrder = styled.section`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: ${props => props.showConfigure ? '100%' : '50%'};
  padding-top: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.rayloBlue};
  transition: width 1s ease-in-out;

  ::-webkit-scrollbar {
	   width: 9px;
  }

  ::-webkit-scrollbar-track {
  	background: none;
  }

  ::-webkit-scrollbar-thumb {
  	background: ${colors.rayloPink};
  	border: 0;
  	box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  }

  scrollbar-color: ${colors.rayloPink} ${colors.rayloBlue};
  scrollbar-width: thin;

  @media (max-width: 1024px) {
    width: 100%;
    z-index: 3;
    transform: ${props => props.visible ? 'translateY(0)' : 'translateY(-100%)'};
    transition: transform 1s cubic-bezier(.22, .82, .45, 1.04);
  }

  @media (max-height: 1000px) and (min-width: 661px) {
    padding: 125px 40px 0;
    overflow-y: scroll;
    display: block;
    height: auto;
  }

  @media (max-height: 750px) {
    display: block;
    height: auto;
    padding: 75px 0 0;
    overflow: auto;
  }

  @media (max-width: 660px) {
    display: block;
    -webkit-overflow-scrolling: touch;
  }
`

export const SuccessWrapper = styled(MainWrapper)`
  background-color: ${colors.rayloBlue};
  justify-content: center;


  ::-webkit-scrollbar {
	   width: 9px;
  }

  ::-webkit-scrollbar-track {
  	background: none;
  }

  ::-webkit-scrollbar-thumb {
  	background: ${colors.rayloPink};
  	border: 0;
  	box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  }

  scrollbar-color: ${colors.rayloPink} ${colors.rayloBlue};
  scrollbar-width: thin;

  @media (max-height: 750px) {
    padding-top: 75px;
    overflow-y: auto;
  }
`

export const OrderSuccess = styled.section`
  width: 40%;
  padding: 75px 20px 0 40px;
  background-color: ${colors.rayloBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 900px) {
    width: 50%;
    padding: 0 20px;
  }

  @media (max-width: 540px) {
    width: 100%;
    align-items: flex-start;
    padding: 100px 0 40px;
  }

  @media (max-height: 1024px) and (min-width: 541px) {
    justify-content: flex-end;
  }

  @media (max-height: 700px) and (min-width: 541px) {
    height: auto;
  }

  @media (max-height: 750px) {
    height: auto;
    padding-top: 40px;
    display: block;
  }
`

export const OrderSuccessDetails = styled.div`
  max-width: 350px;

  @media (max-height: 750px) and (min-width: 541px) {
    margin: 0 0 0 auto;
  }

  @media (max-height: 750px) and (max-width: 540px) {
    margin: 0 auto;
  }

  @media (max-width: 540px) {
    text-align: center;
    padding: 0 20px;
  }
`

export const OrderSuccessImage = styled.section`
  width: 40%;
  height: 100%;
  background-color: ${colors.rayloBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 75px 40px 40px 20px;

  @media (max-width: 900px) {
    width: 50%;
    padding: 0 20px;
  }

  @media (max-width: 540px) {
    width: 100%;
    padding: 20px 40px;
    justify-content: center;

    img {
      max-width: 100%;
    }
  }

  @media (max-height: 1024px) and (min-width: 541px) {
    justify-content: flex-start;
  }

  @media (max-height: 750px) and (min-width: 541px) {
    img {
      margin: 0 auto 40px 0;
    }
  }

  @media (max-height: 750px) and (max-width: 540px) {
    img {
      margin: 0 auto;
    }
  }

  @media (max-height: 750px) {
    height: auto;
    padding-top: 0;
    display: block;
  }
`

export const RayloBlockBackground = styled.section`
  position: absolute;
  top: 40px;
  right: 40px;
  bottom: 40px;
  width: 40%;

  @media (max-width: 1024px) {
    display: none;
  }
`

export const SignupFeedbackContainer = styled.div`
  padding: 75px 20px;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;

  // @media (min-width: 1025px) and (max-width: 1330px) {
  //   width: 100%;
  // }

  // @media (max-width: 1024px) {
  //   padding: 20px;
  // }
  // @media (max-width: 768px) {
  //   width: 90%;
  // }

  // @media (max-width: 540px) {
  //   width: 100%;
  //   max-width: unset;
  //   padding: 20px;
  // }
`

export const SignupAddressActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  @media (max-width: 374px) {
    flex-direction: column-reverse;
    margin-bottom: 20px;

    div {
      margin-bottom: 12px;
      margin-right: 0;
    }

    button {
      width: 100%;
    }
  }
`

export const SignupFeedbackActions = styled.div`
  display: flex;

  div {
    margin-left: 18px;
  }

  div:first-of-type {
    margin-left: 0px;
  }

  @media (max-width: 640px) {
    flex-direction: column;

    div {
      width: 100%;
      margin-left: 0px;
      margin-top: 18px;

      &:first-of-type {
        margin-top: 0px;
      }
    }
  }
`

export const TradeInActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -7px;
  div {
    margin: 0 18px 9px 0; 
  }
  div:last-of-type {
    margin-right: 0px;
  }
`

export const ConfigureWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
  width: 100%;
  height: 100%;

  @media (max-width: 540px) {
    align-items: flex-start;
    padding-top: 0;
  }

  @media (max-height: 750px) {
    height: auto;
  }
`

export const ConfigureImage = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 20px;

  @media (max-width: 1024px) {
    margin-left: -80px;
  }

  @media (max-width: 540px) {
    display: none;
  }
`

export const ConfigureForm = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 768px) {
    width: 70%;
  }

  @media (max-width: 540px) {
    width: 100%;
    height: auto;
    align-items: flex-start;
  }

  ::-webkit-scrollbar {
	   width: 9px;
  }

  ::-webkit-scrollbar-track {
  	background: none;
  }

  ::-webkit-scrollbar-thumb {
  	background: ${colors.rayloPink};
  	border: 0;
  	box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
    border-radius: 12px;
  }

  scrollbar-color: ${colors.rayloPink} ${colors.rayloBlue};
  scrollbar-width: thin;
`

export const RayloSection = styled.div`
  border-bottom: ${props => props.noBorder ? '0' : '1px solid #7277FF'};
  padding-bottom: 30px;
  margin-bottom: 20px;

  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .cell-lease {
    flex: 1;
    display: flex;
    justify-content: space-between;


    h5, p {
      flex: 1
    }

    h5 {
      @media (min-width: 1331px) {
        margin-right: 50px;
      }
      @media (max-width: 1330px) {
        padding-right: 20px;
      }
    }

  }

  .cell {
    flex: 1;

    @media (min-width: 1331px) {
      &:not(:last-of-type) {
        margin-right: 50px;
      }
    }

    @media (max-width: 1330px) {
      &:not(:last-of-type) {
        padding-right: 20px;
      }
    }

    @media (max-width: 1200px) and (min-width: 1025px) {
      margin-bottom: 20px;

      &:only-child {
        margin-bottom: 0;
      }
    }
  }
`

export const RayloInsurance = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #7277FF;

  .container {
    display: flex;
    align-items: center;
    justify-content: ${props => props.insuranceAdded ? 'flex-start' : 'space-between'};
  }
`

export const ConfigureSection = styled(RayloSection)`
  padding-bottom: 20px;
`
export const ConfigureSectionCols = styled.div `
  margin-bottom: 10px; 
  @media screen and (min-width: 400px) {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -10px;
    > div {
      margin: 5px 10px;
      whitespace: no-wrap;
      min-width: 150px;
    }
  }
`

export const DOBContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 540px) {
    flex-direction: column;
    align-items: stretch;
  }
`

export const DOBDate = styled.div`
  width: 30%;

  @media (max-width: 540px) {
    width: 100%;
  }
`

export const DOBMonth = styled.div`
  width: 30%;

  @media (max-width: 540px) {
    width: 100%;
  }
`

export const DOBYear = styled.div`
  width: 30%;

  @media (max-width: 540px) {
    width: 100%;
  }
`

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  text-align: center;
`

export const MarketingRadioButtons = styled.div`
  display: flex;

  @media (max-width: 540px) {
    margin-bottom: 36px;
  }
`


export const AcceptedCards = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid #434343;
  border-bottom: 1px solid #434343;
  margin-bottom: 24px;

  span {
    font-family: 'Raylo-Bold';
    font-weight: 700;
    font-size: 0.8125em;
    color: ${colors.rayloPink};
    line-height: 1.875em;
    display: inline-block;
    margin-right: 18px;
  }

  ul {
    margin: 0;
    padding: 0;
    & > * + * {
      margin-left: 16px;
    }

    li {
      display: inline-block;
    }
  }
`
export const TradeInBlock = styled.div`
  margin-bottom: 23px;
  p {
    margin-bottom: 16px;
  }
`

export const TradeInQuestion = styled.div`
  width: 100%;
  margin-bottom: 46px;
  p {
    margin-bottom: 16px;
  }
`

export const TrustPilotScore = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 14px;
  border-bottom: 1px solid #404040;
  margin-bottom: 46px;
`

export const InsuranceSignup = styled.div`
  margin-bottom: 60px;
  
  .intro {
    font-family: 'Raylo-Regular', Arial, sans-serif;
    font-weight: normal;
    font-size: 0.875em;
    color: ${colors.rayloLight};
    line-height: 1.85;
    margin-bottom: 40px;
  }

  .features {
    list-style-type: none;
    padding: 0;
    margin: 0 0 40px;

    li {
      display: flex;
      align-items: center;

      img {
        display: inline-block;
        flex-basis: 10%;
        margin-right: 12px;
      }

      span {
        flex-basis: 90%;
      }
    }
  }

  p, li {
    font-family: "Raylo-Regular", Arial, sans-serif;
    font-size: 0.875em;
    color: ${colors.rayloLight};
    line-height: 1.625em;
    margin-bottom: 18px;
  }
`




export const NewCheckoutWrapper = styled.div`
`
export const NewWrapper = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 75px 0;
  width: 50vw;
`
export const NewMain = styled.div`
  flex: 1;
`
export const NewContent = styled.div`
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
`
export const NewSidebar = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: ${props => props.showConfigure ? '100vw' : '50vw'};
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.rayloBlue};

  ::-webkit-scrollbar {
    width: 9px;
 }

 ::-webkit-scrollbar-track {
   background: none;
 }

 ::-webkit-scrollbar-thumb {
   background: ${colors.rayloPink};
   border: 0;
   box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
 }

 scrollbar-color: ${colors.rayloPink} ${colors.rayloBlue};
 scrollbar-width: thin;

`
export const NewFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50vw;
  z-index: 20;
`
export const NewHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 20;
  height: 75px;
  padding: 0 40px;
  background-color: transparent; // ${colors.rayloBlue};
  display: flex;
  cursor: pointer;
`
