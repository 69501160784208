import {useState, useEffect} from 'react'
import _ from 'lodash'

// NAVIGATION

import {Modal} from 'components/common/Modal'
import dayjs from 'dayjs'

import {AccountFooter} from 'components/Account/AccountFooter'
import {QL_CUSTOMER} from 'screens/Account/queries'
import useRayloQuery from 'utils/useRayloQuery'
import * as A from 'styles/account'

import {Helmet} from 'react-helmet'
import {YourDevices} from 'components/Account/YourDevices'
import Container from 'elements/Container'
import Header from 'elements/Header'
import {primaryColors} from 'styles/variables/colors'
import Copy from 'elements/Copy'
import Spacer from 'elements/Spacer'
import {removeSessionStorage} from 'utils/handleSessionStorage'
import {YourTechStatus} from './YourTechStatus'
import AddTechCarouselSection from 'components/AddTechCarouselSection'
import {QL_PRODUCTS} from 'screens/Upgrade/queries'
import {useHistory} from 'react-router'
import ReferAFriend from 'components/ReferAFriend'
import useRayloLazyQuery from 'utils/useRayloLazyQuery'
import Icon from "../../elements/Icon"
import useMedia from "use-media"
import {breakpoints} from 'styles/breakpoints'
import {px2Rem} from 'utils/px2Rem'
import {track} from 'helpers/analytics/segment'
import {checkInArrears} from 'helpers/subscriptions/checkInArrears'

export const parseOrder = (order) => {
  if (order.items && order.items.length > 0) {
    const orderItem = order.items[0]
    const {variant, pricePlan, fulfillmentItem, subscription} = orderItem

    let status = ""
    let dueOnIsInFuture, dueOnIsInPast, hasActiveCheckout, isInArrears, upgradeAvailable, dueOnDate

    if (subscription) {
      const {dueOn, eligible, checkout} = subscription.upgrade
      dueOnIsInFuture = dueOn && dayjs(dueOn).isBefore(dayjs().add(30, 'd')) && dayjs(dueOn).isAfter(dayjs())
      dueOnIsInPast = dueOn && dayjs(dueOn).isBefore(dayjs())
      hasActiveCheckout = checkout && checkout.token && checkout.state && ["STARTED", "ABANDONED"].includes(checkout.state)
      isInArrears = checkInArrears(subscription);
      upgradeAvailable = dueOnIsInPast && (eligible || hasActiveCheckout) && !isInArrears
      dueOnDate = dueOn
    }
    //TODO - update statuses dynamically

    if (isInArrears) {
      status = YourTechStatus.arrears
    } else if (subscription?.state === 'CANCELLED') {
      status = YourTechStatus.leaseCancelled
    } else if (upgradeAvailable) {
      status = YourTechStatus.upgradeAvailable
    } else if (subscription?.startedOn && dayjs(subscription.startedOn).isBefore(dayjs().subtract(14, 'd'))) {
      status = `${YourTechStatus.active} - Upgrade available from ${dayjs(dueOnDate).format('DD.MM.YY')}`
    } else if (order?.state === 'CANCELLED') {
      status = YourTechStatus.orderCancelled
    } else if (fulfillmentItem && fulfillmentItem.deliveredAt) {
      status = `${YourTechStatus.delivered} on ${dayjs(fulfillmentItem.deliveredAt).format('DD.MM.YY')}`
    } else if (fulfillmentItem && fulfillmentItem.dispatchedAt) {
      status = `${YourTechStatus.dispatched} on ${dayjs(fulfillmentItem.dispatchedAt).format('DD.MM.YY')}`
    } else if (fulfillmentItem && fulfillmentItem.expectedDeliveryAt) {
      status = YourTechStatus.pending
    } else {
      status = YourTechStatus.orderPlaced
    }

    return {
      id: orderItem.id,
      orderId: order.id,
      model: variant.product.displayName,
      image: _.find(variant.images, {tag: "Front"}),
      storage: _.find(variant.optionValues, o => o.optionType.slug === 'storage').displayName,
      color: _.find(variant.optionValues, o => o.optionType.slug === 'colour').displayName,
      payMonthly: orderItem.costSummary.recurring.totalAmount.valueAfterTax.value,
      payRecurring: orderItem.costSummary.recurring,
      payToday: orderItem.costSummary.initial.totalAmount.valueAfterTax.value,
      currency: pricePlan.currencyCode,
      insurance: pricePlan.includesInsurance,
      preOrder: variant.product.preOrder,
      subscription,
      orderItem,
      status
    }
  } else {
    return undefined
  }
}

export const Overview = () => {
  const [getProducts, {data: {products}, loading: productsLoading}] = useRayloLazyQuery(QL_PRODUCTS)
  const {data: {customer}, loading} = useRayloQuery(QL_CUSTOMER, {fetchPolicy: "network-only"})
  const [devices, setDevices] = useState([])
  const [allDevices, setAllDevices] = useState([])
  const [preApproved, setPreApproved] = useState(false)
  const [activeOrderIsPending, setActiveOrderIsPending] = useState(false)
  const [filteredProducts, setFilteredProducts] = useState(null)
  const [checkoutToken, setCheckoutToken] = useState()
  const [trackedAccountViewedEvent, setTrackedAccountViewedEvent] = useState(false)

  const history = useHistory()

  const handleViewAllTech = () => {
    history.push('/account/products')
  }

  const pendingStates = ['REFERRED', 'PENDING']

  useEffect(() => {
    if (customer && customer.orders) {
      let allPhones = []
      _.each(customer.orders, (order) => {
        const phone = parseOrder(order)
        if (phone) {
          allPhones.push(phone)
        }
      })
      setDevices(allPhones)
    }
    if (customer) {
      setPreApproved(!!customer.preApproval?.checkout || customer.preApproval?.successful)
      setActiveOrderIsPending(pendingStates.includes(customer?.preApproval?.checkout?.decision?.outcome))
    }
  }, [customer])

  useEffect(() => {
    removeSessionStorage('additionalTech')
    removeSessionStorage('checkoutToken')
  }, [])

  useEffect(() => {
    if (!loading) {
      setCheckoutToken((customer?.preApproval?.checkout?.token))
    }
  }, [loading])

  useEffect(() => {
    getProducts({variables: {checkoutToken: checkoutToken}})
  }, [checkoutToken])

  useEffect(() => {
    if (products) {
      setFilteredProducts(_.sortBy(_.filter(products, o => o.variantConditions.includes("NEW")), ["position"]).splice(0, 7))
    }
  }, [products])

  useEffect(() => {
    if (yourDevices.length > 0 && !trackedAccountViewedEvent) {
      track('My Account Viewed', {
        screen: 'account overview',
        in_arrears: hasDeviceInArrears() ? 'Yes' : 'No',
        when: 'on page load',
        url: window.location.href,
      })

      setTrackedAccountViewedEvent(true)
    }
  })

  useEffect(() => {
    const checkout = customer?.preApproval?.checkout
    const itemsInReferralQueue = checkout?.items
    if (activeOrderIsPending && !!itemsInReferralQueue) {
      const referredCheckoutItems = customer?.preApproval?.checkout?.items?.map(({variant}) => {
        return {
          model: variant?.displayName,
          image: _.find(variant?.images, {tag: "Front"}),
          status: "Pending further checks",
          storage: _.find(variant.optionValues, o => o.optionType.slug === 'storage').displayName,
          color: _.find(variant.optionValues, o => o.optionType.slug === 'colour').displayName,
          payMonthly: checkout?.costSummary?.recurring?.totalAmount?.valueAfterTax?.value
        }
      })
      setAllDevices([...devices, ...referredCheckoutItems])
    }
  }, [loading, devices, activeOrderIsPending])

  const yourDevices = !!allDevices.length ? allDevices : devices

  const isMobile = useMedia({maxWidth: breakpoints.mobile})

  const hasDeviceInArrears = () => {
    let inArrears = false
    yourDevices.some((device) => {
      if (checkInArrears(device.subscription)) {
        inArrears = true
      }
    })

    return inArrears
  }
  
  const headerText = () => {
    if (hasDeviceInArrears()) {
      return (
        <div>
          <Icon
            name={'WhiteAlert'}
            size={isMobile ? px2Rem(28) : px2Rem(24)}
            styles={`
            ${isMobile ? `` : `
            display: inline-block;
            vertical-align: sub;
            margin-right: ${px2Rem(12)};
            `}
          `}/>
          {isMobile ? <Spacer height={12}/> : null}
          <Copy fontSize={24} color="#fff" styles={`display: inline-block;`}>You need to make a payment</Copy>
        </div>
      )
    }
    return <Copy fontSize={24} color="#fff">Here is your account overview</Copy>
  }

  if (loading || devices?.length === 0 || productsLoading || products?.length === 0) return (
    <Modal visible text="Loading..."/>)

  return (
    <A.AccountContainer>
      <Helmet><title>Overview - Raylo</title></Helmet>
      <Container center backgroundColor={primaryColors.b01} styles={{
        textAlign: 'center',
        paddingBottom: 0,
        paddingTop: 0
      }}>
        <Spacer height={16}/>
        <Header level={2} color="#fff">Hi {customer?.firstName}</Header>
        <Spacer height={12}/>
        {headerText()}
        <Spacer height={24}/>
      </Container>
      <YourDevices phones={yourDevices} preApproved={preApproved} activeOrderIsPending={activeOrderIsPending}/>
      <Spacer height={8}/>
      {preApproved && !activeOrderIsPending &&
        <Container center padding="0">
          <Header level={2}>Your pre-approved tech</Header>
          <Spacer height={24}/>
          <AddTechCarouselSection products={filteredProducts} onClick={handleViewAllTech}
            checkoutToken={checkoutToken}/>
        </Container>
      }
      <Container padding="0" width="100vw" maxWidth="76.25rem" styles={{margin: "auto"}}>
        <ReferAFriend/>
      </Container>
      <AccountFooter/>
    </A.AccountContainer>
  )
}
