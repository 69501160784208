import styled from 'styled-components';
import { primaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';

const StyledOrderSummaryMobile = styled.div`
  background-color: ${primaryColors.w01};
  min-height: 100vh;
  width: 100%;
`;

const FeaturesWrapper = styled.div`
  padding: 0 ${px2Rem(24)};
`;

export { StyledOrderSummaryMobile, FeaturesWrapper };
