import { useEffect, useState } from 'react';
import constate from 'constate';
import useRayloQuery from 'utils/useRayloQuery';
import { GET_CHECKOUT_PRICE_PLANS } from 'screens/Checkout/graphQL/queries';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { getPricePlans } from 'utils/getPricePlans';
import { useMutation } from '@apollo/client';
import { MU_SWAP_CHECKOUT } from 'screens/Checkout/graphQL/mutations';

const useStepLeaseTerm = ({ onSuccess }: any) => {
  const [displayName, setDisplayName] = useState('');
  const [displayImage, setDisplayImage] = useState<string | undefined>();
  const [activeIndex, setActiveIndex] = useState<string | undefined>();
  const [pricePlans, setPricePlans] = useState<any>();
  const [itemId, setItemId] = useState<string | undefined>();
  const [variantId, setVariantId] = useState<string | undefined>();

  const { checkoutToken } = useCheckoutContext();

  const { data } = useRayloQuery(GET_CHECKOUT_PRICE_PLANS, {
    variables: {
      token: checkoutToken,
    },
    skip: !checkoutToken,
  });

  const [swapCheckout] = useMutation(MU_SWAP_CHECKOUT, {
    onCompleted() {
      onSuccess();
    },
  });

  useEffect(() => {
    if (data) {
      const name = data?.checkout?.items?.[0]?.variant?.displayName;
      const image = data?.checkout?.items?.[0]?.variant?.images?.[0]?.url;
      const plans = getPricePlans(data?.checkout);
      const id = data?.checkout?.items?.[0]?.id;
      const variant = data?.checkout?.items?.[0]?.variant?.id;
      setDisplayName(name);
      setDisplayImage(image);
      setPricePlans(plans);
      setItemId(id);
      setVariantId(variant);
    }
  }, [data]);

  useEffect(() => {
    if (pricePlans) {
      const optionValue =
        data?.checkout?.costSummary?.recurring?.totalAmount?.valueAfterTax
          ?.value;
      const activeOption = pricePlans.find(
        ({ monthlyPrice }: any) => monthlyPrice === optionValue,
      )?.id;
      setActiveIndex(activeOption);
    }
  }, [pricePlans]);

  const handleSubmit = () => {
    swapCheckout({
      variables: {
        variantId,
        pricePlanId: activeIndex,
        checkoutToken,
        itemId,
      },
    });
  };

  return {
    displayName,
    displayImage,
    pricePlans,
    activeIndex,
    setActiveIndex,
    handleSubmit,
  };
};

const [StepLeaseTermProvider, useStepLeaseTermContext] =
  constate(useStepLeaseTerm);
export { StepLeaseTermProvider, useStepLeaseTermContext };
