import gql from 'graphql-tag';
import { useState, useEffect } from 'react';
import { isAdditonalTech } from 'utils/handleGetSessionStorageValues';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import useRayloQuery from 'utils/useRayloQuery';

export enum CheckoutMode {
  UPGRADE = 'UPGRADE',
  ADDITIONAL_TECH = 'ADDITIONAL_TECH',
  NEW_CUSTOMER = 'NEW_CUSTOMER',
}

export const useGetCheckoutMode = () => {
  const { checkoutToken } = useCheckoutContext();
  const [newCustomer, setNewCustomer] = useState(false);
  const [checkoutMode, setCheckoutMode] = useState<CheckoutMode | undefined>(
    CheckoutMode.NEW_CUSTOMER,
  );

  const { data, loading } = useRayloQuery(
    gql`
      query CheckoutModeQuery($token: String!) {
        checkout(token: $token) {
          items {
            originSubscription {
              id
            }
          }
        }
      }
    `,
    {
      variables: {
        token: checkoutToken,
      },
    },
  );

  useEffect(() => {
    if (!loading) {
      const upgrade = !!data?.checkout?.items[0]?.originSubscription?.id;
      if (upgrade) {
        setCheckoutMode(CheckoutMode.UPGRADE);
      } else if (isAdditonalTech()) {
        setCheckoutMode(CheckoutMode.ADDITIONAL_TECH);
      } else {
        setCheckoutMode(CheckoutMode.NEW_CUSTOMER);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (checkoutMode === CheckoutMode.NEW_CUSTOMER) {
      setNewCustomer(true);
    }
  }, [checkoutMode]);

  return {
    newCustomer,
    checkoutMode,
    data,
  };
};
