import {useMutation} from "@apollo/client"
import {MU_TRACKING_CHECKOUT} from "../screens/Checkout/graphQL/mutations"
import {captureErrorEvent} from "./errorTracking"
import constate from "constate"

const useTrackCheckout = () => {
  const [trackCheckoutMutation] = useMutation(MU_TRACKING_CHECKOUT)

  const trackCheckout = (checkoutToken, trackingData, callback) => {
    trackCheckoutMutation({
        variables: {
          token: checkoutToken,
          inputs: trackingData
        },
        update: (proxy, { data: {trackCheckout: {success, errors}}}) => {
          if (!success) {
            captureErrorEvent("Error updating trackings", {trackings: trackingData, errors: errors})
          }
          callback()
        }
      }
    )
  }
  return { trackCheckout }
}
const [CheckoutTracker, useTrackCheckoutContext] = constate(useTrackCheckout)
export {CheckoutTracker, useTrackCheckoutContext}
