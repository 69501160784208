import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Icon from 'elements/Icon';
import { primaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { useUiContext } from 'utils/useUiContext';
import CheckoutNavBar from '../CheckoutNavBar';
import { CheckoutStepsComponent } from '../CheckoutSteps/CheckoutSteps';
import { StyledCheckoutHeader } from './styles';
import { ICheckoutHeader } from './types';

export const CheckoutHeader = ({
  dataTestId,
  title,
  condition,
}: ICheckoutHeader) => {
  const { showSummary, showConfigure, setShowConfigure, hideProgressBar } =
    useUiContext();

  const handleBack = () => setShowConfigure(false);
  const showProgressSteps = !showSummary && !hideProgressBar;

  return (
    <StyledCheckoutHeader data-testid={dataTestId}>
      {!showConfigure && (
        <>
          <CheckoutNavBar
            dataTestId="checkout-nav-bar"
            title={title}
            condition={condition}
          />
          {showProgressSteps && (
            <CheckoutStepsComponent dataTestId="chekout-steps-component" />
          )}
        </>
      )}
      {showConfigure && (
        <Container
          onClick={handleBack}
          cursorPointer
          flexRow
          padding={`${px2Rem(24)} ${px2Rem(16)}`}
          height={px2Rem(70)}
          backgroundColor={primaryColors.b01}
        >
          <Icon name="BackArrow" size={px2Rem(24)} color={primaryColors.w01} />
          <Container padding={`${px2Rem(2)} ${px2Rem(12)}`}>
            <Copy color={primaryColors.w01}>Back</Copy>
          </Container>
        </Container>
      )}
    </StyledCheckoutHeader>
  );
};
