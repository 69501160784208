export const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
export const AMAZON_VOUCHER_VALUE = '30';

export const GTM = process.env.REACT_APP_GTM;

export const AUTH_CONFIG = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
  realm: process.env.REACT_APP_AUTH0_REALM,
  stateToken: process.env.REACT_APP_AUTH0_STATE_TOKEN
}

export const KUDOS_CONFIG = {
  callbackUri: process.env.REACT_APP_CREDIT_KUDOS_CALLBACK_URI
}