import styled from 'styled-components';
import { primaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';

const StyledMobileCheckoutHeader = styled.div`
  & div {
    background-color: ${({ theme }) => theme?.checkoutHeader?.backgroundColor};
  }
  color: ${primaryColors.w01};
`;

const HeaderWrapper = styled.div``;
const ButtonWrapper = styled.div`
  padding-left: ${px2Rem(16)};
  & button {
    color: ${primaryColors.w01};
    border-color: ${primaryColors.w01};
  }
`;

export { StyledMobileCheckoutHeader, HeaderWrapper, ButtonWrapper };
