import gql from 'graphql-tag';

export const CUSTOMER_SUBSCRIPTIONS = gql`
  query CustomerSubscriptionsQuery {
    customer {
      subscriptions {
        id
        arrearsAmount {
          valueInSubunit
        }
      }
    }
  }
`;
