import { Switch, Route } from 'react-router-dom'
import * as Sentry from '@sentry/react';
import { SubscriptionUpgrades } from './SubscriptionUpgrades';
import { UpgradePhone } from './UpgradePhone';
import { CheckoutProvider } from 'utils/useCheckoutContext';

const SentryRoute = Sentry.withSentryRouting(Route);

export const UpgradeRouter = () => {
  return (
    <CheckoutProvider>
      <Switch>
        <SentryRoute path="/account/upgrade/:subscriptionId/:deviceName">
          <UpgradePhone />
        </SentryRoute>
        <SentryRoute path="/account/additional-tech/:deviceName">
          <UpgradePhone />
        </SentryRoute>
        <SentryRoute path="/account/upgrade/:subscriptionId">
          <SubscriptionUpgrades />
        </SentryRoute>
      </Switch>
    </CheckoutProvider>
  )
}