import { px2Rem } from 'utils/px2Rem';
import { secondaryColors } from 'styles/variables/colors';
import styled from 'styled-components';

const StyledHowItWorksCard = styled.div`
  border: solid 1px ${secondaryColors.c04};
`;

const StyledStepNumber = styled.div`
  height: ${px2Rem(32)};
  width: ${px2Rem(32)};
  border-radius: 50%;
  background-color: ${secondaryColors.b04};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { StyledHowItWorksCard, StyledStepNumber };
