import PreApproveBannerImage from 'public/pre-approval-account-homepage-banner.png';
import TabletPreApproveBannerImage from 'public/tablet-pre-approval-account-homepage-banner.png';
import { breakpoints } from 'styles/breakpoints';
import useMedia from 'use-media';
import PreApprovalBanner from '.';
import { AccountHomePageBannerWrapper } from './styles';
import { IPreApprovalBanner } from './types';

export const PreApprovalHomePageBanner = ({ hideCopy }: IPreApprovalBanner) => {
  const isTablet = useMedia({ maxWidth: breakpoints.desktop });
  return (
    <AccountHomePageBannerWrapper>
      <PreApprovalBanner
        hideCopy={hideCopy}
        bannerImage={
          isTablet ? TabletPreApproveBannerImage : PreApproveBannerImage
        }
        bannerImageSize={isTablet ? 'contain' : 'initial'}
        isAccountHomePage
        headerCopy="Get the latest"
        headerSubCopy="pre-approved tech"
        headerCopy2="for you and your family"
        labelOnRight
      />
    </AccountHomePageBannerWrapper>
  );
};
