import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { track } from 'helpers/analytics/segment';
import * as A from '../../../../styles/account';
import { PaymentProcessing } from './PaymentProcessing';
import { PaymentStatus } from './PaymentStatus.enum';
import { PaymentSuccessful } from './PaymentSuccessful';
import { PaymentFailed } from './PaymentFailed';
import { PROCESS_INCOMING_PAYMENT_MUTATION } from '../../../../graphql/mutations/incomingPayment/processIncomingPaymentMutation.graphql';
import { CUSTOMER_INCOMING_PAYMENTS } from '../../../../graphql/queries/customer/incomingPayment/incomingPayments.graphql';
import { IncomingPaymentStatus } from '../../../../graphql/mutations/incomingPayment/incomingPaymentStatuses.enum';

export const ArrearsPaymentStatusScreen = () => {
  const [paymentStatus, setPaymentStatus] = useState(PaymentStatus.Initial);
  const [trackedAccountViewedEvent, setTrackedAccountViewedEvent] =
    useState(false);

  const { incomingPaymentId } = useParams();

  const [processIncomingPayment] = useMutation(
    PROCESS_INCOMING_PAYMENT_MUTATION,
    {
      variables: {
        incomingPaymentId,
      },
    },
  );

  const customerIncomingPaymentsQuery = useQuery(CUSTOMER_INCOMING_PAYMENTS, {
    pollInterval: 1000,
    onCompleted(data: any) {
      const subscription = data.customer?.subscriptions.find((s: any) =>
        s.incomingPayments.find((incomingPayment: any) => {
          return incomingPayment.id === incomingPaymentId;
        }),
      );
      const incomingPaymentState = subscription?.incomingPayments.find(
        (incomingPayment: any) => incomingPayment.id === incomingPaymentId,
      )?.state;
      if (
        incomingPaymentState === IncomingPaymentStatus.Cancelled ||
        incomingPaymentState === IncomingPaymentStatus.Declined
      ) {
        customerIncomingPaymentsQuery.stopPolling();
        if (!trackedAccountViewedEvent) {
          track('My Account Viewed', {
            screen: 'payment failure',
            when: 'on page load',
            url: window.location.href,
          });
        }
        setTrackedAccountViewedEvent(true);
        setPaymentStatus(PaymentStatus.Failed);
      }
      if (incomingPaymentState === IncomingPaymentStatus.Accepted) {
        customerIncomingPaymentsQuery.stopPolling();
        if (!trackedAccountViewedEvent) {
          track('My Account Viewed', {
            screen: 'payment success',
            when: 'on page load',
            url: window.location.href,
          });
        }
        setTrackedAccountViewedEvent(true);
        setPaymentStatus(PaymentStatus.Successful);
      }
    },
  });

  useEffect(() => {
    processIncomingPayment();
  }, []);

  const renderPaymentStatus = () => {
    switch (paymentStatus) {
      case PaymentStatus.Successful: {
        return <PaymentSuccessful />;
      }
      case PaymentStatus.Failed: {
        return <PaymentFailed />;
      }
      default: {
        return <PaymentProcessing />;
      }
    }
  };

  return <A.AccountContainer>{renderPaymentStatus()}</A.AccountContainer>;
};
