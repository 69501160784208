import { terms } from './terms';
import { checkout } from './checkout';
import { button } from './buttons';
import { checkoutHeader } from './checkoutHeader';
import { typography } from './typography';
import { form } from './form';
import { storyBook } from './storyBook';
import { components } from './components';

const rayloDark = {
  button,
  checkoutHeader,
  typography,
  form,
  storyBook,
  components,
  checkout,
  terms,
};

export { rayloDark };
