import { breakpoints } from 'styles/breakpoints';
import styled from 'styled-components';

const StyledCheckoutHeader = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 50vw;
  z-index: 1000;

  @media (max-width: ${breakpoints.desktop}px) {
    width: 100vw;
  }
`;

export { StyledCheckoutHeader };
