import { createElement } from 'react';
import { StyledHeaderContent } from './styles';
import { IHeader } from './types';

const BaseHeader = ({ level, children }: IHeader) => {
  return createElement(
    `h${level}`,
    {
      'data-testid': 'header',
    },
    children,
  );
};

const Header = ({
  children,
  color,
  subColor,
  fontSize,
  lineHeight,
  fontWeight,
  dataTestId,
  level,
  subFontWeight,
  centered,
  style,
}: IHeader) => {
  return (
    <BaseHeader level={level || 1}>
      <StyledHeaderContent
        color={color}
        subColor={subColor}
        fontSize={fontSize}
        lineHeight={lineHeight}
        fontWeight={fontWeight}
        data-testid={dataTestId}
        subFontWeight={subFontWeight}
        centered={centered}
        style={style}
      >
        {children}
      </StyledHeaderContent>
    </BaseHeader>
  );
};

export default Header;
