/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Currency from 'react-currency-formatter';
import { activateOptimize } from 'utils/optimize'

// HOOKS
import useFormal from "@kevinwolf/formal-web";
import { useMutation } from '@apollo/client';

// STATE
import { useUiContext } from 'utils/useUiContext';

// GRAPHQL
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';

// VISUAL COMPONENTS
import * as T from 'styles/type';
import * as I from 'styles/inputs';
import * as L from 'styles/layout';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { TextInput } from 'components/common/TextInput';
import { Modal } from 'components/common/Modal';
import { BackForwardGroup } from 'components/common/BackForwardGroup';
import { Button } from 'components/common/Button';
import { BackButton } from 'components/common/BackButton';
import { CheckboxInput } from 'components/common/CheckboxInput';
import { DDModal } from 'components/Checkout/DDModal';
import { ContactModal } from 'components/Checkout/ContactModal';

import { DirectDebit } from 'screens/Feedback/DirectDebit'

import directDebitLogo from 'public/direct_debit_logo.png';


// FORM VALIDATION
import * as yup from "yup";
import useRayloQuery from 'utils/useRayloQuery'
import { useHistory } from 'react-router';
import {useSegmentIdentifyAndCheckoutTracking} from "hooks/segment/segment.hooks"

const schema = yup.object().shape({
  bankAccountName: yup.string().required("Please enter your account name").matches(/^[a-zA-Z'.\- ]+$/, "Characters accepted: A-Z and simple punctuation").nullable(),
  bankAccountNumber: yup.string().required("Please enter a valid account number").max(8,"This should be 8 numbers long").min(7,"This should be 8 numbers long").nullable(),
  bankBranchIdentifier: yup.string().required("Please enter a valid sort code").length(6,"This should be 6 numbers long").nullable(),
  directDebitMandateAuthorised: yup.boolean().required("Please agree to the Direct Debit Mandate")
});

export const StepBankNewCustomer = ({onSuccess, onBack, checkoutToken, slug}) => {
  useSegmentIdentifyAndCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'bank info',
    checkoutScreen: 'add bank details',
    when: 'on page load'
  });

  // HOOKS
  const history = useHistory()
  const [formErrors, setFormErrors] = useState(null)

  const [mode, setMode] = useState("standard")

  const [updateMutation] = useMutation(CheckoutSteps.stepBank.mutation, {
    update: (proxy, {data: {updateCheckout: { checkout, errors }}}) => {
      setIsSubmitting(false)
      setShowModal(false)

      if(errors && errors.length > 0) {
        updateInputModeAndSlug("edit")
        setFormErrors(errors)
      } else {
        // updateInputModeAndSlug("view")
        setFormErrors(null)
        onSuccess()
        
      }
    }
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showFields] = useState(true)
  const { setShowFeedback, setShowModal } = useUiContext()
  const [bankError, setBankError] = useState(false)
  const [inputMode, setInputMode] = useState("edit")
  const [showDDModal, setShowDDModal] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)
  
  const { data: { checkout }, loading } = useRayloQuery(CheckoutSteps.stepBank.query, {
    variables: {
      token: checkoutToken
    }
  })

  useEffect(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }, [inputMode])

  useEffect(() => {
    activateOptimize()
  })

  // HANDLE ERRORS
  useEffect(() => {
    if(formErrors) {
      let errors = {}
      _.each(formErrors, error => {
        switch(error.field) {
          case 'base': 
            errors.base = error.message
            break;
          case "payment_info.bank_account_number" :
            if(error.code === "bank_account_verification_failed") {
              setBankError(true)
            } else {
              errors.bankAccountNumber = error.message
            }
            break;
          case "payment_info.bank_branch_identifier" :
            errors.bankBranchIdentifier = error.message
            break;
          case "payment_info.bank_account_name" :
            errors.bankAccountName = error.message
            break;
          case "payment_info.direct_debit_mandate_authorised" :
            errors.directDebitMandateAuthorised = error.message
            break;
          default:
      }})
      formal.setErrors(errors)
    } else {
      formal.clearErrors()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors])

  useEffect(() => {
    try {
      if (checkout.paymentInfo.bankAccountNumber.length > 4) {
        updateInputModeAndSlug("view")
      } else {
      }
    } catch(e) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout])

  function handleChange(e) {
    const fs = window.location.pathname.split("/")[4]
    if (fs === 'view' || fs === 'check') {
      setInputMode(fs)
    } else {
      setInputMode('edit')
    }
  }

  useEffect(() => {
    window.addEventListener('popstate', handleChange)
    return () => window.removeEventListener('popstate', handleChange)
  }, [])

  const updateInputModeAndSlug = (newInputMode) => {
    setInputMode(newInputMode)

    if (newInputMode === 'edit') {
      history.push(`/checkout/step/${slug}`)
    } else if (newInputMode === 'view') {
      history.push(`/checkout/step/${slug}/view`)
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'checkout-step',
        'step': slug,
        'substep': 'view'
      })
    } else if (newInputMode === 'check') {
      history.push(`/checkout/step/${slug}/check`)
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'checkout-step',
        'step': slug,
        'substep': 'check'
      })
    }
  }

  const [initialValues, setInitialValues] = useState({ 
    bankAccountName: "",
    bankAccountNumber: "",
    bankBranchIdentifier: "",
    directDebitMandateAuthorised: false
  })

  // FORM HANDLER
  const formal = useFormal(initialValues, {
    schema: schema,
    onSubmit: values => {
      if(mode === "upgrade") {
        commit()
      } else {
        updateInputModeAndSlug("check")
      }
    }
  })

  useEffect(() => {
    if(bankError) {
      setShowFeedback(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankError])

  useEffect(() => {
    if(checkout && 
      checkout.paymentInfo && 
      checkout.paymentInfo.bankAccountName) {
      setInitialValues(_.merge(
        initialValues,
        _.pick(checkout.paymentInfo, 'bankAccountName', 'bankAccountNumber', 'bankBranchIdentifier','directDebitMandateAuthorised')
      ))
    } else if(checkout && 
        checkout.items && 
        checkout.items[0] && 
        checkout.items[0].originSubscription &&
        checkout.items[0].originSubscription.paymentInfo
      ) {
      setMode("upgrade")
      setInputMode("check")
      setInitialValues(_.merge(
        initialValues,
        _.pick(checkout.items[0].originSubscription.paymentInfo, 'bankAccountName', 'bankAccountNumber', 'bankBranchIdentifier')
      ))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout])

  if(loading) return (<Modal visible text="Loading..." />)

  const commit = () => {

    if(inputMode === "view") {
      onSuccess()
    } else {

      setIsSubmitting(true)
      setShowModal(true)

      const { values } = formal
      updateMutation({
        variables: {
          ...values,
          directDebitMandateAuthorised: values.directDebitMandateAuthorised ? true : false,
          checkoutToken
        }
      })
    }

  }

  const changeDetails = () => {
    setMode("standard")
    updateInputModeAndSlug("edit")
  }

  const resetError = () => {
    setBankError(false)
    setShowFeedback(false)
  }

  const showModal = () => {
    setShowDDModal(true)
  }

  const hideModal = () => {
    setShowDDModal(false)
  }

  const showContact= () => {
    setShowContactModal(true)
  }

  const hideContact = () => {
    setShowContactModal(false)
  }

  if(bankError) {
    return <DirectDebit onBack={() => resetError()}  />
  }

  const pricePlan = checkout.items[0].pricePlan

  return (
    <div className="checkout--step">
      {showDDModal &&
        <DDModal visible={showDDModal} onBack={hideModal} />
      }
      {showContactModal &&
        <ContactModal visible={showContactModal} onBack={hideContact} />
      }

      <p>{mode} {inputMode}</p>

      {mode === "upgrade" && inputMode !== "upgrade" ? 

        <FadeIn visible={showFields}>
          <T.RayloBodyHeading>
            Bank details
          </T.RayloBodyHeading>
        </FadeIn>

      : inputMode === "check" ? 
        
        <FadeIn visible={showFields}>
          <div>
            <T.RayloSectionHeader hasSubHeading={true}>Bank details</T.RayloSectionHeader>
            <T.RayloSubHeading style={{ marginBottom: 10 }}>
              We'll setup the Direct Debit after you have placed an order and the product has been dispatched.
            </T.RayloSubHeading>
            <T.RayloSubHeading>
              This will be no later than 10 working days before the first Direct Debit payment is collected. A confirmation will be sent by email.
            </T.RayloSubHeading>
          </div>
        </FadeIn>
      :
        <FadeIn visible={showFields}>
          <div>
            {mode === "upgrade" ? 
              <T.RayloBodyHeading>Direct Debit Confirmation</T.RayloBodyHeading> 
              :
              <T.RayloSectionHeader hasSubHeading={true}>Bank Details</T.RayloSectionHeader>
            }
            <T.RayloSubHeading>
            <strong>Soft search</strong><br />
            The benefit of a soft search is there's no impact on your credit score. At Raylo, only if we say "Yes" and approve your application will a full credit search be undertaken.
            </T.RayloSubHeading>
          </div>
        </FadeIn>
      }

      {inputMode !== "edit" && (
        <SlideDownFadeIn visible={showFields}>
          <I.RayloFormRow>
          {inputMode !== "upgrade" && <I.RayloFieldHeader>
              <I.RayloFieldLabel isFocused={true}>Are the details correct?</I.RayloFieldLabel>
            </I.RayloFieldHeader>}
            <I.RayloFieldContent>
              <T.RayloAddress>Account Name: {formal.values.bankAccountName}<br/></T.RayloAddress>
              <T.RayloAddress>Account Number: {formal.values.bankAccountNumber}<br/></T.RayloAddress>
              <T.RayloAddress>Sort Code: {formal.values.bankBranchIdentifier}<br/></T.RayloAddress>
            </I.RayloFieldContent>
          </I.RayloFormRow>
        </SlideDownFadeIn>
      )}

      {inputMode !== "edit" && inputMode !== "upgrade" && (
        <>
          <SlideDownFadeIn visible={showFields}>
            <L.SignupAddressActions>
              <div>
                <Button
                  buttonStyle="secondary"
                  limitWidth="true"
                  onClick={() => changeDetails() }>No, let me change them</Button>
              </div>
              {mode === "upgrade" ? 
                <div>
                  <Button
                    buttonStyle="primaryBlue"
                    onClick={() => setInputMode("upgrade") } >Yes</Button>
                </div>
              : 
                <div>
                  <Button
                    buttonStyle="primaryBlue"
                    onClick={() => commit() } >Yes</Button>
                </div>
              }
            </L.SignupAddressActions>
          </SlideDownFadeIn>

          <SlideDownFadeIn visible={showFields}>
            <div>
              <BackButton onClick={onBack} />
            </div>
          </SlideDownFadeIn>
        </>
      )}

      { (inputMode === "edit" || inputMode === "upgrade") && (
        <form {...formal.getFormProps()}>

          {inputMode === "edit" && (
            <>
            <SlideDownFadeIn visible={showFields}>
              <TextInput
                label="Name of Account Holder(s)"
                mask={{
                  mask: /^([a-zA-Z0-9\ \-\'\,])+$/,
                }}
                autoComplete="my-bank-field"
                field={formal.getFieldProps('bankAccountName')}
                errorMessage={formal.errors.bankAccountName}
              />
            </SlideDownFadeIn>


            <SlideDownFadeIn visible={showFields}>
              <TextInput
                label="Account Number"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                mask={{
                  mask: '00000000'
                }}
                field={formal.getFieldProps('bankAccountNumber')}
                errorMessage={formal.errors.bankAccountNumber}
              />
            </SlideDownFadeIn>

            <SlideDownFadeIn visible={showFields}>
              <TextInput
                label="Sort Code"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                mask={{
                  mask: '000000'
                }}
                field={formal.getFieldProps('bankBranchIdentifier')}
                errorMessage={formal.errors.bankBranchIdentifier}
              />
              
            </SlideDownFadeIn>
            <SlideDownFadeIn><div style={{ marginBottom: 20 }}>
              <p style={{ fontSize: '0.875em', lineHeight: 1.625 }}><T.RayloLink onClick={showContact}>Contact us if you have any questions</T.RayloLink></p></div>
            </SlideDownFadeIn>
          </>)}

          <SlideDownFadeIn>
            <div style={{ marginBottom: 40 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CheckboxInput
                  checked={formal.values.directDebitMandateAuthorised}
                  field={formal.getFieldProps('directDebitMandateAuthorised')}
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={{ fontSize: '0.875em', color: '#FFF', lineHeight: 1.625, marginLeft: 18, maxWidth: 300 }}>
                    I confirm I am the account holder and the only person required to authorised Direct Debits on this account and I agree to the <T.RayloLink inline onClick={showModal}>Direct Debit Guarantee</T.RayloLink>
                  </p>

                  <img src={directDebitLogo} alt="The Direct Debit Guarantee" style={{ paddingLeft: 8 }} />
                </div>
              </div>
              <div>
                <I.RayloError visible={true}>{formal.errors.directDebitMandateAuthorised || <span>&nbsp;</span>}</I.RayloError>
              </div>
            </div>
          </SlideDownFadeIn>

          {formal.errors.base && <FadeIn visible={showFields}><div style={{marginBottom: 48}}>
            <I.RayloError visible={formal.errors.base}>
              {formal.errors.base || <span>&nbsp;</span>}
            </I.RayloError></div>
          </FadeIn>}

          <SlideDownFadeIn visible={showFields}>
            <BackForwardGroup
              submit={
                {
                  ...formal.getSubmitButtonProps(),
                  disabled: isSubmitting
                }}
              buttonText={mode === "upgrade" ? "Next" : "Review"}
              onBack={onBack}/>
          </SlideDownFadeIn>
        </form>
      )}
    </div>
  )
}
