import { useState, useEffect, useRef } from 'react';
import { SlideDownFadeIn } from 'components/animations/Transitions';
import FormErrorMessages from 'components/FormErrorMessages';
import Container from 'elements/Container';
import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import CheckBox from 'elements/CheckBox';
import Copy from 'elements/Copy';
import Grid from 'elements/Grid';
import Spacer from 'elements/Spacer';
import TextLink from 'elements/TextLink';
import { px2Rem } from 'utils/px2Rem';
import { useStepSignContext } from 'screens/Checkout/containers/StepSign/hooks/useStepSignContext';
import { useOnScreen } from 'styles/useOnScreen';
import { useUiContext } from 'utils/useUiContext';
import {
  primaryColors,
  secondaryColors,
  uiStateColors,
} from 'styles/variables/colors';
import { ITermsAndConditions } from './types';
import { StyledTermsAndConditions } from './styles';

export const TermsAndConditions = ({
  dataTestId,
  children,
}: ITermsAndConditions) => {
  const [optInError, setOptInError] = useState(false);
  const [optInFalse, setOptInFalse] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptTradeInTerms, setAcceptTradeInTerms] = useState(false);
  const [submitReady, setSubmitReady] = useState(false);
  const [hasRead, setHasRead] = useState(false);
  const [elementHeight, setElementHeight] = useState(px2Rem(350));

  const {
    formErrors,
    tradeInExists,
    optInTrue,
    handleSubmitCheckout,
    setOptInTrue,
  } = useStepSignContext();
  const { handleShowTradeInTermsModal } = useUiContext();
  const ref: any = useRef();
  const scrolled = useOnScreen(ref, '-10px');

  const handleOptIn = (value: string) => {
    if (value === 'in') {
      setOptInFalse(false);
      setOptInTrue(true);
    } else {
      setOptInFalse(true);
      setOptInTrue(false);
    }
  };

  useEffect(() => {
    if (scrolled) {
      setHasRead(true);
      setElementHeight('180px');
    }
  }, [scrolled]);

  useEffect(() => {
    if (tradeInExists) {
      if (hasRead && acceptTerms && acceptTradeInTerms) {
        setSubmitReady(true);
      }
      if (!acceptTerms || !acceptTradeInTerms) {
        setSubmitReady(false);
      }
    } else {
      if (hasRead && acceptTerms) {
        setSubmitReady(true);
      }
      if (!acceptTerms) {
        setSubmitReady(false);
      }
    }
  }, [scrolled, acceptTerms, acceptTradeInTerms]);

  useEffect(() => {
    if (optInTrue || optInFalse) {
      setOptInError(false);
    }
  }, [optInTrue, optInFalse]);

  const handleSubmit = () => {
    handleSubmitCheckout();
  };

  return (
    <Container dataTestId={dataTestId} padding="0">
      <Container backgroundColor={secondaryColors.c05}>
        <StyledTermsAndConditions
          height={elementHeight}
          scrolled={scrolled}
          data-testid="termsAndConditions"
        >
          {scrolled && <div style={{ marginTop: '-180px' }} />}
          {children}
          <button
            ref={ref}
            style={{ opacity: '0' }}
            data-testid="termsBottom"
            onClick={() => setHasRead(true)}
            type="button"
          >
            Bottom
          </button>
        </StyledTermsAndConditions>
      </Container>
      {!hasRead ? (
        <Container center>
          <Copy color={primaryColors.c01} fontSize={16}>
            Scroll to the bottom to accept
          </Copy>
        </Container>
      ) : (
        <SlideDownFadeIn>
          <Container noPadding>
            <Container padding="0">
              <Spacer height={24} />
              <Copy color={primaryColors.c01} lineHeight={22}>
                Would you like to opt into marketing emails? <br />
                We’ll only send you things we think are useful, promise.
              </Copy>
              <Spacer height={16} />
              <Container padding="0">
                <Grid>
                  <Container padding="0" width="50%">
                    <CheckBox
                      dataTestId="marketingOptOut"
                      id="testCheck"
                      label="No"
                      onClick={() => handleOptIn('out')}
                      active={optInFalse}
                    />
                  </Container>
                  <Container padding="0" width="50%">
                    <CheckBox
                      id="testCheck"
                      label="Yes"
                      onClick={() => handleOptIn('in')}
                      active={optInTrue}
                    />
                  </Container>
                </Grid>
                <Spacer height={12} />
                {optInError && (
                  <Copy color={uiStateColors.error} fontSize={12}>
                    Please select an option
                  </Copy>
                )}
              </Container>
            </Container>
            <Spacer height={16} />
            <Container padding="0">
              <CheckBox
                dataTestId="acceptTerms"
                id="testCheck"
                label="I accept the Subscription T&Cs and agree to a credit check"
                onChange={() => {}}
                onClick={() => setAcceptTerms(!acceptTerms)}
                active={acceptTerms}
              />
            </Container>
            <Spacer height={16} />
            {tradeInExists && (
              <Container padding="0" flexRow>
                <Container noPadding width={px2Rem(36)}>
                  <CheckBox
                    dataTestId="acceptTradeIn"
                    id="testCheck"
                    onChange={() => {}}
                    onClick={() => setAcceptTradeInTerms(!acceptTradeInTerms)}
                    active={acceptTradeInTerms}
                  />
                </Container>
                <Container noPadding width="90%">
                  <Copy>
                    I accept the{' '}
                    <TextLink onClick={handleShowTradeInTermsModal}>
                      Trade-in T&amp;Cs
                    </TextLink>
                  </Copy>
                </Container>
              </Container>
            )}
          </Container>
        </SlideDownFadeIn>
      )}
      <FormErrorMessages formErrors={formErrors} />
      <Spacer height={tradeInExists ? 64 : 32} />
      <Button
        dataTestId="placeOrder"
        disabled={!submitReady}
        size="large"
        fullWidth
        text="place order"
        buttonColor={ButtonTypes.b01}
        onClick={handleSubmit}
      />
    </Container>
  );
};
