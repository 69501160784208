import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { activateOptimize } from 'utils/optimize'

// HOOKS
import useFormal from "@kevinwolf/formal-web";
import { useMutation } from '@apollo/client';
import useForceUpdate from 'use-force-update';
import useRayloQuery from 'utils/useRayloQuery'

// STATE
import { useUiContext } from 'utils/useUiContext';

// GRAPHQL
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';

// VISUAL COMPONENTS
import * as L from 'styles/layout';
import * as T from 'styles/type';
import * as I from 'styles/inputs';
import { RadioButton } from 'components/common/RadioButton';
import { Modal } from 'components/common/Modal';
import { BackForwardGroup } from 'components/common/BackForwardGroup';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';

import { Age } from 'screens/Feedback/Age'
import { Location } from 'screens/Feedback/Location'
import {RayloSelect} from 'components/common/RayloSelect';

// FORM VALIDATION
import * as yup from "yup";
import { TextInput } from 'components/common/TextInput';
import { MONTHS } from 'components/Checkout/DOBGroup'
import {useSegmentIdentifyAndCheckoutTracking} from "hooks/segment/segment.hooks"

const schema = yup.object().shape({
  dayOfBirth: yup.string().required("Please enter your date of birth").nullable().default(""),
  monthOfBirth: yup.string().required("Please enter your date of birth").nullable().default(""),
  yearOfBirth: yup.string().required("Please enter your date of birth").nullable().default(""),
  country: yup.string().required("Please state where you live").nullable(),
});

export const StepAgeCountry = ({checkoutToken, onSuccess, onBack}) => {
  useSegmentIdentifyAndCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'your details',
    checkoutScreen: 'add date of birth',
    when: 'on page load'
  });

  // HOOKS
  const [formErrors, setFormErrors] = useState(null)
  const [updateMutation] = useMutation(CheckoutSteps.step2.mutation, {
    update: (proxy, {data: {updateCheckout: { errors }}}) => {
      if(errors && errors.length > 0) {
        setIsSubmitting(false)
        setFormErrors(errors)
      } else {
        setShowFields(false)
        //setTimeout(() => {
          setFormErrors(null)
          onSuccess()
        //}, 800)
      }
    }
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { setShowFeedback } = useUiContext()
  const [showFields, setShowFields] = useState(true)
  const [countryError, setCountryError] = useState(false)
  const [ageError, setAgeError] = useState(false)
  const [dateOfBirth, setDateOfBirth] = useState()
  const [isFocused, setIsFocused] = useState(false)
  const forceUpdate = useForceUpdate()
  const [initialValues, setInitialValues] = useState({
    country: "",
    dayOfBirth: "",
    monthOfBirth: "",
    yearOfBirth: ""
  })

  const { data: { checkout }, loading } = useRayloQuery(CheckoutSteps.step2.query, {
    variables: {
      token: checkoutToken
    }
  })

  useEffect(() => {
    activateOptimize()
  })

  // HANDLE ERRORS
  useEffect(() => {
    if(formErrors) {
      let errors = {}
      _.each(formErrors, error => {
        switch(error.field) {
          case 'base' :
            errors.base = error.message
            break;
          case "address.country_iso_alpha2" :
            errors.country = error.message
            if(error.code === "unsupported") {
              setCountryError(true)
            }
            break;
          case "customer_info.date_of_birth" :
            errors.dayOfBirth = error.message
            errors.monthOfBirth = error.message
            errors.yearOfBirth = error.message
            if(error.code === "on_or_before") {
              setAgeError(true)
            }
            break;
          default:
      }})
      formal.setErrors(errors)
    } else {
      formal.clearErrors()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors])

  useEffect(() => {
    if(checkout) {

      let newValues = {
        country: (checkout.address?.country?.isoAlpha2) || ""
      }

      let date = dayjs((checkout.customerInfo?.dateOfBirth) || null)
      if(date.isValid()) {

        newValues = {
          ...newValues,
          dayOfBirth: date.format('DD') || "",
          monthOfBirth: date.format('MM') ? parseInt(date.format('MM')) : "",
          yearOfBirth: date.format('YYYY') || "",
        }
      }
      setInitialValues(_.merge(
        initialValues,
        newValues
      ))

      forceUpdate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout])

  // FORM HANDLER
  const formal = useFormal(initialValues, {
    schema,
    onSubmit: values => {
      setIsSubmitting(true)
      const dateOfBirth = `${values.yearOfBirth}-${values.monthOfBirth}-${values.dayOfBirth}`;
      setDateOfBirth(dateOfBirth)

      updateMutation({
        variables: {
          dateOfBirth,
          countryIsoAlpha2: values.country,
          checkoutToken: checkout.token
        }
      })
    }
  })

  const resetError = () => {
    setAgeError(false)
    setCountryError(false)
    setShowFeedback(false)
  }

  useEffect(() => {
    if(ageError || countryError) {
      setShowFeedback(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ageError, countryError])

  if(ageError) {
    return <Age onBack={() => resetError()} onOK={() => {
      window.location = process.env.REACT_APP_FRONTEND_URL
    }} dateOfBirth={dateOfBirth} />
  }
  if(countryError) {
    return <Location onBack={() => resetError()} onOK={() => {
      window.location = process.env.REACT_APP_FRONTEND_URL
    }} />
  }

  if(loading) return (<Modal visible text="Loading..." />)

  return (
    <div className="checkout--step">
      <FadeIn visible={showFields}>
        <T.RayloSectionHeader >Your Details</T.RayloSectionHeader>
      </FadeIn>

      <form {...formal.getFormProps()}>
        <SlideDownFadeIn visible={showFields}>
          <I.RayloFormRow onBlur={() => setIsFocused(false)} onFocus={() => setIsFocused(true)}>
            <I.RayloFieldLabelFake isFocused={isFocused}>
              What is your date of <span>bi</span>rth?
            </I.RayloFieldLabelFake>
            <L.DOBContainer>
              <L.DOBDate>
                <TextInput
                  field={formal.getFieldProps('dayOfBirth')}
                  type="text"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  mask={{
                    mask: '00'
                  }}
                  autoComplete="my-day-of-birth"
                  placeholder="DD"
                  noError={true}
                />
              </L.DOBDate>
              <L.DOBMonth>

                <RayloSelect
                  options={MONTHS}
                  placeholder="Month"
                  field={formal.getFieldProps('monthOfBirth')}
                  noError={true}
                />
              </L.DOBMonth>
              <L.DOBYear>
                <TextInput
                  field={formal.getFieldProps('yearOfBirth')}
                  type="text"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  mask={{
                    mask: '0000'
                  }}
                  autoComplete="my-year-of-birth"
                  placeholder="YYYY"
                  noError={true} />
              </L.DOBYear>
            </L.DOBContainer>
            <I.RayloError visible={formal.errors.dayOfBirth || formal.errors.monthOfBirth || formal.errors.yearOfBirth}>
              {formal.errors.dayOfBirth || formal.errors.monthOfBirth || formal.errors.yearOfBirth || <span>&nbsp;</span>}
            </I.RayloError>
          </I.RayloFormRow>
        </SlideDownFadeIn>

        <SlideDownFadeIn visible={showFields}>
          <I.RayloFormRow style={{ marginBottom: 20 }}>
            <I.RayloFieldHeader>
              <I.RayloFieldLabel isFocused={isFocused}>
                Do you live in the UK?
              </I.RayloFieldLabel>
            </I.RayloFieldHeader>

            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: 20 }}>
                <RadioButton type="country" id="countrySelectFalse" name="countrySelect" value="XX" label="No" checked={formal.values.country === "XX"} onClick={() => formal.change('country', "XX")} />
              </div>
              <div>
                <RadioButton type="country" id="countrySelectTrue" name="countrySelect" value="GB" label="Yes" checked={formal.values.country === "GB"} onClick={() => formal.change('country', "GB")} />
              </div>
            </div>
            <I.RayloError visible={formal.errors.country}>
              {formal.errors.country || <span>&nbsp;</span>}
            </I.RayloError>

          </I.RayloFormRow>
        </SlideDownFadeIn>

        {formal.errors.base && <FadeIn visible={showFields}><div style={{marginBottom: 48}}>
          <I.RayloError visible={formal.errors.base}>
            {formal.errors.base || <span>&nbsp;</span>}
          </I.RayloError></div>
        </FadeIn>}

        <SlideDownFadeIn visible={showFields}>
          <I.RayloFormRow>
            <BackForwardGroup submit={
              {
                ...formal.getSubmitButtonProps(),
                disabled: isSubmitting
              }} onBack={onBack} />
          </I.RayloFormRow>
        </SlideDownFadeIn>
      </form>
    </div>
  )
}
