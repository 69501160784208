import React, { Component } from 'react';
import { Lottie } from '@crello/react-lottie';

import quadloader from 'public/quadloader.json';
import quadloaderblue from 'public/quadloader-blue.json';
import singleloader from 'public/singleloader.json';

export class Loader extends Component {
  render() {
    let animation;
    let container;

    const size = {
      large: {
        width: '30px',
        height: '30px'
      },
      small: {
        width: '16px',
        height: '16px'
      }
    }

    if(this.props.type === 'quad') {
      animation = quadloader;
      container = size.large;
    } else if(this.props.type === 'quadblue') {
      animation = quadloaderblue;
      container = size.large;
    } else if (this.props.type === 'single') {
      animation = singleloader;
      container = size.small;
    } else {
      animation = singleloader;
      container = size.small;
    }

    return (
      <div style={container}>
        
        {process.env.NODE_ENV !== 'test' && <Lottie
          config={{
            animationData: animation,
            loop: true,
          }}
        />}
      </div>
    )
  }
}
