import CarouselComponent from 'components/CarouselComponent';
import { PreApprovalHomePageBanner } from 'components/PreApprovalBanner/PreApprovalHomePageBanner';
import PreApprovalBannerMobile from 'components/PreApprovalBannerMobile';
import PreApprovedDeviceCard from 'components/PreApprovedDeviceCard';
import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import Container from 'elements/Container';
import { breakpoints } from 'styles/breakpoints';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import useMedia from 'use-media';
import { StyledAddTechCarouselSection } from './styles';
import { IAddTechCarouselSection } from './types';

export const AddTechCarouselSection = ({
  dataTestId,
  onClick,
  products,
  subscriptionId,
  checkoutToken,
}: IAddTechCarouselSection) => {
  const isMobile = useMedia({ maxWidth: breakpoints.mobile });
  if (!products) {
    return null;
  }
  return (
    <StyledAddTechCarouselSection data-testid={dataTestId}>
      <Container
        padding="0"
        width="100vw"
        maxWidth="76.25rem"
        backgroundColor={primaryColors.w01}
      >
        {isMobile ? (
          <PreApprovalBannerMobile promo />
        ) : (
          <PreApprovalHomePageBanner hideCopy />
        )}
        <Container
          styles={{
            borderLeft: `solid 1px ${secondaryColors.c04}`,
            borderRight: `solid 1px ${secondaryColors.c04}`,
          }}
        >
          <CarouselComponent>
            {products?.map((product: any) => (
              <PreApprovedDeviceCard
                product={product}
                key={product.id}
                subscriptionId={subscriptionId}
                previewCard
                checkoutToken={checkoutToken}
              />
            ))}
          </CarouselComponent>
        </Container>
        <Container
          center
          styles={{
            borderLeft: `solid 1px ${secondaryColors.c04}`,
            borderRight: `solid 1px ${secondaryColors.c04}`,
          }}
        >
          <Container width="362px">
            <Button
              text="View all tech"
              size="large"
              buttonType="solid"
              buttonColor={ButtonTypes.b01}
              onClick={onClick}
            />
          </Container>
        </Container>
      </Container>
    </StyledAddTechCarouselSection>
  );
};
