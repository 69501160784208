import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { StyledCarouselComponent } from './styles';
import { ICarouselComponent } from './types';
// import { ICarouselComponent } from './types';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 601 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

export const CarouselComponent = ({
  dataTestId,
  children,
}: ICarouselComponent) => {
  return (
    <StyledCarouselComponent data-testid={dataTestId}>
      <Carousel
        responsive={responsive}
        showDots
        removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
      >
        {React.Children.map(children, (Child) => {
          return <>{Child}</>;
        })}
      </Carousel>
    </StyledCarouselComponent>
  );
};
