
import React from 'react';

import * as A from 'styles/account';
import * as T from 'styles/type';
import dayjs  from 'dayjs';
import _ from 'lodash'

export const LastPayment = ({transactions}) => {

  const orderedTransactions = _.orderBy(
    _.filter(transactions, o => o.amountIncludingTax.value >= 0)
  , ['happenedAt'], ['desc'])

  const lastTransaction = _.first(orderedTransactions)

  if(lastTransaction) {
    return (
      <A.PaymentRow>
        <A.PaymentRowHeader>
          <T.PaymentRowLabel>Previous Payment</T.PaymentRowLabel>
        </A.PaymentRowHeader>

        <A.PaymentRowData>
          <div>
            <T.Payment>{dayjs(lastTransaction.happenedAt).format('DD/MM/YY')}</T.Payment>
          </div>

          <div>
            <T.Payment>{lastTransaction.amountIncludingTax.formattedValue}</T.Payment>
          </div>
        </A.PaymentRowData>
      </A.PaymentRow>
    )
  } else {
    return null
  }
}
