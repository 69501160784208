import ReactTooltip from 'react-tooltip';
import useMedia from 'use-media';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Icon from 'elements/Icon';
import Spacer from 'elements/Spacer';
import {
  primaryColors,
  secondaryColors,
  uiStateColors,
} from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { breakpoints } from 'styles/breakpoints';
import { Input, InputWrapper, StyledTextInput, IconWrapper } from './styles';
import { ITextInput } from './types';

export const TextInput = ({
  dataTestId,
  id,
  labelText,
  placeHolderText,
  error,
  success,
  onBlur,
  onChange,
  onClick,
  onFocus,
  value,
  height,
  maxLength,
  tooltip,
  fieldName,
  pattern,
  type,
}: ITextInput) => {
  const isMobile = useMedia({ maxWidth: breakpoints.mobile });
  return (
    <StyledTextInput
      data-testid={dataTestId}
      error={error}
      height={height}
      isMobile={isMobile}
    >
      <Container noPadding flexRow>
        <Container noPadding width="90%">
          {labelText && <Copy bold>{labelText}</Copy>}
        </Container>
        <Container noPadding alignRight width="10%">
          {!!tooltip && (
            <>
              <div data-tip data-for="registerTip">
                <Icon
                  name="InfoCircle"
                  color={secondaryColors.c03}
                  size="20px"
                />
              </div>
              <ReactTooltip
                id="registerTip"
                place="top"
                effect="solid"
                backgroundColor={secondaryColors.p04}
                textColor={primaryColors.c01}
                offset={{ left: isMobile ? 80 : 0 }}
              >
                <div
                  style={{
                    maxWidth: '160px',
                    padding: '8px 0',
                    zIndex: 2000,
                    fontSize: px2Rem(14),
                  }}
                >
                  {tooltip}
                </div>
              </ReactTooltip>
            </>
          )}
        </Container>
      </Container>
      <Spacer height={tooltip ? 8 : 12} />
      <InputWrapper>
        <Input
          id={id}
          placeholder={placeHolderText}
          error={error}
          onChange={onChange}
          onBlur={onBlur}
          onClick={onClick}
          onFocus={onFocus}
          value={value}
          maxLength={maxLength}
          name={fieldName}
          pattern={pattern}
          type={type}
        />
        <IconWrapper>
          {error && !success && (
            <Icon
              name="AlertCircle"
              size={px2Rem(16)}
              color={uiStateColors.error}
            />
          )}
          {success && !error && (
            <Icon
              name="CheckCircleFill"
              size={px2Rem(16)}
              color={uiStateColors.success}
            />
          )}
        </IconWrapper>
      </InputWrapper>
      <Spacer height={4} />
      {error && (
        <Copy fontSize={14} color={uiStateColors.error}>
          {error}
        </Copy>
      )}
    </StyledTextInput>
  );
};
