import { QL_DECISION } from 'screens/Checkout/graphQL/queries';
import { Helmet } from 'react-helmet';
import { Modal } from 'components/common/Modal';
import { primaryColors } from 'styles/variables/colors';
import useRayloQuery from 'utils/useRayloQuery';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { useErrorNavigator } from 'utils/useErrorNavigator';
import { StepSecurityDepositContent } from 'screens/Checkout/components/StepSecurityDepositContent';
import { PlainHeader } from 'components/common/PlainHeader';
import { ThemeProvider } from 'styled-components';
import { rayloLight } from 'styles/themes/rayloLight';
import { useSegmentIdentifyAndCheckoutTracking } from 'hooks/segment/segment.hooks';

export const DecisionSecurityDeposit = () => {
  useSegmentIdentifyAndCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'security deposit',
    checkoutScreen: 'security deposit intro',
    when: 'on page load',
  });

  const errorNav = useErrorNavigator();
  const { checkoutToken } = useCheckoutContext();

  const {
    // TODO: checkout throwing warning as unused, but removing it is breaking journey
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: { checkout },
    error,
    loading,
  } = useRayloQuery(QL_DECISION, {
    variables: {
      checkoutToken,
    },
  });

  if (error) {
    errorNav('Decision: failure', error);
  }

  if (loading) {
    return <Modal visible text="Loading..." />;
  }
  const backgroundColor = primaryColors.w01;

  return (
    <ThemeProvider theme={rayloLight}>
      <div
        className="checkout--index"
        style={{ backgroundColor, width: '100vw' }}
      >
        <Helmet>
          <title>Security Deposit - Raylo</title>
        </Helmet>
        <div className="checkout--header">
          <PlainHeader />
        </div>
        <div className="checkout--main">
          <div className="checkout--step">
            <StepSecurityDepositContent dataTestId="securityDeposit" />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};
