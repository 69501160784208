import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Spacer from 'elements/Spacer';
import { primaryColors } from 'styles/variables/colors';
import { StyledNumberedList, StyledOutlinedNumber } from './styles';
import { INumberedList } from './types';

export const NumberedList = ({ dataTestId, listItems }: INumberedList) => {
  return (
    <StyledNumberedList data-testid={dataTestId}>
      {listItems?.map((item, i) => (
        <>
          <Container key={item} noPadding flexRow>
            <StyledOutlinedNumber>{i + 1}</StyledOutlinedNumber>
            <Container padding="0 0 0 16px">
              <Copy color={primaryColors.c01} lineHeight={22}>
                {item}
              </Copy>
            </Container>
          </Container>
          <Spacer height={24} />
        </>
      ))}
    </StyledNumberedList>
  );
};
