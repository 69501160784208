import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';
import { IToggle } from './types';

const StyledToggle = styled.div<IToggle>`
  & div {
    border-color: ${({ theme }) => theme && theme?.form?.toggle?.borderColor};
  }
  & div > div {
    ${({ active }) => !active && 'opacity: 0.5;'}
  }
  & span:nth-child(2) {
    ${({ active }) => !active && 'opacity: 0.5;'}
  }
  & span {
    color: ${({ theme }) => theme && theme?.form?.toggle?.textColor};
    ${({ textSize }) => textSize && `font-size: ${px2Rem(textSize)}`};
  }
  & div > div > span {
    background: ${({ theme }) => theme && theme?.form?.toggle?.activeColor};
    ${({ theme, active }) =>
      !active && `background: ${theme?.form?.toggle?.color};`}
  }
`;

export { StyledToggle };
