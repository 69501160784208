
import gql from 'graphql-tag';

export const QL_HEADER = gql`
  query UpgradeHeaderQuery {
  customer {
    id
    orders {
      id
      items {
        id
        subscription {
          id
          upgrade {
            recommendedProducts {
              id
            }
          }
        }
        variant {
          id
          displayName
        }
      }
    }
  }
}`

export const QL_CURRENT_LEASE = gql`
  query UpgradeCurrentLeaseQuery {
  customer {
    id
    orders {
      id
      items {
        id
        costSummary {
          recurring {
            totalAmount {
              valueAfterTax {
                value
                currencyCode
              }
            }
          }
        }
        subscription {
          id
          pricePlan {
            id
            termLength
          }
        }
        variant {
          id
          displayName
          optionValues {
            id
            displayName
            optionType {
              id
              slug
            }
          }
          product {
            id
            displayName
          }
        }
      }
    }
  }
}`



export const QL_SUBSCRIPTION = gql`
  query SubscriptionQuery {
  customer {
    id
    subscriptions {
      id
      upgrade {
        eligible
      }
    }
  }
}`

export const QL_RECOMMENDED_PHONES = gql`
  query RecommendedPhonesQuery {
  customer {
    id
    subscriptions {
      id
      upgrade {
        eligible
        recommendedProducts {
          id
          displayName
          available
          variantConditions
          specifications
          position
          lowestRecurringCostPricePlan {
            id
            termLength
            costSummary {
              recurring {
                totalAmount {
                  valueAfterTax {
                    value 
                    currencyCode
                  }
                }
              }
            }
            variants(first: 1) {
              nodes {
                id
                displayName
                condition
                available
                images(tag: "Front") {
                  id
                  alt
                  position
                  tag
                  url
                }
              }
            }
          }
        }
      }
    }
  }
}`


export const MU_CREATE_CHECKOUT = gql`
  mutation CheckoutCreateMutation(
    $pricePlanId: ID!, 
    $variantId: ID!,
    $subscriptionId: ID) {
    createCheckout(input: {
      privacyPolicyAccepted: true,
      items: [{
        variantId: $variantId,
        pricePlanId: $pricePlanId,
        originSubscriptionId: $subscriptionId
      }]
    }) {
      checkout {
        id
        token
        initialPaymentMethodRequired
      },
      errors {
        field,
        code,
        message
      }
    }
}`


export const QL_PRODUCTS = gql`
  query ProductsForUpgradeQuery ($checkoutToken: String){
  products(forCheckout: true, checkoutToken: $checkoutToken) {
    id
    displayName
    available
    variantConditions
    specifications
    position
    category {
      displayName
    }
    lowestRecurringCostPricePlan {
      id
      termLength
      costSummary {
        recurring {
          totalAmount {
            valueAfterTax {
              value 
              currencyCode
            }
          }
        }
      }
      variants(first: 1) {
        nodes {
          id
          displayName
          condition
          available
          images(tag: "Front") {
            id
            alt
            position
            tag
            url
          }
        }
      }
    }
  }
}`
