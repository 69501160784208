import React from 'react';
import useMedia from 'use-media';
import { useHistory } from 'react-router';
import { FadeIn } from 'components/animations/Transitions';
import { Card } from '../../../../components/common/Card';
import Spacer from '../../../../elements/Spacer';
import { px2Rem } from '../../../../utils/px2Rem';
import { breakpoints } from '../../../../styles/breakpoints';
import {
  primaryColors,
  secondaryColors,
} from '../../../../styles/variables/colors';
import Header from '../../../../elements/Header';
import Icon from '../../../../elements/Icon';
import Copy from '../../../../elements/Copy';
import useRayloQuery from '../../../../utils/useRayloQuery';
import { QL_CUSTOMER_SIMPLE } from '../../queries';
import Button from '../../../../elements/Button';
import { ButtonTypes } from '../../../../elements/Button/types';

export const PaymentSuccessful = () => {
  const history = useHistory();

  const {
    data: { customer },
  } = useRayloQuery(QL_CUSTOMER_SIMPLE, { fetchPolicy: 'network-only' });

  const isMobile = useMedia({ maxWidth: breakpoints.mobile });

  return (
    <FadeIn>
      <div>
        {!isMobile && <Spacer height={48} />}
        <Card
          width="1200"
          styles={`
            margin: auto;
            min-height: ${px2Rem(800)};
            
            @media (max-width: ${breakpoints.mobile}px) {
              padding: ${px2Rem(36)} ${px2Rem(30)} ${px2Rem(20)} ${px2Rem(30)};
              max-width: 100%;
              box-shadow: none;
            `}
        >
          <div style={{ maxWidth: px2Rem(362), margin: 'auto' }}>
            <Header
              level={1}
              color={primaryColors.c01}
              fontSize={24}
              style={isMobile ? { marginTop: px2Rem(30) } : undefined}
              centered
            >
              Your payment was successful
            </Header>
            <Icon
              name="PaymentSuccessful"
              styles={`
          width: fit-content;
          display: block;
          margin: ${px2Rem(29)} auto ${px2Rem(29)};
        `}
            />
            <Header
              level={4}
              color={primaryColors.c01}
              fontSize={16}
              style={isMobile ? { marginTop: px2Rem(30) } : undefined}
              centered
            >
              Thank you
            </Header>
            <Spacer height={28} />
            <div
              style={{
                padding: px2Rem(20),
                backgroundColor: secondaryColors.p05,
              }}
            >
              <Copy fontSize={16} textAlign="center">
                Your account will be updated within 24 hours.
              </Copy>
              <Spacer height={28} />
              <Copy fontSize={16} textAlign="center">
                A receipt for your payment will be emailed to
                <span style={{ fontWeight: 'bold' }}> {customer?.email}</span>
              </Copy>
            </div>
            <Spacer height={52} />
            <Button
              text="view account overview"
              buttonColor={ButtonTypes.b01}
              fullWidth
              size="large"
              onClick={() => history.push('/account')}
            />
          </div>
        </Card>
        {!isMobile && <Spacer height={256} />}
      </div>
    </FadeIn>
  );
};
