import { useState, useEffect } from 'react';
import store from 'store'
import styled from 'styled-components/macro';
import _ from 'lodash'
import dayjs from 'dayjs';
import { history } from 'utils/history';

import iconUpgrade from 'public/icon--upgrade.svg'
import { primaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import StatusBanner from 'components/StatusBanner';
import Spacer from 'elements/Spacer';
import useMedia from 'use-media';
import { breakpoints } from 'styles/breakpoints';
import {checkInArrears} from 'helpers/subscriptions/checkInArrears'

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)



export const PhoneStatusUpgrade = ({ phone, name }) => {

  let dueOn, checkout, subscription
  let eligible = false

  if (phone.subscription) {
    subscription = phone.subscription
    dueOn = subscription.upgrade.dueOn
    eligible = subscription.upgrade.eligible
    checkout = subscription.upgrade.checkout
  }

  const isMobile = useMedia({ maxWidth: breakpoints.mobile })


  const dueOnIsInFuture = dueOn && dayjs(dueOn).isBefore(dayjs().add(30, 'd')) && dayjs(dueOn).isAfter(dayjs())
  const dueOnIsInPast = dueOn && dayjs(dueOn).isBefore(dayjs())
  const hasActiveCheckout = checkout && checkout.token && checkout.state && ["STARTED", "ABANDONED"].includes(checkout.state)
  const isInArrears = checkInArrears(subscription)

  const showTimer = dueOnIsInFuture && !isInArrears
  const showButton = dueOnIsInPast && (eligible || hasActiveCheckout) && !isInArrears

  const calculateTimeLeft = () => {
    if (!dueOn) {
      return
    }
    const difference = dayjs(dueOn).diff(dayjs(), 'milliseconds')
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60)
    };
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    if (dueOnIsInFuture) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 500);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft, dueOnIsInFuture]);

  const onClick = () => {
    store.remove('checkoutToken')
    history.push(`/account/upgrade/${phone.subscription.id}`)
  }

  if (showButton) {
    return (
      <StatusBanner buttonText={checkout ? "Resume upgrade" : "View upgrades"} buttonOnClick={onClick} showButton={showButton} flexRow={!isMobile}>
        <>
          {
            isMobile ?
              (
                <>
                  <div
                    style={{
                      marginLeft: px2Rem(12),
                      cursor: "pointer"
                    }}
                    onClick={() => onClick()}
                  >
                    <img src={iconUpgrade} className="icon" alt="Upgrade icon" style={{
                      width: px2Rem(36),
                      height: px2Rem(36),
                    }}/>
                  </div>
                  <h2>It's good news. It’s time for your FREE upgrade!</h2>
                </>
              ) : (
                <>
                  <h2>It's good news. It’s time for your FREE upgrade!</h2>
                  <div
                    style={{
                      marginLeft: px2Rem(12),
                      cursor: "pointer"
                    }}
                    onClick={() => onClick()}
                  >
                    <img src={iconUpgrade} className="icon" alt="Upgrade icon" />
                  </div>
                </>
              )
          }
         
        </>
      </StatusBanner>
    )
  } else if (showTimer) {
    return (
      <StatusBanner buttonText={checkout ? "Resume upgrade" : "View upgrades"} buttonOnClick={onClick}>
        <>
          <h2>Not long now. You'll soon be eligible for a free upgrade on your {phone.model}.</h2>
          <Spacer height={24} />
          <UpgradeTimers>
            <UpgradeTimer>{timeLeft.days}<small>Days</small></UpgradeTimer>
            <UpgradeTimer>{timeLeft.hours}<small>Hours</small></UpgradeTimer>
            <UpgradeTimer>{timeLeft.minutes}<small>Mins</small></UpgradeTimer>
            <UpgradeTimer>{timeLeft.seconds}<small>Secs</small></UpgradeTimer>
          </UpgradeTimers>
        </>
      </StatusBanner>
    )
  } else {
    return null
  }
}

export const UpgradeOuterContainer = styled.div`
  background-color: ${primaryColors.b01};
  padding: 75px 0;

  @media (max-width: 540px) {
    padding: 40px 0;
  }
`

export const UpgradeInnerContainer = styled.div`
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 40px;

  @media (max-width: 540px) {
    padding: 0 20px;
  }
`

export const UpgradePhoneImage = styled.div`
  position: relative;
`

export const UpgradePhoneIcon = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
}`

export const UpgradeInner = styled.div`
  width: 100%;
  max-width: 595px;
  display: flex;
  justify-contents: flex-start;
  align-items: flex-start;
`

export const UpgradeDetails = styled.div`
  margin-left: 20px;
`

export const UpgradeHeader = styled.div`
  margin-bottom: 20px;
  font-family: 'Raylo-Medium', sans-serif;
  color: white;
  font-size: 20px;
  line-height: 39px;
`


export const UpgradeTimers = styled.div`
  display: inline-block;
  border: 1px solid rgba(255,206,199,0.43);
  padding: 20px 10px;
`

export const UpgradeTimer = styled.div`
  display: inline-block;
  font-family: 'Raylo-Medium', sans-serif;
  font-size: ${px2Rem(16)};
  font-weight: bold;
  padding: 0 15px;
  color: #FFFFFF;
  letter-spacing: -0.23px;
  small {
    margin-top: 10px;
    display: block;
    font-size: ${px2Rem(16)};
    font-weight: 300;
    color: ${primaryColors.p01};
    letter-spacing: -0.16px;
  }
`
