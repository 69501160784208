import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useRayloQuery from 'utils/useRayloQuery';
import { QL_PRODUCTS } from 'screens/Upgrade/queries';
import { filter, sortBy } from 'lodash'
import { PhoneBox } from './PhoneBox';

import classNames from 'classnames'
import PreApprovedDeviceCard from 'components/PreApprovedDeviceCard';
import { px2Rem } from 'utils/px2Rem';
import { breakpoints } from 'styles/breakpoints';
import Container from 'elements/Container';
import Spacer from 'elements/Spacer';
import { QL_CUSTOMER } from 'screens/Account/queries';
import { ProductCategories } from 'types';
import Header from 'elements/Header';
import useRayloLazyQuery from 'utils/useRayloLazyQuery';
import { removeSessionStorage } from 'utils/handleSessionStorage';

export const AllPhones = ({ additionalTech, subscriptionId }) => {
  const [getProducts, { data: { products }, loading: productsLoading }] = useRayloLazyQuery(QL_PRODUCTS)
  const { data: { customer }, loading } = useRayloQuery(QL_CUSTOMER, { fetchPolicy: "network-only" })
  const [isUpgrade, setIsUpgrade] = useState(false)
  const [tab, setTab] = useState("ALL")
  const [filteredProducts, setFilteredProducts] = useState([])
  const [productCategories, setProductCategories] = useState([])
  const [checkoutToken, setCheckoutToken] = useState()
  const [pageLoading, setPageLoading] = useState(true)

  useEffect(() => {
    setIsUpgrade(window?.location?.pathname?.includes('upgrade'))
    removeSessionStorage('checkoutToken')
    setTimeout(() => {
      setPageLoading(false)
    }, 1000)
  }, [])

  useEffect(() => {
    if (additionalTech) {
      setCheckoutToken(customer?.preApproval?.checkout?.token)
    } else {
      const subscription = customer?.subscriptions.find((s) => s.id === subscriptionId)
      setCheckoutToken(subscription?.upgrade?.checkout?.token)
    }
  }, [loading])

  useEffect(() => {
    getProducts({ variables: { checkoutToken } })
  }, [checkoutToken])

  useEffect(() => {
    if (tab && products) {
      switch (tab) {
        case "NEW":
          setFilteredProducts(sortBy(filter(products, o => o.variantConditions.includes("NEW")), ["position"]))
          break;
        case "REFURBISHED":
          setFilteredProducts(sortBy(filter(products, o => o.variantConditions.includes("REFURBISHED")), ["position"]))
          break;
        case "TABLETS":
          setFilteredProducts(sortBy(filter(products, o => o?.category?.displayName === ProductCategories.TABLET), ["position"]))
          break;
        case "PHONES":
          setFilteredProducts(sortBy(filter(products, o => o?.category?.displayName === ProductCategories.PHONE), ["position"]))
          break;
        case "LAPTOPS":
          setFilteredProducts(sortBy(filter(products, o => o?.category?.displayName === ProductCategories.LAPTOP), ["position"]))
          break;
        default:
          setFilteredProducts(sortBy(products, ["position"]))
          break;
      }
    }
  }, [tab, products])

  useEffect(() => {
    if (products) {
      const categories = products.map((product) => product.category.displayName)
      setProductCategories([...new Set(categories)])
    }
  }, [products])

  const TabHeader = () => {
    if (additionalTech) {
      return (
        <div className="phone-boxes__tabs">
          <button className={classNames('phone-boxes__tabs-tab', { active: tab === 'ALL' })} onClick={() => setTab('ALL')}>For you</button>
          {productCategories.map((product) => {
            const name = `${product}s`
            return (
              <button className={classNames('phone-boxes__tabs-tab', { active: tab === name.toUpperCase() })} onClick={() => setTab(name.toUpperCase())}>{name}</button>
            )
          }
          )}
        </div>
      )
    }
    return (
      <div className="phone-boxes__tabs">
        <button className={classNames('phone-boxes__tabs-tab', { active: tab === 'ALL' })} onClick={() => setTab('ALL')}>All</button>
        <button className={classNames('phone-boxes__tabs-tab', { active: tab === 'NEW' })} onClick={() => setTab('NEW')}>New</button>
        <button className={classNames('phone-boxes__tabs-tab', { active: tab === 'REFURBISHED' })} onClick={() => setTab('REFURBISHED')}>Refurbished</button>
      </div>
    )
  }

  if (!productsLoading && !pageLoading) {
    return (
      <Container backgroundColor="white" center>
        <Spacer height={24} />
        <Container maxWidth="1200px">
          {isUpgrade &&
            <>
              <Header level={2}>All available options</Header>
              <Spacer height={32} />
            </>
          }
          <TabHeader />
          <AllDevices>
            {filteredProducts.map((product, i) => (
              <PreApprovedDeviceCard 
                key={product.id}
                product={product}
                subscriptionId={subscriptionId}
                index={i}
                checkoutToken={checkoutToken}
              />
            ))}
          </AllDevices>
        </Container>
      </Container>
    )
  } else {
    return (<></>)
  }
}

const AllDevices = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${px2Rem(32)};
  grid-auto-rows: minmax(${px2Rem(100)}, auto);
  @media(max-width: ${breakpoints.desktop}px) {
      grid-template-columns: repeat(2, 1fr);
  }
  @media(max-width: ${breakpoints.mobile}px) {
      grid-template-columns: 1fr;
  }
`