import styled from 'styled-components';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { IStyledDualToneHeader } from './types';

const StyledDualToneHeader = styled.div<IStyledDualToneHeader>`
  & h2 {
    font-weight: 700;
    font-size: ${px2Rem(24)};
    color: ${primaryColors.c01};
    ${({ fontSize }) => fontSize && `font-size: ${px2Rem(fontSize)};`}
    ${({ fontColor }) => fontColor && `color: ${fontColor};`}
    & span {
      color: ${secondaryColors.c02};
      ${({ subFontColor }) => subFontColor && `color: ${subFontColor};`}
    }
  }
`;

export { StyledDualToneHeader };
