import React from 'react';
import styled from 'styled-components/macro';
import colors from 'styles/colors';
import rayloLogo from 'public/raylo-logo.svg';


export const PlainHeader = ({transparent = false}) => {
  return (
    <PlainHeaderSC transparent={transparent}>
      <PlainHeaderContainer>
        <PlainHeaderLogo src={rayloLogo} />
      </PlainHeaderContainer>
    </PlainHeaderSC>
  )
}

export const PlainHeaderSC = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 75px;
  padding: 0 40px;
  background-color: ${props => props.transparent ? 'background-color: transparent;' : `${colors.rayloBlue};`} 
  display: flex;
  cursor: pointer;
  z-index: ${props => props.configure ? '11' : '25'};

  @media (max-width: 540px) {
    padding: 0 20px;
  }
`

export const PlainHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;

  @media (max-width: 1024px) {
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      opacity: ${props => props.bordered ? '0.7' : '0'};
      background-color: #7277FF;
      transition: opacity 1s;
    }
  }
`

export const PlainHeaderLogo = styled.img`
  display: block;
`
