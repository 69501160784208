import React from 'react';
import styled from 'styled-components/macro';
import colors from 'styles/colors';

import pill from 'public/footer-logo.svg';
import facebookIcon from 'public/icon--facebook.svg';
import instagramIcon from 'public/icon--instagram.svg';
import twitterIcon from 'public/icon--twitter.svg';

import 'styles/footer.css';


export const AccountFooter = () => (
  <div className="footer">
    <div className="footer--container">
      <div className="footer--grid">
        <div className="footer--address">
          <img src={pill} alt="Raylo Pill" className="raylo-pill" />
          <p>Raylo Group Limited is authorised and regulated by the Financial Conduct Authority (841488).</p>
          <p>Raylo is a trading name of Raylo Group Limited. Raylo Group Limited is registered in England and Wales company number 11554120.</p>
          <p><strong>Head office</strong>5 New Street Square, City of London, EC4A 3TW</p>
        </div>
        <div className="footer--links">
          <div className="footer__title">Raylo</div>
          <div className="footer__text"><a href="https://app.raylo.com/login" className="footer__link">My account</a></div>
          <div className="footer__text"><a href="https://www.raylo.com/products" className="footer__link">Get&nbsp;started</a></div>
          <div className="footer__text"><a href="https://www.raylo.com/sustainability" className="footer__link">Sustainability</a></div>
          <div className="footer__text"><a href="https://www.raylo.com/#how-it-works" className="footer__link">How it works</a></div>
          <div className="footer__text"><a href="https://www.raylo.com/#pricing" className="footer__link">Pricing</a></div>
          <div className="footer__text"><a href="https://www.raylo.com/blog" className="footer__link">Blog</a></div>
          <div className="footer__text"><a href="https://www.raylo.com/brands/new" className="footer__link">New phones</a></div>
          <div className="footer__text"><a href="https://www.raylo.com/brands/refurbished" className="footer__link">Refurbished phones</a></div>
          <div className="footer__text"><a href="https://www.raylo.com/about" className="footer__link">About</a></div>
        </div>
        <div className="footer--links">
          <div className="footer__title">Customer service</div>
          <div className="footer__text"><a href="https://help.raylo.com/" className="footer__link">Help</a></div>
          <div className="footer__text"><a href="https://help.raylo.com/en/articles/3271547-contact-raylo" className="footer__link">Contact us</a></div>
          <div className="footer__text"><a href="https://jobs.eu.lever.co/raylo" className="footer__link">Careers</a></div>
          <div className="footer__text"><a href="https://www.raylo.com/post" className="footer__link">Guides</a></div>
          <div className="footer__text"><a href="https://www.raylo.com/legal" className="footer__link">Legal</a></div>
          <div className="footer__text"><a href="https://raylo.connect.studentbeans.com/uk" className="footer__link">Students</a></div>
        </div>
      </div>

      <div className="footer--lower">
        <div className="footer--legal">&copy; Copyright of Raylo {new Date().getFullYear()}. All rights reserved.</div>
        <div className="social-icons">
          <a href="https://www.facebook.com/joinraylo/" target="_blank" rel="noopener noreferrer">
            <img src={facebookIcon} alt="Facebook" width={18} />
          </a>
          <a href="https://www.instagram.com/joinraylo/" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" width={18} />
          </a>
          <a href="https://twitter.com/raylo" target="_blank" rel="noopener noreferrer">
            <img src={twitterIcon} alt="Twitter" width={18} />
          </a>
        </div>
      </div>
    </div>
  </div>
)

export const AccountFooterSC = styled.footer`
  width: 100%;
  padding: 55px 0;
  position: relative;
  background-color: ${colors.rayloDark};

  @media (max-width: 560px) {
    padding: 28px 0;
  }
`

export const AccountFooterContainer = styled.div`
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 40px;

  @media (max-width: 560px) {
    padding: 0 20px;
  }
`

export const AccountFooterUpper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  padding-bottom: 70px;
  border-bottom: 1px solid #6C6C6C;

  @media (max-width: 560px) {
    display: block;
    padding-bottom: 28px;

    .raylo-pill {
      margin-bottom: 48px;
    }
  }
`

export const AccountFooterLower = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-family: 'Raylo-Regular', Arial, sans-serif;
  font-weight: normal;
  font-size: 0.6875em;
  color: #ABABAB;
  padding-top: 10px;

  @media (max-width: 620px) {
    display: block;
    text-align: center;
  }
`

export const AccountFooterNavContainer = styled.div`
  display: flex;
  flex-basis: 50%;

  @media (max-width: 560px) {
    width: 100%;
  }

  @media (max-width: 420px) {
    display: block;
  }

`

export const AccountFooterNav = styled.nav`
  flex: 1;

  &:first-of-type {
    margin-right: 20px;

    @media (max-width: 560px) {
      margin-right: 0;
      margin-bottom: 48px;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: block;

    @media (max-width: 560px) {
      &:not(:last-of-type) {
        margin-bottom: 18px;
      }
    }
  }
`

export const AccountFooterNavHeading = styled.h5`
  font-family: 'Raylo-Medium', Arial, sans-serif;
  font-weight: 500;
  font-size: 0.75em;
  color: ${colors.rayloPink2Dark};
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin: 0 0 28px;
`

export const NavLink = styled.a`
  font-family: 'Raylo-Medium', Arial, sans-serif;
  font-size: 0.75em;
  font-weight: 500;
  color: ${colors.rayloLight};
  letter-spacing: 0.4px;
  text-transform: uppercase;
  line-height: 2.14;
  text-decoration: none;
`

export const AccountFooterContact = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-basis: 50%;

  @media (max-width: 620px) {
    display: block;
  }

  .address {
    flex: 1;
    margin-right: 18px;

    @media (max-width: 620px) {
      display: none;
    }

    p {
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 1em;
    }
  }

  .social-icons {
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex: 1;

    @media (max-width: 620px) {
      margin-top: 12px;
    }

    li {
      display: inline-block;
      vertical-align: middle;

      &:not(:last-of-type) {
        margin-right: 18px;
      }
    }
  }
`
