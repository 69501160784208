import { StyledMarkdown } from './styles';
import { IMarkdown } from './types';

export const Markdown = ({ dataTestId, copy, color }: IMarkdown) => {
  return (
    <StyledMarkdown data-testid={dataTestId}>
      <p
        style={{
          color,
          lineHeight: '19px',
        }}
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{ __html: copy }}
      />
    </StyledMarkdown>
  );
};
