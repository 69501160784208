/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Modal from 'react-modal';
import iconModalClose from "public/icon--modal-close.svg";
import iconShield from "public/icon--shield.svg";
import 'styles/condition-modal.css';

if(process.env.NODE_ENV !== "test") {
  Modal.setAppElement('#root');
}


export const ChoiceModal = ({modalIsOpen, setIsOpen}) => {
  return (

    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setIsOpen(false)}
      contentLabel="Example Modal"
      className="condition-modal"
    >
      <a className="condition-modal--close" onClick={() => setIsOpen(false)}>
        <img src={iconModalClose} alt="modal close" />
      </a>
      
      <div className="condition-modal--inner">
      <img className="condition-modal--icon" src={iconShield} alt="condition icon"/>
      <h2>Your Choices</h2>
      <p>When you order, you choose a lease term of 12 or 24 months.</p>
      <p>Here are your choices at the end of this term.</p>
      <h3>Upgrade</h3>
      <p>Apply for a new device of your choice with no upgrade fee. We’ll send it to you and you have 14 days to return the old one.</p>
      <h3>Keep</h3>
      <p>If you’re happy with the device you’ve been using, then just carry on paying monthly or pay a one off <a href="https://help.raylo.com/en/articles/3342369-after-the-lease-term-what-happens">non-return fee.</a></p>
      <p>If you want to purchase the device, which would enable you to sell it or give it to somebody else, please contact us to discuss.</p>
      <h3>Cancel</h3>
      <p>Hand the device back and sail off into the sunset.</p></div>
    </Modal>
  )
}