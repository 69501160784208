import { px2Rem } from 'utils/px2Rem';
import styled from 'styled-components';

const StyledDoubleButtonRow = styled.div`
  & button {
    max-height: ${px2Rem(60)};
  }
`;

export { StyledDoubleButtonRow };
