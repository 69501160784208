import Container from 'elements/Container';
import TextLink from 'elements/TextLink';
import { EditLinkWrapper, StyledEditContentBox } from './styles';
import { IEditContentBox } from './types';

export const EditContentBox = ({
  dataTestId,
  children,
  onClick,
  backgroundColor,
  containerTestId,
}: IEditContentBox) => {
  return (
    <Container backgroundColor={backgroundColor} dataTestId={containerTestId}>
      <StyledEditContentBox data-testid={dataTestId}>
        {children}
        <EditLinkWrapper>
          <TextLink onClick={onClick} data-testid="editLink">
            Edit
          </TextLink>
        </EditLinkWrapper>
      </StyledEditContentBox>
    </Container>
  );
};
