import React from 'react';
import styled from 'styled-components/macro'
import * as T from 'styles/type';
import colors from 'styles/colors';

import { BackButton } from 'components/common/BackButton';
import { PlainHeader } from 'components/common/PlainHeader';


export const ContactModal = ({ visible, onBack }) => {
  return (
    <RayloModal visible={visible}>
      <PlainHeader />
      <ModalContainer>
        <ModalBack>
          <BackButton onClick={onBack} />
          <p className="back-text" onClick={onBack}>Back</p>
        </ModalBack>

        <T.RayloSectionHeader>
          Contact Us
        </T.RayloSectionHeader>

        <div style={{ marginBottom: 40 }}>
        <T.RayloBodyType>

        <p>If you have any questions about your Direct Debit we're happy to help.</p></T.RayloBodyType></div>

        <T.RayloUnorderedList>
          <li>Email: <T.RayloLink href="mailto:help@raylo.com">help@raylo.com</T.RayloLink></li>
          <li>Phone: 0333 050 9541</li>
          <li>Post: 5 New Street Square, London, EC4a 3TW</li>
        </T.RayloUnorderedList>
      </ModalContainer>
    </RayloModal>
  )
}

export const RayloModal = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: #000;
  opacity: ${props => props.visible ? '1' : '0'};
  transition: opacity .25s ease-in .25s;

  ::-webkit-scrollbar {
	   width: 9px;
  }

  ::-webkit-scrollbar-track {
  	background: none;
  }

  ::-webkit-scrollbar-thumb {
  	background: ${colors.rayloPink};
  	border: 0;
  	box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
    border-radius: 12px;
  }

  scrollbar-color: ${colors.rayloPink} #000;
  scrollbar-width: thin;
`

export const ModalContainer = styled.section`
  padding: 100px 40px 200px 12.6%;
  max-width: 800px;

  @media (max-width: 768px) {
    padding: 120px 20px;
  }
`

export const ModalBack = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  .back-text {
    font-size: 0.75em;
    color: #FFF;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-left: 14px;
    cursor: pointer;
  }
`
