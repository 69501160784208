import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Icon from 'elements/Icon';
import { px2Rem } from 'utils/px2Rem';
import { StyledIconWithText, IconWrapper } from './styles';
import { IIconWithText } from './types';

export const IconWithText = ({
  dataTestId,
  iconName,
  iconColor,
  color,
  iconSize,
  iconClick,
  textColor,
  textSize,
  textMargin,
  text,
}: IIconWithText) => {
  return (
    <StyledIconWithText data-testid={dataTestId}>
      <Container width="initial" padding={`${px2Rem(4)} 0 0 0`}>
        <IconWrapper onClick={iconClick}>
          <Icon
            name={iconName}
            color={iconColor || color}
            size={(iconSize && px2Rem(iconSize)) || px2Rem(24)}
          />
        </IconWrapper>
      </Container>
      <Container
        width="initial"
        padding={(textMargin && `0 ${px2Rem(textMargin)}`) || '0 16px'}
        centerHorizontal
      >
        <Copy fontSize={textSize || 14} color={textColor || color}>
          {text}
        </Copy>
      </Container>
    </StyledIconWithText>
  );
};
