import React from 'react';
import styled from 'styled-components/macro'
import * as T from 'styles/type';

import { Loader } from 'components/common/Loader';
import { primaryColors } from 'styles/variables/colors';
import { useUiContext } from 'utils/useUiContext';
import Container from 'elements/Container';
import Copy from 'elements/Copy';

const dark = 'rgba(0,0,0,0.8);'

export const Modal = ({ visible, text }) => {
  const { lightMode } = useUiContext();
  return (
    <RayloModal visible={visible} className='raylo-modal' lightMode={lightMode}>
      <Container backgroundColor={lightMode ? primaryColors.w01 : dark} center>
      <Loader type="quad" />

      {text &&
        <Copy color={lightMode? primaryColors.c01 : primaryColors.p01}>
          {text}
        </Copy>
      }

      </Container>
    </RayloModal>
  )
}

export const RayloModal = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${({lightMode}) => lightMode ? primaryColors.w01 : dark};
  opacity: ${props => props.visible ? '1' : '0'};
  transition: opacity .25s ease-in .25s;
`
