import { ReactComponent as ShipmentPackage } from 'public/icons/streamline/icon_shipment-upload.svg';
import { ReactComponent as Calendar } from 'public/icons/streamline/icon_calendar-1.svg';
import { ReactComponent as CustomerSupport } from 'public/icons/streamline/icon_headphones-customer-support-human.svg';
import { ReactComponent as MoneyWalletOpen } from 'public/icons/streamline/icon_money-wallet-open.svg';
import { ReactComponent as PhoneActionShield } from 'public/icons/streamline/icon_phone-action-shield.svg';
import { ReactComponent as Charger } from 'public/icons/streamline/icon_charger.svg';
import { ReactComponent as PhoneDoubleCamera } from 'public/icon--phone-double-camera.svg';
import { ReactComponent as ShieldPhone } from 'public/icons/streamline/icon_shield-phone.svg';
import { ReactComponent as ShipmentInTransit } from 'public/icons/streamline/icon_shipment-in-transit.svg';
import { ReactComponent as CheckShield } from 'public/icons/streamline/icon_check-shield.svg';
import { ReactComponent as TrustPilotLogo } from 'public/trustpilot-vector-logo.svg';
import { ReactComponent as PhoneActionRefresh } from 'public/icons/icon_streamline_phone action refresh.svg';
import { ReactComponent as CreditCardCheck } from 'public/icons/streamline/icon_credit-card-check.svg';
import { ReactComponent as CalendarFavoriteHeart } from 'public/icon---calendar-favourite-heart.svg';
import { ReactComponent as SynchronizeArrowClock } from 'public/icons/streamline/icon_synchronize-arrow-clock.svg';
import { ReactComponent as NetworkShield } from 'public/icons/streamline/icon_network-shield.svg';
import { ReactComponent as CheckCircle } from 'public/icons/streamline/icon_check-circle-1.svg';
import { ReactComponent as SimCard } from 'public/icon--sim-card.svg';
import { ReactComponent as View } from 'public/icons/streamline/icon_view-1.svg';
import { ReactComponent as ChargingBatteryFull } from 'public/icon--battery-charging.svg';
import { ReactComponent as TakingPicturesCameras } from 'public/icons/streamline/icon_taking-pictures-cameras.svg';
import { ReactComponent as Flash } from 'public/icons/streamline/icon_flash-1.svg';
import { ReactComponent as PhoneActionCheck } from 'public/icons/streamline/icon_phone-action-check.svg';
import { ReactComponent as CustomScreenProtector } from 'public/icons/icon_custom_screen-protector.svg';
import { ReactComponent as PhoneDamage } from 'public/icons/icon_custom_phone-damage.svg';
import { ReactComponent as Chevron } from 'public/icons/icon_custom_chevron.svg';
import { ReactComponent as CheckCircleFill } from 'public/icons/streamline/icon_check-circle-fill.svg';
import { ReactComponent as Call } from 'public/icons/icon_custom_call.svg';
import { ReactComponent as RayloSymbol } from 'public/icons/icon_custom_raylo-symbol.svg';
import { ReactComponent as Close } from 'public/icons/icon_custom_close.svg';
import { ReactComponent as EcologyGlobeNature } from 'public/icons/icon_custom_ecology-globe-nature.svg';
import { ReactComponent as UpfrontCost } from 'public/icons/icon_custom_upfront-cost.svg';
import { ReactComponent as PhoneSelfieFront } from 'public/icons/icon_custom_phone-selfie-front.svg';
import { ReactComponent as TakingPicturesX3 } from 'public/icons/icon_custom_taking-pictures-camerasx3.svg';
import { ReactComponent as BackArrow } from 'public/icons/icon_custom_back-arrow_.svg';
import { ReactComponent as CircleFill } from 'public/icons/icon_custom-circle-fill.svg';
import { ReactComponent as AlertCircle } from 'public/icons/streamline/icon_alert-circle.svg';
import { ReactComponent as CheckSqaured } from 'public/icons/icon_check-circle-squared.svg';
import { ReactComponent as DirectDebit } from 'public/icons/icon_direct-debit.svg';
import { ReactComponent as Add } from 'public/icons/streamline/icon_add.svg';
import { ReactComponent as RoundArrowBack } from 'public/icons/icon_arrow-round-back.svg';
import { ReactComponent as MultipleUsers } from 'public/icons/icon-multiple-users.svg';
import { ReactComponent as Email } from 'public/icons/icon--email.svg';
import { ReactComponent as Twitter } from 'public/icons/icon--twitter.svg';
import { ReactComponent as Facebook } from 'public/icons/icon--facebook.svg';
import { ReactComponent as Whatsapp } from 'public/icons/icon--whatsapp.svg';
import { ReactComponent as CheckCircle2 } from 'public/icons/icon_check-circle.svg';
import { ReactComponent as RayloLogo } from 'public/icon__raylo-logo.svg';
import { ReactComponent as InfoCircle } from 'public/icons/icon_InfoCircle.svg';
import { ReactComponent as PhoneRefresh } from 'public/icons/icon__phone-shield-refresh.svg';
import { ReactComponent as RedAlert } from 'public/icons/icon_red_alert.svg';
import { ReactComponent as WhiteAlert } from 'public/icons/icon_white_alert.svg';
import { ReactComponent as PaymentSuccessful } from 'public/icons/icon_payment_successful.svg';
import { ReactComponent as PaymentUnsuccessful } from 'public/icons/icon_payment_unsuccessful.svg';
import { ReactComponent as SecurityDeposit } from 'public/icons/icon_security_deposit.svg';
import { ReactComponent as RPLogo } from 'public/icons/icon_RPlogo.svg';
import { ReactComponent as RPLogoLight } from 'public/icons/icon_RPlogo-light.svg';
import { ReactComponent as RPLogoLightLarge } from 'public/icons/icon_RPlogo-light-large.svg';
import { ReactComponent as Info } from 'public/icon-custom-info.svg';
import { ReactComponent as RPWallet } from 'public/icons/icon_RPWallet.svg';
import { ReactComponent as RPTech } from 'public/icons/icon_RPTech.svg';
import { ReactComponent as RPRocket } from 'public/icons/icon_RPRocket.svg';

export const icons = [
  {
    iconName: 'CheckShield',
    icon: <CheckShield />,
  },
  {
    iconName: 'PhoneActionRefresh',
    icon: <PhoneActionRefresh />,
  },
  {
    iconName: 'CreditCardCheck',
    icon: <CreditCardCheck />,
  },
  {
    iconName: 'ShipmentPackage',
    icon: <ShipmentPackage />,
  },
  {
    iconName: 'CalendarFavoriteHeart',
    icon: <CalendarFavoriteHeart />,
  },
  {
    iconName: 'SynchronizeArrowClock',
    icon: <SynchronizeArrowClock />,
  },
  {
    iconName: 'NetworkShield',
    icon: <NetworkShield />,
  },
  {
    iconName: 'CheckCircle',
    icon: <CheckCircle />,
  },
  {
    iconName: 'CheckCircle2',
    icon: <CheckCircle2 />,
  },
  {
    iconName: 'ShieldPhone',
    icon: <ShieldPhone />,
  },
  {
    iconName: 'MoneyWalletOpen',
    icon: <MoneyWalletOpen />,
  },
  {
    iconName: 'Calendar',
    icon: <Calendar />,
  },
  {
    iconName: 'Charger',
    icon: <Charger />,
  },
  {
    iconName: 'PhoneActionShield',
    icon: <PhoneActionShield />,
  },
  {
    iconName: 'SimCard',
    icon: <SimCard />,
  },
  {
    iconName: 'CustomerSupport',
    icon: <CustomerSupport />,
  },
  {
    iconName: 'ShipmentInTransit',
    icon: <ShipmentInTransit />,
  },
  {
    iconName: 'PhoneDoubleCamera',
    icon: <PhoneDoubleCamera />,
  },
  {
    iconName: 'View',
    icon: <View />,
  },
  {
    iconName: 'ChargingBatteryFull',
    icon: <ChargingBatteryFull />,
  },
  {
    iconName: 'TakingPicturesCameras',
    icon: <TakingPicturesCameras />,
  },
  {
    iconName: 'Flash',
    icon: <Flash />,
  },
  {
    iconName: 'PhoneActionCheck',
    icon: <PhoneActionCheck />,
  },
  {
    iconName: 'CustomScreenProtector',
    icon: <CustomScreenProtector />,
  },
  {
    iconName: 'PhoneDamage',
    icon: <PhoneDamage />,
  },
  {
    iconName: 'Chevron',
    icon: <Chevron />,
  },
  {
    iconName: 'BackArrow',
    icon: <BackArrow />,
  },
  {
    iconName: 'CheckCircleFill',
    icon: <CheckCircleFill />,
  },
  {
    iconName: 'Call',
    icon: <Call />,
  },
  {
    iconName: 'RayloSymbol',
    icon: <RayloSymbol />,
  },
  {
    iconName: 'Close',
    icon: <Close />,
  },
  {
    iconName: 'EcologyGlobeNature',
    icon: <EcologyGlobeNature />,
  },
  {
    iconName: 'UpfrontCost',
    icon: <UpfrontCost />,
  },
  {
    iconName: 'PhoneSelfieFront',
    icon: <PhoneSelfieFront />,
  },
  {
    iconName: 'TakingPicturesX3',
    icon: <TakingPicturesX3 />,
  },
  {
    iconName: 'TrustPilotLogo',
    icon: <TrustPilotLogo />,
  },
  {
    iconName: 'CircleFill',
    icon: <CircleFill />,
  },
  {
    iconName: 'AlertCircle',
    icon: <AlertCircle />,
  },
  {
    iconName: 'CheckSqaured',
    icon: <CheckSqaured />,
  },
  {
    iconName: 'DirectDebit',
    icon: <DirectDebit />,
  },
  {
    iconName: 'Add',
    icon: <Add />,
  },
  {
    iconName: 'RoundArrowBack',
    icon: <RoundArrowBack />,
  },
  {
    iconName: 'MultipleUsers',
    icon: <MultipleUsers />,
  },
  {
    iconName: 'Email',
    icon: <Email />,
  },
  {
    iconName: 'Twitter',
    icon: <Twitter />,
  },
  {
    iconName: 'Facebook',
    icon: <Facebook />,
  },
  {
    iconName: 'Whatsapp',
    icon: <Whatsapp />,
  },
  {
    iconName: 'RayloLogo',
    icon: <RayloLogo />,
  },
  {
    iconName: 'InfoCircle',
    icon: <InfoCircle />,
  },
  {
    iconName: 'PhoneRefresh',
    icon: <PhoneRefresh />,
  },
  {
    iconName: 'RedAlert',
    icon: <RedAlert />,
  },
  {
    iconName: 'WhiteAlert',
    icon: <WhiteAlert />,
  },
  {
    iconName: 'PaymentSuccessful',
    icon: <PaymentSuccessful />,
  },
  {
    iconName: 'PaymentUnsuccessful',
    icon: <PaymentUnsuccessful />,
  },
  {
    iconName: 'SecurityDeposit',
    icon: <SecurityDeposit />,
  },
  {
    iconName: 'RPLogo',
    icon: <RPLogo />,
  },
  {
    iconName: 'RPLogoLight',
    icon: <RPLogoLight />,
  },
  {
    iconName: 'RPLogoLightLarge',
    icon: <RPLogoLightLarge />,
  },
  {
    iconName: 'Info',
    icon: <Info />,
  },
  {
    iconName: 'RPWallet',
    icon: <RPWallet />,
  },
  {
    iconName: 'RPTech',
    icon: <RPTech />,
  },
  {
    iconName: 'RPRocket',
    icon: <RPRocket />,
  },
];
