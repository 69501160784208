import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { Modal } from 'components/common/Modal';
import Container from 'elements/Container';
import { useSegmentIdentifyAndCheckoutTracking } from 'hooks/segment/segment.hooks';
import StepUpgradeContent from 'screens/Checkout/components/StepUpgradeContent';
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';
import useRayloQuery from 'utils/useRayloQuery';
import { activateOptimize } from 'utils/optimize';
import { IStepUpgrade } from './types';

export const StepUpgradeContainer = ({
  dataTestId,
  checkoutToken,
  // onBack,
  onSuccess,
}: IStepUpgrade) => {
  useSegmentIdentifyAndCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'upgrade',
    checkoutScreen: "let's upgrade you",
    when: 'on page load',
  });

  const {
    data: { checkout },
    loading,
  } = useRayloQuery(CheckoutSteps.stepUpgrade.query, {
    variables: {
      token: checkoutToken,
    },
  });

  const [showFields, setShowFields] = useState<boolean>(true);
  const [phone, setPhone] = useState<string | undefined>(undefined);
  const [oldPhone, setOldPhone] = useState<string>('unknown');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [customerInfo, setCustomerInfo] = useState<any>();
  const [feeSummary, setFeeSummary] = useState<{
    value: number;
    currencyCode: string;
  }>({
    value: 0,
    currencyCode: 'GBP',
  });

  useEffect(() => {
    activateOptimize();
  }, []);

  useEffect(() => {
    if (checkout?.customer) {
      setCustomerInfo(
        _.pick(
          checkout.customer,
          'firstName',
          'lastName',
          'dateOfBirth',
          'email',
        ),
      );
    }
  }, [checkout]);

  useEffect(() => {
    if (checkout) {
      const item = checkout.items[0];
      const { variant, originSubscription } = item;
      setPhone(variant.product?.displayName);
      if (originSubscription) {
        setOldPhone(originSubscription.activeAsset.variant.product.displayName);
        setFeeSummary(originSubscription.feeSummary.currentNonReturnFee);
      }
    }
  }, [checkout]);

  const [updateMutation] = useMutation(CheckoutSteps.stepUpgrade.mutation, {
    update: (
      proxy,
      {
        data: {
          updateCheckout: { errors },
        },
      },
    ) => {
      if (errors && errors.length > 0) {
        setIsSubmitting(false);
      } else {
        if (window.analytics) {
          window.analytics.identify(checkout.id, {
            email: checkout?.customerInfo?.email,
          });
        }
        setShowFields(false);
        onSuccess();
      }
    },
  });

  const onSubmit = () => {
    setIsSubmitting(true);
    updateMutation({
      variables: {
        ...customerInfo,
        checkoutToken,
      },
    });
  };

  if (loading) {
    return <Modal visible text="Loading..." />;
  }

  return (
    <Container dataTestId={dataTestId} noPadding>
      <StepUpgradeContent
        showFields={showFields}
        device={phone}
        oldDevice={oldPhone}
        feeSummary={feeSummary}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />
    </Container>
  );
};
