import React, {useEffect, useState } from 'react';
import _ from 'lodash';
import { activateOptimize } from 'utils/optimize'

// HOOKS
import useFormal from "@kevinwolf/formal-web";
import { useMutation } from '@apollo/client';
import useForceUpdate from 'use-force-update';

// GRAPHQL
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';

// VISUAL COMPONENTS
import * as I from "styles/inputs";
import * as T from 'styles/type';
import * as L from 'styles/layout';
import { TextInput } from 'components/common/TextInput';
import { Modal } from 'components/common/Modal';
import { BackForwardGroup } from 'components/common/BackForwardGroup';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { Button } from 'components/common/Button';
import { BackButton } from 'components/common/BackButton';

// FORM VALIDATION
import * as yup from "yup";
import useRayloQuery from 'utils/useRayloQuery'

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Please enter a valid email address")
    .nullable().default("")
});

export const StepEmail = ({onSuccess, onBack, checkoutToken}) => {

  // HOOKS
  const [formErrors, setFormErrors] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showFields, setShowFields] = useState(true)
  const [mode, setMode] = useState("upgrade")

  const [updateMutation] = useMutation(CheckoutSteps.stepEmail.mutation, {
    update: (proxy, {data: {updateCheckout: { errors, checkout }}}) => {
      if(errors && errors.length > 0) {
        setFormErrors(errors)
        setIsSubmitting(false)
      } else {

        if(window.analytics) {
          window.analytics.identify(checkout.id, { email: checkout.customerInfo.email })
        }

        setShowFields(false)
        //setTimeout(() => {
          setFormErrors(null)
          onSuccess()
        //}, 800)
      }
    }
  })
  const forceUpdate = useForceUpdate()

  const [initialValues, setInitialValues] = useState({ email: ""})

  const { data: { checkout }, loading } = useRayloQuery(CheckoutSteps.stepEmail.query, {
    variables: {
      token: checkoutToken
    }
  })

  const changeEmail = () => {
    setMode("standard")
  }

  // HANDLE FORM ERRORS
  useEffect(() => {
    if(formErrors) {
      let errors = {}
      _.each(formErrors, error => {
        switch(error.field) {
          case 'base' :
            errors.base = error.message
            break;
          case 'customer_info.email' :
            errors.email = error.message
            break;
          default:
        }
      })
      formal.setErrors(errors)
    } else {
      formal.clearErrors()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors])

  useEffect(() => {
    let email
    if(checkout) {
      if(checkout.customerInfo?.email) {
        email = checkout.customerInfo.email
      } else if(checkout.customer?.email) {
        email = checkout.customer?.email
      }

      if(email) {    
        setInitialValues(_.merge(
          initialValues,
          {
            email
          }
        ))
        forceUpdate()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout])

  const onSubmit = (values) => {
    setIsSubmitting(true)
    updateMutation({
      variables: {
        ...formal.values,
        ...values,
        checkoutToken: checkout?.token
      }
    })
  }

  // FORM HANDLER
  const formal = useFormal(initialValues, {
    schema,
    onSubmit: (values) => onSubmit(values)
  })

  useEffect(() => {
    activateOptimize()
  })

  if(loading) return (<Modal visible text="Loading..." />)
  return (
    <div className="checkout--step">
      {mode === "upgrade" ?
        <FadeIn visible={showFields}>
          <T.RayloBodyHeading>
          Is this email address correct?
          </T.RayloBodyHeading>
        </FadeIn>
        :
        <FadeIn visible={showFields}>
          <T.RayloSectionHeader>Your Details</T.RayloSectionHeader>
        </FadeIn>
      }


      {mode === "upgrade" ? 
      
        
      <>
        <SlideDownFadeIn visible={showFields}>
          <I.RayloFormRow>
            <I.RayloFieldContent>
              <T.RayloAddress>{formal.values.email}</T.RayloAddress>
            </I.RayloFieldContent>
          </I.RayloFormRow>
        </SlideDownFadeIn>
        <SlideDownFadeIn visible={showFields}>
        <L.SignupAddressActions>
          <div>
              <Button
                buttonStyle="secondary"
                limitWidth="true"
                onClick={() => changeEmail() }>No, update my email</Button>
          </div>
          <div>
              <Button
                buttonStyle="primaryBlue"
                onClick={() => onSubmit()}>Yes</Button>
            </div>
        </L.SignupAddressActions>
        </SlideDownFadeIn>
        <SlideDownFadeIn visible={showFields}>
        <BackButton onClick={onBack} />
        </SlideDownFadeIn>
      </>
  
    : 
        <form {...formal.getFormProps()}>
          <SlideDownFadeIn visible={showFields}>
            <TextInput
              label="Email Address"
              type="email"
              field={formal.getFieldProps('email')}
              errorMessage={formal.errors.email}
            />
          </SlideDownFadeIn>

          {formal.errors.base && <FadeIn visible={showFields}><div style={{marginBottom: 48}}>
            <I.RayloError visible={formal.errors.base}>
              {formal.errors.base || <span>&nbsp;</span>}
            </I.RayloError></div>
          </FadeIn>}

          <SlideDownFadeIn visible={showFields}>
            <BackForwardGroup submit={
              {
                ...formal.getSubmitButtonProps(),
                disabled: isSubmitting
              }} onBack={onBack} />
          </SlideDownFadeIn>
        </form>
      }
    </div>
  )
}
