/*
.legalText {
  color: white;
}

.legalText p {
  font-family: 'Raylo-Regular', Arial, sans-serif;
  font-size: 0.875em;
  font-weight: normal;
  line-height: 1.625;
  margin-bottom: 1em;
}

.legalText table {
  border-collapse: collapse;
  margin: 2em 0;
}
.legalText td, .legalText th {
  padding: 10px;
  vertical-align: top;
  text-align: left;
}

.legalText::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  background:#333;
}

.legalText::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #FFCEC7;
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
*/

import styled from 'styled-components/macro';
import colors from './colors';
import { primaryColors, secondaryColors } from './variables/colors';


export const RayloInsuranceText = styled.div`
  color: white;
  h1, h2, h3 {
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-weight: 500;
    margin: 3em 0 1em 0;
    line-height: 1.625;
  }
  h1 {
    font-size: 1.1em;
    margin-top: 0;
  }
  h2 {
    font-size: 0.875em;
    color: ${colors.rayloPink};
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: bold;
    
  }
  h2 + h3 {
    margin-top: 0em;
  }
  h3 {
    font-size: 0.875em;
  }

  th {
    color: ${colors.rayloPink};
  }

  td {
    color: ${colors.white};
  }

  td, th {
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875em;
  }


  a {
    color: ${secondaryColors.p02};
  }


  p, li {
    color: ${primaryColors.c01};
    font-family: 'Raylo-Regular', Arial, sans-serif;
    font-size: 0.875em;
    font-weight: normal;
    line-height: 1.625;
    margin-bottom: 1em;

    span.generated {
      color: ${secondaryColors.p02};
    }
  }
  li {
    margin-bottom: 0;
  }
`

export const RayloLegalText = styled.div`
  color: ${({theme}) => theme?.terms?.copy?.color};
  border: ${({theme}) => theme?.terms?.border};
  padding: 10px;
  margin-bottom: 20px;

  @media (min-width: 541px) {
    ${({lightMode}) => !lightMode && `
      height: 400px;
      overflow-y: scroll;
    `}
  }

  table.important {
    border-collapse: collapse;
    margin: 2em 0;
    border: 1px solid ${({theme}) => theme?.terms?.table?.borderColor};
  }

  table.important td, table.important th {
    padding: 10px;
    vertical-align: top;
    text-align: left;
  }
  
   table.non-return-fees {
    border-collapse: collapse;
    border: 1px solid #6C6C6C;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  table.non-return-fees td, table.non-return-fees th {
    padding: 4px;
    vertical-align: top;
    text-align: center;
  }
  table.non-return-fees td {
    border-bottom: 1px solid #6C6C6C;
  }

  table.damage {
    border-collapse: collapse;
    border: 1px solid #6C6C6C;
    margin-top: 2em;
  }

  table.damage td, table.damage th {
    padding: 4px;
    vertical-align: top;
    text-align: left;
  }
  table.damage td {
    border-bottom: 1px solid #6C6C6C;
  }

  h1, h2, h3 {
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-weight: 500;
    margin: 3em 0 1em 0;
    line-height: 1.625;
  }
  h1 {
    font-size: 1.1em;
    margin-top: 0;
  }
  h2 {
    font-size: 0.875em;
    color: ${({theme}) => theme?.terms?.subHeader?.color};
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: bold;
    
  }
  h2 + h3 {
    margin-top: 0em;
  }
  h3 {
    font-size: 0.875em;
  }

  th {
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875em;
    color: ${({theme}) => theme?.terms?.subHeader?.color};

  }
  
  table.centered { text-align: center }

  }


  p {
    font-family: 'Raylo-Regular', Arial, sans-serif;
    font-size: 0.875em;
    font-weight: normal;
    line-height: 1.625;
    margin-bottom: 1em;

    span.generated {
      color: ${({theme}) => theme?.terms?.subHeader?.color};
    }
  }

  ::-webkit-scrollbar {
    ${({lightMode}) => !lightMode && `
      -webkit-appearance: none;
      width: 10px;
      background:#333;
    `}
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: ${colors.rayloPink};
  }

`

export const RayloLegalTable = styled.table`
  border-collapse: collapse; 
  border:1px solid ${colors.raylo3Dark};
  margin: 30px 0;


  * {
    text-align: left;
  }

  td, th {
    border: 1px solid ${colors.raylo3Dark};
    padding: 7px;
  }

  th:first-of-type {
    width: 60%;
  }

  .double-border {
    border-top: 2px solid ${colors.raylo3Dark};
  }
`
