import React from 'react'
import styled from 'styled-components/macro'
import _ from 'lodash'
import LazyLoad from 'react-lazyload';

function buildUrl(baseUrl, queryParams) {
  let url = new URL(baseUrl);
  let newSearchParams = new URLSearchParams(url.searchParams);
  Object.keys(queryParams).forEach(function (key) {
    if(!!queryParams[key]) {
      newSearchParams.set(key, queryParams[key]);
    }
  });

  url.search = newSearchParams.toString();
  return url.toString();
}

const maxDimensions = {
  'large': {width: null, height: 900},
  'medium': {width: null, height: 592},
  'small': {width: null, height: 400},
  'icon': {width: null, height: 100},
  'responsive': {width: 1024, height: 1024}
}

export const PhoneImage = ({ source, size, alt, bg, fm='jpg', notlazy = false }) => {

  if(notlazy) {
    return <InternalPhoneImage size={size} source={source} alt={alt} bg={bg} fm={fm} />
  } else {
    return (
      <LazyLoad height={maxDimensions[size].height}>
        <InternalPhoneImage size={size} source={source} alt={alt} bg={bg} fm={fm} />
      </LazyLoad>
    )
  }
}

export const InternalPhoneImage = ({ source, size, alt, bg, fm='jpg' }) => {
    let dimensions = maxDimensions[size]
    let commonParams = {
      bg,
      fm,
      fit: 'max',
      w: dimensions.width || 1024,
      h: dimensions.height || 1024,
      dpr: window.devicePixelRatio || 1,
      trim: "auto",
      "trim-md": "0"
    }
    let formattedSource = buildUrl(source, commonParams)
    formattedSource = `${formattedSource}&auto=format&fm=webp`
    let pixelRatios = [1, 2]

    if (window.devicePixelRatio) {
      pixelRatios.push(window.devicePixelRatio)
    }
    pixelRatios = _.reverse(_.sortBy(_.uniq(pixelRatios)))

    /*
      Produces a string for the srcSet attribute, in the form of:
        https://imgix.com/high_dpr.jpg 2x, https://imgix.com/low_dpr.jpg 1x
    */
    let srcSet = pixelRatios.map(dpr => {
      let url = buildUrl(source, { ...commonParams, dpr: dpr})
      return `${url} ${dpr}x`
    }).join(', ')
  return (
    <>
      {{
        'large':
        <>
          <LargePhone src={`${formattedSource}`} srcSet={srcSet} alt={alt} />
        </>,

        'medium':
        <>
          <MediumPhone src={`${formattedSource}`} srcSet={srcSet} alt={alt} />
        </>,

        'small':
        <>
          <SmallPhone src={`${formattedSource}`} srcSet={srcSet} alt={alt} />
        </>,
        
        'icon':
        <>
          <IconPhone src={`${formattedSource}`} srcSet={srcSet} alt={alt} />
        </>,
        'responsive':
        <>
          <ResponsivePhone src={`${formattedSource}`} srcSet={srcSet} alt={alt} />
        </>
      }[size]
    }</>
  )
}

export const Phone = styled.img`
  display: block;
  height: auto;
  color: #FFF;
  /* Color is for alt text */
`

export const LargePhone = styled(Phone)`
  max-height: 900px;
`

export const MediumPhone = styled(Phone)`
  max-height: 592px;
`

export const SmallPhone = styled(Phone)`
  max-height: 400px;
`
export const IconPhone = styled(Phone)`
  max-height: 100px;
`

export const ResponsivePhone = styled(Phone)`
  max-width: 100%;

  @media (max-width: 1024px) and (orientation: landscape) {
    max-height: 700px;
  }

  @media (max-height: 1024px) {
    max-height: 600px;
  }

  @media (max-height: 660px) {
    /* max-height: 425px; */
  }
`
