import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { StyledNumberedSteps, StyledOutlinedNumber } from './styles';
import { INumberedSteps } from './types';

export const NumberedSteps = ({ dataTestId, listItems }: INumberedSteps) => {
  return (
    <StyledNumberedSteps data-testid={dataTestId}>
      {listItems?.map((item, i) => (
        <>
          <Container
            key={item.title}
            flexRow
            backgroundColor={secondaryColors.c05}
            padding={`${px2Rem(20)} ${px2Rem(16)}`}
            boxShadow
          >
            <StyledOutlinedNumber>
              <div
                style={{
                  marginTop: `-${px2Rem(1)}`,
                  width: px2Rem(20),
                  textAlign: 'center',
                }}
              >
                {i + 1}
              </div>
            </StyledOutlinedNumber>
            <Container padding="0 0 0 16px" margin="auto">
              <Spacer height={5} />
              <Header level="5" fontSize={16} color={primaryColors.c01}>
                {item.title}
              </Header>
              <Copy
                color={secondaryColors.c02}
                lineHeight={19}
                fontSize={16}
                styles={`
                margin-top: ${px2Rem(14)}
              `}
              >
                {item.text}
              </Copy>
            </Container>
          </Container>
          <Spacer height={24} />
        </>
      ))}
    </StyledNumberedSteps>
  );
};
