import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';

const StyledStepNameEmailContent = styled.div`
  @media (max-width: 500px) {
    margin-top: ${px2Rem(40)};
  }
`;

export { StyledStepNameEmailContent };
