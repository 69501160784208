export const InsuranceCopy = () => (
  <div style={{
    padding: "16px 0 60px 0"
  }}>
    <p><strong>Our Policy </strong></p>
    <p>The policy is held in Our name and covers Us against the Goods being accidentally lost, stolen, or accidentally damaged. Given this is Our policy, You have no direct rights under the policy, but instead can benefit from Our policy, and claim on Our behalf should something happen to the Goods.</p>
    <p>You will only receive the benefits under Our policy if We make a successful claim, and any benefit to You will be limited to the amount that We receive.</p>
    <p><strong>The Underwriter </strong></p>
    <p>Our insurance is underwritten by Inter Partner Assistance SA, which is fully owned by the AXA Partners Group (the “Underwriter”) and administered by Pier Insurance Managed Services Limited.</p>
    <p><strong>Excess</strong></p>
    <p>As with most insurance policies, if We make a successful claim, there will be an excess charged as a contribution towards the cost of a repair to the Goods, or for a replacement Goods.</p>
    <p>If You claim on Our behalf on Our policy, You will be responsible for the payment of an excess, as follows:</p>

    <table>
      <tr>
        <th>Goods type</th>
        <th>Accidental damage or liquid damage claims</th>
        <th>Accidental loss or theft claims</th>
      </tr>
      <tr>
        <td>All other goods</td>
        <td>&pound;89.00</td>
        <td>&pound;125.00</td>
      </tr>
      <tr className='double-border'>
        <td>Macbook Air 13-inch M1</td>
        <td rowspan="1">&pound;99.00</td>
        <td rowspan="1">&pound;125.00</td>
      </tr>
      <tr className='double-border'>
        <td>iPhone 13 Pro</td>
        <td rowspan="6">&pound;99.00</td>
        <td rowspan="6">&pound;175.00</td>
      </tr>
      <tr>
        <td>iPhone 13 Pro Max</td>
      </tr>
      <tr>
        <td>iPad Pro 11-inch WiFi</td>
      </tr>
      <tr>
        <td>iPad Pro 12.9-inch WiFi</td>
      </tr>
      <tr>
        <td>Samsung Galaxy S22</td>
      </tr>
      <tr>
        <td>Samsung Galaxy Tab S8 Ultra 512GB</td>
      </tr>
      <tr>
        <td>Macbook Pro 14-inch M1</td>
        <td rowspan="2">&pound;150.00</td>
        <td rowspan="2">&pound;175.00</td>
      </tr>
      <tr>
        <td>Macbook Pro 16-inch M1</td>
      </tr>
      <tr>
        <td>Samsung Galaxy S22 Ultra 128GB, 256GB, 512GB</td>
      </tr>
      <tr>
        <td>Samsung Galaxy Tab S8 Ultra 512GB</td>
      </tr>
    </table>

    <p><strong>What is covered? </strong></p>
    <ul type="disc">
      <li>Repair costs of the Goods if they are accidentally damaged.</li>
      <li>Replacement of the Goods if they are accidentally lost.</li>
      <li>Replacement of the Goods if they are stolen.</li>
      <li>Repair or replacement of the Goods in the event of mechanical breakdown when out of the manufacturer&rsquo;s warranty period.</li>
    </ul>
    <p><strong>What is not covered? </strong></p>
    <ul type="disc">
      <li>Any claim where all reasonable precautions have not been taken, for example accidental damage when a case and screen protector were not fitted.</li>
      <li>Theft from a motor vehicle unless the Goods were out of view in either an enclosed compartment, boot or luggage space and the vehicle&rsquo;s windows and doors were securely locked. In such an event You will need to provide additional evidence.</li>
      <li>Theft or loss of the Goods that have been left unattended, when away from Your home.</li>
      <li>Repairs carried out by third party repair centres not authorised or agreed for use by the Underwriter.</li>
      <li>A device that has been operating without a functioning network SIM card.</li>
      <li>Repair costs for wear, tear and cosmetic damage to the Goods that do not stop the Goods working normally.</li>
      <li>The relevant excess, as set out above.</li>
    </ul>
    <p><strong>Restrictions on the cover </strong></p>
    <ul type="disc">
      <li>2 claims within a rolling 12-month period.</li>
      <li>Replacement Goods may be with refurbished items, where possible with an identical specification, but may differ in colour. Replacements come with a 12-month warranty.</li>
    </ul>
    <p><strong>Where does the cover apply? </strong></p>
    <p>The policy will cover the Goods anywhere in the world. Repaired or replacement Goods will be delivered only to a UK address.</p>
    <p><strong>How to make a claim on Our behalf on Our Policy </strong></p>
    <p>Log in to your account on the website, or contact Us on the chat application, via email (<a href="mailto:help@raylo.com">help@raylo.com</a>), or via phone (<a href="tel:0333 050 9880">0333 050 9880</a>) for information about how to make a claim.</p>
  </div>
)
