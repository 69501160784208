import DoubleButtonFullWidth from 'components/DoubleButtonFullWidth';
import EditContentBox from 'components/EditContentBox';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { useEffect, useState } from 'react';
import { useCheckoutAddressContext } from 'screens/Checkout/containers/StepAddress/hooks/useCheckoutAddressContext';
import {
  CheckoutMode,
  useGetCheckoutMode,
} from 'screens/Checkout/hooks/useGetCheckoutMode';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { StyledAddressCheck } from './styles';
import { IAddressCheck } from './types';

export const AddressCheck = ({ dataTestId }: IAddressCheck) => {
  const [newCustomer, setNewCustomer] = useState(false);
  const { checkoutMode } = useGetCheckoutMode();
  const {
    address,
    newAddress,
    previousAddress,
    addPreviousAddress,
    setAddressCheck,
    onSubmit,
    onSubmitPreviousAddress,
    handleAddPreviousAddress,
    setEditAddress,
  } = useCheckoutAddressContext();

  useEffect(() => {
    setNewCustomer(checkoutMode === CheckoutMode.NEW_CUSTOMER);
  }, [checkoutMode]);

  const handleEdit = () => {
    setEditAddress(true);
    setAddressCheck(false);
  };

  const handleSubmit = () => {
    onSubmit();
  };

  const addressToDisplay = previousAddress || newAddress || address;

  return (
    <StyledAddressCheck data-testid={dataTestId}>
      <Spacer height={32} />
      <Container noPadding>
        {addressToDisplay && (
          <EditContentBox
            dataTestId="addressToBeConfirmed"
            onClick={handleEdit}
            backgroundColor={secondaryColors.c04}
          >
            <Copy color={primaryColors.c01} fontSize={18} lineHeight={22}>
              {addressToDisplay?.line1}
            </Copy>
            <Copy color={primaryColors.c01} fontSize={18} lineHeight={22}>
              {addressToDisplay?.line2}
            </Copy>
            <Copy color={primaryColors.c01} fontSize={18} lineHeight={22}>
              {addressToDisplay?.line3}
            </Copy>
            <Copy color={primaryColors.c01} fontSize={18} lineHeight={22}>
              {addressToDisplay?.city}
            </Copy>
            <Copy color={primaryColors.c01} fontSize={18} lineHeight={22}>
              {addressToDisplay?.postcode}
            </Copy>
          </EditContentBox>
        )}
      </Container>
      <Spacer height={48} />
      <Container noPadding>
        {newCustomer && !addPreviousAddress ? (
          <>
            <Header level={4} fontSize={16} color={primaryColors.c01}>
              Have you lived at this address for more than 3 years?
            </Header>
            <Spacer height={24} />
            <DoubleButtonFullWidth
              topButtonText="Yes"
              bottomButtonText="No"
              topButtonOnClick={handleSubmit}
              bottomButtonOnClick={handleAddPreviousAddress}
              topButtonTestId="confirmAddress"
              ghostMode
            />
          </>
        ) : (
          <>
            {addPreviousAddress && (
              <>
                <Header level={4} fontSize={16} color={primaryColors.c01}>
                  Is this the correct address?
                </Header>
                <Spacer height={24} />
              </>
            )}
            <DoubleButtonFullWidth
              topButtonText="Yes"
              bottomButtonText="No"
              topButtonOnClick={
                addPreviousAddress ? onSubmitPreviousAddress : handleSubmit
              }
              bottomButtonOnClick={handleEdit}
              topButtonTestId="confirmAddress"
            />
          </>
        )}
      </Container>
    </StyledAddressCheck>
  );
};
