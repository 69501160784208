import ProgressBarWithBackArrow from 'components/ProgressBarWithBackArrow';
import Container from 'elements/Container';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { SLUGS } from 'screens/Checkout/helpers/slugs';
import {
  CheckoutMode,
  useGetCheckoutMode,
} from 'screens/Checkout/hooks/useGetCheckoutMode';
import { px2Rem } from 'utils/px2Rem';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { StyledCheckoutSteps } from './styles';
import { ICheckoutSteps } from './types';

export const CheckoutStepsComponent = ({ dataTestId }: ICheckoutSteps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [previousStep, setPreviousStep] = useState();
  const [previousSlug, setPreviousSlug] = useState();

  const { progressBarSteps, currentStep, setCurrentStep } =
    useCheckoutContext();
  const { checkoutMode } = useGetCheckoutMode();
  const history = useHistory();

  useEffect(() => {
    setActiveIndex(progressBarSteps?.indexOf(currentStep));
  }, [progressBarSteps, currentStep]);

  useEffect(() => {
    const prevStep = progressBarSteps[activeIndex - 1];
    setPreviousStep(prevStep);
    setPreviousSlug(SLUGS[prevStep]);
  }, [activeIndex]);

  const handleBack = () => {
    if (activeIndex > 0) {
      history.push(`/checkout/step/${previousSlug}`);
      setCurrentStep(previousStep);
    } else if (checkoutMode !== CheckoutMode.NEW_CUSTOMER) {
      history.push('/account');
    } else {
      history.push('/checkout');
    }
  };

  return (
    <StyledCheckoutSteps data-testid={dataTestId}>
      <Container padding={`0 ${px2Rem(24)}`}>
        <ProgressBarWithBackArrow
          steps={progressBarSteps}
          activeIndex={activeIndex}
          backArrowOnClick={handleBack}
        />
      </Container>
    </StyledCheckoutSteps>
  );
};
