export const getSessionStorage = (key: string) => {
  if (window?.sessionStorage) {
    const value = window.sessionStorage.getItem(key);
    return value;
  }
  return null;
};

export const getSessionStorageObject = (key: string) => {
  if (window?.sessionStorage) {
    return JSON.parse(window.sessionStorage.getItem(key) || '');
  }
  return null;
};

export const removeSessionStorage = (key: string) => {
  if (window?.sessionStorage?.getItem(key)) {
    window.sessionStorage.removeItem(key);
  }
  return null;
};

export const setSessionStorage = (key: string, value: any) => {
  // clear existing storage first
  if (getSessionStorage(value)) {
    removeSessionStorage(value);
  }
  let setValue = value;
  if (typeof value === 'object') {
    setValue = JSON.stringify(value);
  }
  if (window?.sessionStorage) {
    window.sessionStorage.setItem(key, setValue);
  }
  return null;
};
