import React, { useState } from 'react';
import Select, { components } from 'react-select'
import styled from 'styled-components/macro';
import _ from 'lodash';

import colors from 'styles/colors';
import pinkDownArrow from 'public/icon--arrow--pink--down.svg';
import { getAvailableOptions } from 'utils/getAvailableOptions';

export const ProductDropdown = ({ products, currentVariant, onChange, setIsRefurb }) => {

  const [isFocused, setIsFocused] = useState(false)

  let newProducts = []
  let refurbProducts = [] 

  let defaultProduct
  
  _.each(products, o => {

    let newVariants = _.filter(o.variants, {condition: "NEW"})
    let refurbVariants = _.filter(o.variants, {condition: "REFURBISHED"})



    
    if(newVariants.length > 0) {
      //let cheapestNew = _.flatten(_.map(newVariants, o => _.map(o.pricePlans, o => o.costSummary.recurring.totalAmount.valueAfterTax.value)))
      let product = {
        ...o,
        variants: newVariants,
        // fromPrice: Math.min(...cheapestNew),
        refurb: false,
        new: true
      }
      newProducts.push(product)

      if(!!currentVariant && _.find(newVariants, {id: currentVariant.id})) {
        defaultProduct = product
      }
    }
    if(refurbVariants.length > 0) {
      // let refurbNew = _.flatten(_.map(refurbVariants, o => _.map(o.pricePlans, o => o.costSummary.recurring.totalAmount.valueAfterTax.value)))
      let product = {
        ...o,
        variants: refurbVariants,
        // fromPrice: Math.min(...refurbNew),
        refurb: true,
        new: false
      }

      refurbProducts.push(product)

      if(!!currentVariant && _.find(refurbVariants, {id: currentVariant.id})) {
        defaultProduct = product
      }
    }

  })

  newProducts = _.reverse(_.sortBy(newProducts, ['position']))
  refurbProducts = _.reverse(_.sortBy(refurbProducts, ['position']))

  const combinedProducts = _.concat(newProducts, refurbProducts)

  const allProducts = getAvailableOptions(combinedProducts)

  const toggle = () => setIsFocused(!isFocused)

  const DownArrow = () => {
    return <i style={{ display: 'inline-block', width: 12, height: 7, backgroundImage: `url(${pinkDownArrow})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} />;
  }
  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <DownArrow />
        </components.DropdownIndicator>
      )
    );
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
      padding: 0,
    }),
    control: state => ({
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      background: 0,
      outline: 0,
      borderRadius: 0,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: `${colors.rayloBlue3Dark}`,
      fontFamily: 'Raylo-Regular, sans-serif',
      fontSize: 14,
      color: `${colors.rayloPink}`,
      lineHeight: 2.14,
      padding: '0.5em 0 0.5em 1em',
      transition: 'border-color .35s',

      '&:focus': {
        borderColor: `${colors.rayloLight}`,
      },

      '&.react-select__control--menu-is-open': {
        borderColor: `${colors.rayloLight}`,
      },

      '&.react-select__control--is-focused': {
        borderColor: `${colors.rayloLight}`,
      },
    }),
    valueContainer: provided => ({
      ...provided,
      padding: 0,
    }),
    input: (provided, state) => ({
      ...provided,
      fontFamily: 'Raylo-Regular, sans-serif',
      fontSize: 14,
      fontWeight: 'normal',
      color: `${colors.rayloPink}`,
      padding: 0
    }),
    placeholder: provided => ({
      color: `${colors.rayloPink}`
    }),
    dropdownIndicator: provided => ({
      padding: '0 1em 0 0'
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none'
    }),
    singleValue: provided => ({
      ...provided,
      color: `#FFF`,
      width: '100%',
      cursor: 'default',
      '.productPrice': {
        display: 'none'
      }
    }),
    menu: (provided) => ({
      ...provided,
      margin: 0,
      width: '100%',
      zIndex: 10,
      background: colors.rayloBlue
    }),
    menuList: (provided) => ({
      ...provided,
      margin: 0,
      borderRadius: 0,
      padding: 0,
      background: colors.rayloBlue
    }),
    option: (provided, state) => ({
      ...provided,
      color: '#FFF',
      backgroundColor: 'transparent',
      fontFamily: 'Raylo-Regular, sans-serif',
      fontSize: 14,
      height: '50px',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      width: '100%',
      borderBottomColor: `${colors.rayloBlue3Dark}`,
      display: 'flex',
      justifyContent: 'flexStart',
      alignItems: 'center',
      '&:last-child': {
        borderBottomWidth: 0,
      },
      '> div': {
        opacity: 0.5
      },
      '&:hover > div': {
        opacity: 1
      },
    })
  }
  
  const handleChange = (item) => {
    setIsRefurb(item.refurb)
    onChange(item.id)
  }
  const formatOptionLabel = props => {


    return (
      <ProductOption>
        <ProductOptionName>
          <span>{props.refurb ? "Refurbished " : ""}
          {props.displayName}</span>
          {props.new && !props.preOrder ? <ProductOptionTag>NEW</ProductOptionTag> : null}
        </ProductOptionName>

        {
          props.preOrder ? <ProductOptionTag>PRE-ORDER</ProductOptionTag> : null
        }
      </ProductOption>
    )

  }

  return (
    <Select
      options={allProducts}
      defaultValue={defaultProduct}
      styles={customStyles}
      components={{ DropdownIndicator }}
      classNamePrefix="react-select"
      onFocus={toggle}
      onBlur={toggle}
      onChange={(item) => handleChange(item)}
      captureMenuScroll
      isSearchable={false}
      formatOptionLabel={formatOptionLabel}
      value={defaultProduct}
    />
  )
}



export const ProductOption = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  line-height: 1em;
  @media screen and (max-width: 380px) {
    .productPrice { display: none }
  }
`
export const ProductOptionName = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
export const ProductOptionTag = styled.div`
  margin-left: 10px;
  border: 1px solid ${colors.rayloLight};
  font-size: 85%;
  padding: 5px;
`
export const ProductOptionPrice = styled.div`

`