import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { activateOptimize } from 'utils/optimize'

// HOOKS
import { useMutation } from '@apollo/client';

// STATE
import { useUiContext } from 'utils/useUiContext';

// GRAPHQL
import { MU_CREATE_PAYMENT } from '../graphQL/mutations';
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';

// STRIPE
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, useStripe, Elements} from '@stripe/react-stripe-js';

// VISUAL COMPONENTS
import * as T from 'styles/type';
import * as I from 'styles/inputs';
import * as L from 'styles/layout';

import { CardAddress } from 'screens/Feedback/CardAddress'
import { CardError } from 'screens/Feedback/CardError'
import { CardDecline } from 'screens/Feedback/CardDecline'
import { CardCredit } from 'screens/Feedback/CardCredit'

import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { BackForwardGroup } from 'components/common/BackForwardGroup';
import { Button } from 'components/common/Button';
import { BackButton } from 'components/common/BackButton';
import { Modal } from 'components/common/Modal';
import useRayloQuery from 'utils/useRayloQuery'

// ICONS
import visaIcon from 'public/card--visa.svg';
import mastercardIcon from 'public/card--mastercard.svg';
import amexIcon from 'public/card--amex.svg';
import maestroIcon from 'public/card--maestro.svg';
import lockIcon from 'public/icon--lock.svg';
import sslIcon from 'public/SSL-Secure-Connection.png';

const PaymentForm = ({clientSecret, onBack, onSuccess,onChangeAddress, inputMode, onChangeDetails, checkout}) => {

  const stripe = useStripe();

  const [ref, setRef] = useState(undefined)
  const [error, setError] = useState(undefined)
  const [isFocused, setIsFocused ] = useState(false)
  const [showFields, setShowFields] = useState(true)
  const setFocus = () => setIsFocused(!isFocused)
  const { setShowFeedback, setShowModal } = useUiContext()
  const [isSubmitting, setIsSubmitting ] = useState(false)


  const onSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    setShowModal(true)
    const { customerInfo, address } = checkout

    const { error } = await stripe.handleCardSetup(
      clientSecret,
      ref,
      {
        payment_method_data: {
          billing_details: {
            name: `${customerInfo?.firstName} ${customerInfo?.lastName}`,
            address: {
              line1: address.line1,
              line2: address.line2,
              city: address.city,
              country: 'GB',
              postal_code: address.postcode,
              state: address.region
            },
            email: customerInfo.email
          }
        }
      }
    )
    setIsSubmitting(false)
    setShowModal(false)

    if(error) {
      setError(error)
    } else {
      setShowFields(false)
      //setTimeout(() => {
        onSuccess()
      //}, 800)
    }
  }

  const resetError = () => {
    setShowFeedback(false)
    setError(null)
  }

  const changeAddress = () => {
    setShowFeedback(false)
    onChangeAddress()
  }

  if(error) {
    switch(error.code) {
      case "incorrect_zip" :
        setShowFeedback(true)
        return (<CardAddress address={checkout.address} onBack={() => resetError()} onChange={() => changeAddress()} />)
      case "processing_error" :
      setShowFeedback(true)
        return (<CardError onClick={() => resetError()} />)
      case "generic_decline" :
      setShowFeedback(true)
        return (<CardDecline onClick={() => resetError()} />)
      case "card_declined" :
        setShowFeedback(true)
        if(error.decline_code === "fraudulent") {
          return (<CardCredit onClick={() => resetError()} />)
        } else {
          return (<CardDecline onClick={() => resetError()} />)
        }
      default:
    }
  }

  let cardDetails = {}
  if(!inputMode && checkout.paymentInfo?.cardDetails) {
    cardDetails = checkout.paymentInfo.cardDetails
  }

  const BrandIcon = styled.img`
    display: inline-block;
    margin-right: 12px;
  `

  const brandIcon = (brand) => {
    switch(brand) {
      case "visa" : return (<BrandIcon src={visaIcon} width={32} height={20} />);
      case "amex" : return (<BrandIcon src={amexIcon} width={32} height={20} />);
      case "mastercard" : return (<BrandIcon src={mastercardIcon} width={32} height={20} />);
      case "maestro" : return (<BrandIcon src={maestroIcon} width={32} height={20} />);
      default: return (<p>{brand}</p>)
    }
  }


  return(
    <div className="checkout--step">
      <FadeIn visible={showFields}>
        <div>
          <T.RayloSectionHeader style={{marginBottom: 20}}>Card Details</T.RayloSectionHeader>
          <T.SecuredByStripe>
            <img src={lockIcon} alt="Icon of a secure lock"/>
            <div className="stripe-sub-header">
              <p>Your card will not be charged. This is to check that your billing address matches your delivery
                address. You'll have a chance to review and edit your order later.</p>
            </div>
          </T.SecuredByStripe>
          <L.AcceptedCards>
            <span>We accept:</span>
            <ul>
              <li>
                <img src={mastercardIcon} alt="Mastercard" />
              </li>
              <li>
                <img src={visaIcon} alt="Visa" />
              </li>
              <li>
                <img src={maestroIcon} alt="Maestro" />
              </li>
              <li>
                <img src={amexIcon} alt="American Express" />
              </li>
            </ul>

          </L.AcceptedCards>
        </div>
      </FadeIn>

      <div style={{ marginBottom: 40 }}>
        {inputMode ? (
          <form onSubmit={onSubmit}>
            <SlideDownFadeIn visible={showFields}>
              <I.RayloFormRow>
                <I.RayloFieldHeader>
                  <I.RayloFieldLabel isFocused={isFocused}>
                    Payment Details
                  </I.RayloFieldLabel>
                </I.RayloFieldHeader>

                <CardElement
                  onReady={setRef}
                  onFocus={setFocus}
                  onBlur={setFocus}
                  options={{
                    hidePostalCode: true,
                    style: {
                      base: {
                        fontFamily: "'Raylo-Medium', Arial, sans-serif",
                        fontWeight: 500,
                        fontSize: '16px',
                        color: '#FFF',
                        iconColor: '#FFF',
                      },
                      invalid: {
                        color: '#FFCEC7',
                        iconColor: '#FFCEC7'
                      }
                    }
                  }} />



                <I.RayloError visible={error}>
                  {(error && error.message) || <span>&nbsp;</span>}
                </I.RayloError>
              </I.RayloFormRow>
            </SlideDownFadeIn>

            <SlideDownFadeIn visible={showFields}>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <BackForwardGroup
                  onBack={onBack}
                  submit={{
                    type: "submit",
                    disabled: isSubmitting,
                    className: 'stripe-next-button'
                  }}
                />
                <img src={sslIcon} alt="SSL Secure Connection" height={50} />
              </div>
              
            </SlideDownFadeIn>
          </form>
        ) : (
          <>
            <SlideDownFadeIn visible={showFields}>
              <I.RayloFormRow>
                <I.RayloFieldHeader>
                  <I.RayloFieldLabel isFocused={true}>Are the details correct?</I.RayloFieldLabel>
                </I.RayloFieldHeader>
                {cardDetails &&
                  <I.RayloFieldContent>
                    <div style={{ display: 'flex', alignItems: 'center', color: '#FFF' }}>
                      {cardDetails.brand && brandIcon(cardDetails.brand)} Ending in: {cardDetails.last4}
                    </div>
                  </I.RayloFieldContent>
                }
              </I.RayloFormRow>
            </SlideDownFadeIn>

            <SlideDownFadeIn visible={showFields}>
              <L.SignupAddressActions>
                <div>
                  <Button
                    buttonStyle="secondary"
                    limitWidth="true"
                    onClick={onChangeDetails}>No, let me change them</Button>
                </div>

                <div>
                  <Button
                    buttonStyle="primaryBlue"
                    onClick={() => onSuccess() } >Yes</Button>
                </div>

              </L.SignupAddressActions>
            </SlideDownFadeIn>

            <SlideDownFadeIn visible={showFields}>
              <div>
                <BackButton onClick={onBack} />
              </div>
            </SlideDownFadeIn>
          </>
        )}
      </div>
    </div>
  )
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
  betas: ['payment_intent_beta_3']
});

export const StepStripe = ({onSuccess, onBack, onChangeAddress, checkoutToken}) => {

  // HOOKS
  const [createCheckoutPayment] = useMutation(MU_CREATE_PAYMENT, {
    update: (proxy, {data: { createCheckoutPayment: { clientSecret }}}) => {
      setClientSecret(clientSecret)
    }
  })
  const [clientSecret, setClientSecret] = useState(undefined)
  const [inputMode, setInputMode] = useState(false)

  const { data: { checkout }, loading } = useRayloQuery(CheckoutSteps.stepStripe.query, {
    variables: {
      token: checkoutToken
    },
    fetchPolicy: 'network-only'

  })

  useEffect(() => {
    activateOptimize()
  })

  const onChangeDetails = () => {
    setInputMode(true)
    getPaymentToken()
  }

  const getPaymentToken = () => {
      createCheckoutPayment({
      variables: {
        checkoutToken
      }
    })
  }

  useEffect(() => {
    if(checkout) {
      if(!(checkout &&
        checkout.paymentInfo &&
        checkout.paymentInfo.cardDetails &&
        checkout.paymentInfo.cardDetails.last4
      )) {
        onChangeDetails()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout])

  useEffect(() => {
    activateOptimize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(loading || (inputMode && !clientSecret))])


  if(loading || (inputMode && !clientSecret)) return ((<Modal visible text="Loading..." />))

  return (
    <Elements stripe={stripePromise}><PaymentForm
      clientSecret={clientSecret}
      onBack={onBack}
      onSuccess={onSuccess}
      checkout={checkout}
      onChangeAddress={onChangeAddress}
      onChangeDetails={onChangeDetails}
      inputMode={inputMode}
    /></Elements>
  )
}
