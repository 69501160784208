import {
  StyledBackArrowHeader,
  Wrapper,
} from 'components/BackArrowLink/styles';
import Container from 'elements/Container';
import Header from 'elements/Header';
import Icon from 'elements/Icon';
import { Link } from 'react-router-dom';
import { primaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { useHistory } from 'react-router';
import {
  IBackArrowLinkContentType,
  IBackArrowLinkType,
} from './BackArrowLinkType';

const Contents = ({ text, dataTestId, styling }: IBackArrowLinkContentType) => {
  return (
    <StyledBackArrowHeader data-testid={dataTestId} styling={styling}>
      <Container width="24px" center padding="4px 0 0 0">
        <Icon
          name="BackArrow"
          color={primaryColors.b01}
          size={`${px2Rem(30)}`}
        />
      </Container>
      <Container justifyCenter>
        <Header color={primaryColors.b01} level={3} fontSize={18}>
          {text}
        </Header>
      </Container>
    </StyledBackArrowHeader>
  );
};

export const BackArrowLink = ({
  linkLocation,
  text,
  dataTestId,
  styling,
  goBack,
}: IBackArrowLinkType) => {
  const history = useHistory();
  return (
    <Wrapper>
      {linkLocation && (
        <Link to={linkLocation} replace>
          <Contents text={text} dataTestId={dataTestId} styling={styling} />
        </Link>
      )}
      {goBack && !linkLocation && (
        <button
          onClick={() => history.goBack()}
          type="button"
          style={{
            background: 'none',
            color: 'inherit',
            border: 'none',
            padding: 0,
            font: 'inherit',
            cursor: 'pointer',
          }}
        >
          <Contents text={text} dataTestId={dataTestId} styling={styling} />
        </button>
      )}
    </Wrapper>
  );
};
