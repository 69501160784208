import React, { useEffect } from 'react';
import { activateOptimize } from 'utils/optimize'

// VISUAL COMPONENTS
import * as T from 'styles/type';
import { BackForwardGroup } from 'components/common/BackForwardGroup';

export const Location = ({onBack, onOK}) => {
  useEffect(() => {
    activateOptimize()
  })
  return (
  <div className="checkout--step feedback">
    <T.RayloBodyHeading><strong>Sorry, you must live in the UK to join Raylo</strong></T.RayloBodyHeading>

    <div style={{ marginBottom: 40 }}>
      <T.RayloBodyType>Raylo is UK only for now. To join, you’ll need to have a UK address, with a UK debit card and bank account registered to that address.</T.RayloBodyType>
    </div>

    <BackForwardGroup
      submit={onOK}
      onBack={onBack}
      buttonText="Finish" />
  </div>

)}
