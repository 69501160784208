import { px2Rem } from 'utils/px2Rem';
import styled from 'styled-components';

const StyledCheckoutNavBar = styled.div``;

const StyledDesktopNavBar = styled.div`
  height: ${px2Rem(60)};
  background-color: ${({ theme }) => theme?.navBar?.backgroundColor};
  padding-left: ${px2Rem(24)};
  & svg {
    margin-top: ${px2Rem(-24)};
  }
`;

export { StyledCheckoutNavBar, StyledDesktopNavBar };
