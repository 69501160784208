import { useHistory } from 'react-router';

import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import NumberedSteps from 'components/NumberedSteps';
import Icon from 'elements/Icon';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { StyledSecurityDepositView } from './styles';
import { IStepSecurityDepositContent } from './types';

export const StepSecurityDepositContent = ({
  dataTestId,
}: IStepSecurityDepositContent) => {
  const depositValue = '';
  const history = useHistory();
  const handleSubmit = () => {
    history.push('/checkout/decision/pay-deposit');
  };

  const listItems = [
    {
      title: 'Pay your deposit ',
      text: 'Click below to securely pay by card.',
    },
    {
      title: 'We’ll ship your order',
      text: 'Once you’ve paid your deposit we’ll ship your device.',
    },
    {
      title: 'We’ll refund you',
      text: 'After you’ve made your first 3 monthly payments we’ll automatically refund you. No need to get in touch with us.',
    },
  ];

  const payDepositLabel = `Pay ${depositValue} Deposit`;

  return (
    <StyledSecurityDepositView data-testid={dataTestId}>
      <Container center>
        <Icon
          name="SecurityDeposit"
          size={px2Rem(124)}
          color={secondaryColors.p02}
        />
      </Container>
      <Header level={3} fontSize={24} color={primaryColors.c01}>
        Based on your credit score we need to take a {depositValue} security
        deposit before we can ship your order.
      </Header>
      <Spacer height={24} />
      <Copy>
        We think it&apos;s better to give you this option rather than saying no.
        <span style={{ fontWeight: 'bold', marginLeft: px2Rem(3) }}>
          This deposit will be refunded after you&apos;ve made your first 3
          monthly payments.
        </span>
      </Copy>
      <Spacer height={40} />
      <Header level={4} color={primaryColors.c01}>
        Next Steps
      </Header>
      <Spacer height={24} />
      <NumberedSteps listItems={listItems} />
      <Spacer height={24} />
      <Button
        dataTestId="directDebitConfirm"
        buttonColor={ButtonTypes.b01}
        fullWidth
        onClick={handleSubmit}
        size="large"
        text={payDepositLabel}
      />
    </StyledSecurityDepositView>
  );
};
