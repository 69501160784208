import { identify, track } from 'helpers/analytics/segment';
import { UseSegmentCheckoutTrackingParams } from 'graphql/queries/segment/interfaces/useSegmentCheckoutTrackingParams.interface';
import { UseSegmentLoginParams } from 'graphql/queries/segment/interfaces/UseSegmentLoginParams.interface';
import { useProductPropertiesEventQuery } from 'graphql/queries/segment/useProductPropertiesQuery';
import { useEffect } from 'react';
import { useCheckoutContext } from 'utils/useCheckoutContext';

export const useSegmentIdentify = () => {
  const { checkoutID } = useCheckoutContext();
  useEffect(() => {
    identify(checkoutID);
  }, []);
};

export const useSegmentLoginTracking = (options: UseSegmentLoginParams) => {
  if (options.email) {
    identify(options.email);
  }
  track('Login Step Viewed', options);
};

export const useSegmentCheckoutTracking = (
  options: UseSegmentCheckoutTrackingParams,
) => {
  useProductPropertiesEventQuery((data: {}) => {
    const segmentEvent = {
      checkout_area: options.checkoutArea,
      checkout_screen: options.checkoutScreen,
      when: options.when,
      ...data,
    };

    track(options.eventName, segmentEvent);
  });
};

export const useSegmentIdentifyAndCheckoutTracking = (
  options: UseSegmentCheckoutTrackingParams,
) => {
  useSegmentIdentify();
  useSegmentCheckoutTracking(options);
};
