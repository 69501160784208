import { IPricePlan } from 'components/PricePlanOptions/types';
import { useEffect, useState } from 'react';
import StepLeaseTerm from 'screens/Checkout/components/StepLeaseTerm';
import { useStepLeaseTermContext } from './hooks/useStepLeaseTermContext';

export const StepLeaseTermContainer = () => {
  const [initialActiveIndex, setInitialActiveIndex] = useState<
    string | undefined
  >();
  const [imageUrl, setImageUrl] = useState<undefined | string>();
  const [activePricePlan, setActivePricePlan] = useState<
    IPricePlan | undefined
  >();

  const {
    displayName,
    displayImage,
    pricePlans,
    activeIndex,
    setActiveIndex,
    handleSubmit,
  } = useStepLeaseTermContext();

  useEffect(() => {
    if (pricePlans) {
      const lastIndex = pricePlans.length - 1;
      setInitialActiveIndex(pricePlans?.[lastIndex]?.id);
      setActiveIndex(pricePlans?.[lastIndex]?.id);
    }
  }, [pricePlans]);

  useEffect(() => {
    const activePlan = pricePlans?.find(
      ({ id }: any) => id === activeIndex || id === initialActiveIndex,
    );
    setActivePricePlan(activePlan);
  }, [activeIndex, initialActiveIndex]);

  useEffect(() => {
    setImageUrl(`${displayImage}?trim=color&fm=webp`);
  }, [displayImage]);

  const onClickFunction = (id: any) => {
    setActiveIndex(id);
  };

  return (
    <StepLeaseTerm
      title={displayName}
      imageUrl={imageUrl}
      pricePlans={pricePlans}
      activeIndex={activeIndex || initialActiveIndex}
      activePricePlan={activePricePlan}
      handleSelect={onClickFunction}
      confirmOnClick={handleSubmit}
    />
  );
};
