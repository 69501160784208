
import React from 'react';

import * as A from 'styles/account';
import * as T from 'styles/type';
import dayjs  from 'dayjs';

export const NextPayment = ({nextPaymentScheduleEvent}) => {

  if(nextPaymentScheduleEvent) {
    return (
      <A.PaymentRow>
        <A.PaymentRowHeader>
          <T.PaymentRowLabel>Next Payment</T.PaymentRowLabel>
        </A.PaymentRowHeader>

        <A.PaymentRowData>
          <div>
            <T.Payment>{dayjs(nextPaymentScheduleEvent.paymentOn).format('DD/MM/YY')}</T.Payment>
          </div>

          <div>
            <T.Payment>{nextPaymentScheduleEvent.amount.formattedValue}</T.Payment>
          </div>
        </A.PaymentRowData>
      </A.PaymentRow>
    )
  } else {
    return null
  }
}
  