import amazonCardTop from 'public/amazon-card.svg';
import amazonCardBottom from 'public/amazon-card--bottom.svg';
import {
  StyledAmazonCard,
  StyledAmazonCardAnimation,
  StyledAmazonCardBottom,
} from './styles';
import { IAmazonCardAnimation } from './types';

export const AmazonCardAnimation = ({ dataTestId }: IAmazonCardAnimation) => {
  return (
    <StyledAmazonCardAnimation data-testid={dataTestId}>
      <StyledAmazonCardBottom>
        <img src={amazonCardBottom} alt="amazon card" />
      </StyledAmazonCardBottom>
      <StyledAmazonCard>
        <img src={amazonCardTop} alt="amazon card" />
      </StyledAmazonCard>
    </StyledAmazonCardAnimation>
  );
};
