import { px2Rem } from 'utils/px2Rem';
import { primaryColors } from 'styles/variables/colors';
import styled from 'styled-components';

const StyledMerchantCheckoutWidget = styled.div`
  width: 468px;
  height: 250px;
  background-color: ${primaryColors.w01};
`;
const StyledMerchantProductWidget = styled.div`
  width: 468px;
  height: 80px;
  background-color: ${primaryColors.w01};
`;

const SavingsCopy = styled.p`
  margin-left: 8px;
  padding: 4px;
  background-color: ${primaryColors.b01};
  color: ${primaryColors.w01};
  border-radius: 4px;
  font-size: 13px;
`;

const WidgetSpacer = styled.div<{ height: number }>`
  margin-bottom: ${({ height }) => px2Rem(height)};
`;

export {
  StyledMerchantCheckoutWidget,
  StyledMerchantProductWidget,
  SavingsCopy,
  WidgetSpacer,
};
