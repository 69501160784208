import { Container, Copy, Spacer } from 'elements';
import RadioButtonBox from 'elements/RadioButtonBox';
import { primaryColors } from 'styles/variables/colors';
import { StyledPricePlanOptions } from './styles';
import { IPricePlanOptions, IPricePlan } from './types';

export const PricePlanOptions = ({
  dataTestId,
  pricePlans,
  onClick,
  activeIndex,
}: IPricePlanOptions) => {
  if (!pricePlans) {
    return null;
  }
  return (
    <StyledPricePlanOptions data-testid={dataTestId}>
      <Copy color={primaryColors.c01} fontSize={16}>
        Pick your lease term
      </Copy>
      <Spacer height={20} />
      <Container noPadding>
        {pricePlans?.map(({ termLength, id, monthlyPrice }: IPricePlan) => (
          <div key={id}>
            <RadioButtonBox
              monthlyPrice={monthlyPrice}
              onClick={onClick}
              pricePlan
              termLength={termLength}
              active={id === activeIndex}
              index={id}
              large
            />
            <Spacer height={20} />
          </div>
        ))}
      </Container>
    </StyledPricePlanOptions>
  );
};
