import DoubleButtonFullWidth from 'components/DoubleButtonFullWidth';
import RayloPayModal from 'components/RayloPayModal';
import { Container, Copy, Spacer } from 'elements';
import { primaryColors } from 'styles/variables/colors';
import { useUiContext } from 'utils/useUiContext';
import { StyledRPOrderCancelModal } from './styles';
import { IRPOrderCancelModal } from './types';

export const RPOrderCancelModal = ({ dataTestId }: IRPOrderCancelModal) => {
  const { setShowRPCancelOrderModal } = useUiContext();
  const onClose = () => {
    setShowRPCancelOrderModal(false);
  };
  const handleCancelOrder = () => {
    window.location.href = 'https://www.4gadgets.co.uk/';
  };
  return (
    <StyledRPOrderCancelModal data-testid={dataTestId}>
      <RayloPayModal header="Cancel order" hideLogo onClose={onClose}>
        <Container noPadding>
          <Copy color={primaryColors.c01} fontSize={16}>
            Are you sure you want to exit and return to 4gadgets?
          </Copy>
          <Spacer height={16} />
          <Copy color={primaryColors.c01} fontSize={16}>
            You&apos;ll lose your progress on this order and will have to start
            again.
          </Copy>
          <Spacer height={24} />
          <DoubleButtonFullWidth
            topButtonText="Complete Order"
            bottomButtonText="Exit to merchant"
            topButtonOnClick={onClose}
            bottomButtonOnClick={handleCancelOrder}
          />
        </Container>
      </RayloPayModal>
    </StyledRPOrderCancelModal>
  );
};
