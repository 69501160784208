import IconWithText from 'components/IconWithText';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { primaryColors } from 'styles/variables/colors';
import { StyledPhoneDetailsCard, StyledPhoneImage } from './styles';
import { IPhoneDetailsCard } from './types';

export const PhoneDetailsCard = ({
  dataTestId,
  url,
  storage,
  phoneColor,
  phoneHexColor,
}: IPhoneDetailsCard) => {
  return (
    <StyledPhoneDetailsCard data-testid={dataTestId}>
      <Container width="35%" alignBottom centerVertical>
        <StyledPhoneImage src={url} alt="Phone image" />
      </Container>
      <Container width="65%">
        <Container>
          <Header level={4} color={primaryColors.c01}>
            Storage
          </Header>
          <Spacer height={16} />
          <Copy color={primaryColors.c01}>{storage}</Copy>
        </Container>
        <Container width="130%">
          <Header level={4} color={primaryColors.c01}>
            Colour
          </Header>
          <Spacer height={16} />
          <IconWithText
            iconName="CircleFill"
            iconColor={phoneHexColor}
            iconSize={16}
            text={phoneColor}
            textColor={primaryColors.c01}
            textMargin={12}
            textSize={16}
          />
        </Container>
      </Container>
    </StyledPhoneDetailsCard>
  );
};
