import { IncomingPaymentStatus } from 'graphql/mutations/incomingPayment/incomingPaymentStatuses.enum';

export const stripeClientSecret = (checkout: any) => {
  return checkout?.incomingPayments
    .find(
      (incomingPayment: any) =>
        incomingPayment.state === IncomingPaymentStatus.Requested,
    )
    ?.partnerReferences.find(
      (partner: any) =>
        partner.referenceType === 'stripe_setup_intent_client_secret',
    );
};
