import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import useMedia from 'use-media';
import { useQuery } from '@apollo/client';
import { stripeFormStyles } from 'styles/screens/Payment/stripeFormStyles';
import { FadeIn } from 'components/animations/Transitions';
import { breakpoints } from 'styles/breakpoints';
import { Loader } from 'components/common/Loader';
import { px2Rem } from 'utils/px2Rem';
import { CHECKOUT_INCOMING_PAYMENTS } from 'graphql/queries/checkout/incomingPayment/incomingPayments.graphql';
import { PaymentForm } from 'screens/Payment/PaymentForm';
import { IPaymentFormWrapper } from 'screens/Payment/paymentFormWrapper.interface';
import { stripeClientSecret } from '../../../../utils/checkout/incomingPayments/stripeClientSecretFromSetupIntent';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

export const DepositPaymentFormWrapper = ({
  successUrl,
  checkoutToken,
}: IPaymentFormWrapper) => {
  const [clientSecret, setClientSecret] = useState('');
  const isMobile = useMedia({ maxWidth: breakpoints.mobile });

  const { stopPolling } = useQuery(CHECKOUT_INCOMING_PAYMENTS, {
    variables: { token: checkoutToken },
    pollInterval: 500,
    onCompleted(data: any) {
      const partnerReference = stripeClientSecret(data.checkout);

      if (partnerReference) {
        stopPolling();
        setClientSecret(partnerReference.reference);
      }
    },
  });

  const options = {
    clientSecret,
    appearance: stripeFormStyles(isMobile),
  };

  return clientSecret ? (
    <FadeIn>
      <Elements stripe={stripePromise} options={options}>
        <PaymentForm successUrl={successUrl} />
      </Elements>
    </FadeIn>
  ) : (
    <FadeIn>
      <div style={{ margin: `${px2Rem(50)} auto`, width: 'fit-content' }}>
        <Loader type="quad" />
      </div>
    </FadeIn>
  );
};
