import styled from 'styled-components';
import { secondaryColors, primaryColors } from 'styles/variables/colors';
import { IRadioBox } from './types';

const StyledRadioButtonBox = styled.div``;

const StyledRadioButton = styled.input`
  opacity: 0;
`;

const RadioDisplayBox = styled.div<IRadioBox>`
  cursor: pointer;
  height: 60px;
  border: 1px solid ${secondaryColors.c03};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${primaryColors.c01};
  ${({ active }) => active && `border: 2px solid ${primaryColors.b01};`}
  ${({ large }) => large && 'height: 85px; border-radius: 4px;'}

  & h6 {
    padding: 0;
    margin: 0;
  }
`;

export { StyledRadioButtonBox, StyledRadioButton, RadioDisplayBox };
