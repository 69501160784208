import { HireAgreement } from 'components/Checkout/HireAgreement';
import { HireAgreementOld } from 'components/Checkout/HireAgreementOld';
import TermsAndConditions from 'components/TermsAndConditions';
import dayjs from 'dayjs';
import Container from 'elements/Container';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { useSegmentIdentifyAndCheckoutTracking } from 'hooks/segment/segment.hooks';
import { useEffect } from 'react';
import { useStepSignContext } from 'screens/Checkout/containers/StepSign/hooks/useStepSignContext';
import { primaryColors } from 'styles/variables/colors';
import { StyledStepSignContent } from './styles';
import { IStepSignContent } from './types';

export const StepSignContent = ({ dataTestId }: IStepSignContent) => {
  useSegmentIdentifyAndCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'terms',
    checkoutScreen: 'accept terms',
    when: 'on page load',
  });
  const { checkout, phone, noInsurance, orderPlaced } = useStepSignContext();

  useEffect(() => {
    if (orderPlaced) {
      window.location.pathname = 'checkout/decision';
    }
  }, [orderPlaced]);

  const hireAgreement = (options: any) => {
    const comparisonDate = dayjs('2021-11-03');
    const pricePlanCreatedAt = dayjs(phone?.pricePlan?.createdAt);

    const agreement =
      pricePlanCreatedAt < comparisonDate ? (
        <HireAgreementOld {...options} />
      ) : (
        <HireAgreement {...options} />
      );
    return agreement;
  };
  return (
    <StyledStepSignContent data-testid={dataTestId}>
      <Container noPadding>
        <Spacer height={40} />
        <Header level={2} color={primaryColors.c01}>
          One last step...
        </Header>
        <Spacer height={16} />
        <Header level={4} color={primaryColors.c01}>
          Please scroll down and accept the T&amp;C’s to proceed
        </Header>
        <Spacer height={16} />
        <TermsAndConditions>
          {hireAgreement({
            checkout,
            phone,
            noInsurance,
          })}
        </TermsAndConditions>
      </Container>
    </StyledStepSignContent>
  );
};
