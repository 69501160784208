import React, { useEffect, useState } from 'react';
import * as A from 'styles/account';
import useMedia from 'use-media';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router';
import { track } from 'helpers/analytics/segment';
import { START_SUBSCRIPTION_ARREARS_PAYMENT_MUTATION } from 'graphql/mutations/subscription/startSubscriptionArrearsPaymentMutation.graphql';
import { CUSTOMER_SUBSCRIPTIONS } from 'graphql/queries/customer/subscription/subscriptions.graphql';
import { checkInArrears } from 'helpers/subscriptions/checkInArrears';
import { PaymentFormWrapper } from '../../Payment/PaymentFormWrapper';
import { Card } from '../../../components/common/Card';
import Container from '../../../elements/Container';
import Spacer from '../../../elements/Spacer';
import Header from '../../../elements/Header';
import { primaryColors, uiStateColors } from '../../../styles/variables/colors';
import { AcceptedCards } from '../../Payment/AcceptedCards';
import { BackArrowLink } from '../../../components/common/BackArrowLink/BackArrowLink';
import Copy from '../../../elements/Copy';
import { FadeIn } from '../../../components/animations/Transitions';
import { px2Rem } from '../../../utils/px2Rem';
import { breakpoints } from '../../../styles/breakpoints';

export const PaymentScreen = () => {
  const [fromPaymentFailedScreen, setFromPaymentFailedScreen] = useState(false);
  const [incomingPayment, setIncomingPayment] = useState(undefined);
  const [inArrears, setInArrears] = useState(false);
  const [trackedAccountViewedEvent, setTrackedAccountViewedEvent] =
    useState(false);

  const isMobile = useMedia({ maxWidth: breakpoints.mobile });
  const { subscriptionId } = useParams();
  const history = useHistory();

  useQuery(CUSTOMER_SUBSCRIPTIONS, {
    onCompleted(data: any) {
      setInArrears(false);
      const subscription = data.customer.subscriptions.find(
        (s: any) => s.id === subscriptionId,
      );
      if (checkInArrears(subscription)) {
        setInArrears(true);
      } else {
        history.push('/account');
      }
    },
  });

  const [startSubscriptionArrearsPayment] = useMutation(
    START_SUBSCRIPTION_ARREARS_PAYMENT_MUTATION,
    {
      variables: {
        subscriptionId,
      },
      onCompleted(data: any) {
        setIncomingPayment(
          data.startSubscriptionArrearsPayment.incomingPayment,
        );
      },
    },
  );

  useEffect(() => {
    if (localStorage.getItem('previousScreen') === 'paymentFailed') {
      setFromPaymentFailedScreen(true);
      localStorage.removeItem('previousScreen');
    }
  }, []);

  useEffect(() => {
    startSubscriptionArrearsPayment();
  }, [inArrears]);

  useEffect(() => {
    if (!trackedAccountViewedEvent) {
      track('My Account Viewed', {
        screen: 'payment input',
        when: 'on page load',
        url: window.location.href,
      });

      setTrackedAccountViewedEvent(true);
    }
  });

  return (
    <A.AccountContainer>
      <FadeIn>
        <>
          <Container
            width="1200px"
            styles={{
              maxWidth: '100%',
              margin: 'auto',
            }}
          >
            <BackArrowLink
              text={fromPaymentFailedScreen ? 'Account Overview' : 'Back'}
              styling="margin-left: 2.5%;"
              goBack={!fromPaymentFailedScreen}
              linkLocation={fromPaymentFailedScreen ? '/account' : undefined}
            />
            <Spacer height={8} />
            <Header
              level={1}
              color={primaryColors.c01}
              fontSize={24}
              style={isMobile ? { marginTop: px2Rem(30) } : undefined}
              centered
            >
              Please make a payment
            </Header>
          </Container>
          <Card
            width="1200"
            styles={`
            min-height: ${px2Rem(800)}
            
            @media (max-width: ${breakpoints.mobile}px) {
              padding: ${px2Rem(36)} ${px2Rem(20)} ${px2Rem(20)} ${px2Rem(20)};
              max-width: 100%;
              box-shadow: none;
            }
          `}
          >
            <Container
              width="474px"
              styles={{
                maxWidth: '100%',
                margin: 'auto',
                padding: 0,
              }}
            >
              <Container
                padding="0"
                styles={{
                  display: 'grid',
                  gridTemplateColumns: '70% 30%',
                }}
              >
                <Copy
                  color={primaryColors.c01}
                  fontSize={isMobile ? 16 : 18}
                  bold
                >
                  Total to be paid today
                </Copy>
                <Copy
                  color={uiStateColors.error}
                  fontSize={isMobile ? 16 : 18}
                  bold
                  textAlign="right"
                >
                  {incomingPayment
                    ? incomingPayment.totalAmount.formattedValue
                    : null}
                </Copy>
              </Container>
              <Spacer height={48} />
              <AcceptedCards />
              <PaymentFormWrapper
                successUrl={`/account/payment/${incomingPayment?.id}/processing`}
              />
            </Container>
          </Card>
          <Spacer height={48} />
        </>
      </FadeIn>
    </A.AccountContainer>
  );
};
