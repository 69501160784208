import EditContentBox from 'components/EditContentBox';
import Copy from 'elements/Copy';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { formatSortCode } from 'utils/formatSortCode';
import { StyledBankDetailsContainer } from './styles';
import { IBankDetailsContainer } from './types';

export const BankDetailsContainer = ({
  dataTestId,
  bankDetails,
  onEdit,
}: IBankDetailsContainer) => {
  return (
    <StyledBankDetailsContainer data-testid={dataTestId}>
      <EditContentBox onClick={onEdit} backgroundColor={secondaryColors.c04}>
        <Copy color={primaryColors.c01} lineHeight={22}>
          Account Name: {bankDetails?.bankAccountName}
        </Copy>
        <Copy color={primaryColors.c01} lineHeight={22}>
          Account Number: {bankDetails?.bankAccountNumber}
        </Copy>
        <Copy color={primaryColors.c01} lineHeight={22}>
          Sort Code: {formatSortCode(bankDetails?.bankBranchIdentifier)}
        </Copy>
      </EditContentBox>
    </StyledBankDetailsContainer>
  );
};
