/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

// STATE
import { ChangePhoneContext, useChangePhoneContext } from 'utils/useChangePhoneContext';

import { ChoosePhone } from 'screens/Upgrade/ChoosePhone';
import { useHistory, useParams } from 'react-router';

// GRAPHQL
import { CurrentLease } from 'components/Upgrade/CurrentLease';
import { Modal } from 'components/common/Modal';
import iconArrowLeft from 'public/icon--arrow--pink--left.svg'
import { HowItWorks } from 'components/Upgrade/HowItWorks';
import { PhoneFeatures } from 'components/Upgrade/PhoneFeatures';
import { CertifiedRefurbished } from 'components/Upgrade/CertifiedRefurbished';
import { getSessionStorage } from 'utils/handleSessionStorage';
import { isAdditonalTech } from 'utils/handleGetSessionStorageValues';

export const UpgradePhone = () => {

  const {variantId} = useParams()
  const variant = getSessionStorage('variantId') || variantId

  return (
    <ChangePhoneContext initialVariantId={variant}>
      <UpgradePhoneInner />
    </ChangePhoneContext>
  )
}

export const UpgradePhoneInner = () => {

  const {subscriptionId} = useParams()
  const subscription = getSessionStorage('subscriptionId') || subscriptionId
  const history = useHistory()

  const { variant } = useChangePhoneContext()

  const isRefurb = variant && variant.condition === "REFURBISHED"

  const backToAccount = (event) => {
    event.preventDefault();
    if (isAdditonalTech()) {
      history.push(`/account/products`)
    } else {
      history.push(`/account/upgrade/${subscription}`)
    }
  }

  if(!variant) return (<Modal visible text="Loading..." />)

  return (
    <div className="upgrade-subscription">

      <div className="upgrade-subscription__header">
        <div className="upgrade-subscription__container">
          <div className="upgrade-subscription__left">
            <a href="#" onClick={backToAccount} className="upgrade-subscription__back"><img src={iconArrowLeft} alt="Arrow left icon" />Back</a>
            <p className="upgrade-subscription__phone">{variant.displayName}</p>
          </div>
          <div className="upgrade-subscription__right">
            <CurrentLease />
          </div>
        </div>
      </div>


      <div className="upgrade-subscription__configure">
        <div className="upgrade-subscription__configure-container">
          <ChoosePhone subscriptionId={subscription} />
        </div>
      </div>

      <div className="upgrade-subscription__details">
      <div className="upgrade-subscription__details-container">

      <div className="upgrade-subscription__title">How it works</div>
        <HowItWorks />
      </div></div>
      <PhoneFeatures  />

      {isRefurb && <CertifiedRefurbished />}


    </div>
  )
}
