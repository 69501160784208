import NumberedList from 'components/NumberedList';
import RayloModal from 'components/RayloModal';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { primaryColors } from 'styles/variables/colors';
import { useUiContext } from 'utils/useUiContext';
import { ITradeInModal } from './types';

export const TradeInModal = ({ dataTestId }: ITradeInModal) => {
  const { setShowModal, setShowTradeInModal } = useUiContext();
  const handleModalClose = () => {
    setShowModal(false);
    setShowTradeInModal(false);
  };

  const listItems = [
    'Find out how much your old device is worth. Answer a few questions to get your estimated Trade-In value. You could earn up to £9.00/mo for 24 months (£216.00 in total).',
    'We’ll collect your old device a few days after your new device arrives. You won’t need to go to the post office. It couldn’t be easier.',
    "If everything checks out, you’ll get trade-in cashback for 24 months. Our team will check that your device's condition matches your description, then, if everything looks good, your Trade-In is complete. You’ll receive your first cashback instalment the month after. (We’ll contact you if we need to change your Trade-In estimate).",
  ];
  return (
    <RayloModal
      header="Raylo Trade-In"
      onClose={handleModalClose}
      dataTestId={dataTestId}
    >
      <Header level={2} color={primaryColors.c01}>
        Here&apos;s how Raylo Trade-In works
      </Header>
      <Spacer height={32} />
      <NumberedList listItems={listItems} />
      <Spacer height={48} />
    </RayloModal>
  );
};
