import React from 'react';
import dayjs from 'dayjs';

export const DeliveryEstimate = ({delivery}) => {

  if(!delivery) {
    return null
  } else {
    const early = dayjs(delivery.min)
    const late = dayjs(delivery.max)

    if(early.isSame(late)) {
      return <>{early.format('dddd, D MMM YYYY')}</>
    } else {
      if(early.get('month') === late.get('month')) {
        return <>{early.format('D')} &ndash; {late.format('D MMM YYYY')}</>
      } else if (early.get('year') === late.get('year')) {
        return <>{early.format('D MMM')} &ndash; {late.format('D MMM YYYY')}</>
      } else {
        return <>{early.format('D MMM YYYY')} &ndash; {late.format('D MMM YYYY')}</>
      }
    }
  }

}
