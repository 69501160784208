import { useMedia } from 'use-media';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Icon from 'elements/Icon';
import Spacer from 'elements/Spacer';
import TextLink from 'elements/TextLink';
import { YourTechStatus } from 'screens/Account/YourTechStatus';
import { breakpoints } from 'styles/breakpoints';
import { primaryColors, uiStateColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { formatDisplayPrice } from 'utils/formatDisplayPrice';
import Button from 'elements/Button';
import { track } from 'helpers/analytics/segment';
import { IDeviceCard } from './types';
import { StyledDeviceCard, UpgradeIconWrapper } from './styles';
import { ButtonTypes } from '../../elements/Button/types';

export const DeviceCard = ({ dataTestId, phone }: IDeviceCard) => {
  const {
    model,
    color,
    storage,
    payMonthly,
    image,
    orderId,
    status,
    subscription,
  } = phone;
  const url = image?.url;
  const productImage = `${url}?trim=color&auto=format&fm=webp`;

  const history = useHistory();
  const isSmallMobile = useMedia({ maxWidth: breakpoints.smallMobile });
  const isMobile = useMedia({ maxWidth: breakpoints.mobile });
  const lineHeight = isMobile ? 22 : 30;

  const [isLaptop, setIsLaptop] = useState(false);

  const upgradeAvailable = status === YourTechStatus.upgradeAvailable;

  const handleUpgrade = () => {
    history.push(`/account/${orderId}`);
  };

  const handlePayNowClick = () => {
    track('My Account Pay Now Clicked', {
      screen: 'account overview',
      where: 'account overview product tile',
      when: "on clicking the 'Pay Now' button on the product tile",
      url: window.location.href,
    });
    history.push(`/account/payment/${subscription.id}`);
  };

  const isStatusBold = (): boolean => {
    switch (status) {
      case YourTechStatus.arrears:
      case YourTechStatus.upgradeAvailable:
        return true;
      default:
        return false;
    }
  };

  const statusColour = (): string => {
    switch (status) {
      case YourTechStatus.arrears:
        return uiStateColors.error;
      case YourTechStatus.upgradeAvailable:
        return uiStateColors.success;
      default:
        return primaryColors.c01;
    }
  };

  const statusText = (): string => {
    switch (status) {
      case YourTechStatus.arrears:
        return `${subscription?.arrearsAmount?.formattedValue} overdue`;
      default:
        return status;
    }
  };

  useEffect(() => {
    setIsLaptop(phone.model.includes('MacBook'));
  }, [phone]);

  const DeviceCardComponent = () => (
    <div>
      <Container
        flexRow
        backgroundColor={primaryColors.w01}
        dataTestId={dataTestId}
        boxShadow
        height={isMobile ? 'auto' : px2Rem(300)}
        width={!isMobile ? '598px' : 'initial'}
        padding={isMobile ? `${px2Rem(16)}` : `${px2Rem(32)}`}
        styles={{
          position: 'relative',
        }}
      >
        <Container
          center
          width={isMobile ? '30%' : '40%'}
          backgroundImage={productImage}
          backgroundImagePosition={
            isLaptop || isSmallMobile ? '50%' : '50% 100%'
          }
          backgroundImageSize={isLaptop && isSmallMobile ? '115%' : 'contain'}
        >
          {upgradeAvailable && (
            <UpgradeIconWrapper onClick={handleUpgrade}>
              <Icon
                name="RoundArrowBack"
                color="white"
                size={isSmallMobile ? px2Rem(16) : px2Rem(22)}
              />
            </UpgradeIconWrapper>
          )}
        </Container>
        <Container
          padding={`0 ${px2Rem(16)}`}
          width={isMobile ? '70%' : '60%'}
          styles={{
            marginLeft: px2Rem(16),
          }}
          justifyCenter
        >
          <Container padding="0">
            <Copy
              color={primaryColors.c01}
              bold
              lineHeight={lineHeight}
              fontSize={isSmallMobile ? 14 : 16}
            >
              {model}
            </Copy>
          </Container>
          <Copy
            color={primaryColors.c01}
            lineHeight={lineHeight}
            fontSize={isSmallMobile ? 14 : 16}
          >
            {color}, {storage}
          </Copy>
          <Spacer height={12} />
          <Copy
            color={primaryColors.c01}
            bold
            lineHeight={lineHeight}
            fontSize={isSmallMobile ? 14 : 16}
          >
            Status
          </Copy>
          <Copy
            color={statusColour()}
            lineHeight={lineHeight}
            bold={isStatusBold()}
            fontSize={isSmallMobile ? 14 : 16}
          >
            {status === YourTechStatus.arrears ? (
              <Icon
                name="RedAlert"
                styles={`
              display: inline-block;
              margin-right: ${px2Rem(8)};
              vertical-align: middle;
              `}
              />
            ) : null}
            {statusText()}
          </Copy>
          {status === YourTechStatus.arrears ? (
            <Container padding="0" width={isMobile ? '100%' : px2Rem(193)}>
              <Spacer height={4} />
              <Button
                buttonColor={ButtonTypes.b01}
                size="small"
                text="pay now"
                onClick={() => handlePayNowClick()}
              />
              <Spacer height={4} />
            </Container>
          ) : null}
          {!isMobile && (
            <>
              <Spacer height={12} />
              <Copy color={primaryColors.c01} bold lineHeight={lineHeight}>
                Monthly Payments
              </Copy>
              <Copy color={primaryColors.c01} lineHeight={lineHeight}>
                £{formatDisplayPrice(payMonthly)}
              </Copy>
            </>
          )}
        </Container>
        {!isMobile && !!orderId && (
          <div
            style={{
              position: 'absolute',
              bottom: px2Rem(36),
              right: px2Rem(32),
            }}
          >
            <TextLink link={`/account/${orderId}`}>
              <span data-testid="viewUpgrades">View details</span>
            </TextLink>
          </div>
        )}
      </Container>
    </div>
  );
  if (isMobile) {
    return (
      <StyledDeviceCard data-testid="deviceCard">
        {orderId ? (
          <Link
            to={`/account/${orderId}`}
            onClick={(event) => {
              if (event.target.tagName === 'BUTTON') {
                event.preventDefault();
              }
            }}
            replace
          >
            <DeviceCardComponent />
          </Link>
        ) : (
          <DeviceCardComponent />
        )}
      </StyledDeviceCard>
    );
  }
  return <DeviceCardComponent />;
};
