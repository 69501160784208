import { secondaryColors } from 'styles/variables/colors';
import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';

const StyledPreApprovalLabel = styled.div`
  background-color: ${secondaryColors.p02};
  display: flex;
  width: fit-content;
  border-radius: ${px2Rem(24)};
  padding: ${px2Rem(2)} ${px2Rem(12)} 0 ${px2Rem(4)};
`;

export { StyledPreApprovalLabel };
