import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import {QL_SUMMARY} from 'screens/Checkout/graphQL/queries';

// STATE
import { OrderSummary } from 'components/Checkout/OrderSummary';
import { useUiContext } from 'utils/useUiContext'
import useRayloQuery from 'utils/useRayloQuery'

export const PhoneSummary = ({checkoutToken, displayOnly = false}) => {

  // HOOKS
  const [phone, setPhone] = useState({})
  const [delivery, setDelivery] = useState({})
  const [tradeIn, setTradeIn] = useState({})
  const { uiPricePlan, tradeInInfo, checkoutStep } = useUiContext()

  const { data: { checkout, referralProgram }, loading } = useRayloQuery(QL_SUMMARY, {
    variables: {
      token: checkoutToken
    }
  })

  useEffect(() => {

    if(checkout?.items) {
      const variant = checkout?.items[0]?.variant
      const colorOptionValue = _.find(variant.optionValues, o => o.optionType.slug === 'colour')
      const pricePlan = uiPricePlan || checkout.items[0].pricePlan

      setDelivery(checkout.items[0].variant.deliveryExpectedBetween)
      
      setPhone({
        model: variant?.product.displayName,
        image: _.find(variant?.images, {tag: "Front"}),
        storage: _.find(variant?.optionValues, o => o.optionType?.slug === 'storage').displayName,
        color: colorOptionValue?.displayName,
        hexColor: colorOptionValue?.raw,
        payMonthly: pricePlan?.costSummary?.recurring?.totalAmount?.valueAfterTax?.value,
        payToday: pricePlan?.costSummary?.initial?.totalAmount?.valueAfterTax?.value,
        payRecurring: pricePlan?.costSummary?.recurring,
        currency: pricePlan?.currencyCode,
        insurance: pricePlan?.includesInsurance,
        termLength: pricePlan?.termLength,
        preOrder: variant?.product.preOrder,
        releasedAt: variant?.product.releasedAt,
        category: variant?.product?.category?.id,
        categoryName: variant?.product?.category?.displayName
      })
    }

    if (checkout?.tradeIns) {
      setTradeIn(checkout.tradeIns[0])
    } else {
      setTradeIn(undefined)
    }

  }, [checkout, uiPricePlan])

  if(loading || !referralProgram) return (<div></div>)

  return (
    <>
      <OrderSummary
        checkout={checkout}
        referralDescription={referralProgram.reward.referralDescription}
        phone={phone}
        delivery={delivery}
        displayOnly={displayOnly}
        tradeIn={tradeIn}
        tradeInExample={tradeInInfo}
        checkoutStep={checkoutStep}
      />
    </>
  )
}
