import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';

const StyledProgressBarWithBackArrow = styled.div`
  height: ${px2Rem(80)};
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);
`;

const IconWrapper = styled.div`
  padding-right: ${px2Rem(32)};
  cursor: pointer;
`;

const ProgressWrapper = styled.div`
  flex-basis: 100%;
`;

export { StyledProgressBarWithBackArrow, IconWrapper, ProgressWrapper };
