import {
  ApolloClient,
  ApolloLink,
  Observable,
  InMemoryCache,
  createHttpLink
} from '@apollo/client'
import fetch from 'unfetch';
import { onError } from '@apollo/link-error';
import { BatchHttpLink } from 'apollo-link-batch-http'
import { MU_TOKEN } from './useClientContext';
import { GRAPHQL_ENDPOINT, CLIENT_ID, FRONTEND_URL } from './constants';
import { history } from './history'
import store from 'store'
import { captureErrorEvent } from './errorTracking'
import { SentryLink } from 'apollo-link-sentry';
import * as Sentry from '@sentry/react';

const httpOptions = { uri: GRAPHQL_ENDPOINT, fetch: fetch }

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = store.get('token');
  const userToken = store.get('userToken')
  const override = store.get('override')
  
  let uri = GRAPHQL_ENDPOINT
  if(process.env.REACT_APP_DYNAMIC_GRAPHQL_ENABLED && store.get('endpoint')) {
    uri = store.get('endpoint')
  }

  operation.setContext(({ headers = {} }) => {
    
    const newHeaders = {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }

    if(userToken) {
      newHeaders['X-User-Token'] = userToken
    }

    if(override && override === 'Y') {
      newHeaders['Feature-Similar-Checkout-Details-Bypass-Enabled'] = true
    }

    return ({
      headers: newHeaders,
      uri
    })
  });
  return forward(operation);
})

const sentryTransactionMiddleware = new ApolloLink((operation, forward) => {
  const type = operation.query.definitions[0].operation
  if(type === "mutation") {
    const transaction = Sentry.startTransaction({ name: `${type}-${operation.operationName}` })

    return forward(operation).map((response) => {
      transaction.finish()
      return response
    })
  } else {
    return forward(operation)
  }
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {

  if (graphQLErrors) {
    for(let err of graphQLErrors) {
      switch(err.type) {
        case 'UNPROCESSABLE' : {
          store.remove('checkoutToken')
          window.location = FRONTEND_URL
          return null
        }
        case 'NOT_READY' : {
          captureErrorEvent("GraphQL NOT_READY Error", err)
          window.location = `/errors/not-ready`
          return null
        }
        case 'UNAUTHORIZED':

          if(err.authorizations && err.authorizations.client && !err.authorizations.user) {
            captureErrorEvent(
              "[GraphQL UNAUTHORIZED Error]",
              { graphQLErrors: graphQLErrors,  networkError: networkError, operation: operation, forward: forward, error: err }
            )

            store.remove("userToken")
            window.location = "/login"
            return
          }

          return new Observable(observer => {
            apolloSimpleClient.mutate({
              mutation: MU_TOKEN,
              variables: { clientId: CLIENT_ID }
            }).then(({data: {authenticateClient: {clientAuthToken}}}) => {

              store.set('token',clientAuthToken);
              operation.setContext(({ headers = {} }) => ({
                headers: {
                  // Re-add old headers
                  ...headers,
                  // Switch out old access token for new one
                  authorization: `Bearer ${clientAuthToken}` || null,
                }
              }))

            }).then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer)
              }
              // Retry last failed request
              forward(operation).subscribe(subscriber)
            }).catch(error => {
              // No refresh or client token available, we force user to login
              observer.error(error)
            })
          })
        default:
          console.log("[GraphQL Error]:", err)

          captureErrorEvent(
            "[GraphQL Generic Error]",
            { graphQLErrors: graphQLErrors,  networkError: networkError, operation: operation, forward: forward, error: err }
            )
      }
    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);

    captureErrorEvent(
      "[GraphQL network error]",
      { graphQLErrors: graphQLErrors,  networkError: networkError, operation: operation, forward: forward }
      )
    history.push('/errors/oops')
  }
});

const httpLink = createHttpLink(httpOptions)
const batchHttpLink = new BatchHttpLink(httpOptions)
const sentryLinkOptions = {
  setTransaction: false,
  breadcrumb: {
    includeVariables: false,
    includeResponse: false,
    includeError: true,
    includeQuery: true
  }
}

const sentryLink = new SentryLink(sentryLinkOptions)

const cache = new InMemoryCache()

let links = [
  sentryTransactionMiddleware,
  sentryLink,
  authMiddleware,
  errorLink
]

if(process.env.REACT_APP_BATCH_GRAPHQL_ENABLED) {
  links.push(batchHttpLink)
} else {
  links.push(httpLink)
}

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: ApolloLink.from(links),
  cache,
  resolvers: {}
})

const apolloSimpleClient = new ApolloClient({
  link: httpLink,
  cache
})
