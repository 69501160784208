import React from 'react';

import { ClientProvider } from './useClientContext';
import { UiProvider } from './useUiContext';
import { CheckoutTracker } from './trackCheckout';

export const StateProvider = ({children, pricePlan = null}) =>(
  <ClientProvider>
    <UiProvider pricePlan={pricePlan}>
      <CheckoutTracker>{children}</CheckoutTracker>
    </UiProvider>
  </ClientProvider>
);
