import { Container, Copy, Spacer } from 'elements';
import Markdown from 'elements/Markdown';
import { primaryColors } from 'styles/variables/colors';
import { IContentSection } from './types';

export const ContentSection = ({
  dataTestId,
  title,
  content,
}: IContentSection) => (
  <Container noPadding dataTestId={dataTestId}>
    <Copy color={primaryColors.b01} bold>
      {title}
    </Copy>
    <Markdown color={primaryColors.c01} copy={content} />
    <Spacer height={16} />
  </Container>
);
