import { useState } from 'react';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Grid from 'elements/Grid';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import TextInput from 'elements/TextInput';
import {
  primaryColors,
  secondaryColors,
  uiStateColors,
} from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { dateValidation } from 'utils/formValidation';
import { StyledDateOfBirthInput } from './styles';
import { IDateOfBirthInput } from './types';

export const DateOfBirthInput = ({
  dataTestId,
  name,
  error,
  onChange,
  values,
}: IDateOfBirthInput) => {
  const [formError, setFormError] = useState<string | null>(null);
  const onBlur = (field: string, e: any) => {
    const { value } = e?.target;
    const validateEntry = dateValidation(field, value);
    if (validateEntry !== 'success') {
      setFormError(validateEntry);
    } else {
      setFormError(null);
    }
  };

  return (
    <StyledDateOfBirthInput data-testid={dataTestId}>
      <Header level={3} color={primaryColors.c01} fontSize={16}>
        {name}
      </Header>
      <Grid>
        <Container width={px2Rem(64)} padding="0">
          <TextInput
            type="number"
            placeHolderText="DD"
            height="fit-content"
            onChange={onChange}
            onBlur={(e) => onBlur('day', e)}
            maxLength={2}
            fieldName="dayOfBirth"
            value={values?.DD}
          />
        </Container>
        <Container
          width={px2Rem(64)}
          padding="0"
          margin={`0 0 0 ${px2Rem(16)}`}
        >
          <TextInput
            type="number"
            placeHolderText="MM"
            height="fit-content"
            onChange={onChange}
            onBlur={(e) => onBlur('month', e)}
            maxLength={2}
            fieldName="monthOfBirth"
            value={values?.MM}
          />
        </Container>
        <Container
          width={px2Rem(92)}
          padding="0"
          margin={`0 0 0 ${px2Rem(16)}`}
        >
          <TextInput
            type="number"
            placeHolderText="YYYY"
            height="fit-content"
            onChange={onChange}
            onBlur={(e) => onBlur('year', e)}
            maxLength={4}
            fieldName="yearOfBirth"
            value={values?.YYYY}
          />
        </Container>
      </Grid>
      {error && (
        <>
          <Spacer height={4} />
          <Copy color={uiStateColors.error}>{error}</Copy>
        </>
      )}
      {formError && !error && (
        <>
          <Spacer height={4} />
          <Copy color={uiStateColors.error}>{formError}</Copy>
        </>
      )}
      {!error && (
        <>
          <Spacer height={8} />
          <Copy color={secondaryColors.c02}>For example 31 12 1970</Copy>
        </>
      )}
    </StyledDateOfBirthInput>
  );
};
