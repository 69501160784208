import {useState, useEffect} from 'react';
import styled from 'styled-components/macro';
import * as L from 'styles/layout';
import * as T from 'styles/type';
import colors from 'styles/colors';
import downArrow from 'public/icon--arrow--pink--down.svg';
import brokenPhone from 'public/icon--broken-phone.svg';
import globe from 'public/icon--globe.svg';
import infoIcon from 'public/icon--warning--outline--white.svg';

import Currency from 'react-currency-formatter';
import { RadioButton } from 'components/common/RadioButton';
import { Button } from 'components/common/Button';
import { Toggle } from 'components/common/Toggle';
import { FadeIn } from 'components/animations/Transitions';
import { Insurance } from 'components/Checkout/Insurance';
import { Link } from 'components/common/Link';
import { ProductDropdown } from 'components/common/ProductDropdown';
import dayjs from 'dayjs';
import { Loader } from 'components/common/Loader';
import shippingIcon from 'public/icon--shipping.svg';
import { DeliveryEstimate } from 'components/common/DeliveryEstimate';
import { OrderSummaryDelivery } from './OrderSummary'
import { useChangePhoneContext } from 'utils/useChangePhoneContext';
import { ConditionModal } from './ConditionModal';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { getSessionStorage } from 'utils/handleSessionStorage';
import { formatDisplayPrice } from 'utils/formatDisplayPrice';

export const PhoneConfigure = ({

  buttonLabel = "Confirm",
  onConfirm,
  disabled,
  configureLoaded,
  categoryId,
  changeDevice,
  isUpgrade = false}) => {

  const {
    product,
    colour,
    storage,
    pricePlan,
    productCategories,
    productCategoriesId,
    manufacturer,
    setProductCategoriesId,
    setProductId,
    setColourId,
    setStorageId,
    setManufacturerId,
    setHasInsurance,
    setTermLength,
    setIsRefurb,
    products,
    allColours,
    colours,
    allStorages,
    storages,
    manufacturers,
    pricePlans,
    pricePlanWithInsurance,
    pricePlanWithoutInsurance,
    variant,
  } = useChangePhoneContext()

  const orderActive = variant; //  && variant.inStock;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const [showInsurancePolicy, setShowInsurancePolicy] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [phoneStorages, setPhoneStorages] = useState([]);

  const isRefurb = variant && variant.condition === "REFURBISHED"

  const checkout = useCheckoutContext()

  const showInsuranceModal = () => {
    setShowInsurancePolicy(true)
  }

  const hideInsuranceModal = () => {
    setShowInsurancePolicy(false)
  }

  const handleInsuranceChange = () => {
    if(pricePlan.id === pricePlanWithInsurance.id) {
      //setPricePlan(pricePlanWithoutInsurance.id)
      setHasInsurance(false)
    } else {
      //setPricePlan(pricePlanWithInsurance.id)
      setHasInsurance(true)
    }
  }

  useEffect(() => {
    if(categoryId) {
      setProductCategoriesId(categoryId)
    }
  }, [categoryId])

  useEffect(() => {
    if (getSessionStorage('product')){
      setProductCategoriesId(getSessionStorage('product'))
    }
  }, [configureLoaded])

  useEffect(() => {
    if(document.getElementById('insurance-dropdown')) {
      let height = document.getElementById('insurance-dropdown').scrollHeight;
      setDropdownHeight(height);
    }
  }, [dropdownVisible])

  useEffect(() => {
    const sortedArray = allStorages.sort((a,b) => a.position - b.position);
    setPhoneStorages(sortedArray);
  }, [allStorages])

  const riskPricing = pricePlan && pricePlan.costSummary.recurring.adjustmentAmount.valueAfterTax.value > 0

  return (
    <PhoneConfigureContainer className="phone-configure--container">
      {!isUpgrade &&
      <L.ConfigureSection>
            <T.RayloLabel>Products</T.RayloLabel>
            {productCategories?.length > 0 ? 
              <>
                {productCategories.map((item, i) => <RadioButton
                  key={item.id}
                  id={item.id}
                  type="model"
                  onClick={() => setProductCategoriesId(item.id)}
                  label={item.displayName}
                  checked={item.id === productCategoriesId}
                  dataTestId={item.slug}
                  />)}
              </>
              : 
                <LoadingBlock>
                  <Loader type="single" />
                  <T.RayloSubHeading>Loading...</T.RayloSubHeading>
                </LoadingBlock>
              }
          </L.ConfigureSection>}


      {showInsurancePolicy &&
        <Insurance visible={showInsurancePolicy} onBack={hideInsuranceModal} />
      }

      
      <form>
        {!isUpgrade ? 
        <>
          <L.ConfigureSection>
            <T.RayloLabel>Make</T.RayloLabel>
            {manufacturers && manufacturers.length > 0 ? 
              <>
                {manufacturers.map((item, i) => <RadioButton
                  key={item.id}
                  id={item.id}
                  type="model"
                  onClick={() => setManufacturerId(item.id)}
                  label={item.displayName}
                  checked={manufacturer && item.id === manufacturer.id}
                  dataTestId={item.displayName}
                  />)}
              </>
              : 
                <LoadingBlock>
                  <Loader type="single" />
                  <T.RayloSubHeading>Loading...</T.RayloSubHeading>
                </LoadingBlock>
              }
          </L.ConfigureSection>
          <L.ConfigureSection>
            <T.RayloLabel>Model</T.RayloLabel>


            {products && products.length > 0 && product && variant ?
              <>
                <ProductDropdown products={products} currentVariant={variant} onChange={setProductId} setIsRefurb={setIsRefurb} />
                {product.preOrder && product.releasedAt && <PreOrder>
                  <img src={infoIcon} alt="Information icon" />
                  Pre-Order: Will be released on {dayjs(product.releasedAt).format('MMMM D')}</PreOrder>}
              </>
            : 
              <LoadingBlock>
                <Loader type="single" />
                <T.RayloSubHeading>Loading...</T.RayloSubHeading>
              </LoadingBlock>
            }

          </L.ConfigureSection>
        </> : null}

        <div>
          <L.ConfigureSection style={{ paddingBottom: 5 }}>
            <T.RayloLabel>Colour</T.RayloLabel>
            <L.ConfigureSectionCols>

            {allColours && allColours.length > 0 ? 
              <>
                {allColours.map((item) => <RadioButton
                key={item.id}
                id={item.name}
                hex={item.raw}
                label={colours.includes(item) ? item.displayName : `${item.displayName} - out of stock`}
                checked={colour && item.id === colour.id}
                onClick={() => setColourId(item.id)}
                disabled={!colours.includes(item)}
                type="colour"
                outOfStock={!colours.includes(item)}
                />)}
              </>
            : 
              <LoadingBlock>
                <Loader type="single" />
                <T.RayloSubHeading>Loading...</T.RayloSubHeading>
              </LoadingBlock>
            }
            </L.ConfigureSectionCols>
          </L.ConfigureSection>

          <L.ConfigureSection>
            <T.RayloLabel>Storage</T.RayloLabel>
            {phoneStorages.length > 0 ?
              <div style={{ display: 'flex' }}>
                {phoneStorages.map((item) => <RadioButton
                  key={item.id}
                  id={item.id}
                  label={item.displayName}
                  checked={storage && item.id === storage.id}
                  onClick={() => setStorageId(item.id)}
                  disabled={!storages.includes(item)}
                  type="storage"
                  outOfStock={!storages.includes(item)}
                  />)}
              </div>
            : 
              <LoadingBlock>
                <Loader type="single" />
                <T.RayloSubHeading>Loading...</T.RayloSubHeading>
              </LoadingBlock>
            }
          </L.ConfigureSection>
          <L.ConfigureSection style={{ paddingBottom: 13 }}>
            <T.RayloLabel style={{marginBottom: 10}}>Lease Term</T.RayloLabel>
            {pricePlans && pricePlans.length > 0 ?
              <div style={{ display: 'flex' }}>
                {pricePlans.map((item) => <RadioButton
                  key={item.id}
                  id={item.id}
                  label={`${item.termLength} Months`}
                  checked={pricePlan && item.id === pricePlan.id}
                  onClick={() => setTermLength(item.termLength)}
                  type="term"
                  />)}
              </div>
            : 
              <LoadingBlock>
                <Loader type="single" />
                <T.RayloSubHeading>Loading...</T.RayloSubHeading>
              </LoadingBlock>
            }
          </L.ConfigureSection>


          {isRefurb && 
          <>
          <L.ConfigureSection style={{ paddingBottom: 13 }}>
            <T.RayloLabel style={{marginBottom: 10}}>Condition</T.RayloLabel>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <RadioButton
                label="Very good"
                checked={true}
                type="condition"
                id={"condition"}
                />
              <div style={{marginBottom: 12, marginLeft: 12}}><T.RayloLink onClick={() => setIsOpen(true)} style={{ fontSize: 12}}>Learn more</T.RayloLink></div>
            </div>
          </L.ConfigureSection>
          <ConditionModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
          </>}



          {pricePlanWithInsurance && pricePlanWithoutInsurance ?
          <>

          {!isUpgrade ?
            <L.ConfigureSection style={{ paddingBottom: 20 }}>
              <ToggleInsurance>
                <div className="status-row" onClick={() => setDropdownVisible(!dropdownVisible)}>
                  <T.RayloLabel noMargin>Insurance for £{formatDisplayPrice(pricePlanWithInsurance.costSummary.recurring.insuranceAmount.valueAfterTax.value)}/mo</T.RayloLabel>

                  <ToggleInsuranceTrigger onClick={() => setDropdownVisible(!dropdownVisible)}>
                    <ToggleInsuranceStatus open={dropdownVisible}>
                      {pricePlan.id === pricePlanWithInsurance.id ? "Insurance Included" : "Don't Include"}
                    </ToggleInsuranceStatus>
                    <DownArrow src={downArrow} open={dropdownVisible} />
                  </ToggleInsuranceTrigger>
                </div>

                <ToggleInsuranceDropdown id="insurance-dropdown" expanded={dropdownVisible} maxHeight={dropdownHeight}>
                  <div className="container">
                    <p>For just £{formatDisplayPrice(pricePlanWithInsurance.costSummary.recurring.insuranceAmount.valueAfterTax.value)} per month, we’ll include you in Raylo's very own insurance policy which includes:</p>

                    <ul className="features">
                      <li>
                        <img src={brokenPhone} alt="Icon of a broken smartphone" />
                        <span>Cover against loss, theft &amp; accidental damage</span>
                      </li>
                      <li>
                        <img src={globe} alt="Icon of the Earth with a tick mark" />
                        <span>Worldwide protection</span>
                      </li>
                    </ul>

                    <div>
                      <p>Provided by Pier, underwritten by AXA and 5-star Defaqto rating.</p>
                    </div>

                    <div>
                      <p>Since it's tied to your Raylo subscription, you won't be able to add it later. <Link onClick={() => showInsuranceModal()} inline>Read our policy summary for more information</Link></p>
                    </div>

                    <Toggle
                      active={pricePlan.id === pricePlanWithInsurance.id}
                      labelOff="Don't Include"
                      labelOn={`Include for £${formatDisplayPrice(pricePlanWithInsurance.costSummary.recurring.insuranceAmount.valueAfterTax.value)}/mo`}
                      onClick={handleInsuranceChange} />
                  </div>
                </ToggleInsuranceDropdown>
              </ToggleInsurance>
            </L.ConfigureSection>
           : null }

            <L.ConfigureSection>
              {riskPricing ? 
                <>
                  <T.RayloLabel>Total Monthly Payment</T.RayloLabel>
                  <T.RayloData><Currency quantity={pricePlan.costSummary.recurring.totalAmount.valueAfterTax.value || 0} currency={pricePlan.currencyCode} /></T.RayloData>
                </>
                :
                <div style={{ display: 'flex' }}>
                  <PhoneConfigureField fieldActive={orderActive}>
                    <T.RayloLabel>Pay Today</T.RayloLabel>
                    <T.RayloData>{pricePlan ? <Currency quantity={(pricePlan.costSummary.initial.totalAmount.valueAfterTax.value) || 0} currency={pricePlan.currencyCode} /> : "£-"}</T.RayloData>
                  </PhoneConfigureField>

                  <PhoneConfigureField fieldActive={orderActive}>
                    <T.RayloLabel>Pay Monthly</T.RayloLabel>
                    <T.RayloData>{pricePlan ? <Currency quantity={pricePlan.costSummary.recurring.totalAmount.valueAfterTax.value || 0} currency={pricePlan.currencyCode} /> : "£-"}</T.RayloData>
                  </PhoneConfigureField>
                </div>
              }
            </L.ConfigureSection>
            {variant && 
              <L.ConfigureSection>
                <OrderSummaryDelivery>
                  <img src={shippingIcon} alt="Delivery icon" style={{ marginRight: 12 }} />
                  <T.RayloData>Delivered <DeliveryEstimate delivery={variant.deliveryExpectedBetween} /></T.RayloData>
                </OrderSummaryDelivery>
              </L.ConfigureSection>
            }
            <L.ConfigureSection>
              <div style={{ marginTop: 28 }}>
                <Button type="submit" buttonStyle="primaryPink" onClick={onConfirm} disabled={!orderActive || disabled}>{riskPricing && !changeDevice ? 'Checkout' : buttonLabel}</Button>
              </div>
            </L.ConfigureSection>


          </> : 
            <LoadingBlock>
              <Loader type="single" />
              <T.RayloSubHeading>Loading...</T.RayloSubHeading>
            </LoadingBlock>
          }

        {!orderActive && false &&
          <FadeIn>
            <PhoneConfigureHelp>
              Please select a {colour && colour.id ? "storage" : "colour" } option.
            </PhoneConfigureHelp>
          </FadeIn>
        }
        </div>

      </form>
    </PhoneConfigureContainer>
  )
}

export default PhoneConfigure;

export const PhoneConfigureContainer = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 40px 20px;
  margin-bottom: 80px;

  @media (max-width: 540px) {
    padding: 20px;
    max-width: unset;
  }
`

export const PreOrder = styled.p`
font-family: 'Raylo-Medium', Arial, sans-serif;
font-weight: 500;
font-size: 0.875em;
padding: 1em 0;
color: ${colors.rayloLight};
display: flex;
align-items: center;

img { 
  margin-right: 10px;
}


`

export const PhoneConfigureField = styled.div`
  flex: 1;
  opacity: ${props => props.fieldActive ? '1' : '0.3'};
  transition: opacity .35s;
`

export const PhoneConfigureHelp = styled.p`
  font-family: 'Raylo-Medium', Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875em;
  padding: 1em 0;
  color: ${colors.rayloPink};
`

export const ToggleInsurance = styled.div`
  .status-row {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 420px) {
      flex-direction: column;
      align-items: self-start;
      img {
        position: absolute;
        top: 8px;
        right: 0px;
      }
    }

  }
`

export const LoadingBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  h2 {
    margin-bottom: 0;
    margin-left: 10px;
  }
`

export const ToggleInsuranceTrigger = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const ToggleInsuranceStatus = styled.p`
  font-family: 'Raylo-Medium', Arial, sans-serif;
  font-size: 0.875em;
  color: ${colors.rayloLight};
  line-height: 2.14;
  margin-right: 12px;
  opacity: ${props => props.open ? '0' : '1'};
  transition: opacity .35s;
`

export const DownArrow = styled.img`
  display: inline-block;
  width: 20px;
  height: 11px;
  transform: ${props => props.open ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform .35s;
`

export const ToggleInsuranceDropdown = styled.div`
  height: auto;
  overflow: hidden;
  max-height: ${props => props.expanded ? `${props.maxHeight}px` : '0'};
  transition: max-height .35s ease-in-out;

  .container {
    padding-top: 18px;
  }

  .features {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;

      img {
        display: inline-block;
        flex-basis: 10%;
        margin-right: 12px;
      }

      span {
        flex-basis: 90%;
      }
    }
  }

  p, li {
    font-family: "Raylo-Regular", Arial, sans-serif;
    font-size: 0.875em;
    color: ${colors.rayloLight};
    line-height: 1.625em;
    margin-bottom: 18px;
  }
`

