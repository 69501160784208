import styled from 'styled-components';
import { primaryColors } from '../../styles/variables/colors';
import { px2Rem } from '../../utils/px2Rem';

const StyledRayloPayHeader = styled.div`
  height: ${px2Rem(82)};
  background-color: ${primaryColors.w01};
`;

export { StyledRayloPayHeader };
