import store from 'store';
import { resetUserToken } from './resetUserToken';

export const resetSessionForNewCheckout = () => {
  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);
  const merchantDomain = queryParams.get('merchant_domain');

  // the get method from the store returns undefined when it cannot find the key
  // setting it to null allows a comparison with the merchantId
  const existingMerchantDomain = store.get('merchant_domain') || null;

  // remove merchantDomain that was not provided in the URL from the store
  store.remove('merchant_domain');
  if (merchantDomain) {
    store.set('merchant_domain', merchantDomain);
  }

  if (store.get('userToken')) {
    store.remove('checkoutToken');
    resetUserToken();
  }

  // if the checkout was not originated from the same merchant, remove the existing checkout token
  if (existingMerchantDomain !== merchantDomain) {
    store.remove('checkoutToken');
  }
};
