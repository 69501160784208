import BankDetailsContainer from 'components/BankDetailsContainer';
import DoubleButtonFullWidth from 'components/DoubleButtonFullWidth';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { useStepBankContext } from 'screens/Checkout/containers/StepBank/hooks/useStepBankContext';
import { primaryColors } from 'styles/variables/colors';
import DirectDebitConfirm from '../DirectDebitConfirm';
import DirectDebitConfirmNewDetails from '../DirectDebitConfirmNewDetails';
import { StyledBankDetailsConfirm } from './styles';
import { IBankDetailsConfirm } from './types';

export const BankDetailsConfirm = ({ dataTestId }: IBankDetailsConfirm) => {
  const {
    initialValues,
    setConfirmDetails,
    directDebitConfirm,
    confirmNewDetails,
    setDirectDebitConfirm,
    setValues,
  } = useStepBankContext();
  const handleEdit = () => {
    setConfirmDetails(false);
  };
  const handleSubmit = () => {
    setValues(initialValues);
    setDirectDebitConfirm(true);
  };
  if (confirmNewDetails) {
    return <DirectDebitConfirmNewDetails />;
  }
  if (directDebitConfirm) {
    return <DirectDebitConfirm />;
  }
  return (
    <StyledBankDetailsConfirm data-testid={dataTestId}>
      <Spacer height={40} />
      <Header
        level={2}
        color={primaryColors.c01}
        fontSize={24}
        dataTestId="bankDetailsConfirmHeader"
      >
        Are these bank details correct?
      </Header>
      <Spacer height={16} />
      <Header level={4} color={primaryColors.c01}>
        Your bank details
      </Header>
      <Spacer height={16} />
      <BankDetailsContainer bankDetails={initialValues} onEdit={handleEdit} />
      <Spacer height={48} />
      <DoubleButtonFullWidth
        topButtonText="Yes"
        bottomButtonOnClick={handleEdit}
        topButtonOnClick={handleSubmit}
        bottomButtonText="No"
        topButtonTestId="stepBankConfirm"
      />
    </StyledBankDetailsConfirm>
  );
};
