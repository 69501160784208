import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';
import { IStyledTermsAndConditions } from './types';

const StyledTermsAndConditions = styled.div<IStyledTermsAndConditions>`
  overflow: scroll;
  transition: height 500ms;
  padding-right: ${px2Rem(16)};
  ${({ height }) => height && `height: ${height};`};
`;

export { StyledTermsAndConditions };
