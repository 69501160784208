import _ from 'lodash';

// COMPONENTS
import { FadeIn } from 'components/animations/Transitions';
import { PhoneImage }  from 'components/common/PhoneImage';
import { PhoneConfigure } from 'components/Checkout/PhoneConfigure';
import { useHistory } from 'react-router';
import { useChangePhoneContext } from 'utils/useChangePhoneContext';
import store from 'store'
import { primaryColors } from 'styles/variables/colors';

export const ChoosePhone = ({subscriptionId}) => {
  const history = useHistory();
  const { variant, pricePlan } = useChangePhoneContext();
  const image = variant && _.first(variant.images, {tag: "Front" });
  
  const pickPhone = () => {
    store.remove("checkoutToken");
    if (subscriptionId) {
      history.push(`/pick/${variant.id}/${pricePlan.id}/${subscriptionId}`);
    } else {
      history.push(`/pick/${variant.id}/${pricePlan.id}`);
    }
  }

  if(!variant) return null

  return (
    <FadeIn exit={true}>
      <div className="phone-configure">
        <div className="phone-configure--image">
          {variant && image && <PhoneImage size="responsive" bg={primaryColors.b01} source={image?.url} alt={image?.alt || variant?.displayName} />}
        </div>

        <div className="phone-configure--form">
          <PhoneConfigure
            onConfirm={() => pickPhone()}
            disabled={false}
            isUpgrade={true}
            buttonLabel="Checkout"
          />
        </div>
      </div>
    </FadeIn>
  )
}
