import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';

const StyledProgressBarComponent = styled.div`
  position: relative;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const CircleIconWrapper = styled.div`
  height: ${px2Rem(24)};
  overflow: hidden;
  padding: ${px2Rem(4)};
`;

export { StyledProgressBarComponent, IconWrapper, CircleIconWrapper };
