import HowItWorksCard from 'components/HowItWorksCard';
import { Container, Spacer } from 'elements';
import { useHowItWorksContext } from './useHowItWorksContext';

export const HowItWorksContent = () => {
  const { howItWorksContent } = useHowItWorksContext();

  return (
    <Container noPadding>
      {howItWorksContent?.map(({ icon, step, content }) => (
        <div key={step}>
          <HowItWorksCard step={step} icon={icon} content={content} />
          <Spacer height={24} />
        </div>
      ))}
    </Container>
  );
};
