import { captureException, captureErrorEvent } from 'utils/errorTracking'


export function credoAppStartEventTracking() {
  if(window?.credoappsdk?.CredoAppService){
    window.credoAppService = new window.credoappsdk.CredoAppService(
      process.env.REACT_APP_CREDOAPP_URL,
      process.env.REACT_APP_CREDOAPP_AUTH_KEY
    )
    window.credoAppService.startEventTracking()
  }
}


export function credoAppCollectData(recordNumber) {
  if (!window.credoAppService) {
    return false
  }

  if (!recordNumber) {
    captureErrorEvent("Missing record number for credoApp")
    return null
  }

  window.credoAppService.stopEventTracking()
  window.credoAppService.collectAsync(recordNumber).then((recordNumber) => {
    console.log(recordNumber)

    window.credoAppService = null
  }, captureException)
  return true
}
