import React from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { ShareLink } from 'components/common/ShareLink';
import _ from 'lodash';
import moment from 'moment';


import { QL_REFERRALS, QL_REFERRAL_PROGRAMS } from 'screens/Account/queries';


import { AccountFooter } from 'components/Account/AccountFooter';
import useRayloQuery from 'utils/useRayloQuery';

import colors from 'styles/colors';
import * as A from 'styles/account';
import * as T from 'styles/type';
import { Modal } from 'components/common/Modal';
import tickBlue from 'public/icon--tick--blue.svg';
import { Helmet } from 'react-helmet';
import Container from 'elements/Container';
import useMedia from 'use-media';
import { breakpoints } from 'styles/breakpoints';
import { px2Rem } from 'utils/px2Rem';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import Copy from 'elements/Copy';
import Divider from 'elements/Divider';
import AmazonCardAnimation from 'components/AmazonCardAnimation';

export const Referrals = () => {
  const { data: { customer }, loading} = useRayloQuery(QL_REFERRALS, {fetchPolicy: "network-only"})
  const  rpQuery = useRayloQuery(QL_REFERRAL_PROGRAMS)
  const isMobile = useMedia({maxWidth: breakpoints.mobile})

  const referralProgram = rpQuery?.data?.referralProgram


  if (loading || rpQuery?.loading) return ( <Modal visible text = "Loading..." /> )

  return (
    <>
      <Helmet><title>Refer a Friend - Raylo</title></Helmet>
      <Container backgroundColor={secondaryColors.c05} noPadding alignCenter>
        <Spacer height={24} />
        <Header level={2} color={primaryColors.c01}>Refer a friend</Header>
        <Spacer height={24} />
        <Container backgroundColor={primaryColors.w01} boxShadow={!isMobile} maxWidth={px2Rem(1220)} flexRow={!isMobile} padding={`${px2Rem(48)} ${px2Rem(24)}`}>
        {isMobile &&
          <Container alignRight width={"100%"} height={px2Rem(285)}>
            <AmazonCardAnimation />
          </Container>}
          <Container width={isMobile ? "100%" : "50%"} maxWidth={px2Rem(404)}>
            <Copy color={primaryColors.c01} fontSize={24}>Refer a Friend and you'll both earn a £30 Amazon Giftcard</Copy>
            <Spacer height={24} />
            <Copy color={primaryColors.c01} fontSize={16}>For each friend who becomes a Raylo customer using your unique referral link, both you and your friend will each receive a £30 Amazon Giftcard!</Copy>
            <Spacer height={24} />
            <Divider color={secondaryColors.c04} width="100%" />
            <Spacer height={32} />
            <Copy color={primaryColors.c01} fontSize={16} bold>To refer a friend, simply send them your unique referral link:</Copy>
            <Spacer height={24} />
            <Divider color={secondaryColors.c04} width="100%" />
            <Spacer height={24} />
            <ShareLink link={customer?.referralInfo?.url} description={referralProgram.reward.referralDescription} />
          </Container>
          {!isMobile &&
          <Container alignRight width={!isMobile ? "100%" : "50%"}>
            <AmazonCardAnimation />
          </Container>}
        </Container>
        <Spacer height={40} />
      </Container>

      {customer?.referralInfo?.referrals.length > 0 && 

        <ReferralsLower>
          <ReferralsContainer>
            <ReferralsHeader>
              <h1 className="heading">Your Referrals</h1>
            </ReferralsHeader>

            {customer?.referralInfo?.successfulReferralCount > 0 &&
            <ReferralRow>
              <ReferralRowHeader>
                <T.RowLabel> 
                  You have {customer?.referralInfo?.successfulReferralCount} Successful Referral{customer?.referralInfo?.successfulReferralCount === 1 ? '' : 's'}
                </T.RowLabel>
              </ReferralRowHeader>

              {_.filter(customer?.referralInfo?.referrals, a => a.state.enum === "SUCCESSFUL").map((referral,i) => 
                <ReferralRowData key={`SUCCESSFUL-${i}`}>
                  <div>
                    {false  && <ReferralDate></ReferralDate>}
                    <ReferralName>{referral.name}</ReferralName>
                  </div>

                  <div>
                    <ReferralStatus status="success">
                      <img src={tickBlue} alt="" style={{ marginRight: 8 }} />
                      Successfully signed up - gift card sent
                    </ReferralStatus>
                  </div>
                </ReferralRowData>
              )}
            </ReferralRow>}

            {customer?.referralInfo?.pendingReferralCount > 0 &&
            <ReferralRow>
              <ReferralRowHeader>
                <T.RowLabel> 
                  You have {customer?.referralInfo?.pendingReferralCount} Pending Referral{customer?.referralInfo?.pendingReferralCount === 1 ? '' : 's'}
                </T.RowLabel>
              </ReferralRowHeader>

               {_.filter(customer?.referralInfo?.referrals, a => a.state.enum === "PENDING").map((referral,i) => 
                <ReferralRowData key={`PENDING-${i}`}>
                  <div>
                    {false  && <ReferralDate></ReferralDate>}
                    <ReferralName>{referral.name}</ReferralName>
                  </div>

                  <div>
                    <ReferralStatus status="pending">
                      Signed up. Gift card due to be sent {moment(referral?.rewardAvailableAt).fromNow()}.
                    </ReferralStatus>
                  </div>
                </ReferralRowData>
              )}
            </ReferralRow>}

            
            {customer?.referralInfo?.unsuccessfulReferralCount > 0 &&
            <ReferralRow>
              <ReferralRowHeader>
                <T.RowLabel>
                  You have {customer?.referralInfo?.unsuccessfulReferralCount} Cancelled Signup{customer?.referralInfo?.unsuccessfulReferralCount === 1 ? '' : 's'}
                </T.RowLabel>
              </ReferralRowHeader>

              {_.filter(customer?.referralInfo?.referrals, a => a.state.enum === "UNSUCCESSFUL").map((referral,i) => 
                <ReferralRowData key={`UNSUCCESSFUL-${i}`}>
                  <div>
                    {false  && <ReferralDate></ReferralDate>}
                    <ReferralName>{referral.name}</ReferralName>
                  </div>

                  <div>
                    <ReferralStatus status="cancelled">
                      Signed up, then cancelled subscription.
                    </ReferralStatus>
                  </div>
                </ReferralRowData>
              )}
            </ReferralRow>}
          </ReferralsContainer>
        </ReferralsLower>
      }

      <AccountFooter />
    </>
  )
}


export const ReferralsUpper = styled.section`
  width: 100%;
  background: #000;
`

export const ReferralsUpperContainer = styled.div`
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 40px;
  display: flex;
  align-items: stretch;
  min-height: 786px;
  padding: 40px 20px;

  @media (max-width: 675px) {
    flex-direction: column-reverse;
  }

  @media (max-width: 560px) {
    padding: 0 20px;
  }
`

export const ReferralsLower = styled.section`
  background: #FFF;
  padding: 75px 0;

  @media (max-width: 768px) {
    padding: 40px 0;
  }
`

export const ReferralsContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 0 40px;
`

export const ReferralsHeader = styled.div`
  text-align: center;
  margin-bottom: 88px;

  @media (max-width: 768px) {
    margin-bottom: 28px;
  }

  .heading {
    font-family: 'Raylo-Regular', Arial, sans-serif;
    font-size: 1.25em;
    font-weight: normal;
    color: #2D2D2D;
    line-height: 1.5;
    text-transform: uppercase;
  }
`

export const ReferAFriend = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding-right: 40px;

  @media (max-width: 675px) {
    padding-right: 0;
    justify-content: center;
  }
`

export const cardRotate = keyframes`
  0% {
    transform: rotate(0deg) translateY(0); 
  }

  100% {
    transform: rotate(10deg) translateY(10px);
  }
`

export const cardRotateAnimation = css`
  ${cardRotate} 1s cubic-bezier(0.32, 0.09, 0, 0.98) forwards normal 1s;
`

export const AmazonCards = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;

  @media (max-width: 675px) {
    padding: 40px 0;
  }

  .stack {
    position: relative;
    width: 100%;
    max-width: 525px;
    height: 371px;

    @media (max-width: 675px) {
      max-width: 325px;
      height: 275px;
    }

    @media (max-width: 350px) {
      height: 200px;
    }
  }

  .bottom,
  .top {
    position: absolute;

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  .top {
    animation: ${cardRotateAnimation};
  }
`

export const ReferralRow = styled.div`
  margin-bottom: 60px;

  @media (max-width: 768px) {
    padding-bottom: 20px;
    margin-bottom: 28px;
  }
`

export const ReferralRowHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    margin-bottom: 12px;
  }

  @media (max-width: 425px) {
    justify-content: center;
  }
`

export const ReferralRowData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    align-items: center;
  }

  &:not(:last-of-type) {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #E0E0E0;
  }

  @media (max-width: 675px) {
    flex-direction: column;
    align-items: flex-end;
  }

  @media (max-width: 425px) {
    align-items: center;
  }
`

export const ReferralDate = styled.span`
  font-family: 'Raylo-Regular', Arial, sans-serif;
  font-size: 0.875em;
  color: #6C6C6C;
  line-height: 1.7;
  margin-right: 12px;
`

export const ReferralName = styled.span`
  font-family: 'Raylo-Medium', Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875em;
  color: #2D2D2D;
  line-height: 1.7;
`

export const ReferralStatus = styled.span`
  font-family: 'Raylo-Regular', Arial, sans-serif;
  font-size: 0.875em;
  color: ${props => props.status ? {success: "#404EFF", pending: "#ED7D7B", cancelled: "#ED7D7B"}[props.status] : "#2d2d2d"};
  letter-spacing: 0;
  line-height: 1.7;
`

