import React from 'react';
import styled from 'styled-components/macro';
import * as B from 'styles/buttons';

export const BackButton = ({onClick, withText}) => {
  return (
    <BackButtonContainer>
      <B.RayloBackButton onClick={onClick} />
      {withText &&
        <p className="back-text">Back</p>
      }
    </BackButtonContainer>
  )
}

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;

  .back-text {
    font-family: 'Raylo-Regular', Arial, sans-serif;
    font-weight: normal;
    font-size: 0.75em;
    color: #FFF;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-left: 14px;
    cursor: pointer;
  }
`
