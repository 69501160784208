import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router'

// NAVIGATION


import * as A from 'styles/account';
import * as T from 'styles/type';
import { Modal } from 'components/common/Modal';

import { AccountFooter } from 'components/Account/AccountFooter';
import { DirectDebitSummary } from 'components/Account/DirectDebitSummary';
import { QL_CUSTOMER } from 'screens/Account/queries';
import { PaymentOverview } from 'components/Account/PaymentOverview';
import cardIcon from 'public/icon--card--pink.svg';
import useRayloQuery from 'utils/useRayloQuery';
import { Helmet } from 'react-helmet';
import {track} from 'helpers/analytics/segment'

export const Payments = () => {

  const [orderItem, setOrderItem] = useState({})
  const [trackedAccountViewedEvent, setTrackedAccountViewedEvent] = useState(false)

  const { orderId } = useParams()

  const { data: { customer }, loading } = useRayloQuery(QL_CUSTOMER, {fetchPolicy: "network-only"})

  useEffect(() => {

    if(customer && customer.orders) {
      const order = customer.orders.find(order => order.id === orderId)
      setOrderItem(order.items[0])
    }
  }, [customer])

  useEffect(() => {
    if (!trackedAccountViewedEvent) {
      track('My Account Viewed', {
        screen: 'payment history',
        when: 'on page load',
        url: window.location.href,
      })

      setTrackedAccountViewedEvent(true)
    }
  })

  if(loading || !orderItem.id) return (<Modal visible text="Loading..." />)

  const showPayments = orderItem?.fulfillmentItem?.dispatchedAt


  return (
    <>
      <A.AccountContainer>
        <Helmet><title>Payments - Raylo</title></Helmet>
        
        <A.PaymentOverviewBanner>
          <img src={cardIcon} alt="Card icon" />
          <T.RayloBodyHeading style={{ marginBottom: 0 }}>Payment Overview</T.RayloBodyHeading>
          {!showPayments &&
            <p className="body">Your payment overview will be available here shortly after your phone has been dispatched</p>
          }
        </A.PaymentOverviewBanner>
        {showPayments && 
          <PaymentOverview subscription={orderItem?.subscription} />
        }
        <DirectDebitSummary subscription={orderItem?.subscription} />

        <AccountFooter />
      </A.AccountContainer>
    </>
  )
}
