import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import Container from 'elements/Container';
import Spacer from 'elements/Spacer';
import { breakpoints } from 'styles/breakpoints';
import useMedia from 'use-media';
import { StyledDoubleButtonRow } from './styles';
import { IDoubleButtonRow } from './types';

export const DoubleButtonRow = ({
  dataTestId,
  leftButtonText,
  rightButtonText,
  leftButtonOnClick,
  rightButtonOnClick,
  leftButtonDataTestId,
  rightButtonDataTestId,
}: IDoubleButtonRow) => {
  const isMobile = useMedia({ maxWidth: breakpoints.mobile });
  return (
    <StyledDoubleButtonRow data-testid={dataTestId}>
      <Container flexRow={!isMobile} noPadding columnReverse={isMobile}>
        <Container noPadding>
          <Button
            text={leftButtonText}
            size="large"
            buttonType="ghost"
            onClick={leftButtonOnClick}
            type="button"
            dataTestId={leftButtonDataTestId}
          />
        </Container>
        {isMobile && <Spacer height={24} />}
        <Container
          noPadding
          styles={{
            marginLeft: !isMobile && '16px',
          }}
        >
          <Button
            buttonColor={ButtonTypes.b01}
            text={rightButtonText}
            size="large"
            onClick={rightButtonOnClick}
            type="button"
            dataTestId={rightButtonDataTestId}
          />
        </Container>
      </Container>
    </StyledDoubleButtonRow>
  );
};
