import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import Container from 'elements/Container';
import { StepInsurance } from 'screens/Checkout/components/StepInsurance';
import { StepInsuranceContent } from 'screens/Checkout/components/StepInsuranceContent/StepInsuranceContent';
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';
import useRayloQuery from 'utils/useRayloQuery';
import { useUiContext } from 'utils/useUiContext';
import { formatDisplayPrice } from 'utils/formatDisplayPrice';
import useForceUpdate from 'use-force-update';
import { MU_SWAP_CHECKOUT } from 'screens/Checkout/graphQL/mutations';
import { SoldOut } from 'screens/Errors/SoldOut';
import { InvalidChoice } from 'screens/Errors/InvalidChoice';
import { CatchAll } from 'screens/Errors/CatchAll';
import { Insurance } from 'components/Checkout/Insurance';
import { Modal } from 'components/common/Modal';
import { IStepInsurance } from './types';

export const StepInsuranceContainer = ({
  dataTestId,
  checkoutToken,
  onBack,
  onSuccess,
}: IStepInsurance) => {
  const [pricePlanWithInsurance, setPricePlanWithInsurance] = useState<any>({});
  const [pricePlanWithoutInsurance, setPricePlanWithoutInsurance] = useState(
    {},
  );
  const [showPolicy, setShowPolicy] = useState(false);
  const [originalPricePlan, setOriginalPricePlan] = useState<any>({});
  const [variant, setVariant] = useState<any>({});
  const [item, setItem] = useState<any>({});
  const [errorCode, setErrorCode] = useState('');

  const {
    uiPricePlan,
    setUiPricePlan,
    setShowFeedback,
    lightMode,
    setShowHeader,
    setShowInsuranceModal,
    setShowModal,
  } = useUiContext();

  const forceUpdate = useForceUpdate();

  const {
    data: { checkout },
    loading,
  } = useRayloQuery(CheckoutSteps.stepInsurance.query, {
    variables: {
      token: checkoutToken,
    },
  });

  const [swapCheckoutItem] = useMutation(MU_SWAP_CHECKOUT, {
    update: (
      proxy,
      {
        data: {
          swapCheckoutItem: { errors },
        },
      },
    ) => {
      if (errors?.length > 0) {
        if (_.filter(errors, { code: 'sold_out' }).length > 0) {
          setErrorCode('sold_out');
        } else if (_.filter(errors, { code: 'blank' }).length > 0) {
          setErrorCode('bad_choice');
        } else {
          setErrorCode('generic');
        }
      } else {
        onSuccess();
      }
    },
    refetchQueries: ['getProducts', 'queryPhoneSummary'],
  });

  useEffect(() => {
    if (checkout) {
      const { variant: variant2, pricePlan } = checkout.items[0];
      setUiPricePlan(pricePlan);
      setOriginalPricePlan(pricePlan);
      setVariant(variant2);
      setItem(checkout.items[0]);
      setPricePlanWithInsurance(
        _.find(variant2.pricePlans, {
          includesInsurance: true,
          termLength: pricePlan.termLength,
          recurringPeriod: pricePlan.recurringPeriod,
        }),
      );
      setPricePlanWithoutInsurance(
        _.find(variant2.pricePlans, {
          includesInsurance: false,
          termLength: pricePlan.termLength,
          recurringPeriod: pricePlan.recurringPeriod,
        }),
      );
      forceUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout]);

  const handleInsuranceChange = () => {
    const pricePlanToSwap: any =
      uiPricePlan?.id === pricePlanWithInsurance?.id
        ? pricePlanWithoutInsurance
        : pricePlanWithInsurance;
    setUiPricePlan(pricePlanToSwap);
  };

  const showInsuranceModal = () => {
    setShowModal(true);
    setShowInsuranceModal(true);
  };

  const hideInsuranceModal = () => {
    setShowFeedback(false);
    setShowPolicy(false);
    setShowHeader(true);
  };

  const onSubmit = () => {
    if (originalPricePlan.id === uiPricePlan?.id) {
      onSuccess();
      return;
    }

    swapCheckoutItem({
      variables: {
        variantId: variant?.id,
        pricePlanId: uiPricePlan?.id,
        checkoutToken,
        itemId: item?.id,
      },
    });
  };

  if (errorCode === 'sold_out') {
    return <SoldOut onClick={() => setErrorCode('')} buttonStyle="secondary" />;
  }

  if (errorCode === 'bad_choice') {
    return (
      <InvalidChoice onClick={() => setErrorCode('')} buttonStyle="secondary" />
    );
  }

  if (errorCode === 'generic') {
    return (
      <CatchAll onClick={() => setErrorCode('')} buttonStyle="secondary" />
    );
  }

  const insuranceTotal =
    pricePlanWithInsurance?.costSummary?.recurring?.insuranceAmount
      ?.valueAfterTax?.value;
  const totalPrice =
    uiPricePlan?.costSummary?.recurring?.totalAmount?.valueAfterTax?.value;

  return (
    <Container dataTestId={dataTestId} noPadding>
      {lightMode && (
        <>
          {showPolicy && (
            <Insurance visible={showPolicy} onBack={hideInsuranceModal} />
          )}
          {loading && <Modal visible text="Loading..." />}
          <StepInsuranceContent
            toggleLabelOff="Don't Include"
            toggleLabelOn={`Include for £${formatDisplayPrice(
              insuranceTotal,
            )}/mo`}
            toggleOnClick={handleInsuranceChange}
            toggleActive={uiPricePlan?.id === pricePlanWithInsurance?.id}
            insuranceTotal={insuranceTotal}
            totalPrice={totalPrice}
            onSubmit={onSubmit}
            onShowPolicyInfo={() => showInsuranceModal()}
          />
        </>
      )}
      {!lightMode && (
        <>
          {loading && <Modal visible text="Loading..." />}
          <StepInsurance
            checkoutToken={checkoutToken}
            onBack={onBack}
            onSuccess={onSuccess}
            setPricePlanCallback={null}
          />
        </>
      )}
    </Container>
  );
};
