import ProgressBar from 'components/ProgressBarComponent/ProgressBarComponent';
import Icon from 'elements/Icon';
import {
  StyledProgressBarWithBackArrow,
  IconWrapper,
  ProgressWrapper,
} from './styles';
import { IProgressBarWithBackArrow } from './types';

const ProgressBarWithBackArrow = ({
  steps,
  activeIndex,
  dataTestId,
  backArrowOnClick,
  checkout,
}: IProgressBarWithBackArrow) => {
  const hideBackFunction = checkout && activeIndex === 0;
  return (
    <StyledProgressBarWithBackArrow data-testid={dataTestId}>
      {!hideBackFunction && (
        <IconWrapper onClick={backArrowOnClick} data-testid="back-arrow">
          <Icon name="BackArrow" />
        </IconWrapper>
      )}
      {hideBackFunction && (
        <IconWrapper data-testid="back-arrow">
          <Icon name="BackArrow" />
        </IconWrapper>
      )}
      <ProgressWrapper>
        <ProgressBar
          steps={steps}
          activeIndex={activeIndex}
          dataTestId="progress-bar"
        />
      </ProgressWrapper>
    </StyledProgressBarWithBackArrow>
  );
};

export default ProgressBarWithBackArrow;
