import MobileCheckoutHeader from 'components/MobileCheckoutHeader';
import RayloPayLogo from 'components/RayloPayLogo';
import Icon from 'elements/Icon';
import { breakpoints } from 'styles/breakpoints';
import useMedia from 'use-media';
import { useUiContext } from 'utils/useUiContext';
import { StyledCheckoutNavBar, StyledDesktopNavBar } from './styles';
import { ICheckoutNavBar } from './types';

export const CheckoutNavBar = ({
  dataTestId,
  title,
  condition,
}: ICheckoutNavBar) => {
  const { showSummary, setShowSummary, isRayloPay } = useUiContext();
  const isMobile = useMedia({ maxWidth: breakpoints.desktop });

  const isRefurbished = condition === 'REFURBISHED';

  const handleButtonOnClick = () => {
    setShowSummary(!showSummary);
  };

  return (
    <StyledCheckoutNavBar data-testid={dataTestId}>
      {!isMobile && (
        <StyledDesktopNavBar>
          {isRayloPay ? (
            <RayloPayLogo />
          ) : (
            <Icon name="RayloLogo" size="7rem" />
          )}
        </StyledDesktopNavBar>
      )}
      {isMobile && (
        <MobileCheckoutHeader
          buttonText={showSummary ? 'Continue' : 'Review'}
          buttonOnClick={handleButtonOnClick}
          text={title}
          isRefurbished={isRefurbished}
        />
      )}
    </StyledCheckoutNavBar>
  );
};
