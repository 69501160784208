
import gql from 'graphql-tag'

export const Checkout = {
  fragments: {
    base: gql`
      fragment CheckoutBase on Checkout {
        id,
        token,
        termsAndConditionsAcceptedAt,
        tradeInTermsAndConditionsAcceptedAt,
        initialPaymentMethodRequired,
        state,
        decision {
          outcome
          outcomeReason
        }
      }
    `,
    mutationBase: gql`
      fragment CheckoutMutationBase on Checkout {
        id,
        token,
        initialPaymentMethodRequired,
      }
    `,
    customerInfo: gql`
      fragment CheckoutCustomerInfo on Checkout {
        customerInfo {
          firstName,
          lastName,
          email,
          dateOfBirth,
          mobilePhoneNumber
        }
      }
    `,
    address: gql`
      fragment CheckoutAddress on Checkout {
        address {
          line1,
          line2,
          line3,
          city,
          region,
          postcode,
          country {
            isoAlpha2
          }
        }
      }
    `,
    costSummary: gql`
      fragment PricePlanCostSummary on PricePlan {
        costSummary {
          recurring {
            baseAmount {
              valueAfterTax {
                value
                currencyCode
              }
            }
            totalAmount {
              valueAfterTax {
                value
                currencyCode
              }
            }
            adjustmentAmount {
              valueAfterTax {
                value
                currencyCode
              }
            }
            insuranceAmount {
              valueAfterTax {
                value
                currencyCode
              }
            }
          }
          initial {
            totalAmount {
              valueAfterTax {
                value
                currencyCode
              }
            }
          }
        }
      }
    `,
    feeSummary: gql`
    fragment PricePlanFeeSummary on PricePlan {
        feeSummary {
          gradeDDamageFee {
            formattedValue
          }
          gradeEDamageFee {
            formattedValue
          }
          nonReturnFee {
            formattedValue
          }
          earlyNonReturnFee {
            formattedValue
          }
          endOfTermNonReturnFee {
            formattedValue
          }
          earlyNonReturnFeeChargeLength
          nonReturnFeeChargeLength
        }
      }  
    `,
    pricePlan: gql`
      fragment CheckoutItemPricePlan on CheckoutItem {
        pricePlan {
          id
          name
          currencyCode
          includesInsurance
          termLength
          recurringPeriod
          costSummary {
            initial {
              totalAmount {
                valueAfterTax {
                  value
                  currencyCode
                }
              }
            }
            recurring {
              adjustmentAmount {
                valueAfterTax {
                  value
                  currencyCode
                }
              }
              baseAmount {
                valueAfterTax {
                  value
                  currencyCode
                }
              }
              insuranceAmount {
                valueAfterTax {
                  value
                  currencyCode
                }
              }
              totalAmount {
                valueAfterTax {
                  value
                  currencyCode
                }
              }
            }
          }
        }
      }
    `,
    variantPricePlan: gql `
      fragment VariantPricePlan on Variant {
        pricePlans {
          id
          name
          currencyCode
          includesInsurance
          termLength
          recurringPeriod
          costSummary {
            initial {
              totalAmount {
                valueAfterTax {
                  value
                  currencyCode
                }
              }
            }
            recurring {
              adjustmentAmount {
                valueAfterTax {
                  value
                  currencyCode
                }
              }
              baseAmount {
                valueAfterTax {
                  value
                  currencyCode
                }
              }
              insuranceAmount {
                valueAfterTax {
                  value
                  currencyCode
                }
              }
              totalAmount {
                valueAfterTax {
                  value
                  currencyCode
                }
              }
            }
          }
        }
      }
    `,
    variant: gql`
      fragment CheckoutItemVariant on CheckoutItem {
        variant {
          id,
          displayName,
          hireAgreementName,
          available
          condition,
          deliveryExpectedBetween {
            max
            min
          }
          optionValues {
            id,
            displayName,
            raw,
            optionType {
              id,
              slug
            }
          }
          product {
            id,
            displayName,
            preOrder,
            releasedAt,
            category {
              displayName,
              id
            },
            manufacturer {
              id,
              displayName
            }
          },
          images {
            alt,
            position,
            tag,
            title
            url
          }
        }
      }
    `,
    paymentInfo: gql`
      fragment CheckoutPaymentInfo on Checkout {
        paymentInfo {
          id
          bankAccountName,
          bankAccountNumber,
          bankBranchIdentifier,
          directDebitMandateAuthorised,
          cardDetails {
            expiryMonth,
            expiryYear,
            funding,
            brand,
            last4
          }
        }
      }
    `,
    incomplete: gql`
      fragment CheckoutIncomplete on Checkout {
        incompleteAttributes {
          code,
          field,
          message
        }
      }
  `,
  }
}
