import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import useMedia from 'use-media';
import { UpgradeIconWrapper } from 'components/DeviceCard/styles';
import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Icon from 'elements/Icon';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { breakpoints } from 'styles/breakpoints';
import { YourTechStatus } from 'screens/Account/YourTechStatus';
import {
  primaryColors,
  secondaryColors,
  uiStateColors,
} from 'styles/variables/colors';
import { isDeviceImageLandscape } from 'utils/isDeviceImageLandscape';
import { px2Rem } from 'utils/px2Rem';
import { formatDisplayPrice } from 'utils/formatDisplayPrice';
import { track } from 'helpers/analytics/segment';
import { StyledDeviceLeaseProfileCard } from './styles';
import { IDeviceLeaseProfileCard } from './types';

export const DeviceLeaseProfileCard = ({
  dataTestId,
  deviceData,
  subscriptionId,
}: IDeviceLeaseProfileCard) => {
  const isMobile = useMedia({ maxWidth: breakpoints.mobile });
  const isTablet = useMedia({
    minWidth: breakpoints.mobile,
    maxWidth: breakpoints.desktop,
  });
  const isDesktop = useMedia({ minWidth: breakpoints.desktop });
  const history = useHistory();

  const [backgroundImagePosition, setBackgroundImagePosition] = useState<
    string | null
  >(null);
  const [backgroundImageSize, setBackgroundImageSize] = useState<string | null>(
    null,
  );

  useEffect(() => {
    const {
      orderItem: {
        variant: {
          product: {
            category: { displayName },
          },
        },
      },
    } = deviceData;
    const deviceImageIsLandscape = isDeviceImageLandscape(displayName);
    if (deviceImageIsLandscape) {
      setBackgroundImageSize('80%');
      setBackgroundImagePosition('50% 50%');
      if (isMobile) {
        setBackgroundImageSize('70%');
      }
    }
  }, [deviceData]);

  const upgradeOnClick = () => {
    history.push(`upgrade/${subscriptionId}`);
  };

  const payNowClick = () => {
    track('My Account Pay Now Clicked', {
      screen: 'lease details',
      where: 'lease details tile',
      when: "on clicking the 'Pay Now' button on the product tile",
      url: window.location.href,
    });
    history.push(`/account/payment/${deviceData?.subscription.id}`);
  };

  const UpgradeIcon = () => (
    <UpgradeIconWrapper onClick={upgradeOnClick}>
      <Icon name="RoundArrowBack" color="white" size={px2Rem(22)} />
    </UpgradeIconWrapper>
  );

  const statusColour = (): string => {
    switch (deviceData?.status) {
      case YourTechStatus.arrears:
        return uiStateColors.error;
      case YourTechStatus.upgradeAvailable:
        return uiStateColors.success;
      default:
        return primaryColors.c01;
    }
  };

  const isStatusBold = (): boolean => {
    return [YourTechStatus.arrears, YourTechStatus.upgradeAvailable].includes(
      deviceData?.status,
    );
  };

  const statusText = (): string => {
    const formattedArrearsAmount =
      deviceData?.subscription?.arrearsAmount.formattedValue;

    switch (deviceData?.status) {
      case YourTechStatus.arrears:
        return `${formattedArrearsAmount} overdue`;
      default:
        return deviceData?.status;
    }
  };

  const showCTA: boolean = [
    YourTechStatus.arrears,
    YourTechStatus.upgradeAvailable,
  ].includes(deviceData?.status);

  const buttonText = (): string => {
    const labels = {
      [YourTechStatus.arrears]: 'pay now',
      [YourTechStatus.upgradeAvailable]: 'view upgrades',
    };
    return labels[deviceData?.status] || '';
  };

  const upgradeAvailable: boolean =
    deviceData?.status === YourTechStatus.upgradeAvailable;

  const isInArrears: boolean = deviceData?.status === YourTechStatus.arrears;

  const buttonClick = (): void => {
    if (isInArrears) {
      payNowClick();
    }
    if (upgradeAvailable) {
      upgradeOnClick();
    }
  };

  return (
    <StyledDeviceLeaseProfileCard data-testid={dataTestId}>
      <Container
        flexRow={!isMobile}
        boxShadow
        padding="0"
        maxWidth={px2Rem(1220)}
        height={!isMobile ? px2Rem(371) : 'initial'}
      >
        {isMobile && (
          <Container
            center
            backgroundColor={secondaryColors.b04}
            width="100%"
            backgroundImage={`${deviceData?.image?.url}?trim=color`}
            backgroundImageSize={backgroundImageSize || '35%'}
            backgroundImagePosition="50% 50%"
            height={px2Rem(224)}
          >
            {upgradeAvailable && <UpgradeIcon />}
          </Container>
        )}
        {isTablet && (
          <Container
            center
            backgroundColor={secondaryColors.b04}
            width={isMobile ? '100%' : '40%'}
            backgroundImage={`${deviceData?.image?.url}?trim=color`}
            backgroundImageSize="80%"
            backgroundImagePosition="50% 50%"
          >
            {upgradeAvailable && <UpgradeIcon />}
          </Container>
        )}
        {isDesktop && (
          <Container
            center
            backgroundColor={secondaryColors.b04}
            width={isMobile ? '100%' : '40%'}
            backgroundImage={`${deviceData?.image?.url}?trim=color`}
            backgroundImageSize={backgroundImageSize || 'auto 400px;'}
            backgroundImagePosition={backgroundImagePosition || '50% 50px'}
          >
            {upgradeAvailable && <UpgradeIcon />}
          </Container>
        )}
        <Container
          width={isMobile ? '100%' : '60%'}
          backgroundColor="#FFF"
          padding={px2Rem(24)}
        >
          <Spacer height={24} />
          <Header level={3} color={primaryColors.c01} fontSize={16}>
            {deviceData?.model}
          </Header>
          <Spacer height={12} />
          <Copy>{deviceData?.color}</Copy>
          <Spacer height={12} />
          <Copy>{deviceData?.storage}</Copy>
          <Spacer height={24} />
          <Header level={3} color={primaryColors.c01} fontSize={16}>
            Status
          </Header>
          <Spacer height={12} />
          <Copy color={statusColour()} bold={isStatusBold()}>
            {isInArrears && (
              <Icon
                name="RedAlert"
                styles={`
              display: inline-block;
              margin-right: ${px2Rem(8)};
              vertical-align: middle;
              `}
              />
            )}
            {statusText()}
          </Copy>
          <Spacer height={24} />
          {showCTA && (
            <Container padding="0" width={isMobile ? '100%' : px2Rem(242)}>
              <Button
                dataTestId="deviceCardCTA"
                buttonColor={ButtonTypes.b01}
                size="medium"
                text={buttonText()}
                onClick={buttonClick}
              />
              <Spacer height={24} />
            </Container>
          )}
          <Header level={3} color={primaryColors.c01} fontSize={16}>
            Monthly payments
          </Header>
          <Spacer height={12} />
          <Copy>£{formatDisplayPrice(deviceData?.payMonthly)}</Copy>
        </Container>
      </Container>
    </StyledDeviceLeaseProfileCard>
  );
};
