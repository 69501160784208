import Container from 'elements/Container';
import { StepEmail } from 'screens/Checkout/components/StepEmail';
import { IStepEmail } from './types';

export const StepEmailContainer = ({
  dataTestId,
  checkoutToken,
  onBack,
  onSuccess,
}: IStepEmail) => {
  return (
    <Container dataTestId={dataTestId} noPadding>
      <StepEmail
        checkoutToken={checkoutToken}
        onBack={onBack}
        onSuccess={onSuccess}
      />
    </Container>
  );
};
