import Container from 'elements/Container';
import { StepBankNewCustomer } from 'screens/Checkout/components/StepBankNewCustomer';
import { IStepBankNewCustomer } from './types';

export const StepBankNewCustomerContainer = ({
  dataTestId,
  slug,
  checkoutToken,
  onBack,
  onSuccess,
}: IStepBankNewCustomer) => {
  return (
    <Container dataTestId={dataTestId} noPadding>
      <StepBankNewCustomer
        slug={slug}
        checkoutToken={checkoutToken}
        onBack={onBack}
        onSuccess={onSuccess}
      />
    </Container>
  );
};
