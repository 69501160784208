
import React from 'react';

import * as A from 'styles/account';
import * as B from 'styles/buttons';
import * as T from 'styles/type';
import blockedIcon from 'public/icon--blocked.svg';

export const PhoneBlockedBanner = ({asset}) => {


  if(asset && asset.blockState.match(/^BLOCK/)) {
    return (
      <A.PhoneBlockedBanner>
        <img className="icon" src={blockedIcon} alt="A blue stop-sign icon" />
        <h5 className="heading">
          {asset.blockState === "BLOCKED" ? "Your phone has been blocked" : "A request to block your phone has been received"}
        </h5>
        <p className="body">If you have recovered your phone and would like to unblock it, please contact us on <T.RayloLink color="#404EFF" href="mailto:help@raylo.com">help@raylo.com</T.RayloLink> or call <T.RayloLink color="#404EFF" href="tel:079 8267 3546">079 8267 3546</T.RayloLink>. Alternatively, you can get in touch with our Online Chat below:</p>
        <B.BlueAccountButton onClick={() => window.Intercom('show')}>Open Online Chat</B.BlueAccountButton>
      </A.PhoneBlockedBanner>
    )
  } else {
    return null
  }
}
