import IconButton from 'components/IconButton';
import Container from 'elements/Container';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { IAddTechComponent } from './types';

export const AddTechComponent = ({
  dataTestId,
  link,
  ref,
}: IAddTechComponent) => {
  return (
    <Container
      dataTestId={dataTestId}
      height={px2Rem(150)}
      maxWidth={px2Rem(598)}
      center
      border={secondaryColors.c04}
      backgroundColor={primaryColors.w01}
      ref={ref}
    >
      <IconButton
        text="add tech"
        iconName="Add"
        buttonType="ghost"
        size="medium"
        href={link}
        dataTestId="addTechButton"
      />
    </Container>
  );
};
