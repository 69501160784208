import { affordableMobiles } from './affordableMobiles';
import { rayloLight } from './rayloLight';

export const themes = [
  {
    name: 'affordableMobiles',
    theme: affordableMobiles,
  },
  {
    name: 'rayloLight',
    theme: rayloLight,
  },
];
