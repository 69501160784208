import Container from 'elements/Container';
import Copy from 'elements/Copy';
import rayloPayAmLogo from 'public/raylo-pay/afm-logo.svg';
import poweredByRaylopay from 'public/raylo-pay/r-plogo.svg';
import { primaryColors } from 'styles/variables/colors';
import { StyledRayloPayLogo } from './styles';
import { IRayloPayLogo } from './types';

export const RayloPayLogo = ({ dataTestId }: IRayloPayLogo) => {
  return (
    <StyledRayloPayLogo data-testid={dataTestId}>
      <Container flexRow maxWidth="250px" padding="0">
        <Container margin="-5px 0 0 0">
          <img src={rayloPayAmLogo} alt="Affordable mobiles logo" />
        </Container>
        <Container>
          <Copy fontSize={9} color={primaryColors.w01}>
            Powered by
          </Copy>
          <img src={poweredByRaylopay} alt="Raylo Pay logo" />
        </Container>
      </Container>
    </StyledRayloPayLogo>
  );
};
