import React from 'react';
import * as I from 'styles/inputs';

export  const CheckboxInput = ({
  label,
  errorMessage,
  field,
  altLabel,
  checked
}) => {
  const { error } = field

  const onChange = (e) => {
    field.onChange({target: {value: e.target.checked}})
  }

  return(
    <I.RayloFormRow>
      {label ? (
        <label htmlFor={field.name} style={{ display: 'flex', alignItems: 'center' }}>
          <I.RayloCheckbox
            {...field}
            onChange={onChange}
            value={true}
            type="checkbox"
            checked={checked}
          />
          <I.RayloCheckboxLabel>{label}</I.RayloCheckboxLabel>
        </label>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <I.RayloCheckbox
            {...field}
            onChange={onChange}
            value={true}
            type="checkbox"
            checked={checked}
          />
          <I.RayloCheckboxLabel htmlFor={field.name}>{altLabel}</I.RayloCheckboxLabel>
        </div>
      )}
      {error && <I.RayloError visible={error}>
        {errorMessage || <span>&nbsp;</span>}
      </I.RayloError>}
    </I.RayloFormRow>
  )
}
