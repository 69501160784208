import React, { useEffect, useRef, useState } from 'react'
import store from 'store'
import { useParams, useHistory, useLocation } from 'react-router';
import useMedia from 'use-media';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useUiContext } from 'utils/useUiContext';
import { credoAppStartEventTracking } from "utils/credoApp"
import { captureException } from 'utils/errorTracking'

import { CheckoutSteps, GET_MERCHANT_ID, QL_CHECKOUT_BASE } from '../graphQL/queries';
import { MU_SWAP_CHECKOUT, MU_RESUME_CHECKOUT } from '../graphQL/mutations';
import { filter } from 'lodash'
import { Modal } from 'components/common/Modal';

import { resetUserToken } from 'utils/resetUserToken'
import { useErrorNavigator}  from 'utils/useErrorNavigator'
import { useTrackCheckoutContext } from 'utils/trackCheckout'
import useCustomerQuery from 'utils/useCustomerQuery';
import _ from 'lodash'
import { isAdditonalTech } from 'utils/handleGetSessionStorageValues';
import Cookies from 'universal-cookie';
import useRayloQuery from 'utils/useRayloQuery';
import { getQueryParam } from 'utils/getQueryParam';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const ProductSelector = () => {
  const query = useQuery()
  const {variantId, pricePlanId, subscriptionId} = useParams()
  const matches = useMedia({maxWidth: 1024 });
  const { setShowSummary } = useUiContext()
  const history = useHistory()
  const errorNav = useErrorNavigator()
  const checkoutToken = useRef(store.get('checkoutToken'))

  const [checkoutCreateMutation] = useMutation(CheckoutSteps.step0.mutation, {
    update: (proxy, {data: {createCheckout: { errors, checkout }}}) => {
      parseResponse(checkout, errors)
    }
  })
  const [resumeCheckout] = useMutation(MU_RESUME_CHECKOUT, {
    update: (proxy, { data: { resumeCheckout: {checkout, errors} } }) => {
      if (errors && errors.length > 0) {
        errorNav("GraphQL resume validation error", {errors: errors})
      }
    }
  })
  const [swapCheckoutItem] = useMutation(MU_SWAP_CHECKOUT, {
    update: (proxy, {data: {swapCheckoutItem: { errors, checkout }}}) => {
      parseResponse(checkout, errors)
    }
  })

  const [customer, loadingCustomer ] = useCustomerQuery(CheckoutSteps.step0.customer)

  const [loadCheckout, { data, loading, called }] = useLazyQuery(QL_CHECKOUT_BASE, {
    variables: {
      token: checkoutToken.current
    }
  })

  const [merchantDomain, setMerchantDomain] = useState()

  useEffect(() => {
     // Raylo Pay TODO - resinstate this line
    const host = window?.location?.hostname
    if(host.includes('raylopay')){
      if(host.includes('staging')){
        setMerchantDomain('app.staging.raylo.com')
      } else {
        setMerchantDomain('app.raylo.com')
      }
    } else {
      setMerchantDomain(getQueryParam('merchant_domain') || window?.location?.hostname)
    }
  }, [])

  
  const { data: merchantData } = useRayloQuery(GET_MERCHANT_ID, {
    variables: {
      domain: merchantDomain
    },
    skip: !merchantDomain
  })

  const checkoutCreate = (variantId, pricePlanId, subscriptionId) => {
    const merchantId = merchantData.merchant.id
    checkoutCreateMutation({
      variables: {
        variantId,
        pricePlanId,
        merchantId,
        originSubscriptionId: subscriptionId
      }
    })

    try {
      credoAppStartEventTracking()
    } catch (error) {
      captureException(error)
    }
  }

  const { trackCheckout } = useTrackCheckoutContext()

  const identifyCheckout = (checkout) => {
    if (window.analytics) {
      window.analytics.identify(checkout?.id)
      window.analytics.track('Product Added', { orderId: checkout?.id })
      trackCheckout(checkout.token, [{category: 'ANALYTICS', provider: 'segment'}, {category: 'EXPERIMENTS', provider: 'raylo', contentReference: 'softsearch control'}], () => {})
    }
  }
  
  function parseResponse(checkout, errors) {
    if(errors && errors.length > 0) {
      if(filter(errors, {code: "sold_out"}).length > 0) {
        history.push(`/errors/sold-out`)
      } else if(filter(errors, {code: "blank"}).length > 0) {
        history.push(`/errors/invalid-choice`)
      } else {
        errorNav("Unhandled error in GraphQL resume", {errors: errors})
      }
    } else if(checkout && checkout.state === "ABANDONED") {
      identifyCheckout(checkout)
      resumeCheckout({variables: { 
        checkoutToken: checkoutToken.current  
      }})
    } else {
      identifyCheckout(checkout)
      store.set('checkoutToken', checkout.token)
      if(matches || window.innerWidth < 1024) {
        setShowSummary(true)
      }
      history.push(`/checkout`)
    }
  }

  useEffect(() => {
    // for logged in customers
    if(!loadingCustomer && merchantData?.merchant?.id) {
      if(!!subscriptionId && customer) {
        const item = _.find(
          _.flatten(_.map(customer.orders, 'items')),
          o => o.subscription && o.subscription.id === subscriptionId
        )
        // check for existing checkout tokens for upgrades
        if(item?.subscription?.upgrade?.checkout && !isAdditonalTech()) {
          store.set("checkoutToken", item.subscription.upgrade.checkout.token)
          checkoutToken.current = item.subscription.upgrade.checkout.token
          loadCheckout()
        }
        else if (isAdditonalTech()) {
          if (customer?.preApproval?.checkout?.token) {
            store.set("checkoutToken", customer?.preApproval?.checkout?.token)
            checkoutToken.current = customer?.preApproval?.checkout?.token
            loadCheckout()
          } else {
            checkoutCreate(variantId, pricePlanId)
          }
        }
        else {
          checkoutCreate(variantId, pricePlanId, subscriptionId)
        }
      }
      // for new customers with abondoned checkouts
      else if(checkoutToken && checkoutToken.current) {
        loadCheckout()
      }
      // for new customers with no abondoned checkouts
      else {
        checkoutCreate(variantId, pricePlanId, subscriptionId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCustomer, merchantData])

  useEffect(() => {
    if(called && !loading && !!data) {
      const checkout = data.checkout

      if(!checkout) {
        store.remove('checkoutToken')
        store.remove('invitationCode')
        errorNav('Checkout not present', {}) // TODO: Remove if noisy
      } else {
        if(checkout && checkout.customer && !subscriptionId) {
          store.remove('checkoutToken')
          store.remove('invitationCode')
          resetUserToken()
          checkoutCreate(variantId, pricePlanId)
        } else if (checkout && checkout.state === "ABANDONED") {
          resumeCheckout({variables: {
            checkoutToken: checkoutToken.current
          }})
        } else if (checkout && checkout.state !== "STARTED") {
          checkoutCreate(variantId, pricePlanId)
        } else {
          const item = checkout.items[0]
          const { pricePlan, variant } = item
          if(variant.id === variantId && pricePlan.id === pricePlanId) {
            if(matches || window.innerWidth < 1024) {
              setShowSummary(true)
            }
            store.set('checkoutToken', checkout.token)
            history.push(`/checkout`)
          } else {

            swapCheckoutItem({
              variables: {
                variantId: variantId,
                pricePlanId: pricePlanId,
                checkoutToken: checkoutToken.current,
                itemId: item.id
              }
            })
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, called, history, resumeCheckout, checkoutToken, variantId, pricePlanId, matches, setShowSummary, swapCheckoutItem, checkoutCreateMutation])


  return (<Modal visible text="Loading..." />)
}
