import Container from 'elements/Container';
import Icon from 'elements/Icon';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import {
  CircleIconWrapper,
  IconWrapper,
  StyledProgressBarComponent,
} from './styles';
import { IProgressBar } from './types';

const ProgressBar = ({
  steps,
  activeIndex,
  dataTestId,
  backgroundColor,
}: IProgressBar) => {
  const renderIcon = (index: number) => {
    if (index === activeIndex) {
      return <Icon name="CheckCircleFill" color={primaryColors.b01} />;
    }
    if (index < activeIndex) {
      return <Icon name="CheckCircleFill" color={secondaryColors.c04} />;
    }
    return (
      <CircleIconWrapper>
        <Icon name="CircleFill" color={secondaryColors.c04} size="16px" />
      </CircleIconWrapper>
    );
  };
  return (
    <StyledProgressBarComponent data-testid={dataTestId}>
      <Container
        height="24px"
        center
        padding="0"
        backgroundColor={backgroundColor || 'white'}
      >
        <Container
          backgroundColor={secondaryColors.c05}
          height="4px"
          padding="0"
          width="98%"
        />
      </Container>
      <IconWrapper>
        {steps?.map((step, i) => (
          <div key={step}>{renderIcon(i)}</div>
        ))}
      </IconWrapper>
    </StyledProgressBarComponent>
  );
};

export default ProgressBar;
