import { useState, useEffect } from 'react'
import constate from 'constate'
import useRayloQuery from 'utils/useRayloQuery'
import { useCheckoutContext } from 'utils/useCheckoutContext'
import gql from 'graphql-tag'
import _ from 'lodash'
import { getVariant } from './getVariant'
import { getSessionStorage } from './handleSessionStorage'

const useChangePhone = ({initialVariantId}) => {

  const [manufacturer, setManufacturer] = useState(undefined)
  const [product, setProduct] = useState(undefined)
  const [pricePlan, setPricePlan] = useState(undefined)
  const [variant, setVariant] = useState(undefined)
  const [colour, setColour] = useState(undefined)
  const [storage, setStorage] = useState(undefined)

  const [initialVariantComplete, setInitialVariantComplete] = useState(false)

  const [hasInsurance, setHasInsurance] = useState(undefined)
  const [termLength, setTermLength] = useState(undefined)

  const [productIds, setProductIds] = useState([])
  const [variantIds, setVariantIds] = useState([])

  const [colours, setColours] = useState([])
  const [storages, setStorages] = useState([])

  const [allColours, setAllColours] = useState([])
  const [allStorages, setAllStorages] = useState([])
  const [allPricePlans, setAllPricePlans] = useState([])

  const [pricePlans, setPricePlans] = useState([])
  const [variants, setVariants] = useState([])

  const [pricePlanWithInsurance, setPricePlanWithInsurance] = useState(undefined)
  const [pricePlanWithoutInsurance, setPricePlanWithoutInsurance] = useState(undefined)
  const [productCategoriesId, setProductCategoriesId] = useState(undefined)
  const [productId, setProductId] = useState(undefined)
  const [variantId, setVariantId] = useState(undefined)
  const [manufacturerId, setManufacturerId] = useState(undefined)
  const [colourId, setColourId] = useState(undefined)
  const [storageId, setStorageId] = useState(undefined)

  const [loadingColours, setLoadingColours] = useState(true)
  const [loadingStorages, setLoadingStorages] = useState(true)
  const [loadingPricePlans, setLoadingPricePlans] = useState(true)
  const [loadingVariant, setLoadingVariant] = useState(true)
  const [isRefurb, setIsRefurb] = useState(false)

  
  const { checkoutToken } = useCheckoutContext();
  const existingCheckoutToken = getSessionStorage("checkoutToken") || null;

  const { data: { checkout }, loading: loadingCheckout } = useRayloQuery(gql`
    query queryCheckout($checkoutToken: String!) {
      checkout(token: $checkoutToken) {
        id
        items {
          id
          pricePlan {
            id
            includesInsurance
            termLength
          }
          variant {
            id
            optionValues {
              id
              optionType {
                id
                slug
              }
            }
            product {
              id
              manufacturer {
                id
              }
            }
          }
        }
      }
    }`, { 
      skip: !checkoutToken,
      variables: { checkoutToken } 
    })


  const { data: { variants: initialVariants }, loading: loadingInitial } = useRayloQuery(gql`
    query queryInitialVariant($variantId: ID!) {
      variants(ids: [$variantId]) {
        id       
        displayName
        condition
        optionValues {
          id
          optionType {
            id
            slug
          }
        }
        product {
          id
          category {
            displayName
          }
          manufacturer {
            id
          }
          category {
            id
          }
        }
        pricePlans {
          id
          includesInsurance
          termLength
          costSummary {
            recurring {
              totalAmount {
                valueAfterTax {
                  value
                }
              }
            }
          }
        }
      }      
    }`, { 
      skip: !initialVariantId,
      variables: { 
        variantId: initialVariantId
      }
    })
    

  const { data: { productCategories }, loading: loadingProductCategories } = useRayloQuery(gql`
    query ProductCategoriesQuery {
      productCategories {
        id
        slug
        displayName
      }
    }`)

  const { data: { manufacturers }, loading: loadingManufacturers } = useRayloQuery(gql`
    query queryManufacturersForCheckout($productCategoryIds: [ID!]) {
      manufacturers(forTradeIn: false, forCheckout: true, productCategoryIds: $productCategoryIds) {
        id
        displayName
      }
    }`, { 
      variables: { productCategoryIds: [productCategoriesId]},
    })

    
    useEffect(() => {
      if(productCategories) {
        const id = productCategories.find((category) => category.slug === "phone")?.id
        setProductCategoriesId(id)
        const initialCategoryId = initialVariants?.[0].product?.category?.id
        setProductCategoriesId(initialCategoryId)
      }
    }, [loadingProductCategories, initialVariants])

  useEffect(() => {
    if(checkout) {
      setHasInsurance(checkout.items[0].pricePlan.includesInsurance)
      setTermLength(checkout.items[0].pricePlan.termLength)
      setProductId(checkout.items[0].variant.product.id)
      setManufacturerId(checkout.items[0].variant.product.manufacturer.id)
      setVariantId(checkout.items[0].variant.id)
      setColourId(_.find(checkout.items[0].variant.optionValues, {optionType: {slug: "colour"}}).id)
      setStorageId(_.find(checkout.items[0].variant.optionValues, {optionType: {slug: "storage"}}).id)
    }
  }, [checkout])

  useEffect(() => {
    if(initialVariants && initialVariants.length > 0 && !initialVariantComplete) {
      setInitialVariantComplete(true)
      const variant = initialVariants[0]
      const pricePlan = _.first(_.sortBy(variant.pricePlans, o => o.costSummary.recurring.totalAmount.valueAfterTax.value))
      setHasInsurance(pricePlan.includesInsurance)
      setTermLength(pricePlan.termLength)
      if(variant.product.id !== productId) {
        setProduct(undefined)
        setProductId(variant.product.id)
        setManufacturer(undefined)
        setManufacturerId(variant.product.manufacturer.id)
      }
      if(variant.id !== variantId) {
        setVariant(undefined)
        setVariantId(variant.id)
      }
      setColourId(_.find(variant.optionValues, {optionType: {slug: "colour"}}).id)
      setStorageId(_.find(variant.optionValues, {optionType: {slug: "storage"}}).id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialVariants, initialVariantComplete])


  useEffect(() => {
    if(manufacturerId && manufacturers && manufacturers.length > 0) {
      setManufacturer(
        _.find(manufacturers, {id: manufacturerId})
      )
    } else {
      setManufacturer(undefined)
    }
    if (manufacturers?.length === 1) {
      setManufacturerId(manufacturers[0].id)
    }
   }, [manufacturers, manufacturerId])

    
  const { data: { manufacturers: manufacturerProducts }, loading: loadingManufacturerProducts, refetch: refetchManufacturerProducts } = useRayloQuery(gql`
    query queryManufacturersWithProducts($ids: [ID!]) {
      manufacturers(ids: $ids, forTradeIn: false, forCheckout: true) {
        id
        displayName
        products {
          id
        }
      }
    }`, { 
      variables: { ids: [manufacturerId]},
      skip: !manufacturerId
    })

  useEffect(() => {
    if (manufacturerProducts && manufacturerProducts.length > 0) {
      setProductIds(_.map(_.flatten(_.map(manufacturerProducts, 'products')),'id'))
    } else {
      setProductIds([])
    }
  }, [manufacturerProducts])

  const { data: { products }, loading: loadingProducts } = useRayloQuery(gql`
    query queryProductsForCheckout($productCategoryIds: [ID!], $manufacturerIDs: [ID!]) {
      products(productCategoryIds: $productCategoryIds, manufacturerIds: $manufacturerIDs, forTradeIn: false, forCheckout: true) {
        id
        displayName
        position
        preOrder
        releasedAt
        specifications
        variants {
          available
          id
          condition
        }
      }
    }`, { 
        variables: {
          productCategoryIds: [productCategoriesId],
          manufacturerIDs: [manufacturerId]
        },
        skip: productIds.length === 0
      })

  useEffect(() => {
    if(products && productId && products.length > 0) {
      setLoadingColours(true)
      setLoadingStorages(true)
      setLoadingPricePlans(true)
      setLoadingVariant(true)
      const sortedProducts = _.reverse(_.sortBy(products, ['position']))
      const newProduct = _.find(products, {id: productId}) || _.first(sortedProducts)
      if(newProduct && newProduct.id !== productId) {
        setProductId(newProduct.id)
      } else {
        setProduct(newProduct)
      }
    } else {
      setProduct(undefined)
    }
  }, [products, productId, isRefurb])


  const { data: { products: productVariants }, loading: loadingProductVariants } = useRayloQuery(gql`
    query queryProducts($ids: [ID!]) {
      products(ids: $ids, forTradeIn: false, forCheckout: true) {
        id
        variants {
          id
        }
      }
    }`, { 
      variables: {
        ids: [productId]
      },
      skip: !productId
    })

  useEffect(() => {
    if (productVariants && productVariants.length > 0) {
      setVariantIds(_.map(_.flatten(_.map(productVariants, 'variants')),'id'))
    } else {
      setVariantIds([])
    }
  }, [productVariants])

  const { data: { variants: allVariants }, loading: loadingVariants } = useRayloQuery(gql`
    query queryVariantsForChangePhoneContext($ids: [ID!]) {
      variants(ids: $ids, forTradeIn: false, forCheckout: true) {
        id
        displayName
        available
        condition
        deliveryExpectedBetween {
          max
          min
        }
        images {
          alt
          position
          tag
          title
          url
        }
        optionValues {
          id
          displayName
          raw
          position
          optionType {
            id
            slug
          }
        }
        pricePlans {
          id
          includesInsurance
          termLength
          costSummary {
            recurring {
              totalAmount {
                valueAfterTax {
                  value
                }
              }
            }
          }
        }
      }
    }  
  `,{ 
    variables: {
      ids: variantIds
    },
    skip: variantIds.length === 0
  })

  useEffect(() => {
    if(allVariants && allVariants.length > 0) {
      setVariants(_.filter(allVariants, {available: true}))
      setAllColours(_.sortBy(_.uniq(_.map(allVariants, o => _.find(o.optionValues, {optionType: {slug: 'colour'}}))), ['displayName']))
      setAllStorages(_.sortBy(_.uniq(_.map(allVariants, o => _.find(o.optionValues, {optionType: {slug: 'storage'}}))), o => parseInt(o.displayName.replace('GB',''))))
    } else {
      setVariants([])
      setAllColours([])
      setAllStorages([])
    }
  }, [allVariants])

  useEffect(() => {
    if(variantId && variants && variants.length > 0) {
      setLoadingVariant(false)
      setVariant(_.find(variants, {id: variantId}))
    } else {
      setVariant(undefined)
    }
  }, [variants, variantId])

  const { data: { variants: variantPricePlans } } = useRayloQuery(gql`
  query queryPricePlans($ids: [ID!], $checkoutToken: String) {
    variants(ids: $ids, forTradeIn: false, forCheckout: true, checkoutToken: $checkoutToken) {
      id
      pricePlans {
        id
        name
        currencyCode
        includesInsurance
        recurringPeriod
        termLength
        feeSummary {
          endOfTermNonReturnFee {
            value
            currencyCode
          }
        }
        costSummary {
          initial {
            totalAmount {
              valueAfterTax {
                value
                currencyCode
              }
            }
          }
          recurring {
            insuranceAmount {
              valueAfterTax {
                value
                currencyCode
              }
            }
            baseAmount {
              valueAfterTax {
                value
                currencyCode
              }
            }
            adjustmentAmount {
              valueAfterTax {
                value
                currencyCode
              }
            }
            totalAmount {
              valueAfterTax {
                value
                currencyCode
              }
            }
          }
        }
      }
    }
  }`,{ 
    variables: {
      ids: [variantId],
      checkoutToken: existingCheckoutToken
    },
    skip: !variantId
  })

  useEffect(() => {
    if(variantPricePlans && variantPricePlans.length > 0) {
      setAllPricePlans(
        _.sortBy(
          _.flatten(_.map(variantPricePlans, "pricePlans"))
        , ['termLength'])
      )
    } else {
      setAllPricePlans([])
    }
  }, [variantPricePlans])

  useEffect(() => {
    if(allPricePlans && termLength && allPricePlans.length > 0) {
      setLoadingPricePlans(false)
      setPricePlanWithInsurance(_.find(allPricePlans, {includesInsurance: true, termLength: termLength}))
      setPricePlanWithoutInsurance(_.find(allPricePlans, {includesInsurance: false, termLength: termLength}))
      setPricePlans(
        _.sortBy(
          _.filter(allPricePlans, {includesInsurance: hasInsurance})
        , ['termLength'])
      )
      const newPricePlan = _.find(allPricePlans, {
        includesInsurance: hasInsurance,
        termLength: termLength
      }) || _.filter(allPricePlans, {includesInsurance: hasInsurance}).pop()
      setPricePlan(newPricePlan)
      setTermLength(newPricePlan.termLength)
    } else {
      setPricePlanWithInsurance(undefined)
      setPricePlanWithoutInsurance(undefined)
      setPricePlans([])
      setPricePlan(undefined)
    }
  }, [allPricePlans, hasInsurance, termLength])

  useEffect(() => {
    if(variants && variants.length > 0) {
      setLoadingColours(false)
      setColours(_.sortBy(_.uniq(_.map(variants, o => _.find(o.optionValues, {optionType: {slug: 'colour'}}))), ['displayName']))
    } else {
      setColours([])
    }
  }, [variants])

  useEffect(() => {
    if(colours && colourId && colours.length > 0) {
      const newColour = _.find(colours, {id: colourId}) || _.first(colours) || _.first(allColours)
      if(newColour && newColour.id !== colourId) {
        setColourId(newColour.id)
      } else {
        setColour(newColour)
      }
    } else {
      setColour(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colours, colourId])

  useEffect(() => {
    if(variants && colour && variants.length > 0) {
      setLoadingStorages(false)
      const variantsWithColour = _.filter(variants, o => _.find(o.optionValues, {id: colour.id}))
      setStorages(_.sortBy(_.uniq(_.map(variantsWithColour, o => _.find(o.optionValues, {optionType: {slug: 'storage'}}))), o => parseInt(o.displayName.replace('GB',''))))
    } else {
      setStorages([])
    }
  }, [variants, colour])

  useEffect(() => {
    if(storages && storageId && storages.length > 0) {
      const newStorage = _.find(storages, {id: storageId}) || _.first(storages) || _.first(allStorages)
      if(newStorage && newStorage.id !== storageId) {
        setStorageId(newStorage.id)
      } else {
        setStorage(newStorage)
      }
    } else {
      setStorage(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storages, storageId])

  useEffect(() => {
    setIsRefurb(false)
  }, [manufacturer])

  useEffect(() => {
    const variantChosen = getVariant(variants, isRefurb, colour, storage)
    if (variantChosen) {
      setVariantId(variantChosen.id)
    } else {
      setVariantId(undefined)
    }
  }, [colour, storage, variants, isRefurb, manufacturer])

  return {
    productCategories,
    productCategoriesId,
    manufacturers,
    products,
    colours,
    storages,
    pricePlans,
    pricePlanWithInsurance,
    pricePlanWithoutInsurance,
    pricePlan,
    manufacturer,
    product,
    variant,
    colour,
    storage,
    isRefurb,
    variantId,

    hasInsurance,
    termLength,

    allColours,
    allStorages,
    
    setProductCategoriesId,
    setStorageId,
    setProductId,
    setColourId,
    setManufacturerId,
    setHasInsurance,
    setTermLength,
    setIsRefurb,
    getVariant,

    loading: loadingManufacturers || loadingCheckout || loadingInitial || loadingManufacturerProducts || loadingProductVariants || loadingProducts || loadingVariants || loadingPricePlans,
    loadingManufacturers,
    loadingInitial, 
    loadingCheckout,
    loadingManufacturerProducts, 
    loadingProductVariants, 
    loadingProducts, 
    loadingVariants, 
    loadingPricePlans,

    loadingColours,

    loadingSubsection: loadingColours || loadingStorages || loadingPricePlans || loadingVariant,

    refetchManufacturerProducts
  }

}

const [ChangePhoneContext, useChangePhoneContext] = constate(useChangePhone);
export { ChangePhoneContext, useChangePhoneContext }
