import gql from 'graphql-tag';

export const START_SUBSCRIPTION_ARREARS_PAYMENT_MUTATION = gql`
  mutation ($subscriptionId: ID!) {
    startSubscriptionArrearsPayment(subscriptionId: $subscriptionId) {
      incomingPayment {
        id
        totalAmount {
          formattedValue
        }
      }
    }
  }
`;
