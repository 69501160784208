// GQL
import gql from 'graphql-tag'

export const QL_GET_MERCHANT_DETAILS = gql`
  query MerchantQuery($domain: String!) {
    merchant(domain: $domain) {
      brandingTheme
      id
    }
  }
`

export const QL_PRODUCTS = gql`
  query ProductsQuery {
  products {
    id
    displayName
    available
    optionTypes {
      id
      slug
      displayName
      optionValues {
        id
        name
        displayName
        variants {
          id
          displayName,
          product {
            id
          }
          pricePlans {
            id
            name
          }
        }
      }
    }
  }
}`

export const QL_VARIANTS = gql`
query VariantsQuery {
  variants {
    id
    displayName
    available
    condition
    pricePlans {
      id
      name
      includesInsurance
      termLength
    }
  }
}`
