import React from 'react';
import { Helmet } from 'react-helmet'
// HOOKS

// GRAPHQL
import { QL_DECISION } from 'screens/Checkout/graphQL/queries';

// VISUAL COMPONENTS
import * as T from 'styles/type';
import * as L from 'styles/layout';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { BlocksAnimation } from 'components/animations/BlocksAnimation';
import { PhoneHeader } from 'screens/Checkout/components/PhoneHeader';
import { Modal } from 'components/common/Modal';
import useRayloQuery from 'utils/useRayloQuery';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { useErrorNavigator}  from 'utils/useErrorNavigator'

export const DecisionDeclined = () => {
  const errorNav = useErrorNavigator()
  const { checkoutToken } = useCheckoutContext()

  // TODO: checkout throwing warning as unused, but removing it is breaking journey
  // eslint-disable-next-line no-unused-vars
  const { data: {checkout}, error, loading } = useRayloQuery(QL_DECISION, {
    variables: {
      checkoutToken
    },
  })

  if(error) {
    errorNav("Decision: failure", error)
  }

  if(loading) {
    return <Modal visible text="Loading..." />
  }
  return (
    <div className="checkout--index">
      <Helmet>
        <title>Sorry - Raylo</title>
      </Helmet>
      {process.env.NODE_ENV !== 'test' && <div className="checkout--header"><PhoneHeader checkoutToken={checkoutToken} /></div>}
      <div className="checkout--main">
        <div className="checkout--step">
          <L.SignupFeedbackContainer>
            <SlideDownFadeIn>
              <div>
                <T.RayloBodyHeading><strong>Unfortunately we are not able to continue with your order</strong></T.RayloBodyHeading>
                <T.RayloBodyType>Based on information held at the credit reference agencies, we are unable to continue with your order. We cannot provide any further information on this decision but we recommend you do the following:</T.RayloBodyType>
                <T.RayloBodyList style={{ marginBottom: 18 }}>
                  <li><T.RayloLink href="https://www.gov.uk/electoral-register" target="_blank" rel="noopener noreferrer">Check you are on the electoral register</T.RayloLink></li>
                  <li>Check the information held at the credit reference agencies: <T.RayloLink href="https://www.experian.co.uk" target="_blank" rel="noopener noreferrer">Experian</T.RayloLink>, <T.RayloLink href="https://www.transunion.co.uk" target="_blank" rel="noopener noreferrer">Transunion</T.RayloLink> or <T.RayloLink href="https://www.equifax.co.uk" target="_blank" rel="noopener noreferrer">Equifax</T.RayloLink>.</li>
                </T.RayloBodyList>
                <T.RayloBodyType>We’ve cancelled your order and will not take any payments from you.</T.RayloBodyType>
              </div>
            </SlideDownFadeIn>
          </L.SignupFeedbackContainer>
        </div>
      </div>
      <FadeIn>
        <BlocksAnimation />
      </FadeIn>
    </div>
  )
}
