import { useMedia } from 'use-media';
import { useUiContext } from 'utils/useUiContext'
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import * as T from 'styles/type';
import * as L from 'styles/layout';
import Currency from 'react-currency-formatter';
import OrderSummaryMobile from 'components/Checkout/OrderSummaryMobile'
import { Button } from 'components/common/Button';
import { PhoneImage } from 'components/common/PhoneImage';
import { DeliveryEstimate } from 'components/common/DeliveryEstimate';
import { FadeIn } from 'components/animations/Transitions';
import shippingIcon from 'public/icon--shipping.svg';
import amazonIcon from 'public/icon--amazon.svg';
import shieldIcon from 'public/icon--insurance-shield.svg';
import tradeInIcon from 'public/icon--trade-in--pink.svg';
import colors from 'styles/colors';
import { ProductCategories } from 'types';
import { useCheckoutContext } from 'utils/useCheckoutContext';

export const OrderSummary = ({checkout, referralDescription, phone, delivery, tradeIn, tradeInExample, checkoutStep, displayOnly = false}) => {
  const [tradeInTotal, setTradeInTotal] = useState();
  const [isLaptop, setIsLaptop] = useState(false)

  const { setShowSummary, setShowConfigure, setShowReview, showReview, showConfigure } = useUiContext();
  const { merchantHasInsuranceOption, merchantHasChangeProductOption } = useCheckoutContext()
  
  const onContinue = () => {
    setShowReview(false)
    setShowSummary(false)
  }
  
  const isMobile = useMedia({maxWidth: 1024})
  const {lightMode, showSummary} = useUiContext()
  const continueLabel = showReview ? "Next" : "Continue"

  const ctaButton = <Button buttonStyle="secondary" dataTestId="ChangeProduct" onClick={() => setShowConfigure(true)}>Change Product</Button>;

  useEffect(() => {
    setIsLaptop(checkout?.items[0]?.variant?.product?.category?.displayName === ProductCategories.LAPTOP)
    setTradeInTotal(checkout?.tradeIns?.[0]?.costSummary?.recurringTotalAmount?.valueAfterTax?.currencyCode)
  }, [checkout])

  if (isMobile && lightMode && showSummary) return (
    <OrderSummaryMobile
      phoneData={phone}
      delivery={delivery}
      onContinue={() => onContinue()}
      tradeInTotal={tradeInTotal}
      onChangePhone={() => setShowConfigure(true)} />
  )
  return (
    <OrderSummaryOuterContainer>
      <FadeIn timeout={1000}>
        <div>
          <OrderSummaryInnerContainer>
            <OrderSummaryImage>
              {phone?.image && 
              <div style={{
                width: isLaptop ? "70%" : "80%",
                margin: "auto"
              }}>
                <PhoneImage size="responsive" bg="404EFF" source={phone.image.url} alt={phone.image.alt || `${phone?.model} in ${phone?.color}`} />
              </div>}

              {!displayOnly && merchantHasChangeProductOption &&
                <OrderSummaryChange style={{ marginTop: 30 }}>
                  {ctaButton}
                </OrderSummaryChange>
              }
            </OrderSummaryImage>

            <OrderSummaryDetails>

              {showReview && <OrderSummaryTitle>Let's review</OrderSummaryTitle>}

              <L.ConfigureSection>
                <div className="row">
                  <div className="cell">
                    <T.RayloLabel>Model</T.RayloLabel>
                    <T.RayloData>{phone.model}</T.RayloData>
                  </div>
                </div>

                <div className="row">
                  <div className="cell">
                    <T.RayloLabel>Storage</T.RayloLabel>
                    <T.RayloData>{phone.storage}</T.RayloData>
                  </div>
                  <div className="cell">
                    <T.RayloLabel>Colour</T.RayloLabel>
                    <T.RayloColor color={phone.hexColor}>{phone.color}</T.RayloColor>
                  </div>
                </div>
              </L.ConfigureSection>

              <L.ConfigureSection>
                <div className="row">

                  <div className="cell-lease">
                    <T.RayloLabel>Lease Term</T.RayloLabel>
                    <T.RayloPrice>{phone.termLength} Months</T.RayloPrice>
                  </div>
                </div>
              </L.ConfigureSection>
              {merchantHasInsuranceOption &&
                <L.ConfigureSection style={{ paddingBottom: 20 }}>
                  {phone.insurance ?
                    <div className="insurance-included" style={{ display: 'flex' }}>
                      <div style={{ marginRight: 20, display: 'flex' }}>
                        <img src={shieldIcon} alt="Icon of a shield with a tick inside it" />
                      </div>
                      <div>
                        <T.RayloData style={{ marginBottom: 0 }}>Insurance Included</T.RayloData>
                      </div>
                    </div>
                    :
                    <div className="row">
                      <div className="cell">
                        <T.RayloLabel style={{ marginBottom: 0 }}>Insurance</T.RayloLabel>
                      </div>

                      <div className="cell">
                        <T.RayloPrice style={{ marginBottom: 0 }}>Not Added</T.RayloPrice>
                      </div>
                    </div>
                  }
                </L.ConfigureSection>
              }
              <L.ConfigureSection>
                <div className="row">
                  <div className="cell">
                    <T.RayloLabel>Pay Monthly via Direct Debit</T.RayloLabel>
                      <>
                        {phone?.payRecurring?.insuranceAmount && 
                          <>
                            <OrderSummaryPriceBreakdown>
                              <T.RayloBodyType>Raylo Lease</T.RayloBodyType>
                              {phone.payRecurring.adjustmentAmount && phone.payRecurring.adjustmentAmount.valueAfterTax.value > 0 ? 
                                <T.RayloPrice><Currency quantity={phone.payRecurring.adjustmentAmount.valueAfterTax.value + phone.payRecurring.baseAmount.valueAfterTax.value} currency={phone.currency} /></T.RayloPrice>
                              :
                                <T.RayloPrice><Currency quantity={phone.payRecurring.baseAmount.valueAfterTax.value} currency={phone.currency} /></T.RayloPrice>
                              }
                            </OrderSummaryPriceBreakdown>
                            {merchantHasInsuranceOption &&
                              (
                                <OrderSummaryPriceBreakdown>
                                  <T.RayloBodyType>Insurance</T.RayloBodyType>
                                  <T.RayloPrice><Currency quantity={phone.payRecurring.insuranceAmount.valueAfterTax.value} currency={phone.currency} /></T.RayloPrice>
                                </OrderSummaryPriceBreakdown>
                              )
                            }
                          </>
                        }
                        {phone.payMonthly != null && 
                          <OrderSummaryPriceBreakdown total>
                            {phone.payRecurring && phone.payRecurring.insuranceAmount ? 
                              <T.RayloBodyType>Monthly Total</T.RayloBodyType>
                            :
                              <T.RayloBodyType>Raylo Lease</T.RayloBodyType>
                            }
                            <T.RayloPrice><Currency quantity={phone.payMonthly} currency={phone.currency} /></T.RayloPrice>
                          </OrderSummaryPriceBreakdown>
                        }
                      </>
                  </div>
                </div> 
              </L.ConfigureSection>

              {tradeIn && tradeIn.costSummary && <L.ConfigureSection>
                <TradeInSection>

                  <img src={tradeInIcon} alt="Trade In" style={{marginRight: 12}}/>
                  <T.RayloPrice>
                    {tradeIn.grade.slug === 'faulty' ? 'At least ' : null}
                    <Currency quantity={Math.abs(tradeIn.costSummary.recurringTotalAmount.valueAfterTax.value)} currency={tradeIn.costSummary.recurringTotalAmount.valueAfterTax.currencyCode} /><span>/mo Trade-In Cashback</span>
                  </T.RayloPrice>
                </TradeInSection>
              </L.ConfigureSection>}

              <L.ConfigureSection>
                {checkout && checkout.referrer &&
                <OrderSummaryAmazonVoucher>
                  <img src={amazonIcon} alt="The Amazon logo" style={{marginTop: 5, marginRight: 12}}/>
                  <div>
                  <T.RayloData>{referralDescription}</T.RayloData>
                  <T.RayloData>Referred by {checkout?.referrer?.firstName}</T.RayloData>
                    </div>
                </OrderSummaryAmazonVoucher>
                }


                {!displayOnly &&
                  <OrderSummaryDelivery>
                    <img src={shippingIcon} alt="Delivery icon" style={{ marginRight: 12 }} />
                    {(phone.preOrder) ?
                      <T.RayloData
                        >Pre-Order<br/>
                        <strong>Delivered <DeliveryEstimate delivery={delivery} /></strong>
                      </T.RayloData>
                      :
                      <T.RayloData>Delivered <DeliveryEstimate delivery={delivery} /></T.RayloData>
                    }
                  </OrderSummaryDelivery>
                }

                {false && tradeInExample && checkoutStep === 'StepTradeIn' ? <OrderSummaryTradeIn>
                  <p>Price after Trade-In Cashback</p>
                  <p>
                    <Currency quantity={phone.payToday} currency={phone.currency} /> <em>Upfront</em>&nbsp;+&nbsp;
                    <Currency quantity={phone.payMonthly - tradeInExample} currency={phone.currency} /><em>/mo</em>
                  </p>
                </OrderSummaryTradeIn> : null}

                <OrderSummaryActions>
                  {!displayOnly &&
                    <div style={{ marginBottom: 20 }}>
                      {ctaButton}
                    </div>
                  }

                  <div>
                    <Button buttonStyle="primaryPink" onClick={() => onContinue()}>{continueLabel}</Button>
                  </div>
                </OrderSummaryActions>
              </L.ConfigureSection>
            </OrderSummaryDetails>
          </OrderSummaryInnerContainer>

          <OrderSummaryMobileActions>
            {!displayOnly &&
              <div>
                {ctaButton}
              </div>
            }

            <div>
              <Button buttonStyle="primaryPink" onClick={() => onContinue()}>{continueLabel}</Button>
            </div>
          </OrderSummaryMobileActions>
        </div>
      </FadeIn>
    </OrderSummaryOuterContainer>
  )
}


export const TradeInSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const OrderSummaryOuterContainer = styled.div`
  padding: 40px;
  width: 100%;
  overflow-x: hidden;

  @media (max-width: 1024px) {
    width: 90%;
    justify-content: center;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 40px 20px;
  }

  @media (max-width: 540px) {
    padding: 20px;
  }

  @media (max-width: 450px) {
    align-items: stretch;
  }
`

export const OrderSummaryPriceBreakdown = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
  > p {
    ${props => props.total ? "font-weight: bold !important;" : ""};
    line-height: 1.6em !important;
    &:first-child {
      margin-right: 10px;
    }
  }
`

export const OrderSummaryInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  /* @media (max-width: 1330px) and (min-width: 1025px) {
    width: 100%;
    justify-content: space-between;
  } */

  @media (max-width: 450px) {
    display: block;
  }
`

export const OrderSummaryImage = styled.div`
  @media (min-width: 1024px) {
    max-width: 650px;
  }

  @media (max-width: 450px) {
    display: none;
  }
`

export const OrderSummaryTitle = styled.h2`
font-family: 'Raylo-Regular', Arial, sans-serif;
font-size: 1.1875em;
color: #FFFFFF;
letter-spacing: -0.21px;
margin-bottom: 35px;
`

export const OrderSummaryDetails = styled.div`
  padding-left: 5%;
  padding-top: 20px;
  max-width: 320px;
  width: 100%;
  flex-grow: 1;

  @media (max-width: 1330px) {
    padding-left: 20px;
  }

  @media (max-width: 1024px) {
    padding-left: 40px;
  }

  @media (max-width: 450px) {
    padding-left: 0;
    max-width: unset;
  }
  @media (max-height: 700px) {
    padding-top: 0;
  }
`

export const OrderSummaryPrices = styled(L.ConfigureSection)`
  display: flex;
  border: 0;
  padding: 0;
`

export const OrderSummaryDelivery = styled.div`
  display: flex;
  align-items: flex-start;

  img {
    margin-top: 3px;
  }

  @media (max-width: 1100px) and (min-width: 1025px) {
    flex-direction: column;

    img {
      margin-top: 0px;
      margin-right: 0;
      margin-bottom: 8px;
    }
  }
`

export const OrderSummaryAmazonVoucher = styled(OrderSummaryDelivery)`
  margin-bottom: 28px;
  img {
    margin-right: 0;
    margin-top: -15px;
    padding-top: -10px;
  }
`

export const OrderSummaryChange = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  @media (max-width: 1024px) {
    display: none;
  }
`


export const OrderSummaryTradeIn = styled.div`
  padding: 15px 20px;
  background-color: rgba(255,255,255,0.1);
  font-size: 13px;
  line-height: 30px;
  margin-top: 20px;
  color: white;
  display: none;
  em {
    color: ${colors.rayloPink};
    font-style: normal;
  }
  @media (max-width: 1024px) {
    display: block;
  }

`


export const OrderSummaryActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 0 0;

  @media (min-width: 1025px) {
    display: none;
  }

  @media (max-width: 660px) {
    display: none;
  }
`

export const OrderSummaryMobileActions = styled.div`
  display: none;
  margin-top: 40px;

  & > div {
    margin-right: 35px;
  }

  @media (max-width: 660px) {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 420px) {
    flex-direction: column;
    align-items: stretch;
    margin-top: 20px;

    div:first-of-type {
      margin: 0 0 20px;
    }

    div:last-of-type {
      margin-right: 0;
    }

    button {
      width: 100%;
    }
  }
`
