import Container from 'elements/Container';
import Icon from 'elements/Icon';
import { secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { StyledPreApprovalLabel } from './styles';
import { IPreApprovalLabel } from './types';

export const PreApprovalLabel = ({ dataTestId, large }: IPreApprovalLabel) => {
  return (
    <StyledPreApprovalLabel data-testid={dataTestId}>
      <Container
        padding={`${px2Rem(2)} ${px2Rem(8)} 0 0`}
        width="initial"
        center
      >
        <Icon
          name="CheckCircle2"
          color={secondaryColors.p02}
          size={px2Rem(24)}
        />
      </Container>
      <Container padding="0" width="initial" center>
        <span
          style={{
            color: '#fff',
            fontSize: px2Rem(large ? 16 : 14),
            fontWeight: 'bold',
            letterSpacing: px2Rem(0.25),
          }}
        >
          PRE-APPROVED
        </span>
      </Container>
    </StyledPreApprovalLabel>
  );
};
