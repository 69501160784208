import { useEffect } from 'react';
import { useHistory } from 'react-router';
import useMedia from 'use-media';
import Container from 'elements/Container';
import { SLUGS } from 'screens/Checkout/helpers/slugs';
import { useUiContext } from 'utils/useUiContext';
import { px2Rem } from 'utils/px2Rem';
import RPOrderCancelModal from 'components/RPOrderCancelModal';
import StepAddressContainer from '../StepAddress';
import StepAddressCheckContainer from '../StepAddressCheck';
import StepAgeCountryContainer from '../StepAgeCountry';
import StepBankContainer from '../StepBank';
import StepBankNewCustomerContainer from '../StepBankNewCustomer';
import StepEmailContainer from '../StepEmail';
import StepInsuranceContainer from '../StepInsurance';
import StepMobileJobContainer from '../StepMobileJob';
import StepNameEmailContainer from '../StepNameEmail';
import StepSignContainer from '../StepSign';
import StepStripeContainer from '../StepStripe';
import { StepTermsContainer } from '../StepTerms/StepTerms';
import StepTradeInContainer from '../StepTradeIn';
import StepUpgradeContainer from '../StepUpgrade';
import { StyledCheckoutStepsContainer } from './styles';
import { ICheckoutStepsContainer } from './types';
import StepLeaseTermContainer from '../StepLeaseTerm';
import { StepLeaseTermProvider } from '../StepLeaseTerm/hooks/useStepLeaseTermContext';
import { CheckoutContentProvider } from './hooks/useCheckoutContentContext';

export const CheckoutStepsContainer = ({
  dataTestId,
  step,
  checkoutToken,
  backToAccount,
  nextStep,
  goToStep,
  previousStep,
  checkout,
}: ICheckoutStepsContainer) => {
  const history = useHistory();
  const isDesktop = useMedia({ maxWidth: 1024 });
  const isSmallMobile = useMedia({ maxWidth: 500 });
  const { setShowSummary, setShowReview, showSummary, showRPCancelOrderModal } =
    useUiContext();

  useEffect(() => {
    window.scroll(0, 0);
  }, [history?.location?.pathname, showSummary]);

  return (
    <StyledCheckoutStepsContainer data-testid={dataTestId}>
      <CheckoutContentProvider>
        <Container alignCenter padding={`0 ${px2Rem(16)}`}>
          <Container width={isSmallMobile ? '100%' : px2Rem(414)} noPadding>
            {step === 'StepUpgrade' && (
              <StepUpgradeContainer
                checkoutToken={checkoutToken}
                onBack={() => backToAccount()}
                onSuccess={() => nextStep('StepUpgrade')}
              />
            )}
            {step === 'StepLeaseTerm' && (
              <StepLeaseTermProvider
                onSuccess={() => nextStep('StepLeaseTerm')}
              >
                <StepLeaseTermContainer />
              </StepLeaseTermProvider>
            )}
            {step === 'StepInsurance' && (
              <StepInsuranceContainer
                checkoutToken={checkoutToken}
                onBack={() => previousStep('StepInsurance')}
                onSuccess={() => nextStep('StepInsurance')}
              />
            )}
            {step === 'StepTradeIn' && (
              <StepTradeInContainer
                slug={SLUGS[step]}
                checkoutToken={checkoutToken}
                onBack={() => previousStep('StepTradeIn')}
                onSuccess={() => nextStep('StepTradeIn')}
              />
            )}
            {step === 'StepTerms' && (
              <StepTermsContainer
                slug={SLUGS[step]}
                checkoutToken={checkoutToken}
                onBack={() => previousStep('StepTerms')}
                onSuccess={() => nextStep('StepTerms')}
              />
            )}
            {step === 'StepEmail' && (
              <StepEmailContainer
                checkoutToken={checkoutToken}
                onBack={() => previousStep('StepEmail')}
                onSuccess={() => nextStep('StepEmail')}
              />
            )}
            {step === 'StepNameEmail' && (
              <StepNameEmailContainer
                checkoutToken={checkoutToken}
                onBack={() => previousStep('StepNameEmail')}
                onSuccess={() => nextStep('StepNameEmail')}
              />
            )}
            {step === 'StepAgeCountry' && (
              <StepAgeCountryContainer
                checkoutToken={checkoutToken}
                onBack={() => previousStep('StepAgeCountry')}
                onSuccess={() => nextStep('StepAgeCountry')}
              />
            )}
            {step === 'StepAddress' && (
              <StepAddressContainer
                checkoutToken={checkoutToken}
                onBack={() => previousStep('StepAddress')}
                onSuccess={() => nextStep('StepAddress')}
              />
            )}
            {step === 'StepAddressCheck' && (
              <StepAddressCheckContainer
                slug={SLUGS[step]}
                checkoutToken={checkoutToken}
                onBack={() => previousStep('StepAddressCheck')}
                onSuccess={() => nextStep('StepAddressCheck')}
              />
            )}
            {step === 'StepMobileJob' && (
              <StepMobileJobContainer
                slug={SLUGS[step]}
                checkoutToken={checkoutToken}
                onBack={() => previousStep('StepMobileJob')}
                onSuccess={() => nextStep('StepMobileJob')}
              />
            )}
            {step === 'StepBank' && (
              <StepBankContainer
                slug={SLUGS[step]}
                checkoutToken={checkoutToken}
                onBack={() => previousStep('StepBank')}
                onSuccess={() => {
                  if (isDesktop) {
                    setShowReview(true);
                    setShowSummary(true);
                  }
                  nextStep('StepBank');
                }}
              />
            )}
            {step === 'StepBankNewCustomer' && (
              <StepBankNewCustomerContainer
                slug={SLUGS[step]}
                checkoutToken={checkoutToken}
                onBack={() => previousStep('StepBankNewCustomer')}
                onSuccess={() => {
                  if (isDesktop) {
                    setShowReview(true);
                    setShowSummary(true);
                  }
                  nextStep('StepBankNewCustomer');
                }}
              />
            )}
            {step === 'StepStripe' && (
              <StepStripeContainer
                slug={SLUGS[step]}
                checkoutToken={checkoutToken}
                onBack={() => previousStep('StepStripe')}
                onSuccess={() => nextStep('StepStripe')}
              />
            )}
            {(step === 'StepSign' || step === 'StepSignOffer') && (
              <StepSignContainer
                slug={SLUGS[step]}
                checkoutToken={checkoutToken}
                onBack={() => {
                  if (
                    checkout?.decision?.outcome === 'USER_ACTION_REQUIRED' &&
                    checkout?.decision?.outcomeReason === 'counter_offer'
                  ) {
                    history.push('/checkout/decision/offer-details');
                  } else {
                    previousStep('StepSign');
                  }
                }}
                onCreditCard={() => goToStep('StepStripe')}
              />
            )}
          </Container>
        </Container>
      </CheckoutContentProvider>
      {showRPCancelOrderModal && <RPOrderCancelModal />}
    </StyledCheckoutStepsContainer>
  );
};
