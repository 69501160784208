import React from 'react';
import _ from 'lodash';

import * as I from 'styles/inputs';
import * as L from 'styles/layout';
import {RayloSelect} from '../common/RayloSelect';

const DAYS = _.range(1,32).map(d => {return {label: d, value: d }})
export const MONTHS = [
  {label: "January", value: 1},
  {label: "February", value: 2},
  {label: "March", value: 3},
  {label: "April", value: 4},
  {label: "May", value: 5},
  {label: "June", value: 6},
  {label: "July", value: 7},
  {label: "August", value: 8},
  {label: "September", value: 9},
  {label: "October", value: 10},
  {label: "November", value: 11},
  {label: "December", value: 12},
]
const YEARS = _.range((new Date()).getFullYear(), (new Date()).getFullYear()-100).map( y => { return { label: y, value: y }})

export const DOBGroup = ({label, field, errorMessage}) => {

  const {error} = field

  return (
    <I.RayloFormRow>
      {label &&
        <I.RayloFieldLabel isFocused={false}>
          {label}
        </I.RayloFieldLabel>
      }
      <L.DOBContainer>
        <L.DOBDate>
          <RayloSelect
            options={DAYS}
            placeholder="Day"
            field={{error}}
          />
        </L.DOBDate>

        <L.DOBMonth>
          <RayloSelect
            options={MONTHS}
            placeholder="Month"
            field={{error}}
          />
        </L.DOBMonth>

        <L.DOBYear>
          <RayloSelect
            options={YEARS}
            placeholder="Year"
            field={{error}}
          />
        </L.DOBYear>
      </L.DOBContainer>

      <I.RayloError visible={error}>
        {errorMessage}
      </I.RayloError>
    </I.RayloFormRow>
    )
}

