import React from 'react';
import styled from 'styled-components/macro';
import * as B from 'styles/buttons';

import { OverduePayment } from 'components/Account/OverduePayment'
import { NextPayment } from 'components/Account/NextPayment'
import { LastPayment } from 'components/Account/LastPayment'

import { useHistory, useParams } from 'react-router';

export const PaymentSummary = ({subscription}) => {
  const history = useHistory()

  const { orderId } = useParams()

  if(subscription) {
    return (
      <PaymentSummaryOuter>
        <PaymentSummaryInner>
          <OverduePayment subscription={subscription} />
          <NextPayment nextPaymentScheduleEvent={subscription.nextPaymentScheduleEvent} />
          <LastPayment transactions={subscription.transactions} />

          {subscription.transactions.length > 0 &&
            <div style={{ textAlign: 'center' }}>
              <B.ViewFullStatement onClick={() => history.push(`/account/payments/${orderId}`)}>View Full Statement</B.ViewFullStatement>
            </div>
          }
        </PaymentSummaryInner>
      </PaymentSummaryOuter>
    )
  } else {
    return null
  }
}

export const PaymentSummaryOuter = styled.section`
  background-color: #fff;
  padding: 75px 0;

  @media (max-width: 768px) {
    padding: 40px 0;
  }
`

export const PaymentSummaryInner = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 0 40px;

  @media (max-width: 540px) {
    padding: 0 20px;
  }
`

export const PaymentSummaryHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 88px;

  @media (max-width: 768px) {
    margin-bottom: 28px;
  }

  .card-icon {
    margin-right: 20px;
  }

  .heading {
    font-family: 'Raylo-Regular', Arial, sans-serif;
    font-size: 1.25em;
    font-weight: normal;
    color: #2D2D2D;
    line-height: 1.5;
    text-transform: uppercase;
  }
`
