import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { history } from "./history"

const ignoreEventMessages = [
  // Add your event type here, not used at the moment but we have the ability if needed
]

const ignoreExceptions = {
  // This error is coming from facebook and is happening from the autofill.payment.js
  // More here https://stackoverflow.com/questions/64042411/json-parse-error-unrecognized-token-error-caught-by-sentry
  "SyntaxError": 'Unexpected identifier "enqueueImmediate"'
}

export function enableErrorTracking() {
  if (isSentryEnabled()) {
    // https://docs.sentry.io/platforms/javascript/guides/react/#monitor-performance
    Sentry.init({
      beforeSend(event, hint) {
        if (event && event.message && ignoreEventMessages.includes(event.message)){
          return null
        } else if(event &&  event.exception && event.exception.values.length !== 0 ) {
          let {type, value} = event.exception.values[0]
          if (ignoreExceptions[type] && value.match(ignoreExceptions[type])) {
            return null
          }
          return event
        }
        return event
      },
      tracesSampleRate: 0.01,
      denyUrls: [
        /(iojs|general5)/, // iovation related
        /i18n\/pixel\/events/
      ],
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_ERROR_TRACKING_ENVIRONMENT,
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: ['localhost', /\.herokuapp.com/, /\.raylo\.com/],
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
        })
      ],
      ignoreErrors: [],
    })
  }
}

function isSentryEnabled() {
  return (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_DSN)
}

export function captureErrorEvent(message, context) {
  isSentryEnabled() && Sentry.captureEvent({
    message: message,
    extra: context
  })
}

export function captureErrorAndNavigate(message, context, url = "/errors/oops") {
  captureErrorEvent(message, context)
}


export function setErrorContext(contextName, contextValues) {
  isSentryEnabled() && Sentry.setContext(contextName, contextValues)
}

export function captureException(error) {
  isSentryEnabled() && Sentry.captureException(error)
}
