import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Divider from 'elements/Divider';
import Grid from 'elements/Grid';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { formatDisplayPrice } from 'utils/formatDisplayPrice';
import { StyledPhonePricingCard } from './styles';
import { IPhonePricingCard } from './types';

export const PhonePricingCard = ({
  dataTestId,
  payMonthly,
  phoneCost,
  insuranceCost,
}: IPhonePricingCard) => {
  return (
    <StyledPhonePricingCard data-testid={dataTestId}>
      <Header level={4} color={primaryColors.c01}>
        Pay Monthly
      </Header>
      <Spacer height={16} />
      <Grid>
        <Container padding="0" width="50%">
          <Copy color={primaryColors.c01}>Raylo Lease</Copy>
          <Spacer height={8} />
          {!!insuranceCost && (
            <>
              <Copy color={primaryColors.c01}>Insurance</Copy>
              <Spacer height={8} />
            </>
          )}
          <Copy color={primaryColors.c01}>Pay upfront today</Copy>
        </Container>
        <Container padding="0" width="50%" alignRight>
          <Copy color={secondaryColors.c02}>
            £{formatDisplayPrice(phoneCost)}
          </Copy>
          <Spacer height={8} />
          {!!insuranceCost && (
            <>
              <Copy color={secondaryColors.c02}>
                £{formatDisplayPrice(insuranceCost)}
              </Copy>
              <Spacer height={8} />
            </>
          )}
          <Copy color={secondaryColors.c02}>£0</Copy>
        </Container>
      </Grid>
      <Spacer height={24} />
      <Divider color={secondaryColors.c04} />
      <Spacer height={24} />
      <Grid>
        <Container padding="0" width="50%">
          <Header level={4} color={primaryColors.c01}>
            Monthly total
          </Header>
        </Container>
        <Container padding="0" width="50%" alignRight>
          <Header level={4} color={primaryColors.c01}>
            £{formatDisplayPrice(payMonthly)}
          </Header>
        </Container>
      </Grid>
    </StyledPhonePricingCard>
  );
};
