import React from 'react';

import * as T from 'styles/type';

export const Link = ({children, href, target, inline, onClick}) => {
  return (
    <T.RayloLink href={href} target={target} inline={inline} onClick={onClick}>
      {children}
    </T.RayloLink>
  )
}
