import React from 'react';
import styled from 'styled-components/macro';

import { OverduePayment } from 'components/Account/OverduePayment'
import { NextPayment } from 'components/Account/NextPayment'
import { PreviousPayments } from 'components/Account/PreviousPayments'

export const PaymentOverview = ({subscription}) => {
  if(subscription) {
    return (
      <PaymentOverviewOuter>
        <PaymentOverviewInner>
          <OverduePayment subscription={subscription} />
          <NextPayment nextPaymentScheduleEvent={subscription.nextPaymentScheduleEvent} />
          <PreviousPayments transactions={subscription.transactions} />
        </PaymentOverviewInner>
      </PaymentOverviewOuter>
    )
  } else {
    return null
  }
}


export const PaymentOverviewOuter = styled.section`
background-color: #fff;
padding: 75px 0;
`

export const PaymentOverviewInner = styled.div`
max-width: 900px;
margin: 0 auto;
padding: 0 40px;

@media (max-width: 540px) {
  padding: 0 20px;
}
`
