import _ from 'lodash';

export const getVariant = (
  variants: any,
  isRefurbished: boolean,
  colour: any,
  storage: any,
) => {
  const activeVariants = variants.filter(
    (v: { pricePlans: [] }) => !!v?.pricePlans?.length,
  );
  let availableOptions = _.filter(activeVariants, {
    condition: isRefurbished ? 'REFURBISHED' : 'NEW',
  });
  if (!availableOptions.length) {
    availableOptions = activeVariants;
  }
  const variantChosen = _.find(availableOptions, (o) => {
    const vcolour = _.find(o.optionValues, { id: colour?.id });
    const vstorage = _.find(o.optionValues, { id: storage?.id });
    return vcolour && vstorage;
  });
  return variantChosen;
};
