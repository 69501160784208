import { FadeIn } from 'components/animations/Transitions';
import DoubleButtonFullWidth from 'components/DoubleButtonFullWidth';
import EditContentBox from 'components/EditContentBox';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { useCheckoutMobileContext } from 'screens/Checkout/containers/StepMobileJob/hooks/useCheckoutMobileContext';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { StyledMobileConfirm } from './styles';
import { IMobileConfirm } from './types';

export const MobileConfirm = ({ dataTestId }: IMobileConfirm) => {
  const { number, setNumber, setFormSubmitted } = useCheckoutMobileContext();

  const handleResetNumber = () => {
    setNumber(undefined);
  };

  const handleSubmit = () => {
    setFormSubmitted(true);
  };

  return (
    <FadeIn>
      <StyledMobileConfirm data-testid={dataTestId}>
        <Spacer height={40} />
        <Header level={2} color={primaryColors.c01}>
          Is this mobile number correct?
        </Header>
        <Spacer height={24} />
        <EditContentBox
          onClick={handleResetNumber}
          backgroundColor={secondaryColors.c04}
        >
          {number}
        </EditContentBox>
        <Spacer height={40} />
        <DoubleButtonFullWidth
          topButtonText="Yes"
          bottomButtonText="No"
          topButtonOnClick={handleSubmit}
          bottomButtonOnClick={handleResetNumber}
          topButtonTestId="stepMobileNext"
        />
      </StyledMobileConfirm>
    </FadeIn>
  );
};
