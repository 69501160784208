import { firstLettersToLowerCase } from './firstLettersToLowerCase';

// Check any fields in this array to ensure they don't contain numbers
const textOnlyFields = ['firstName', 'lastName', 'accountName'];

/* eslint-disable */
const validateEmail = (email: string) => {
  if (email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  return false;
};

const validateName = (field: string, value?: string) => {
  if (value && textOnlyFields.includes(field)) {
    const re = /^[a-zA-Z'.\- ]+$/;
    return re.test(value);
  }
  return false;
};
const validatePhone = (value: string) => {
  if (value) {
    const number = value.replace(/\s/g, '').replace('+', '');
    const re = /((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}/g;
    return re.test(number);
  }
  return false;
};
const validateDay = (value: string) => {
  if (value) {
    const re = /\b(0?[1-9]|[1-2][0-9]|3[0-1])\b/;
    return re.test(value);
  }
  return false;
};
const validateMonth = (value: string) => {
  if (value) {
    const re = /\b(0?[1-9]|[1][0-2])\b/;
    return re.test(value);
  }
  return false;
};
const validateAccountNumber = (value: string) => {
  if (value) {
    const re = /\W*\d{8}\b/;
    return re.test(value);
  }
  return false;
};
const validateSortCode = (value: string) => {
  if (value) {
    const re = /^(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)$/;
    return re.test(value);
  }
  return false;
};
const validateYear = (value: string) => {
  if (value) {
    // if year greater than current year
    if (Number(value) > new Date().getFullYear()) {
      return false;
    }
    const re = /^(19|20)\d{2}$/;
    return re.test(value);
  }
  return false;
};

function getAge(dateString: string) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
/* eslint-enable */

export const isUser18 = (day: string, month: string, year: string) => {
  const date = `${year}/${month}/${day}`;
  return getAge(date) >= 18;
};

export const errorMessages = (name: string) => {
  const entry = firstLettersToLowerCase(name);
  return {
    textNoEntry: `Please enter a valid ${entry}`,
    dobNoEntry: 'Please enter a valid date of birth',
    textInvalidEntry: 'Characters accepted: A-Z and simple punctuation',
    emailNoEntry: 'Please enter a valid email address',
    emailInvalidEntry: 'Please enter a valid email address',
    mobileInvalidEntry: 'Please enter a valid mobile phone number',
    accountNumberInvalidEntry: 'Please enter a valid account number',
    sortCodeInvalidEntry: 'Please enter a valid sort code',
    dobInvalidEntry: 'Please enter a valid date of birth',
    dobUnderAge: 'Sorry, you have to be 18 or older to sign up',
  };
};

export const validation = (
  type: string,
  value: string,
  field: string,
  name: string,
) => {
  const error = errorMessages(name);
  if (type === 'text') {
    if (!value || value?.length < 2) {
      return error.textNoEntry;
    }
    if (!validateName(field, value)) {
      return error.textInvalidEntry;
    }
  }
  if (type === 'email') {
    if (!value) {
      return error.emailNoEntry;
    }
    if (!validateEmail(value)) {
      return error.emailInvalidEntry;
    }
  }
  if (field === 'mobilePhoneNumber') {
    if (!value || !validatePhone) {
      return error.mobileInvalidEntry;
    }
  }
  if (field === 'accountNumber') {
    if (!value || !validateAccountNumber(value)) {
      return error.accountNumberInvalidEntry;
    }
  }
  if (field === 'sortCode') {
    if (!value || !validateSortCode(value)) {
      return error.sortCodeInvalidEntry;
    }
  }
  return 'success';
};

export const dateValidation = (field: string, value: string | any) => {
  const error = errorMessages(field);
  if (!value && field !== 'dateOfBirth') {
    return error.textNoEntry;
  }
  if (field === 'day' && !validateDay(value)) {
    return error.textNoEntry;
  }
  if (field === 'month' && !validateMonth(value)) {
    return error.textNoEntry;
  }
  if (field === 'year' && !validateYear(value)) {
    return error.textNoEntry;
  }
  if (field === 'dateOfBirth') {
    if (!value) {
      return error.dobNoEntry;
    }
    const { DD: day = '00', MM: month = '00', YYYY: year = '0000' } = value;
    if (!validateDay(day) || !validateMonth(month) || !validateYear(year)) {
      return error.dobNoEntry;
    }
    if (!isUser18(day, month, year)) {
      return error.dobUnderAge;
    }
  }
  return 'success';
};
