import { useState, useEffect } from 'react';
import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import DualToneHeader from 'elements/DualToneHeader';
import PreApprovalLabel from 'elements/PreApprovalLabel';
import Spacer from 'elements/Spacer';
import { useHistory } from 'react-router';
import { breakpoints } from 'styles/breakpoints';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import useMedia from 'use-media';
import {
  removeSessionStorage,
  setSessionStorage,
} from 'utils/handleSessionStorage';
import { px2Rem } from 'utils/px2Rem';
import { titleToUrl } from 'utils/titleToUrl';
import { formatDisplayPrice } from 'utils/formatDisplayPrice';
import { ProductCategories } from 'types';
import { StyledPreApprovedDeviceCard } from './styles';
import { IPreApprovedDeviceCard } from './types';

export const PreApprovedDeviceCard = ({
  dataTestId,
  product,
  subscriptionId,
  previewCard,
  index,
  checkoutToken,
}: IPreApprovedDeviceCard) => {
  const {
    displayName = '',
    variantConditions = [],
    lowestRecurringCostPricePlan,
    category,
  } = product;
  const history = useHistory();
  const isRefurbished = variantConditions.includes('REFURBISHED');
  const condition = isRefurbished ? 'Refurbished' : 'Brand new';
  const monthlyPrice =
    lowestRecurringCostPricePlan?.costSummary?.recurring?.totalAmount
      ?.valueAfterTax?.value;
  const variant = lowestRecurringCostPricePlan?.variants?.nodes[0];
  const image = `${variant?.images[0]?.url}?trim=color&auto=format&fm=webp`;
  const deviceTerm = lowestRecurringCostPricePlan?.termLength;

  const [backgroundImagePosition, setBackgroundImagePosition] = useState<
    string | undefined
  >(undefined);
  const [backgroundImageSize, setBackgroundImageSize] = useState<string>('90%');

  const onClick = () => {
    setSessionStorage('subscriptionId', subscriptionId);
    setSessionStorage('variantId', variant.id);
    if (checkoutToken) {
      setSessionStorage('checkoutToken', checkoutToken);
    }
    if (history?.location?.pathname?.includes('upgrade')) {
      history.push(
        `/account/upgrade/${subscriptionId}/${titleToUrl(displayName)}`,
      );
      removeSessionStorage('additionalTech');
      setSessionStorage('upgrade', 'true');
    } else {
      removeSessionStorage('upgrade');
      setSessionStorage('additionalTech', 'true');
      history.push(`/account/additional-tech/${titleToUrl(displayName)}`);
    }
  };

  const isMobile = useMedia({ maxWidth: breakpoints.mobile });
  const isTablet = useMedia({
    minWidth: breakpoints.mobile,
    maxWidth: breakpoints.tablet,
  });

  useEffect(() => {
    if (product?.category?.displayName?.includes(ProductCategories.LAPTOP)) {
      setBackgroundImagePosition('50% 100%');
      if (isMobile) {
        setBackgroundImageSize(px2Rem(300));
      }
    } else {
      setBackgroundImagePosition('50% 0');
      if (isTablet) {
        setBackgroundImageSize('70%');
      } else if (isMobile) {
        setBackgroundImageSize(px2Rem(300));
      }
    }
  }, [product]);

  const cardPadding = isMobile ? 5 : 15;

  const catDisplayName = category?.displayName;
  const dualToneFontColor = isRefurbished
    ? secondaryColors.p03
    : secondaryColors.b03;

  const headerHeight = previewCard ? px2Rem(20) : 'initial';

  return (
    <StyledPreApprovedDeviceCard data-testid={dataTestId}>
      <Container backgroundColor={primaryColors.c01} alignCenter noPadding>
        <Spacer height={24} />
        <PreApprovalLabel />
        <Spacer height={24} />
        <Container noPadding alignCenter height={px2Rem(60)}>
          <Container
            padding={`0 ${px2Rem(16)}`}
            height={headerHeight}
            styles={{
              overflow: 'hidden',
            }}
          >
            <DualToneHeader
              fontSize={16}
              fontColor={dualToneFontColor}
              subFontColor="#fff"
            >
              {condition} <span>{displayName}</span>
            </DualToneHeader>
          </Container>
          <Spacer height={4} />
          <Copy lineHeight={20} color="#fff">
            From {`£${formatDisplayPrice(monthlyPrice)}`}/month
          </Copy>
        </Container>
        <Spacer height={24} />
        <Container
          padding="0"
          backgroundImage={image}
          backgroundImageSize={backgroundImageSize}
          backgroundImagePosition={backgroundImagePosition}
          height={px2Rem(225)}
        />
        <Container
          backgroundColor="#454545"
          padding={previewCard ? '0' : `0 0 ${px2Rem(16)} 0`}
        >
          <Container padding="24px">
            <Button
              dataTestId={`product-${index}`}
              text={`Choose ${catDisplayName}`}
              size="medium"
              buttonColor={ButtonTypes.p01}
              onClick={onClick}
              fontSize={16}
            />
          </Container>
          <Container padding={`0 ${cardPadding}%`}>
            {!previewCard && (
              <>
                <ul>
                  <li>
                    {isRefurbished ? 'Certified Refurbished' : 'Brand new'}
                  </li>
                  {deviceTerm === 12 && <li>12 month lease</li>}
                  {deviceTerm === 24 && <li>12 or 24 month lease</li>}
                  {deviceTerm === 36 && <li>12, 24 or 36 month lease</li>}
                  <li>Free next day delivery</li>
                  <li>Lifetime warranty</li>
                </ul>
              </>
            )}
          </Container>
        </Container>
      </Container>
    </StyledPreApprovedDeviceCard>
  );
};
