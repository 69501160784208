import React, { useState } from 'react';
import Select, { components } from 'react-select'
import * as I from 'styles/inputs';
import { Tooltip } from 'components/common/Tooltip';
import _ from 'lodash';

import colors from 'styles/colors';
import pinkDownArrow from 'public/icon--arrow--pink--down.svg';

export const customStyles = (error = false) => {
  return {
    container: (provided) => ({
      ...provided,
      width: '100%',
      padding: 0,
    }),
    control: state => ({
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      background: 0,
      outline: 0,
      borderRadius: 0,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: error ? `${colors.rayloPink}` : `${colors.raylo3Dark}`,
      fontFamily: 'Raylo-Regular, sans-serif',
      fontSize: 14,
      color: `${colors.rayloPink}`,
      lineHeight: 2.14,
      padding: '0.5em 0 0.5em 1em',
      transition: 'border-color .35s',

      '&:focus': {
        borderColor: `${colors.rayloLight}`
      },

      '&.react-select__control--menu-is-open': {
        borderColor: `${colors.rayloLight}`
      },

      '&.react-select__control--is-focused': {
        borderColor: `${colors.rayloLight}`
      },

      '@media screen and (max-width: 768px)': {
        fontSize: 16,
      }
    }),
    valueContainer: provided => ({
      ...provided,
      padding: 0,
    }),
    input: (provided, state) => ({
      ...provided,
      fontFamily: 'Raylo-Regular, sans-serif',
      fontSize: 14,
      fontWeight: 'normal',
      color: `${colors.rayloPink}`,
      lineHeight: 1.14,
      padding: 0,

      '@media screen and (max-width: 768px)': {
        fontSize: 16
      }
    }),
    placeholder: provided => ({
      color: `${colors.rayloPink}`
    }),
    dropdownIndicator: provided => ({
      padding: '0 1em 0 0'
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none'
    }),
    singleValue: provided => ({
      ...provided,
      color: `${colors.rayloPink}`,
      cursor: 'default'
    }),
    menu: (provided) => ({
      ...provided,
      margin: 0,
      width: '100%',
      zIndex: 10,
    }),
    menuList: (provided) => ({
      ...provided,
      margin: 0,
      borderRadius: 0,
      padding: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#FFF' : state.isFocused ? '#FFF' : `${colors.rayloDark}`,
      backgroundColor: state.isSelected ? `${colors.rayloBlue}` : state.isFocused ? `${colors.rayloBlue}` : `${colors.rayloPink}`,
      fontFamily: 'Raylo-Regular, sans-serif',
      fontSize: 14,
      lineHeight: 2.14,
      '&:active': {
        backgroundColor: `${colors.rayloBlue}`,
      },

      '@media screen and (max-width: 768px)': {
        fontSize: 16,
      }
    })
  }
}

export const DownArrow = () => {
  return <i style={{ display: 'inline-block', width: 12, height: 7, backgroundImage: `url(${pinkDownArrow})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} />;
}

export const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <DownArrow />
      </components.DropdownIndicator>
    )
  );
};

export const RayloSelect = ({ errorMessage, label, placeholder, options, tooltip, field, noError = false}) => {

  const [isFocused, setIsFocused] = useState(false)

  const {error} = field

  const toggle = () => setIsFocused(!isFocused)

  const onChange = (data) => {
    field.onChange({
      target: {
        value: data.value
      }
    })
  }

  const value = _.find(options, {value: field.value})

  return (
    <I.RayloFormRow>
      <I.RayloFieldHeader>
        {label && 
          <I.RayloFieldLabel isFocused={isFocused}>
            {label}
          </I.RayloFieldLabel>
        }
        {tooltip && process.env.NODE_ENV !== 'test' &&
          <Tooltip direction="left">{tooltip}</Tooltip>
        }
      </I.RayloFieldHeader>
    

      <Select
        options={options}
        styles={customStyles(error)}
        components={{ DropdownIndicator }}
        classNamePrefix="react-select"
        placeholder={placeholder}
        onFocus={toggle}
        onBlur={toggle}
        {...field}
        value={value}
        onChange={onChange}
        captureMenuScroll
        isSearchable={false}
      />

      {!noError && <I.RayloError visible={error}>
        {errorMessage || <span>&nbsp;</span>}
      </I.RayloError>}
    </I.RayloFormRow>
  )
}
