import { breakpoints } from 'styles/breakpoints';
import { secondaryColors } from 'styles/variables/colors';

import styled from 'styled-components';

const StyledCarouselComponent = styled.div`
  .react-multi-carousel-track {
    padding: 16px 8px 48px 16px;
  }
  .react-multi-carousel-dot button {
    border: 0;
    background: ${secondaryColors.c04};
  }
  .react-multi-carousel-dot--active button {
    background: ${secondaryColors.c02};
  }
  @media (max-width: ${breakpoints.tablet}px) {
    .react-multi-carousel-track {
      padding: 16px 0 48px 0;
    }
  }
`;

export { StyledCarouselComponent };
