import React from 'react'
import * as LT from 'styles/legalText'
import Currency from 'react-currency-formatter'
import dayjs from 'dayjs'

import _ from "lodash"
import { useUiContext } from 'utils/useUiContext'


export const HireAgreement = ({checkout, phone, noInsurance}) => {

  const {lightMode} = useUiContext();

  return (
    <LT.RayloLegalText lightMode={lightMode}>
      <h1>Hire Agreement regulated by the Consumer Credit Act 1974</h1>
      <p>Between:</p>
      <p>Raylo Group Limited (trading as Raylo) of 5 New Street Square, London, United Kingdom EC4A 3TW and by email at help@raylo.com (we/us/the
        Owner), and</p>
      <p>Name: <span className="generated">{checkout?.customerInfo?.firstName} {checkout?.customerInfo?.lastName}</span></p>
      <p>Postal address: <span
        className="generated">{_.filter([checkout?.address.line1, checkout?.address.line2, checkout?.address.line3, checkout?.address.city, checkout?.address.region, checkout?.address.postcode], (o) => (o && o.length > 1)).join(", ")}</span>
      </p>
      <p>Email address: <span className="generated">{checkout?.customerInfo?.email}</span> (you/the Hirer).</p>
      <h2>Key Financial Information</h2>
      <p>The goods to be hired under this agreement are:</p>
      <p><span className="generated">{phone?.hireAgreementName}</span> (the Goods)</p>
      <p>Advance payment: <span className="generated"><Currency quantity={phone?.payToday}
                                                                currency={phone?.currency}/></span>. To be made on
        entering into this agreement.
      </p>
      <p>Payments to be made by you:</p>
      <p>The first Hire Payment of <span className="generated"><Currency quantity={phone?.payMonthly}
                                                                         currency={phone?.currency}/></span> is due on
        the day falling one month after the Dispatch Date or the next working day thereafter (we will advise you of the
        specific date by email), followed, starting one month later, by a minimum of {phone?.lastRecurringPeriod} consecutive monthly payments
        of <span className="generated"><Currency quantity={phone?.payMonthly} currency={phone?.currency}/></span>. Hire
        Payments will continue to be due (in the same amount and at the same frequency) for any continuation of the
        hiring after the end of the minimum period until the agreement has been terminated in accordance with Clause 9.
      </p>
      <p>Hire Payments include VAT.</p>
      <p>The Goods are to be hired under this agreement for a minimum period expiring {phone?.termLength} months from the Dispatch
        Date.</p>
      <h2>Key information</h2>
      <p>We may charge a late payment fee of up to &pound;19 if any payment due under this agreement is not made on time.</p>
      <p>We may charge you for all reasonable costs and expenses incurred by us as a result of you breaching this
        agreement, in enforcing any term of this agreement, or in carrying out administrative work at your request. See
        clause 3.</p>
      <p>When returned to us, the Goods must be in good condition. If they are not, a charge will be payable. See clause
        7.</p>
      <p>If we terminate this agreement under clause 9 or you terminate it after the expiry of the 30 day cancellation
        period under clause 10 you must pay to us the Early Termination Charge/Early Cancellation Charge calculated in
        accordance with those clauses respectively.</p>
      <p>If you cancel this agreement during the 30 day cancellation period, or terminate it after the expiry of this
        period (in each case, under clause 10) you will have to bear the cost of returning the Goods to us.</p>
      <p>This agreement is not cancellable under the Consumer Credit Act 1974. </p>
      <h2>MISSING PAYMENTS</h2>
      <p>Missing payments could have severe consequences and may make obtaining credit more difficult.</p>
      <h2>IMPORTANT &ndash; READ THIS CAREFULLY TO FIND OUT ABOUT YOUR RIGHTS</h2>


      {phone?.isTermLengthOverOrEqual18Months ?
        <>
          <p>The Consumer Credit Act 1974 (the Act) lays down certain requirements for your protection which should have been
            complied with when this agreement was made. If they were not, the Owner cannot enforce this agreement against 
            you without getting a court order.

          </p>

          <p>The Act also gives you a number of rights. You can end this agreement by writing to the person you make
            your payments to and giving at least 1 month&rsquo;s notice. In order to do this the agreement must have
            been
            allowed to run for at least 18 months though this may include the period of notice. You will have to make
            all
            payments and pay any amounts you owe until the date the agreement comes to an end.
          </p>
        </>
        :
        <>
          <p>The Consumer Credit Act 1974 (the Act) lays down certain requirements for your protection which should have been
            complied
            with when this agreement was made. If they were not, the Owner cannot enforce this agreement against you
            without getting a court order.
          </p>
        </>
      }

      <p>If you would like to know more about your rights under the Act, contact either your local Trading Standards
        Department or your nearest Citizens&rsquo; Advice Bureau.</p>
      <table className="important">
        <tbody>
        <tr>
          <td>
            <p>This is a Hire Agreement regulated by the Consumer Credit Act 1974. Sign it only if you want to be
              legally bound by its terms.</p>
            <p>Signature(s)</p>
            <p>Of Hirer(s)</p>
            <p>Date(s) of signature(s) <span className="generated">{dayjs().format('DD MMMM YYYY')}</span></p>
            <p>Under this agreement the goods do not become your property and you must not sell them.</p>
            <p>To confirm your signature of this agreement click the checkbox: &ldquo;I accept the Subscription T&Cs and agree to a credit check"
              and then click &ldquo;Place order&rdquo;. </p>
          </td>
        </tr>
        </tbody>
      </table>
      <p>The agreement will not be made until we sign it. Our signing the agreement is conditional on you passing our
        credit checks.</p>

      {noInsurance ?
        <>
          <p>We strongly suggest that you have insurance for the Goods. In all scenarios (including where you have
            insurance cover through a packaged bank account) you should inform the insurer that you are hiring the
            Goods from us.</p>
          <p>You have rights to cancel this agreement under the Consumer Contracts (Information, Cancellation and
            Additional Charges) Regulations 2013 as set out below.</p>
        </>

        :
        <>
          <p>You have rights to cancel this agreement under the Consumer Contracts (Information, Cancellation and
            Additional Charges) Regulations 2013 as set out below.</p>
        </>
      }
      <p>Signature on behalf of</p>
      <p>The Owner</p>
      <p>DIRECTOR</p>
      <p>Date of this agreement <span className="generated">{dayjs().format('DD MMMM YYYY')}</span></p>
      <p>The following terms and conditions apply.</p>
      <p></p>
      <h2>Terms and conditions</h2>
      <h3>1. Definitions</h3>
      <p>&ldquo;<strong>you</strong>&rdquo; and &ldquo;<strong>your</strong>&rdquo; means you, our customer. </p>
      <p>&ldquo;<strong>we</strong>&rdquo;, &ldquo;<strong>us</strong>&rdquo; and &ldquo;
        <strong>our</strong>&rdquo; means Raylo Group Limited, trading as Raylo, whose registered office is at 5 New
        Street Square, London, United Kingdom EC4A 3TW. We are registered in England and Wales under company
        registration number 11554120. Our registered VAT number is 316384111. Our Financial Conduct Authority reference
        number is 841488.</p>
      <p>&ldquo;<strong>Dispatch Date</strong>&rdquo; means the date on which we dispatch the Goods to you.</p>
      <p>&ldquo;<strong>Early Cancellation Charge</strong>&rdquo; means the charge you may have to pay if you end all or
        part of your hire during the term of this agreement as described in clause 10 (Cancellation). </p>
      <p>&ldquo;<strong>Early Termination Charge</strong>&rdquo; means the charge you may have to pay if we end all or
        part of your hire during the term of this agreement, as described in clause 9 (Default and Termination).</p>
      <p>&ldquo;<strong>Goods</strong>&rdquo; means the goods meeting the specification set out in your
        order confirmation, renewal or substitution of that we may agree with you.</p>
      <p>&ldquo;<strong>Hire Payment</strong>&rdquo; means the monthly hire payment set out on the first page of this
        agreement.</p>
      <h3>2. Hire of Goods</h3>
      <p>Unless terminated in accordance with Clause 9 (Default and Termination) or Clause 10 (Cancellation) we agree to
        lease, and you agree to take on hire, the Goods described above for a minimum period which expires {phone?.termLength} months
        from the Dispatch Date.</p>
      <p>The hire of the Goods will continue after the minimum period unless and until this agreement is terminated in
        accordance with Clause 9 (Default and Termination) or Clause 10 (Cancellation). The Goods remain our property and you shall not become the
        owner.</p>
      <h3>3. Hire Payments</h3>
      <p>You agree to pay to us, without previous demand, the Hire Payments in the amount set out above and any other
        sums due from you at the times shown for the duration of the minimum period and thereafter until the agreement
        has been terminated in accordance with Clause 9 (Default and Termination) or Clause 10 (Cancellation). The Hire Payments will continue to be
        due notwithstanding any temporary unavailability of the Goods (for example, where they are being repaired).</p>
      <p>Punctual payment by you is an essential term of this agreement.</p>
      <p>If VAT is payable on any other sums due by you under this agreement, you will pay that VAT at the applicable rate.  
        If the rate of VAT changes, corresponding changes in the amounts payable will be made.</p>
      <p>Unless we agree otherwise, payments (including any Repair Fees incurred) must be made by direct debit. We may
        at our discretion allow you to pay by debit card or bank transfer.</p>
      <p>We may request you to provide credit or debit card details. In providing these you irrevocably authorise us to
        charge to such card any amounts due under this agreement in the event that an attempt to obtain them by direct
        debit fails.</p>
      <p>You shall agree to indemnify us for:</p>
      <p>(a) all reasonable costs and expenses incurred by us (both before and after judgment) as a result of you
        breaching this agreement and/or in our enforcing any term of this agreement;</p>
      <p>(b) all reasonable costs and expenses incurred by us for any administrative work carried out by us at your
        request in connection with this agreement. We shall tell you the amount that we are going to charge for such administrative work 
        before we carry out the work,  and obtain your consent to us carrying out the work before the fee is incurred.</p>
      <h3>4. Late Payment Fee</h3>
      <p>We may apply a late payment fee of up to &pound;19 every time that a payment is not paid or made under this agreement
        by its due date.</p>
      <h3>5. Delivery</h3>
      <p>We will use reasonable endeavours to deliver the Goods to you on the date specified when you placed your order.</p>

      <p>If delivery of the Goods is delayed by an event outside our control, we will contact you as soon as possible to let you 
        know and take the appropriate steps to minimise the effect of the delay where possible. Provided we do this, we will not 
        be liable for delays caused by the event, but you may contact us to end this agreement if the delay is longer than 30 days 
        from the date specified when you placed your order.</p>

      <h3>6. Your obligations</h3>
      <p>You must:</p>
      <p>- use the Goods in accordance with the manufacturer&rsquo;s instructions;</p>

      {noInsurance ?
        <p>- take reasonable care of the Goods. You are responsible for any loss or damage.</p>
        :
        <p>- take reasonable care of the Goods (including using a case and screen protector if they can be fitted to the Goods), 
          a failure to do so could invalidate an insurance claim in respect of accidental damage to, or loss or theft of, the Goods;  
        </p>
      }
      <p>return (or allow us to collect) the Goods when reasonably requested to do so in accordance with this agreement 
        (for example upon the termination or cancellation or expiry of this agreement, or where a repair or replacement of 
        the Goods is requested); and</p>
      <p>- immediately notify us if the Goods have been lost or stolen or significantly damaged. </p>
      <p>You must not:</p>
      <p>sell, trade-in, sub-loan, part with, pledge, pawn or otherwise dispose of the Goods (or attempt to do so), 
        nor permit them to be seized by any third party, or allow third party rights to be created over them; or</p>
      <p>make any modifications or alterations to the Goods or attempt to alter or replace any installed operating 
        software of the Goods (other than by applying manufacturer supplied software updates).  </p>
      <p>You may temporarily remove the Goods from the
        UK, {noInsurance ? null : "subject to the terms of our insurance cover,"} provided that the Goods are returned
        to the UK. If we ask you, you must tell us where the Goods are, within 7 working days of our written
        request.</p>
      <p>We will register the IMEI or serial number of the Goods with loss and theft databases. This means that we will be alerted 
        if someone attempts to sell the Goods. In the event the Goods are reported lost or stolen, or go missing, we will make 
        appropriate notifications of the IMEI or serial number to prevent use or resale.</p>
      <p>The agreement will commence when it is electronically signed by both you and us. It will continue for a minimum
        period ending {phone?.termLength} months from the Dispatch Date, subject to termination / cancellation pursuant to clauses 9
        (Default and Termination) and 10 &nbsp;(Cancellation) and your statutory rights, at the end of which you may
        either return the Goods to us (at which point this agreement will terminate), or, if you do not, the agreement
        will continue on a rolling monthly contract basis (during which time Hire Payments will continue to be payable
        by you and taken by direct debit at the same rate and frequency set out above until ended by you or us on at
        least one month&rsquo;s notice).</p>



      <h3>7. Return of the Goods</h3>
      <p>If you cancel this agreement within the cooling off period of 30 days after the agreement is made in accordance
        with clause 10 you must return the Goods to us within 30 days from the point of cancellation. You will have to
        bear the cost of returning the Goods to us. Please see clause 10 below for further information on the return
        of the Goods in these circumstances.</p>
      <p>You must return the Goods to us within 14 days of the end of this agreement, including if you or we terminate 
        the agreement under clause 9 or if you terminate it after the expiry of the 30 day cancellation period but
         before the end of the minimum period of hire under clause 10. 
         If you fail to return the Goods within such 14 day period, then you may be charged a 
         full Hire Payment for each month (or part month) which elapses until either the Goods are actually returned to us, 
         or we charge a fee as compensation for you failing to return the Goods to us as provided for below.</p>
      <p>Instructions on how to prepare the Goods for return will be provided to you and can also be found online at
        www.raylo.com/returns. </p>
      <p>Before returning the Goods, where applicable, you must ensure that all data stored on the Goods has been erased, that you have logged
        out of any personal accounts (e.g. iCloud account), that the Goods have been reset to factory settings, and that any 
        security features (including any password, passcode, pattern, biometric security or remote protection
        features eg &lsquo;find my iPhone&rsquo;) are disabled. Failure to do so will result in additional charges 
        to compensate us for damage to the Goods (see Schedule 1 (Repair Fees) for further details).</p>


      <p>If when the Goods are due to be returned you cannot do so as they have been lost, stolen or damaged beyond repair, 
        then we may agree with you that you may substitute and return other goods of the same model and specification in their place. 
        To be able to agree to the substitution, we require confirmation of the unique identifier of the new goods (the IMEI if the Goods have an IMEI, 
        or otherwise the serial number) from you, and by providing the unique identifier you will be transferring legal title to Raylo. </p>


      <p>If you return the Goods to us at the end of or upon termination/cancellation of this agreement in a damaged
        condition, you agree to compensate us for the value of the Goods in line with the Repair Charges set out in
        Schedule 1 of this agreement within 7 days of our written request.</p>

      <p>We may charge you the following fees as compensation if you fail to return the Goods to us in the following
        periods after the Dispatch Date:</p>


      <table className="non-return-fees">
        <tbody>
        <tr>
          <th>Between 1 and {phone?.feeSummary?.earlyNonReturnFeeChargeLength} months</th>
          <th>Between {phone?.feeSummary?.earlyNonReturnFeeChargeLength} and {phone?.feeSummary?.nonReturnFeeChargeLength + 1} months</th>
          <th>Between {phone?.feeSummary?.nonReturnFeeChargeLength + 1} months</th>
        </tr>
        <tr>
          <td>
            <p className="centered"><span className="generated centered">{phone?.feeSummary?.earlyNonReturnFee ? phone?.feeSummary?.earlyNonReturnFee?.formattedValue : "n/a"}</span></p>
          </td>
          <td>
            <p className="centered"><span className="generated centered">{phone?.feeSummary?.nonReturnFee ? phone?.feeSummary?.nonReturnFee?.formattedValue : "n/a"}</span></p>
          </td>

          <td>
            <p className="centered"><span className="generated centered"><Currency quantity={1}
                                                                                   currency={phone?.currency}/></span>
            </p>
          </td>
        </tr>
        </tbody>
      </table>


      <p>Failure to return the Goods may result in legal steps being taken against you to recover the Goods or
        compensate us for the loss of (or damage to) the Goods. These steps may include:
      </p>

      <p>(a) Reporting to third party credit reference agencies which could adversely affect your credit records, which
        may make it more difficult or more expensive to obtain credit in the future, and/ or</p>
      <p>(b) Taking legal action (which may result in a County Court Judgement, or &ldquo;CCJ&rdquo; against you) and
        the appointment of High Court Enforcement Officers to collect any sums awarded to us. </p>
      <p>If the Goods you return to us are damaged, we may charge you for the damage in line with the Repair Fees set
        out in Schedule 1 of this agreement.</p>


      {noInsurance ?
        <>
          <h3>8. Loss or damage to the Goods</h3>
          <p>You are responsible for any loss of or damage to the Goods from the date they are delivered to you until the date they are returned to us. 
            We strongly suggest that you consider insuring the Goods, or checking that any current
            cover you have is sufficient as, even if you do not, you will remain responsible for paying the costs of any
            repairs or replacement.</p>
        </>
        : <>
          <h3>8. Substitution of the Goods</h3>
          <p>In the event that the Goods are lost, stolen or damaged beyond economic repair (as determined by us, or
            our insurers) or are otherwise unavailable we may, subject to our making a successful claim in respect of
            such loss or damage under the insurance policy referred to at clause 11 below and the other terms of this
            agreement (including the payment by you of any Repair Fee or excess &ndash; if applicable), supply you with
             replacement Goods.</p>
          <p>Where possible we will endeavour to supply you with replacement Goods which is equivalent to the
            specification of the Goods stated in your order confirmation, however in circumstances where sourcing a
            direct replacement is not reasonably practicable (for example, where the model of Goods has been recalled
            by, or is no longer available from, the manufacturer) we reserve the right to provide replacement Goods
            which are different from that stated in your order confirmation. </p>
          <p>Where we make such a substitution we will endeavour to ensure that the substitute Goods are as close to
            the original Goods (in terms of make, functionality and specification) as possible. </p>
          <p>Where any claim in respect of damage (referred to in this Clause 8) is not successful or possible under the
            insurance policy referred to at clause 11 below, then you agree to compensate us in line with the Repair
            Fees set out in Schedule 1 of this agreement. Where any claim in respect of loss or theft is not successful
            or possible under the insurance policy then you agree to pay the reasonable cost of replacement
            Goods.</p>
        </>
      }
      <h3>9. Default, termination and other things we may do</h3>
      <p>Any of the rights that we exercise under this clause are subject to the provisions of the Consumer Credit Act
        1974, and subject to any appropriate notice that we are required to serve on you under that Act.</p>

      <p>We have the right to terminate this agreement if any of the events specified in paragraphs (a) to (k) below
        occur. We will contact you in writing if we terminate the agreement. </p>
      <p>(a) We reasonably believe that the Goods have been used in a way which is not allowed under this agreement
        (although for breaches that you can put right, we may first give you an opportunity to do so within 14
        days);</p>
      <p>(b) You have provided unauthorised payment or other details or we have reasonable grounds to suspect fraud,
        attempted fraud or any other unauthorised activity;</p>
      <p>(c) You or anyone you authorise to deal with your account acts in a way towards our staff or agents which we
        reasonably consider to be inappropriate and sufficiently serious to justify restricting or ending your right to
        hire the Goods;</p>
      <p>(d) You have not paid any Hire Payment within 7 days of its due date; </p>
      <p>(e) You break any of the conditions of this contract, other than breaches that are trivial in nature  (although for breaches that you can put right we may first
        give you an opportunity to do so within 14 days). For serious breaches we will normally exercise this right
        immediately; </p>
      <p>(f) You have provided us {noInsurance ? null : "(or, with respect to any insurance claim, our insurer)"} with
        information that is false or misleading in a material respect;</p>
      <p>(g) Any judgment is obtained against you and remains unsatisfied for more than 7 days or any distress or
        execution is levied against your property;</p>
      <p>(h) You die;</p>
      <p>(i) You cease to be a resident of the UK;</p>
      <p>(j) You cease to have a UK bank account.</p>
      <p>(k) We ask you to provide us with the location of the Goods and you do not do so.</p>
      <p>(l) We reasonably believe that there is a credible risk that the Goods will not be returned to us at the end
        of or upon termination of the agreement when or in the state they should be so returned.</p>
      <p>If any of the events specified above apply and we terminate the agreement you will no longer be in possession of the Goods with our consent. We may also:</p>
      <p>(a) Demand the prompt return of the Goods; </p>
      <p>(b) Prevent the use or resale of the Goods (by making a notification of the IMEI or serial number of the Goods);</p>
      <p>(c) Where the Goods have not yet been delivered, withhold or withdraw such delivery.</p>
      <p>(d) Recover from you the sum, calculated as at the date of termination, equal to the aggregate of:</p>
      <p>(i) Any sums due under this agreement that are in arrears; </p>
      <p>(ii) Any other sum due to us under the agreement at termination; and</p>
      {phone?.isTermLengthOverOrEqual18Months ?
        <>
          <p> (iii) An Early Termination Charge to compensate us for the early termination of this agreement. 
            The Early Termination Charge will be calculated as the balance of Hire Payments that would be payable up 
            to a term of eighteen (18) months had the agreement not been terminated by us.</p>
        </>
        :
        <>
          <p> (iii) An Early Termination Charge to compensate us for the early termination of this agreement. The Early
            Termination Charge will be calculated as the balance of Hire Payments that would have been payable up to the
            end of the minimum period of hire had the agreement not been terminated by us.</p>
        </>
      }
      <p>If any of the events specified above apply, we may also exercise our right to prevent the use or resale of the Goods 
        (by making notification of the IMEI or serial number of the Goods) without first terminating the agreement, as long as we 
        reasonably believe that this is necessary to prevent the potential loss, sale, destruction of, or damage to the Goods.  
        This may have the effect of preventing you using the Goods.  We will notify you before we do this, and we strongly recommend 
        that you contact us to discuss your account in these circumstances.</p>
      <p>If you wish to terminate this agreement on or after the end of the minimum term you must give us a minimum of
        one month's notice and return the Goods to us in accordance with Clause 7 (Return of the Goods).</p>
      <p>On or after the end of the minimum term we may also terminate this agreement on one month&rsquo;s notice, and
        require you to return the Goods to us in accordance with Clause 7 (Return of the Goods). </p>
      <h3>10. Cancellation </h3>
      <p>You can cancel this agreement within the period which ends 30 days after the date this agreement is made without 
        incurring any charge if you return at your own cost the Goods to us in their original, unopened packaging within 30 days 
        following the day you notify us.</p>
      <p>After the expiry of the 30 day cancellation period you must give us a minimum of one month's notice if you wish
        to terminate this agreement and return the Goods to us in accordance with clause 7 (Return of the Goods). You may also have to pay
        us an Early Cancellation Charge calculated as the balance of Hire Payments would be payable up to the end of the
        minimum period of hire.</p>
      <p>You may cancel this agreement by:</p>
      <p>(a) calling us on 0333 050 9880;</p>
      <p>(b) emailing us at help@raylo.com quoting your name, home address, phone number and details of the order;
        or</p>
      <p>(c) contacting us via the chat application on our website quoting your name, home address, phone number and
        details of the order. </p>
      {noInsurance ? null : <>
        <h3>11. Insurance</h3>
        <p>You are responsible for all damage to, or loss of, the Goods from the date that it is provided to you. </p>
        <p>As part of the hire we will give you an interest in (but, for the avoidance of doubt, no rights under) our
          insurance cover which provides cover for the Goods against loss, theft or accidental damage. If your claim
          on our behalf is successful there will be an excess payable by you. You will not be able to claim on our
          behalf on the insurance unless your Hire Payments are up to date. A summary of our policy, in accordance with
          which claims will be assessed, can be found at www.raylo.com/legal.</p>
        <p>If it becomes impossible or uneconomic for us to continue to provide you with an interest in our policy then
          we may at our option cease to provide the same. If we do then we will give you 14 days&rsquo; notice of this,
          and if you wish, allow you to terminate this agreement by returning the Goods without further liability
          (other than any Repair Fees due in accordance with Schedule 1 relating to the condition in which the Goods
          are returned). </p>
        <p>You must notify us immediately in the event that the Goods is lost, stolen or sustains significant damage.
          If the Goods is stolen you must contact the police as soon as possible and obtain a crime reference
          number.</p></>
      }
      <h3>{noInsurance ? "11" : "12"}. Complaints</h3>
      <p>Complaints will be handled in accordance with Raylo&rsquo;s Complaints Policy, a copy of which is available on
        request.</p>
      <p>You may make a complaint by: </p>
      <p>- calling us on 0333 050 9880 </p>
      <p>- emailing us at complaints@raylo.com; or</p>
      <p>- writing to us at Freepost RAYLO.</p>
      <p>However you choose to contact us, we will aim to resolve the issue promptly. </p>
      <p>If the issue is more complex or we can&rsquo;t resolve the problem promptly: </p>
      <p>- We will send you a letter to acknowledge your complaint. We may also contact you to obtain further information relating to your complaint. </p>
      <p>- Once we have completed our investigation into your complaint we will send you a final response letter. This
        will confirm our decision on your complaint, what we propose do to put things right, and information about the
        Financial Ombudsman Service. </p>
      <h3>{noInsurance ? "12" : "13"}. Your right to complain to the Financial Ombudsman Service</h3>
      <p>Where you are not satisfied with our final response and where you are eligible, you will have the right to refer
        your case to the Financial Ombudsman Service (FOS). Their address is: Financial Ombudsman Service, Exchange
        Tower, London, E14 9SR. </p>
      <p>More information about the service can be found on their website www.financial &ndash;ombudsman.org.uk or by
        calling them on free phone 0800 023 4567, or emailing them at complaint.info@financial-ombudman.org.uk. FOS also
        provide an online complaint form which is available on their website at
        https://www.financial-ombudsman.org.uk/contact-us/complain-online </p>
      <p></p>
      <h3>{noInsurance ? "13" : "14"}. Other important terms</h3>
      <p>We will use, store and process your personal data in accordance with our Privacy Policy, which is
        available at: www.raylo.com/legal. </p>
      <p>Any choice not to exercise our rights against you (either in full or in part) or to grant you any concession,
        will not in any way constitute a waiver of such rights or a variation of this agreement. Any and all waivers and
        variations must be in writing. </p>
      <p>Each of the paragraphs of these terms operates separately. If any court or relevant authority decides that any
        of them are unlawful, the remaining paragraphs will remain in full force and effect.</p>
      <p>This agreement constitutes the entire agreement between us. You acknowledge that in entering into this
        agreement, you are not relying on any statement, representation, assurance or warranty that is not set out in
        the agreement.</p>
      <p>Our rights arising before the termination or expiry of this agreement (howsoever arising) will remain in force,
        notwithstanding any such termination or expiry.</p>
      <p>We may transfer ownership of the Goods and/or assign any of our rights under this agreement to any person,
        provided that your rights under this agreement will not be adversely affected by any such transfer. </p>
      <p>Your rights and duties under this agreement cannot be transferred without our prior written consent.</p>
      <p>This contract is between you and us. No other person shall have any rights to enforce any of its terms (unless
        those rights have been assigned to them) and no third party is entitled to benefit under this contract under the
        Contracts (Rights of Third Parties) Act 1999.</p>
      <p>This agreement is governed by English law (unless you live in Scotland or Northern Ireland, in which case it
        will be governed by Scots law or Northern Ireland law, as applicable). You or we can bring legal proceedings in
        the courts of England and Wales who have exclusive jurisdiction, unless you live in Scotland in which case you
        or we can bring legal proceedings in either the Scottish or the English courts or if you live in Northern
        Ireland you or we can bring legal proceedings in either the Northern Irish or the English courts. </p>
      <h2>Schedule 1 &ndash; Repair Fees</h2>
      <table className="damage">
        <tbody>
        <tr>
          <th>Description</th>
          <th>Repair Fee</th>
        </tr>
        <tr>
          <td>
            <p className="centered"><strong>Fair Wear & Tear (Grade C)</strong> &nbsp;</p>
            <p>Obvious signs of use such as some deep scratching (2 or fewer deep scratches on front glass that are less
              than 3cm in length), small dents, discolouring or changes in the paintwork, but no cracks anywhere on the
              Goods.</p>
          </td>
          <td>
            <p><strong>No charge</strong></p>
          </td>
        </tr>
        <tr>
          <td>
            <p className="centered"><strong>Significant Damage (Grade D)</strong> &nbsp;</p>

            <p>Some level of cosmetic damage such as significant deep scratching, chips, cracks and/or more significant
              dents.
              Or may be in the visual condition of Grade C, but has a functional fault identified during our testing
              process.</p>
            <p><strong>Functional fault examples:</strong></p>
            <p>&bull; Broken Speaker or Earphone </p>
            <p>&bull; 3 or more deep scratches greater than 2cm</p>
            <p>&bull; Water Damage (still functional)</p>
            <p>&bull; Damaged/Non-functioning buttons, ports, sim tray</p>
            <p>&bull; Cracked Screen/Screen burn (still fully functional)</p>
            <p>&bull; Display popped/screen displaced</p>
            <p>&bull; Broken Camera</p>
          </td>
          <td>
            <p>{phone?.feeSummary?.gradeDDamageFee ? <span className="generated">{phone?.feeSummary?.gradeDDamageFee?.formattedValue}</span> : "n/a"}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p className="centered"><strong>Locked or Major Functional Damage (Grade E)</strong> &nbsp;</p>

            <p>May be in the visual condition of Grade C or D but all data have not been deleted; or the Goods are logged in to any of your personal accounts (e.g. your Apple ID); or any phone security features (including any user set password, passcode, pattern, biometric security or remote protection features such as ‘find my iPhone’) have not been disabled.</p>
            <p>or</p>
            <p>The Goods have a major functional fault identified during our testing process.</p>
            <p><strong>Major functional fault examples:</strong></p>
            <p>&bull; LCD Bleed/LCD Damage</p>
            <p>&bull; Won&rsquo;t Power on</p>
            <p>&bull; Touch ID fault</p>
            <p>&bull; Water damage (unit does not function)</p>
            <p>&bull; Goods are bent</p>
            <p>&bull; Engraving on the Goods</p>
            <p>&bull; Broken or missing battery</p>
            <p>&bull; Software Issues &ndash; If the Goods freeze, will not start up or any software component failing</p>
          </td>
          <td>
            <p>{phone?.feeSummary?.gradeEDamageFee ? <span className="generated">{phone?.feeSummary.gradeEDamageFee.formattedValue}</span> : "n/a"}
            </p>
          </td>
        </tr>
        </tbody>
      </table>
    </LT.RayloLegalText>
  )
}
