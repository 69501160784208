import React, { useState } from 'react';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Button } from 'elements/Button/Button';
import { StripeError } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import { captureErrorEvent } from 'utils/errorTracking';
import Spacer from '../../elements/Spacer';
import { ButtonTypes } from '../../elements/Button/types';
import { FadeIn } from '../../components/animations/Transitions';
import { px2Rem } from '../../utils/px2Rem';
import { IPaymentForm } from './paymentForm.interface';
import { uiStateColors } from '../../styles/variables/colors';

export const PaymentForm = ({ successUrl }: IPaymentForm) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const enableButtonOnCompletion = (element: any) => {
    if (element.complete) {
      setSubmitDisabled(false);
      return;
    }
    setSubmitDisabled(true);
  };

  const handleSubmit = async (event: any | null) => {
    event?.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setErrorMessage('');
    setSubmitDisabled(true);

    await stripe
      .confirmSetup({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}${successUrl}`,
        },
      })
      .then((value: { error: StripeError }) => {
        if (value.error) {
          if (value.error.setup_intent?.status === 'succeeded') {
            history.push(successUrl);
            return;
          }

          if (value.error.type === 'rate_limit_error') {
            setTimeout(() => {
              handleSubmit(null);
            }, 1050);
          } else if (value.error.type === 'card_error') {
            setErrorMessage(
              'Please try again ensuring your card details are correct and that you have sufficient funds in your account. If your payment is still declined please speak with your bank.',
            );
            setSubmitDisabled(false);
          } else {
            captureErrorEvent('unhandled Stripe Error', value.error);
          }
        }
      });
  };

  return (
    <form>
      <FadeIn>
        <div style={{ minHeight: px2Rem(330) }}>
          <PaymentElement
            onChange={(element) => enableButtonOnCompletion(element)}
          />
        </div>
      </FadeIn>
      <Spacer height={24} />
      <div
        style={{
          width: '100%',
        }}
      >
        <Button
          dataTestId="payNowButtonOnForm"
          disabled={submitDisabled}
          size="large"
          buttonType="solid"
          buttonColor={ButtonTypes.b01}
          onClick={handleSubmit}
          styles={`
              display: inherit;
              margin-left: auto;
              padding: ${px2Rem(20.5)} ${px2Rem(67.5)};
              width: 100%;
            `}
        >
          Pay Now
        </Button>
      </div>
      <div
        style={{
          color: uiStateColors.error,
          fontSize: px2Rem(14),
          width: '100%',
          marginTop: px2Rem(10),
        }}
      >
        {errorMessage}
      </div>
    </form>
  );
};
