import Button from 'elements/Button';
import Container from 'elements/Container';
import Header from 'elements/Header';
import { secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import {
  ButtonWrapper,
  HeaderWrapper,
  StyledMobileCheckoutHeader,
} from './styles';
import { IMobileCheckoutHeader } from './types';

const MobileCheckoutHeader = ({
  text,
  isRefurbished,
  buttonText,
  buttonOnClick,
}: IMobileCheckoutHeader) => {
  return (
    <StyledMobileCheckoutHeader>
      <Container
        height={px2Rem(60)}
        flexRow
        centerVertical
        padding={`${px2Rem(0)} ${px2Rem(24)}`}
        spaceBetween
      >
        <HeaderWrapper>
          <Header fontSize={16} level={3} subColor={secondaryColors.b04}>
            {isRefurbished && <span>Refurbished</span>}
            {` ${text}`}
          </Header>
        </HeaderWrapper>
        <ButtonWrapper>
          <Button
            size="xsmall"
            text={buttonText}
            buttonType="ghost"
            active
            onClick={buttonOnClick}
          />
        </ButtonWrapper>
      </Container>
    </StyledMobileCheckoutHeader>
  );
};

export default MobileCheckoutHeader;
