import RayloModal from 'components/RayloModal';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Spacer from 'elements/Spacer';
import TextLink from 'elements/TextLink';
import { primaryColors } from 'styles/variables/colors';
import { useUiContext } from 'utils/useUiContext';
import { IDirectDebitContactModal } from './types';

export const DirectDebitContactModal = ({
  dataTestId,
}: IDirectDebitContactModal) => {
  const { setShowModal, setShowDDContactModal } = useUiContext();
  const handleModalClose = () => {
    setShowModal(false);
    setShowDDContactModal(false);
  };
  return (
    <RayloModal
      header="Contact Us"
      onClose={handleModalClose}
      dataTestId={dataTestId}
    >
      <Container noPadding>
        <Copy color={primaryColors.c01} lineHeight={22}>
          If you have any questions about your Direct Debit we&apos;re happy to
          help.
        </Copy>
        <Spacer height={16} />
        <Copy color={primaryColors.c01} lineHeight={22}>
          <strong>Email: </strong>
          <TextLink href="mailto:help@raylo.com">help@raylo.com</TextLink>
        </Copy>
        <Spacer height={16} />
        <Copy color={primaryColors.c01} lineHeight={22}>
          <strong>Phone: </strong>0333 050 9541
        </Copy>
        <Spacer height={16} />
        <Copy color={primaryColors.c01} lineHeight={22}>
          <strong>Post: </strong>5 New Street Square, London, EC4a 3TW
        </Copy>
        <Spacer height={32} />
      </Container>
    </RayloModal>
  );
};
