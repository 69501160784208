import Container from 'elements/Container';
import Copy from 'elements/Copy';
import RadioButtonBox from 'elements/RadioButtonBox';
import Spacer from 'elements/Spacer';
import { useEffect, useState } from 'react';
import { primaryColors } from 'styles/variables/colors';
import { isEvenNumber } from 'utils/isEvenNumber';
import { px2Rem } from 'utils/px2Rem';
import { StyledRadioButtonBoxGroup } from './styles';
import { IRadioButtonBoxGroup } from './types';

export const RadioButtonBoxGroup = ({
  dataTestId,
  options,
  flexRow,
  label,
  onChange,
  subCopy,
  initialActiveIndex,
}: IRadioButtonBoxGroup) => {
  const [activeIndex, setActiveindex] = useState(0);

  useEffect(() => {
    if (initialActiveIndex) {
      setActiveindex(initialActiveIndex);
    }
  }, []);

  const handleSelect = (index: number) => {
    setActiveindex(index);
    onChange(options[index]?.label, label);
  };

  return (
    <StyledRadioButtonBoxGroup data-testid={dataTestId}>
      <Copy bold color={primaryColors.c01}>
        {label}
      </Copy>
      <Spacer height={12} />
      {subCopy && subCopy}
      <Spacer height={24} />
      <Container flexRow={flexRow} noPadding>
        {options.map((option: any, i: number) => (
          <>
            <Container
              padding={
                isEvenNumber(i) ? `0 ${px2Rem(8)} 0 0` : `0 0 0 ${px2Rem(8)}`
              }
            >
              <RadioButtonBox
                label={option.label}
                active={activeIndex === i}
                index={i}
                onClick={handleSelect}
                large={option.large}
              />
            </Container>
            {!flexRow && <Spacer height={64} />}
          </>
        ))}
      </Container>
    </StyledRadioButtonBoxGroup>
  );
};
