import React from 'react';
import secureShield from 'public/icons/icon_secure_shield.svg';
import masterCard from 'public/icons/icon_mastercard.svg';
import visaCard from 'public/icons/icon_visa_card.svg';
import { RayloAcceptedCards } from 'styles/screens/Payment/AcceptedCards';
import Copy from '../../elements/Copy';
import { secondaryColors } from '../../styles/variables/colors';
import { px2Rem } from '../../utils/px2Rem';
import Divider from '../../elements/Divider';

export const AcceptedCards = () => {
  return (
    <RayloAcceptedCards>
      <div style={{ display: 'grid', gridTemplateColumns: '70% 30%' }}>
        <div>
          <img
            src={secureShield}
            alt="Secure payment shield icon"
            style={{ display: 'inline' }}
          />
          <Copy
            fontSize={14}
            color={secondaryColors.c02}
            bold
            styles={`
            display: inline
            vertical-align: top;
            margin-left: ${px2Rem(8)}
            line-height: 30px;
            `}
          >
            Secure payment
          </Copy>
        </div>
        <div style={{ textAlign: 'right' }}>
          <img
            src={visaCard}
            alt="Visa card icon"
            style={{ display: 'inline' }}
          />
          <img
            src={masterCard}
            alt="mastercard icon"
            style={{
              display: 'inline',
              marginLeft: px2Rem(12),
            }}
          />
        </div>
      </div>
      <Divider
        color={secondaryColors.c04}
        styles={`margin: ${px2Rem(24)} 0;`}
      />
    </RayloAcceptedCards>
  );
};
