import { ReactNode, useEffect, useState } from 'react';
import constate from 'constate';
import { Copy, Header, Spacer } from 'elements';
import { primaryColors } from 'styles/variables/colors';
import TextLink from 'elements/TextLink';
import { useUiContext } from 'utils/useUiContext';

export interface IHowItworks {
  step: number;
  icon: string;
  content: ReactNode;
}

const useHowItWorks = () => {
  const [howItWorksContent, setHowItWorksContent] = useState<IHowItworks[]>();

  const { setShowRPAfterLeaseModal } = useUiContext();

  useEffect(() => {
    setHowItWorksContent([
      {
        step: 1,
        icon: 'RPWallet',
        content: (
          <>
            <Header color={primaryColors.c01} level={3} fontSize={16}>
              Pay way less every month with no upfront
            </Header>
            <Spacer height={16} />
            <Copy color={primaryColors.c01} fontSize={16}>
              Leasing is the smart way of getting the tech you want at a much
              lower monthly price
            </Copy>
          </>
        ),
      },
      {
        step: 2,
        icon: 'RPTech',
        content: (
          <>
            <Header color={primaryColors.c01} level={3} fontSize={16}>
              Choose the tech and term you want
            </Header>
            <Spacer height={16} />
            <Copy color={primaryColors.c01} fontSize={16}>
              We only do the best tech at even better prices – and all phones
              are SIM-free.
            </Copy>
          </>
        ),
      },
      {
        step: 3,
        icon: 'RPRocket',
        content: (
          <>
            <Header color={primaryColors.c01} level={3} fontSize={16}>
              Upgrade time
            </Header>
            <Spacer height={16} />
            <Copy color={primaryColors.c01} fontSize={16}>
              At the end of your term, the choice is yours. Apply for a free
              upgrade and get the latest model, choose to keep your device or
              leave us by returning your device.
            </Copy>
            <Spacer height={16} />
            <Copy color={primaryColors.c01} fontSize={16}>
              <TextLink onClick={() => setShowRPAfterLeaseModal(true)}>
                Find out more
              </TextLink>
            </Copy>
          </>
        ),
      },
    ]);
  }, []);

  return {
    howItWorksContent,
  };
};

const [HowItWorksProvider, useHowItWorksContext] = constate(useHowItWorks);
export { HowItWorksProvider, useHowItWorksContext };
